import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByPosition'
})
export class SortByPositionPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value.sort( function ( a, b ) {
      return a.position - b.position;
    });
    return value;
  }

}
