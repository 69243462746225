<div class="wrap wingContainer" id="wingContainer" [ngClass]="{'hideMaps':isWingSelected}" fxLayout="column" fxLayout.gt-sm="row" fxLayoutWrap="wrap">
  <div class="cardWing cardWing2">
    <!-- <div class="cardWing2" [ngClass]="{'hideMaps':isWingSelected}"> -->
      <div class="cardHeaderAll" *ngIf="!reservedWingOnly" fxLayout="row" fxLayoutAlign="space-between center" (click)="wingDetail(wingSelectedData)">
        <!-- <div class="nameHeeader" fxLayout="row" fxLayoutAlign=" center"> -->
          <span class="nameHeeader">{{wingSelectedData.site_name}}, {{wingSelectedData.name}}</span>
        <!-- </div> -->
        <div class="actionBack" fxLayout="row" fxLayoutAlign="end center">
          <span class="fa fa-search-minus"></span>
          <!-- <span class="fa fa-search-minus">{{button.show_all}}</span> -->
        </div>
      </div>
      <div class="cardHeaderAll no-hover" *ngIf="reservedWingOnly"  fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <div class="nameHeeader" fxLayout="row" fxLayoutAlign=" center"> -->
          <span class="nameHeeader">{{wingSelectedData.site_name}}, {{wingSelectedData.name}}</span>
        <!-- </div> -->
        <div class="actionBack" fxLayout="row" fxLayoutAlign="end center">
          <span></span>
        </div>
      </div>
      <assets-map [isShowSlotsInfo]="isShowSlotsInfo" [isDoubleWingsView]="true" [wingID]="-1" [isReportingIssue]="isReportingIssue" [showAssetsWithoutAllAmenities]="showAssetsWithoutAllAmenities" [assetsSelecteble]="assetsSelecteble" [isTodoCheck]="isTodoCheck" [idSelect]="idSelect" [imageData]="wingSelectedData.image" [assetsData]="wingSelectedData.assets" (onSelectedAsset)="onSelectedAsset($event)"></assets-map>
    </div>
</div>
<div class="wrap wingContainer" id="wingContainer" [ngClass]="{'hideMaps':!isWingSelected}" fxLayout="column" fxLayout.gt-sm="row" fxLayoutWrap="wrap">
  <div class="cardWing" [ngClass]="{'two-maps-view': isTwoMaps}" *ngFor="let wingData of wingsData">
    <div class="cardHeaderAll" fxLayout="row" fxLayoutAlign="space-between center" (click)="wingDetail(wingData)" matTooltip="{{languages}}" matTooltipPosition="above">
      <span class="nameHeeader">{{wingData.site_name}}, {{wingData.name}}</span>
      <div class="actionBack" fxLayout="row" fxLayoutAlign="end center">
        <span class="fa fa-search-plus"></span>
        <!-- <span>{{button.select}}</span> -->
      </div>
    </div>
    <assets-map [isShowSlotsInfo]="isShowSlotsInfo" [isDoubleWingsView]="true" [wingID]="wingData.id" [isReportingIssue]="isReportingIssue" [showAssetsWithoutAllAmenities]="showAssetsWithoutAllAmenities" [assetsSelecteble]="assetsSelecteble" [idSelect]="idSelect" [imageData]="wingData.image" [isTodoCheck]="isTodoCheck" [assetsData]="wingData.assets" (onSelectedAsset)="onSelectedAsset($event)"></assets-map>
  </div>
</div>
