<div class="main-container">
  <h3>{{header}}</h3>

  <div class="input-container form-item" fxLayout="row" fxLayoutAlign="center center">
    <div class="search" [ngClass]="{'disabled': isBlocked}">
      <i class="fa fa-search"></i>
      <span *ngIf="isBlocked">Search</span>
      <input *ngIf="!isBlocked" type="text" placeholder="Search" [(ngModel)]="search">
    </div>
    <div *ngIf="!isBlocked" class="button green" (click)="new()"><i class="fa fa-plus"></i> New</div>
    <div *ngIf="isBlocked" class="button green disabled"><i class="fa fa-plus"></i> New</div>
  </div>

  <div *ngIf="isBlocked && indexSelected == -1" class="input-container form-item" fxLayout="row" fxLayoutAlign="center center">
    <input class="edit" type="text" placeholder="Neighbourhood name" [(ngModel)]="editName">
    <div *ngIf="checkchanges()" class="button blue" (click)="createEdit()"><i class="fa fa-save"></i> Save</div>
    <div *ngIf="!checkchanges()" class="button blue" (click)="cancel()"><i class="fa fa-save"></i> Save</div>
    <div class="button cancel" (click)="cancel()">Cancel</div>
  </div>

  <div class="conteiner" *ngIf="items.length">
    <div class="conteiner-item-space" *ngFor="let item of items | filterByName: search; let i = index">
      <div *ngIf="!isBlocked || indexSelected != i" class="conteiner-item">
        <span>{{item.name}}</span>

        <div class="">
          <i class="fa fa-trash" (click)="remove(item)"></i>
          <i class="fa fa-pencil" (click)="edit(item, i)"></i>
        </div>
      </div>
      <div *ngIf="isBlocked && indexSelected == i" class="input-container form-item" fxLayout="row" fxLayoutAlign="center center">
        <input class="edit" type="text" placeholder="Neighbourhoud name" [(ngModel)]="editName">
        <div *ngIf="checkchanges()" class="button blue" (click)="createEdit()"><i class="fa fa-save"></i> Save</div>
        <div *ngIf="!checkchanges()" class="button blue" (click)="cancel()"><i class="fa fa-save"></i> Save</div>
        <div class="button cancel" (click)="cancel()">Cancel</div>
      </div>
    </div>
  </div>

  <div class="form-item done-button-container" fxLayout="row" fxLayoutAlign="center center">
    <span *ngIf="!isBlocked && hasRedirectLink" class="link" routerLink="{{linkURL}}" (click)="close()">{{linkText}}</span>
    <span *ngIf="isBlocked && hasRedirectLink" class="link link-disabled">{{linkText}}</span>
    <div *ngIf="!isBlocked" class="button blue" (click)="close()">Done</div>
    <div *ngIf="isBlocked" class="button blue disabled">Done</div>
  </div>
</div>
