import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { DateTimeRangeModalComponent } from './../date-time-range-modal/date-time-range-modal.component'
import { LocalizationService } from "./../../services/localization/localization.service";

import * as _ from "lodash";
import * as moment from 'moment';

@Component({
  selector: 'date-time-range-view',
  templateUrl: './date-time-range-view.component.html',
  styleUrls: ['./date-time-range-view.component.css']
})
export class DateTimeRangeViewComponent {

  dateTimeRange = {
    "from": moment().hour(8).minute(0).second(0).toDate(),
    "to":  moment().hour(19).minute(0).second(0).toDate()
  };
  is_admin: boolean = JSON.parse(localStorage.getItem("currentUser")).user.is_admin;
  wants_24h_time_format: boolean = JSON.parse(localStorage.getItem("currentUser")).user.wants_24h_time_format;
  isSameDay: boolean = false;

  @Input() set setDateTimeRange(value) {
    console.log(value)
    if (value.from != undefined && value.to != undefined) {
      this.dateTimeRange =  _.cloneDeep(value);
      this.isSameDay = moment(this.dateTimeRange.from).isSame(this.dateTimeRange.to, 'day');
      this.isSameDayChange.emit(this.isSameDay);
      console.log(this.isSameDay)
    }
  }
  @Input() isReleaseReservationDetails: boolean = false;
  @Input() is5MinutesInterval: boolean = false;
  @Input() isColumn: boolean = false;
  @Input() disable: boolean = false;
  @Input() isDaysOnly: boolean = false;
  @Input() fromOnly: boolean = false;
  @Input() minDate: any = null;
  @Input() maxDate: any = null;

  @Input() text: string = "";
  @Input() descriptions: string[] = [];

  @Output() dateTimeChange : EventEmitter<any>;
  @Output() isSameDayChange : EventEmitter<boolean>;

  languages: any;

  constructor(
    public dialog: MatDialog,
    private localizationService: LocalizationService
   ) {
    if(this.localizationService.getComponentText('shared_components','date_time_range_view').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','date_time_range_view').data;
    }
    this.dateTimeChange = new EventEmitter<any>();
    this.isSameDayChange = new EventEmitter<boolean>();
  }

  ngOnInit() {
    this.isSameDayChange.emit(this.isSameDay);
  }

  rangeModal() {
    if(this.disable) {
      return;
    }
    let dialogData;
    if ((document.getElementsByTagName('body')[0] as HTMLElement).clientWidth > 600 && (document.getElementsByTagName('body')[0] as HTMLElement).clientHeight > 400) {
      dialogData = {
        data: {
          minDate: this.minDate,
          maxDate: this.is_admin ? null : this.maxDate,
          range: this.dateTimeRange,
          isDaysOnly: this.isDaysOnly,
          fromOnly: this.fromOnly,
          text: this.text,
          descriptions: this.descriptions
        },
        panelClass: ['no-scrolls'],
      };
    } else {
      dialogData = {
        data: {
          minDate: this.minDate,
          maxDate: this.is_admin ? null : this.maxDate,
          range: this.dateTimeRange,
          is5MinutesInterval: this.is5MinutesInterval,
          isDaysOnly: this.isDaysOnly,
          fromOnly: this.fromOnly,
          text: this.text,
          descriptions: this.descriptions
        },
        panelClass: ['no-padding', 'no-scrolls'],
        position: {
          // top: '80vh'
          top: 'calc(85vh - (env(safe-area-inset-top) + 252px))'
        },
        maxWidth: (document.getElementsByTagName('body')[0] as HTMLElement).clientWidth < 400 ? '100vw' : '',
        width: (document.getElementsByTagName('body')[0] as HTMLElement).clientWidth < 400 ? '100vw' : '',
      };
    }
    this.dialog.open(DateTimeRangeModalComponent,  dialogData )
    .afterClosed().subscribe(result => {
      if(result) {
        console.log(result)
        this.dateTimeRange =  _.cloneDeep(result);
        this.isSameDay = moment(this.dateTimeRange.from).isSame(this.dateTimeRange.to, 'day');
        console.log(this.isSameDay)
        this.dateTimeChange.emit(this.dateTimeRange);
      }
    });
  }

}
