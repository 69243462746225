import { Component, Input } from '@angular/core';
import { LocalizationService } from "app/services/localization/localization.service";

@Component({
  selector: 'loading-table-spinner',
  templateUrl: './loading-table-spinner.component.html',
  styleUrls: ['./loading-table-spinner.component.css']
})
export class LoadingTableSpinnerComponent {

  @Input() text: string = '';
  languages: any;

  constructor(private localizationService: LocalizationService) {
    if(this.localizationService.getComponentText('shared_components','loading_table_spinner').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','loading_table_spinner').data;
    }
  }

}
