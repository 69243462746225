import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByProxyName'
})
export class FilterByProxyNamePipe implements PipeTransform {

  transform(items: any[], filter: string): any {
     if (!items || !filter) {
         return items;
     }

     return items.filter(item => item.proxy_name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
   }

}
