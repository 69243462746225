import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUserSquadName'
})
export class FilterUserSquadNamePipe implements PipeTransform {

  transform(items: any[], filter: string): any {
     if (!items || !filter) {
         return items;
     }

     return items.filter(item => {
       let tmp = item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
       return  item.squad_name ? tmp || item.squad_name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 : tmp
     });
   }

}
