import { Component } from '@angular/core';

import { environment } from '../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import * as _ from "lodash";
import * as moment from 'moment';

import { SwUpdate } from "@angular/service-worker";

import { LocalizationService } from "./services/localization/localization.service";
import { AuthService } from './services/v2/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AuthService]
})
export class AppComponent {

  constructor(
    public dialog: MatDialog,
    private localizationService: LocalizationService,
    private swUpdate: SwUpdate,
  ) {
    moment.locale(localStorage.getItem("localLanguage") ? localStorage.getItem("localLanguage") : "en")
    console.log(moment.locale(localStorage.getItem("localLanguage") ? localStorage.getItem("localLanguage") : "en"));
    console.log(localStorage.getItem("localLanguage") ? localStorage.getItem("localLanguage") : "en")
    console.log(moment.locale())
    if (localStorage.getItem("standalone") == null || localStorage.getItem("standalone") == undefined) {
      if (location.search.indexOf("mode") > 0) {
        localStorage.setItem('standalone', JSON.stringify(location.search.indexOf("mode")));
      }
    }

    if(environment.structure_checker) {
      this.localizationService.checkStructureLanguages()
    }
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if(!window.location.pathname.includes("/kiosk/landing/")) {
          if (confirm("New version available. Load New Version?")) {
            // Check if the caches API is available in the browser
            if ('caches' in window) {
                // Open the Cache Storage
                caches.keys().then(cacheNames => {
                    // Iterate through all cache names
                    cacheNames.forEach(cacheName => {
                        // Delete the cache
                        caches.delete(cacheName);
                    });
                });
            }
            // force reload use true
            window.location.reload(true);
          }
        }
      });
    }
  }

}
