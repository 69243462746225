import { Component, Input } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { VideoModalComponent } from './../video-modal/video-modal.component';
import { LocalizationService } from "app/services/localization/localization.service";

@Component({
  selector: 'icon-video-modal',
  templateUrl: './icon-video-modal.component.html',
  styleUrls: ['./icon-video-modal.component.css']
})
export class IconVideoModalComponent {

  @Input() header: string = '';
  @Input() video: string = '';
  @Input() withText: boolean = false;
  @Input() marginLeft: boolean = false;
  @Input() marginRight: boolean = false;
  languages: any;

  constructor(
    public dialog: MatDialog,
    private localizationService: LocalizationService
  ) {
    if(this.localizationService.getComponentText('shared_components','icon_video').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','icon_video').data;
    }
  }

  open() {
    this.dialog.open(VideoModalComponent, {data: {header: this.header, video:  this.video}});
  }
}
