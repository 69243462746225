import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalStoreSiteIDService {

  constructor() { }

  static setItem(data: string): void {
    localStorage.setItem("globalStoreSiteID", data);
  }

  static getItem(): any {
    return parseInt(localStorage.getItem("globalStoreSiteID"));
  }

  static removeItem(): void {
    localStorage.removeItem("globalStoreSiteID");
  }
}
