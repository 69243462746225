import {
  Input,
  Output,
  Component,
  EventEmitter
} from '@angular/core';
import { LocalizationService } from "./../../services/localization/localization.service";

@Component({
  selector: 'total-counter-download-table-csv',
  templateUrl: './total-counter-download-table-csv.component.html',
  styleUrls: ['./total-counter-download-table-csv.component.css']
})
export class TotalCounterDownloadTableCsvComponent {

  @Input() isSelected: boolean = false;
  @Input() showButton: boolean = true;
  @Input() showBatchButton: boolean = false;
  @Input() isSiteOnly: boolean = false;
  filteredItems: any[] = [];
  items: any[] = [];
  @Input() set sortedData(value: any[]) {
    this.items = value;
    this.filterItems();
  }
  @Input() selected: number = 0;
  @Input() search: string = "";
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() download = new EventEmitter<void>();
  @Output() batch = new EventEmitter<void>();
  @Output() serachChangeEmiter = new EventEmitter<void>();
  languages: any;

  constructor(private localizationService: LocalizationService) {
    if(this.localizationService.getComponentText('shared_components','total_counter_download_table_csv').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','total_counter_download_table_csv').data;
    }
  }

  downloadCSV() {
    this.download.emit();
  }
  batchClick() {
    this.batch.emit();
  }

  changeValue() {
    this.filterItems()
    this.searchChange.emit(this.search);
    this.serachChangeEmiter.emit();
  }

  filterItems() {
    if (this.items && this.search != '') {
       this.filteredItems = this.items.filter(item =>
         Object.keys(item).some(k =>
           item[k] != null && item[k].toString().toLowerCase().includes(this.search.toLowerCase())
         )
       );
     }
     else {
       this.filteredItems = this.items ? this.items : []
     }
   }
 }
