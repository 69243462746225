import { Component, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'i-tooltipe',
  templateUrl: './i-tooltipe.component.html',
  styleUrls: ['./i-tooltipe.component.css']
})
export class ITooltipeComponent {

  @ViewChild('tooltip') tooltip: MatTooltip;

  @Input() text : string = '';
  constructor() { }


  show() {
    this.tooltip.show();
  }
}
