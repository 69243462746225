import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './custom-date-adapter';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ]
})
export class CustomDateAdapterModule { }
