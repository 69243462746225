import {
  Component,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UsersService } from "./../../services/users/users.service";
import { LocalizationService } from "app/services/localization/localization.service";

@Component({
  selector: 'site-dialog',
  templateUrl: './site-dialog.component.html',
  styleUrls: ['./site-dialog.component.css'],
  providers: [ UsersService ]
})
export class SiteDialogComponent {

  sitesData: any = [];
  selectedSite: number = 1;
  text: string = ''
  description: string = ''
  button: any;

  constructor(
     public dialogRef: MatDialogRef<SiteDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private user: UsersService,
     private localizationService: LocalizationService
   ) {
     if(this.localizationService.getReusableDataText('button').isSuccess) {
       this.button = this.localizationService.getReusableDataText('button').data;
     }
     this.text = data.text;
     this.description = data.description;
     this.sitesData = data.sites;
     this.selectedSite = data.selected;
   }

   onClick(res): void {
     console.log(this.selectedSite);
     this.dialogRef.close({isConfirm: res, site_id: this.selectedSite});
   }

}
