<div class="date-time" fxLayout="row" fxLayoutAlign=" center" *ngIf="dateTimeMini">
  <!-- <mat-form-field class="date-space">
    <input matInput [(ngModel)]="dateTimeMini" [matDatepicker]="from" (dateChange)="onChange($event)" disabled>
    <mat-datepicker #from [startAt]="dateTimeMini" disabled="false"></mat-datepicker>
  </mat-form-field>
  <div class="date" [ngStyle]="{'background': setBackground}" fxLayoutAlign=" center" (click)="from.open()"  matTooltip="Click for display calendar">
    {{dateTimeMini | date: 'dd MMMM yyyy'}}
  </div> -->
  <div class="date" [ngStyle]="{'background': setBackground}" fxLayoutAlign=" center" (click)="timeDialog()"  matTooltip="{{languages.tooltipe.calendar}}">
    {{dateTimeMini |  datex: 'DD MMMM YYYY'}}
  </div>

  <div class="time" [ngStyle]="{'background': setBackground}" fxLayoutAlign=" center" (click)="timeDialog()" matTooltip="{{languages.tooltip.time}}">
    {{dateTimeMini |  datex: 'HH:mm'}}
  </div>
</div>
