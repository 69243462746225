import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import * as moment from 'moment';
import { environment } from '../../../environments/environment';

@Injectable()
export class AssetsService {

    constructor(
      private http: HttpClient
    ) { }

    //Getters
    getAssetssList() {
      return this.http.get(environment.backend_url + environment.api_prefix + '/assets')
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    assetById(idAssets) {
      return this.http.get(environment.backend_url + environment.api_prefix + '/assets/' + idAssets)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    getReservationsSpecificAssets(idAssets) {
      return this.http.get(environment.backend_url + environment.api_prefix + '/assets/' + idAssets + '/reservations')
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    getpossibleactivestates(data: any = {}) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/site/getpossibleactivestates', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    uploadImage(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/assets/upload-image', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    // POST /api/1.0/asset/setactive {asset_id:integer, is_active:boolean} ==> asset info
    setactive(data) {
      if(data.from != undefined || data.to != undefined) {
        data.from = moment(data.from).format("Y-MM-DD HH:mm:ss").toString()
        data.to = moment(data.to).format("Y-MM-DD HH:mm:ss").toString()
      }
      return this.http.post(environment.backend_url + environment.api_prefix + '/assets/setactive', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    removeImage(data) {
      return this.http.post(environment.backend_url + '/api/1.0/assets/remove-image', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    getAssetStatus(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/assets/status', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    createAsset(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/assets', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    duplicateAsset(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/assets/duplicate', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    createAssetQRCode(data) {



      return this.http.post(environment.backend_url + environment.api_prefix + '/generateqrpdfurl', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    setname(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setname', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    setZoning(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setzoning', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    setemail(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setemail', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    editApproverEmail(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setapprovers', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    // {site_id}
    getresourcetypes(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/site/getresourcetypes', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    // {asset_id: id, resource_type: ""}
    setresourcetype(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setresourcetype', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    setcheckin(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setrequirescheckin', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    setzone(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setzone', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    setcapacity(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setcapacity', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    setposition(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setposition', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    makecircle(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/makecircle', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    makesquare(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/makesquare', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    updateAssets(idAssets, data) {
      return this.http.put(environment.backend_url + environment.api_prefix + '/assets/' + idAssets, data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    deleteAssets(idAssets) {
      return this.http.delete(environment.backend_url + environment.api_prefix + '/assets/' + idAssets)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    // Set asset owner
    //
    // Method: POST
    // URL: api/1.0/asset/setowner
    // req: {asset_id, user_id}
    // res: {isSucces: true, data: {asset}, errors: []}
    setAssetOwner(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setowner', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    // Clear asset owner
    //
    // Method: POST
    // URL: api/1.0/asset/clearowner
    // req: {asset_id}
    // res: {isSucces: true, data: {asset}, errors: []}
    clearAssetOwner(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/clearowner', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    // Set device id for asset
    //
    // Method: POST
    // URL: api/1.0/asset/setdeviceid
    // req: {asset_id, device_id (string)}
    // res: {isSucces: true, data: {asset}, errors: []}
    setdeviceIDForAsset(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setdeviceid', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    // /api/1.0/asset/cleardeviceid {asset_id}
    cleardeviceIDForAsset(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/cleardeviceid', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    // Set asset level
    //
    // Method: POST
    // URL: api/1.0/asset/setlevel
    // req: {asset_id, level (integer)}
    // res: {isSucces: true, data: {asset}, errors: []}
    setAssetLevel(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setlevel', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    // /api/1.0/asset/setapprovers
    setApprovers(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setapprovers', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    // Set asset internal/external
    //
    // Method: POST
    // URL: api/1.0/asset/setinternal
    // req: {asset_id, internal (boolean)}
    // res: data:{asset}
    setAssetInternalExternal(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/setinternal', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    listPossibleFilterValues(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/assets/possibleFilterValues', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }

    filteredMaps(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/wings/filter', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    filteredAssets(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/assets/filter', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    // /api/1.0/asset/addamenity {asset_id:[1,2,3], amenity_id:table}
    addamenity(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/addamenity', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
     // /api/1.0/asset/removeamenity {asset_id:[1,2,3], amenity_id:table}
    removeamenity(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/removeamenity', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
    // {asset_id:1}
    amenities(data) {
      return this.http.post(environment.backend_url + environment.api_prefix + '/asset/amenities', data)
      .pipe(
        tap(res => {console.log(res)}),
        switchMap(res => {return of(res)}),
        catchError(err => {return of(err)})
      )
    }
}
