<div class="container" fxLayout="column">
  <h2>{{text}}</h2>
  <p class="info">{{description}}</p>
  <mat-form-field class="siteSelect">
    <mat-select placeholder="Select location" [(ngModel)]="selectedSite">
      <mat-option style="color: #4a4a4a !important" *ngFor="let site of sitesData" [value]="site.id">
        {{ site.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- <p><i class="fa fa-info-circle"></i> Select static site for reservation</p> -->
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="reservation-button default-button" (click)="onClick(false)">{{button.cancel}}</div>
    <div class="reservation-button" (click)="onClick(true)">{{button.confirm}}</div>
  </div>
</div>
