import {
  Input,
  Output,
  Component,
  EventEmitter,
  OnInit
} from '@angular/core';

import * as _ from "lodash";
import * as moment from 'moment';

import { MatDialog } from '@angular/material/dialog';
import { DateTimeRangeModalComponent } from './../date-time-range-modal/date-time-range-modal.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { environment } from '../../../environments/environment';

import { LocalizationService } from "./../../services/localization/localization.service";

@Component({
  selector: 'newsfeed-create-edit-item',
  templateUrl: './newsfeed-create-edit-item.component.html',
  styleUrls: ['./newsfeed-create-edit-item.component.css']
})
export class NewsfeedCreateEditItemComponent implements OnInit {

  _newsItem: any;
  @Input('newsItem')
  set newsItem(value: boolean) {
    this._newsItem = _.cloneDeep(value);
  }
  @Input() disable: boolean = false;
  @Input() isDaysOnly: boolean = false;
  @Input() availableDays: number = 14;
  @Input() site_name: string = '';
  @Output() create = new  EventEmitter<any>();
  @Output() update = new  EventEmitter<any>();
  @Output() remove = new  EventEmitter<any>();
  @Output() cancel = new  EventEmitter<any>();
  @Output() messageToAttendees = new  EventEmitter<any>();

  isEdit: boolean = false;
  editNewsItem: any;
  editNewsItemCloneCheck: any;
  form:FormData = new FormData();

  backend_url: string = environment.backend_url;
  isRelative: boolean = environment.image_url_type != 'absolute';

  dateTimeRangePublic = {
    "from": moment().hour(8).minute(0).second(0).toDate(),
    "to":  moment().hour(19).minute(0).second(0).toDate()
  };
  dateTimeRangeEvent = {
    "from": moment().hour(8).minute(0).second(0).toDate(),
    "to":  moment().hour(19).minute(0).second(0).toDate()
  };
  wants_24h_time_format: boolean = JSON.parse(localStorage.getItem("currentUser")).user.wants_24h_time_format;

  languages: any;
  button: any;

  constructor(
    public dialog: MatDialog,
    private localizationService: LocalizationService
  ) {
    if(this.localizationService.getComponentText('shared_components','newsfeed_create_edit_item').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','newsfeed_create_edit_item').data;
    }
    if(this.localizationService.getReusableDataText('button').isSuccess) {
      this.button = this.localizationService.getReusableDataText('button').data;
    }
  }

  ngOnInit() {
    this.isEdit = this._newsItem ? false : true;
    console.log(this.isEdit )
    this.setDefault();
  }

  editing() {
    console.log('editing: ')
    if(this._newsItem) {
      this.isEdit = true;
      this.editNewsItem = _.cloneDeep(this._newsItem)
      this.editNewsItem.public_date = _.cloneDeep(moment(this._newsItem.public_date).format(this.wants_24h_time_format ? "ddd, D MMM YYYY HH:mm" : "ddd, D MMM YYYY hh:mm A").toString())
      this.editNewsItem.event_from = _.cloneDeep(moment(this._newsItem.event_from).format("Y-MM-DD HH:mm:ss").toString())
      this.editNewsItem.event_to = _.cloneDeep(moment(this._newsItem.event_to).format("Y-MM-DD HH:mm:ss").toString())
      this.dateTimeRangePublic = {
        "from": _.cloneDeep(moment(this._newsItem.public_date).toDate()),
        "to":  _.cloneDeep(moment(this._newsItem.public_date).days(this._newsItem.available_days).toDate())
      };
      this.dateTimeRangeEvent = {
        "from": _.cloneDeep(moment(this._newsItem.event_from).toDate()),
        "to": _.cloneDeep(moment(this._newsItem.event_to).toDate())
      };
      if(this.editNewsItem.image && this.isRelative) {
        this.editNewsItem.image = _.cloneDeep(this.backend_url + this._newsItem.image)
      }
      this.editNewsItemCloneCheck =  _.cloneDeep(this.editNewsItem);
    }
  }

  setDefault() {
    this.form = new FormData();
    this.dateTimeRangePublic = {
      "from": moment().add(1, 'h').minute(0).second(0).toDate(),
      "to":  moment().add(8, 'h').minute(0).second(0).toDate()
    };
    this.dateTimeRangeEvent = {
      "from": moment().add(1, 'h').minute(0).second(0).toDate(),
      "to":  moment().add(8, 'h').minute(0).second(0).toDate()
    };
    this.editNewsItem = {
      public_date: moment(this.dateTimeRangePublic.from).format(this.wants_24h_time_format ? "ddd, D MMM YYYY HH:mm" : "ddd, D MMM YYYY hh:mm A").toString(),
      text: "",
      available_days: this.availableDays,
      image: '',
      type: 'News',
      site_name: this.site_name,
      event_subject: "",
      event_location: this.site_name,
      event_from: moment(this.dateTimeRangeEvent.from).format("Y-MM-DD HH:mm:ss").toString(),
      event_to: moment(this.dateTimeRangeEvent.to).format("Y-MM-DD HH:mm:ss").toString()
    }
    this.editNewsItemCloneCheck =  _.cloneDeep(this.editNewsItem);
  }

  createUpdateItem() {
    if(!this.form.get('image')) {
      this.form.append('image', this.editNewsItem.image)
    }
    this.form.append('available_days', this._newsItem ? this.editNewsItem.available_days.toString() : this.availableDays.toString())
    this.form.append('public_date', moment(this.dateTimeRangePublic.from).format("Y-MM-DD HH:mm:ss").toString())
    this.form.append('type', this.editNewsItem.type.toString())
    this.form.append('text', this.editNewsItem.text.toString())
    this.form.append('site_name', this.editNewsItem.site_name.toString())
    this.form.append('event_subject', this.editNewsItem.event_subject.toString())
    this.form.append('event_location', this.editNewsItem.event_location.toString())
    this.form.append('event_from', moment(this.dateTimeRangeEvent.from).format("Y-MM-DD HH:mm:ss").toString())
    this.form.append('event_to', moment(this.dateTimeRangeEvent.to).format("Y-MM-DD HH:mm:ss").toString())
    if(!this._newsItem) {
      this.create.emit(this.form);
    } else {
      this.form.append('item_id', this.editNewsItem.id.toString())
      this.form.append('site_id', this.editNewsItem.site_id.toString())
      this.update.emit(this.form);
    }
    this.setDefault();
  }
  removeItem() {
    this.cancel.emit({item_id: this._newsItem.id});
  }
  cancelItem() {
    if(this._newsItem && !_.isEqual(this.editNewsItemCloneCheck, this.editNewsItem)) {
      this.dialog.open(ConfirmDialogComponent, { data: { text: this.languages.popup, icon: false } })
      .afterClosed().subscribe(result => {
        if(result) {
          this.isEdit = false;
          this.setDefault();
          this.cancel.emit();
        }
      });
    } else {
      this.isEdit = false;
      this.setDefault();
      this.cancel.emit();
    }
  }

  onImageLoading(event) {
    this.form = event;
  }
  onImageLoadingPreview(img) {
    this.editNewsItem.image = img;
  }
  removeImage() {
    this.editNewsItem.image = null;
    this.form.delete('image')
  }
  setNewDateTimeRange(newRange) {
    this.dateTimeRangeEvent = _.cloneDeep(newRange)
  }
  rangeModal() {
    if(this.disable) {
      return;
    }
    let dialogData;
    if ((document.getElementsByTagName('body')[0] as HTMLElement).clientWidth > 600 && (document.getElementsByTagName('body')[0] as HTMLElement).clientHeight > 400) {
      dialogData = {
        data: {
          range: this.dateTimeRangePublic,
          isDaysOnly: this.isDaysOnly,
          fromOnly: true
        },
        panelClass: ['no-scrolls'],
      };
    } else {
      dialogData = {
        data: {
          range: this.dateTimeRangePublic,
          is5MinutesInterval: false,
          isDaysOnly: this.isDaysOnly,
          fromOnly: true
        },
        panelClass: ['no-padding', 'no-scrolls'],
        position: {
          top: '0px'
        },
        maxWidth: (document.getElementsByTagName('body')[0] as HTMLElement).clientWidth < 400 ? '100vw' : '',
        width: (document.getElementsByTagName('body')[0] as HTMLElement).clientWidth < 400 ? '100vw' : '',
      };
    }
    this.dialog.open(DateTimeRangeModalComponent,  dialogData )
    .afterClosed().subscribe(result => {
      if(result) {
        console.log(result)
        this.dateTimeRangePublic =  _.cloneDeep(result);
        this.editNewsItem.public_date = moment(this.dateTimeRangePublic.from).format(this.wants_24h_time_format ? "ddd, D MMM YYYY HH:mm" : "ddd, D MMM YYYY hh:mm A").toString()
      }
    });
  }

}
