export const en = {
  type:'en',
  code:'en-EN',
  name:'English',
  img:'flag-icon-us',
  pages: {
    login_main_navigation: {
      login: {
        pageName: 'login',
        callback: 'Close this window and continue in the Tribeloo add-in for Outlook',
        email_dialog: {
          header: 'Please enter your Email',
          input: 'Email'
        },
        sso_info:{
          text: "Your browser is blocking the opening of a new tab for the SSO login. Change your browser settings or click on the 'Force open' button",
          button: "Force open"
        },
        text: {
          loading: ['Working for you', 'If it takes too long,', 'please reload'],
          header: 'Welcome',
          sub_header: 'Book your favourite desks find your colleagues and much more.',
          form_headers: {
            login: 'Log in',
            email: 'Please Check email',
            password: 'Forgot Password'
          },
          password_info: ["No worries! We'll send you a recovery link." , 'A new password has been sent to you.'],
          inputs: ['Username', 'Password', 'Email'],
          remember_action: ['Remember me.', 'Not you?', 'Forgot password?', 'Back To Log In'],
          button: ['Login with SSO', 'Log in', 'Reset Password'],
          info_modal: 'Check your e-mail to change your password'
        }
      },
      delegates_modal: {
        header: "Select delegate profile",
        sub_header: "Select the person you want to make a booking for",
        button_select: "Select"
      },
      main_navigation: {
        pageName: 'side nav, main tool bar component',
        nav_tabs_names: {user: 'User', admin: 'Admin'},
        nav_dropdown_corg: {
          about: 'About',
          history: "History",
          feedback: 'Give feedback',
          logout: 'Log out',
          user_settings: "User settings",
          delegate: "Switch Delegate profiles",
          user: "Switch to user profile",
          admin: "Switch to admin profile"
        },
        admin_nav_items: {
          dashboard: 'Dashboard',
          reservation_management: 'Reservation management',
          newsfeed: 'Newsfeed',
          user_management: 'User management',
          site_configuration: 'Site configuration',
          kiosk_configuration: 'Kiosk configuration',
          issue_management: 'Issue management',
          insights: 'Insights'
        }
      }
    },
    external: {
      close_issue_by_hash: 'Thank you for closing this issue!',
      hash_reservation: 'Reservation not available',
      google: 'Loading...',
      outlook_reservation: {
        popup: 'Delete this reservation?',
        login: "Log in",
        no_space_info: ['No space reserved,', ' please contact meeting organiser'],
        site_headr: 'Site location',
        date_time: 'Time shown in time zone of requested location',
        space: {
          header: 'Space',
          internal_external: ['internal', 'external']
        },
        amenities_header: 'Amenities'
      },
      reset_password: {
        info_modal: 'Password changed.',
        button_update: 'Update password',
        placeholders: ['Enter new password', 'Re-enter new password'],
        errors: ["Passwords doesn't meet requirements", 'Passwords are not the same'],
        rules: 'Password must contain at least: 8 characters, 1 upper case, 1 lower case, 1 digit'
      }
    },
    admin: {
      kiosk: {
        main_header: 'Set up a kiosk',
        card: {
          headers: {
            site: 'Select a site',
            wing: ['Select a wing ', '(optional)']
          },
          placeholders: {
            site: 'Please search or select a site',
            wing: 'Please search or select a wing'
          },
          warning: 'Not running in kiosk, PWA or full-screen mode.',
          button: 'Launch kiosk mode',
        },
        info: {
          kiosk: 'For Kiosk - please logout, close browser and than run a following command: ',
          pwa: 'For PWA or full-screen browser - use Google chrome browser and',
          button: 'Click here'
        }
      },
      dashboard: {
        main: {
          refresh: "Refresh",
          site_card: {
            header: " Dashboard",
            action: "Help center",
            sub_header: "Select a site to get an overview"
          },
          small_card: {
            header: " Meeting rooms",
            total: "TOTAL",
            assigned: "Assigned"
          },
          utilization_cards: {
            header: " Max utilization rate ",
            special_header: "Max utilization rate special types",
            tooltip: "The max utilization rate is the max ratio during that day of requested over available resources of the selected type",
            table: {
              headers: [
                { name: "All types", tag_name: "all_types" },
                { name: "Desks", tag_name: "desks" },
                { name: "Meeting rooms", tag_name: "rooms" },
                { name: "Meeting rooms - Large ", tag_name: "big_rooms" },
                { name: "Meeting rooms - External", tag_name: "external" },
                { name: "Released %", tag_name: "releases" },
              ],
              hover: ["Requested ", "Available "]
            },
            info: "Click on a number in the table to see the chart",
            zoom: ['zoom out', 'zoom in']
          },
          to_do_card: {
            header: " To-do’s",
            link: "Go to to-do list",
            table: [
              { name: "To be confirmed", tag_name: "to_be_confirmed" },
              { name: "To be assigned", tag_name: "to_be_assigned" },
              { name: "Untreated request", tag_name: "with_message" },
              { name: "Total", tag_name: "total" }
            ]
          },
          not_enough_card: {
            header: "Not enough resources",
            info_default: "Click on a number in the table to see the solutions.",
            info_selected: "Let’s see how we can solve this.",
            button_reservation: "Remind people about their reservations",
            button_release: "Request more releases"
          }
        },
        releases_table: {
          info: "You can ask people who have not released their resource for this day to release it.",
          headers: {
            user: { popup: 'owners', name: "Assigned to" },
            level: { popup: 'Level of user', name: "Level assigned user" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            asset_level: { popup: '', name: "Asset level" },
            seat: { popup: '', name: "Seat capacity" },
            amenities: { popup: '', name: "Resource’s amenities" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: "Send message to all selected releases?",
            select_owner_message: "At least one user must be selected.",
            error_message: "Error sending",
          }
        },
        reservations_table: {
          info: "You can remind people who have a reservation for this day to cancel their reservation if no longer needed.",
          headers: {
            user: { popup: 'Owners', name: "User" },
            level: { popup: 'Level', name: "Level of user" },
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            rc: { popup: 'Requested seats', name: "Requested seats" },
            ie: { popup: 'internal external', name: "internal/ external" },
            amenities: { popup: '', name: "Requested amenities" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            status: { popup: 'Statuses', name: "Status" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: "Send message to all selected reservations?",
            select_owner_message: "At least one user must be selected.",
            error_message: "Error sending",
          }
        }
      },
      newsfeed: {
        navigation: ['Newsfeed', 'Emergency message'],
        list: {
          main_header: 'Newsfeed',
          site_header: 'Select a site to check its newsfeed.',
          i_tooltipe: 'The newsfeed of a site is shown on the homepage for all sites selected by the user',
          card: {
            header: 'Settings',
            toggle: 'Show newsfeed of site',
            i_tooltipe: 'You can choose to show the newsfeed of another site',
            select: {
              toggle: 'Click here to select the site',
              placeholder: 'Select site'
            },
            auto_remove: {
              text: ['Automatically delete news items that are older than ', ' days. '],
              i_tooltipe: 'Choose how long news items are shown on the newsfeed before they get deleted. Events are removed from the newsfeed once ended.'
            }
          },
          add_item: ' Add new item',
          popups: {
            info: 'Please select a location!',
            confirm: 'Delete all existing newsitems and events of ',
            button: 'OK'
          }
        },
        form: {
          main_header: 'Emergency message ',
          site_header: 'Select a site for which you want to write an emergency message.',
          i_tooltipe: 'An emergency message, once turned on, is visible for all users of that site on the Tribeloo homepage and on the devices of that site',
          message: "Haven't message",
          placeholder: 'Emergency message line ',
          save: 'Save',
          emergency: ['Turn on emergency message', 'Turn off emergency message']
        }
      },
      site_config: {
        navigation: {
          site: "Site configuration",
          rules: "Site rules",
          table: "Resource configuration table",
          types: "Resource types",
          wayfinding: "Wayfinding"
        },
        edit_site: {
          header: "Editing site ",
          video_header: "How to add, edit and delete a site",
          buttons: {
            add: "Add new site",
            delete: "Delete site"
          },
          toggle: "Site is active & visible for users",
          toggle_warning: ["Site is currently ", "inactive & invisible"," for users"],
          base: {
            header: "Basic details",
            video_header: "How to set up a site: basic details",
            placeholder: {
              name: "site name",
              code: "plus code",
              address: "address of this site"
            },
            label: {
              code: "Define coordinates of site (plus code) ",
              address: "Address of site "
            },
            tooltip: {
              code: "Plus code can be found on Google maps and is used to show a map of the location on the wayfinding link",
              address: "Address is shown on the wayfinding link"
            },
            position: {
              header: "Position of wing",
              info: "Select a wing, by clicking on its title, to change its position.",
            },
          },
          amenities: {
            header: "Amenities",
            video_header: "How to set up a site: amenities",
            info: "Define amenities ",
            tooltip: "Amenities are additional features, like a beamer or a flipchart, which can be selected when looking for a resource. Select amenities in the list below, to activate them on the current site, so they can be assigned to a specific resource from this site.",
            counter: [" in total - ", " active"],
            placeholder: "new amenity"
          },
          manager: {
            header: "Site manager settings",
            video_header: "How to set up a site: site manager settings",
            info: "Add e-mail address office manager ",
            tooltip: "Office manager will receive emails when new issues are reported ",
            placeholder: "e-mail",
            toggle: ["Send email to-do's every ", "hours"],
          },
          device: {
            header: "Device settings",
            video_header: "How to set up a site: device settings",
            label: {
              time: "Use 24h time for devices ",
              password: "Device password (0-9 A-E) "
            },
            tooltip: {
              time: "Select time format for meeting room and desk devices: AM/PM or 24h format",
              password: "Password used to access configuration screen on devices"
            },
            placeholder: "code 0-9 A-E"
          },
          insights: {
            header: "Insight settings",
            video_header: "How to set up a site: insight settings",
            labels: {
              days: "Define working days ",
              hours: "Define working hours ",
              room: ["Define a large meeting room", "people or more"],
            },
            tooltip: {
              days: "Working days are used to calculate average utilization rate in insights",
              hours: "Working hours are used to calculate average utilization rate in insights"
            },
            range: ["from", "to"]
          },
          popups: {
            online: {
              text: 'Bring this site online?',
              descriptions: ['This will make the site visible for regular users and ready for reservations.']
            },
            offline: {
              text: 'Bring this site offline?',
              descriptions: ['This will make the site invisible for regular users. Existing reservations will be kept.']
            },
            delete_site: {
              text: 'Delete this site?',
              big_descriptions: ['This will delete all wings, resources and reservations for this site. \nUsers with this home site will be moved to having no home site.']
            },
            cant_delete_site: 'Site can not be deleted.',
            amenity: {
              exists: 'Amenity already exists.',
              cant_updated: 'Amenity cannot be updated. Used by resources on other sites.',
              cant_delete: 'Amenity cannot be deleted, because it is assigned to some resources.',
              cant_disabled: 'Amenity cannot be disabled, because it is assigned to some resources. Remove it first from all resources.'
            }
          },
          edit_amenity_dialog: "Edit amenity",
          edit_wings_sorted: {
            add_button: " Add Wing",
            position: "Position: ",
          },
          wing_position: {
            header: "Selected wing: ",
            position: "Position ",
            move: "Move to position "
          }
        },
        rules: {
          header:"Site rules",
          site_card: {
            header: "Site",
            sub_header: "Select a site for which you want to define user privileges and rules."
          },
          default_reservation_time_card: {
            header: "Default reservation time",
            sub_header_desk: "Desk",
            sub_header_room: "Meeting room",
            label_start: "Start date",
            label_end: "Duration"
          },
          auto_assign_card: {
            header: "Auto-assign resources ",
            video_header: 'How to auto-assign resources to reservations',
            sub_header: "Reservations can get a resource auto-assigned if the user requests one, if none is available it will appear in the to-do list of the office manager",
            toggle: "Activate auto-assign functionality for ‘to assign’ reservations",
          },
          check_in_reminder_email_card: {
            header: "Check-in reminder email ",
            video_header: 'How to use the check-in reminder emails & auto-cancel reservations',
            send_toggle: "Send out reminder email 15 minutes before the start of a reservation that requires check-in",
            add_button_toggle: ["Add ", "‘Cancel reservation’", " button to reminder email"],
            add_desck_toggle: ["For ", "desk", " reservations: add ", "‘Check-in’", " button to reminder email"],
            add_room_toggle: ["For ", "meeting room", " reservations: add ", "‘Check-in’", " button to reminder email"],
            auto_cancel_header: "Auto-cancel reservations that require check-in",
            cancel_desk_toggle: ["Cancel ", "desk", " reservation after", "min, if a check-in is required."],
            cancel_room_toggle: ["Cancel ", "meeting room", " reservation after", "min, if a check-in is required."],
          },
          reminder_email_card: {
            header: ["Reminder email", "for desks"],
            video_header: 'How to use the general reminder emails',
            send_toggle: ["Send reminder email ", "hours before the start of a reservation"],
            subject: {
              label: "Subject:",
              placeholder: "Reminder about your upcoming reservation"
            },
            message: {
              label: ["Message: (Markdown supported, for example to add links. ", "Check guidelines"],
              placeholder: "Add message"
            },
            agree_toggle: ["Add ", "‘I agree’", " button: by agreeing the person acknowledges they have read the message"],
            cancel_toggle: ["Add ", "‘Cancel reservation’", " button"],
            header_special: "Reminder email for meeting rooms",
            send_toggle_special: ["Send reminder email ", "hours before the start of a reservation"]
          },
          time_limit_card: {
            header: "Time limit for future reservations",
            video_header: 'How to use the time limit for future reservations',
            sub_header: "Reservations can only be made a certain time ahead by non-admin users",
            desck_toggle: ["Desk", " reservations can be created in the next"],
            room_toggle: ["Meeting room", " reservations can be created in the next"],
            special_toggle: "Reservations can be created in the next",
            postfix: "working days"
          },
          utilization_limit_card: {
            header: "Utilization limit",
            sub_header: ["Utilization of active ", " can be limited.", "Example: a site has 40 active ", ". When utilization is limited to 50%, only 20 concurrent ", " reservations can be made."],
            sub_header_types: ['desks', 'resources', 'desk'],
            toggle: ["Utilization is limited to", "percent"]
          },
          multi_day_reservation_card: {
            header: "Multi-day reservation",
            sub_header: ["Define if an employee is allowed to create one reservation for several consecutive days at once.", "When allowed, an employee will only receive the reminder emails once and only needs to check-in once at the start of his multi-day reservation, if this is required."],
            toggle: "Multi-day reservations are allowed"
          },
          cleaning_time_card: {
            header: "Cleaning",
            sub_header: "Cleaning time will be foreseen after the end of every reservation. During this period the resource cannot be booked.",
            desk_toggle: ["Time available to clean a ", "desk", "min"],
            room_toggle: ["Time available to clean a ", "meeting room", "min"]
          },
          user_message_card: {
            header: "User message when creating reservation ",
            toggle: 'Show this message when a user creates a reservation for a resource on this site',
            label: 'Message:',
            placeholder: 'Type your message here',
          },
          user_privileges_card: {
            header: "User privileges",
            video_header: 'How to set up user privileges',
            sub_header: "Define which users have access to which resources and have to get confirmation for a reservation",
            levels: {
              header: "1. Define the names of the different user levels and resource levels:",
              toggle: "User level names are the same across all sites",
              description_simple: {start: "User & resource level names", center: " can be set by site admin and are ", end: "specific for the site."},
              description_global: [
                {start: "User level names", center: " can only be set by global admin and are the ", end: "same for all sites."},
                {start: "Resource level names", center: " can be set by site admin and are ", end: "specific for the site."},
              ],
              user_label: "User level ",
              resource_label: "Resource level ",
              placeholder: "Text"
            },
            privileges: {
              header: "2. Define the privileges per user and resource level:",
              cols: "Resource level",
              rows: "User level"
            },
            rules: [
              {id: 0, line1: "Resource not available", line2: "(can be assigned by admin)", class: "red"},
              {id: 2, line1: "Resource only available ", line2: "after confirmation", class: "orange"},
              {id: 1, line1: "Resource available for", line2: "user level", class: "green"}
            ]
          },
        },
        edit_wing: {
          header: "Editing wing ",
          sub_header: "wing details",
          video_header: "How to add, edit and delete a wing",
          placeholder: "wing name",
          button: {
            back: "Back to site edit",
            add: "Add new wing",
            delete: "Delete wing"
          },
          popup: {
            text: 'Delete this wing?',
            big_descriptions:['This will delete all resources of this wing and could affect current reservations. \nCheck your to-do list if proceeding.']
          },
          assets_list: {
            header: "Resources",
            add_button: " Add resource",
            counter: " in total",
            popup: {
              text: 'Delete this resource?',
              big_descriptions:['This could affect current reservations. \nCheck your to-do list if proceeding.']
            }
          }
        },
        edit_asset: {
          buttons:{
            back: "Back to wing edit",
            add: "Add resource",
            clone: "Duplicate resource",
            delete: "Delete resource"
          },
          base: {
            header: "Basic details ",
            video_header: "How to add, edit and delete a resource & define the basic details",
            internal_external: [
              {name:"Internal only", value: true},
              {name:"External allowed", value: false}
            ],
            type_tooltipe: 'INTERNAL ROOM: used for a meeting with colleagues from within the company.\n EXTERNAL ROOM: used when also people of outside the company are invited (e.g. client).\n ZONE: contains multiple single desks that can be booked, without selecting a particular one.',
            user_tooltipe: 'Assigning a resource to a specific person, means that this person has a fixed desk and they alone can use it. They can still choose to release it for others to use, when they aren’t around.',
            zoning_tooltipe: 'Semicolon separated list of zoning names, useful for HVAC/BM integration.\n E.g.:\n lighting_7;heating_3',
            amenity_type: "other asset type",
            placeholders: {
              name: "asset name",
              user: "Select user",
              neighbourhood: "Select neighbourhood",
              amenities: "Select amenities"
            },
            labels: {
              status: "Resource status ",
              neighbourhood: "Neighbourhood ",
              user: "Assigned to ",
              level: "Resource level ",
              resource_zoning: "Resource zoning ",
              capacity: ['Seat capacity', 'Capacity'],
              type: "Resource type ",
              check_in: "Check-in required",
              amenities: "Amenities available ",
              image: "Add image (< 4MB)"
            }
          },
          size: {
            header: "Size, Shape & position ",
            video_header: "How to set up a resource: size, shape & position",
            size: {
              header: "Size & shape",
              sub_header: "Choose a shape",
              width: "Width",
              height: "Height",
              round: "Round corners"
            },
            position: {
              header: "Map position",
              horizontal: "Horizontal",
              vertical: "Vertical"
            }
          },
          calendar: {
            header: "Calendar & approval settings",
            video_header: "How to set up a resource: calendar & approval settings",
            resource: {
              label: "Resource email ",
              placeholder: "Resource email",
              tooltip:"Fill in an email to create a calendar for this resource. Tribeloo will keep the calendar up to date with reservations for this resource."
            },
            approvers: {
              label: "Approvers email ",
              placeholder: "Approvers email",
              tooltip:"Add the email address of the person who will confirm reservations for this resource. Multipe email addresses can be added separated by a semicolon ; and without any spaces before or after the semicolon"
            },
          },
          device: {
            header: "Device configuration",
            video_header: "How to set up a resource: device configuration",
            code: {
              label: "Device code ",
              placeholder: "Code"
            },
            id: {
              label: "Device ID ",
              placeholder: "ID"
            }
          },
          qr_nfc: {
            header: "QR & NFC configuration",
            video_header: "How to set up a resource: QR & NFC configuration",
            qr: {
              header: "Generate QR code",
              info: "Click on the button to generate a QR code of this resource.",
              button: "Generate QR"
            },
            nfc: "NFC configuration"
          },
          popups: {
            nfc_copy: "NFC url copied.",
            set_capacity: {
              text: 'Reduce seat capacity?',
              big_descriptions: ['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            select_owner: {
              text: 'Assign user to resource?',
              big_descriptions: ['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            internal_change: {
              text: "Change resource type to ‘Internal only’?",
              big_descriptions:["This could affect current reservations. \nCheck your to-do list if proceeding."]
            },
            remove: {
              text: 'Delete this resource?',
              big_descriptions:['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            check_card_id: {
              header: "",
              cases: [
                '1. Click "Start test" below',
                '2. On selected device, tap "Book now" or "Book later"',
                '3. Scan your card on the device',
                '4. The RFID code of the scanned card appears below',
                '5. To test another card, go to step 2'
              ],
              start: "Start test",
              stop: "Stop"
            }
          },
          assets_dragging_view: [" - click for edit", "drag & drop", "click to select"]
        },
        edit_info: {
          create: "Created on: ",
          update: "Last updated on: ",
          resource: "Resources: ",
        },
        type: {
          header: "Resource types",
          sub_header_descriprion: {
            first_line: "Standard sites have the type 'room or desk' automatically assigned.",
            second_line: ["If you want to create sites that contain another type of resource, define them here.", "These other resource types will also become visible on the Tribeloo homepage and in the tab 'More' in Outlook."]
          },
          video_header: 'How to define different resource types to book',
          card: {
            list: {
              header: "Existing resource types",
              button: "Add new resource type"
            },
            form: {
              header: ["Create resource type", "Edit resource type"],
              type: {
                label: "Resource type",
                placeholder: "Name of resource type"
              },
              icon: {
                label: "Icon",
                placeholder: "fa fa-archive",
                button: "Check icon list"
              },
              preview: {
                label: "Preview",
                text: ["Book a ", "Book a desk", "Book a meeting room"]
              },
              booking: {
                label: "Double booking by user",
                sub_label: "(desks only)"
              },
              button: {
                create: " Create type",
                delete: " Delete type",
                save: " Save changes",
              }
            }
          },
          popups: {
            unique_name: "You alredy have same type name",
            create: 'Create type?',
            save: 'Save changes?',
            add: "New resource type is added",
            saved: "Changes saved",
            delete: 'Delete this resource type?',
            cant_delete: "This resource can't be deleted, because there are still sites of this type"
          },
        },
        assets_table: {
          buttons: {
            batch: "Batch edit",
            qr: ["QR PDF selected", "QR PDF all"]
          },
          headers: {
            number: {name: "Number", popup: ""},
            resource: {name: "Resource", popup: ""},
            wing: {name: "Wing", popup: "Wings"},
            user: {name: "Assigned to", popup: ""},
            level: {name: "Resource level", popup: "Resource level"},
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            capacity: {name: "Seat capacity", popup: "Seat capacity"},
            type: {name: "Resource type", popup: "Resource type"},
            check_in: {name: "Check-in required", popup: "Check-in required"},
            amenities: {name: "Amenities", popup: ""},
            device_id: {name: "Device ID", popup: ""},
            email: {name: "Resource email", popup: ""},
            status: {name: "Status", popup: "Status"},
            from: {name: "From", popup: ""},
            to: {name: "To", popup: ""}
          },
          popups: {
            batch: {
              header: "Edit resource details",
              sub_header: "Update",
              level: "Resource level",
              capacity: "Seat capacity",
              type: {
                label: "Resource type",
                values: [
                  { name: 'Internal only', value: true },
                  { name: 'External allowed', value: false }
                ],
              },
              check_in: {
                label: "Check-in required",
                values: [
                  { name: 'yes', value: true },
                  { name: 'no', value: false }
                ],
              },
              add_amenities: {
                label: "Add amenities",
                placeholder: "Select amenities"
              },
              remove_amenities: {
                label: "Remove amenities",
                placeholder: "Select amenities"
              },
              approvers: {
                label: "Set approvers",
                placeholder: "approvers email"
              },
              neighbourhood: {
                label: "Neighbourhoods",
                placeholder: "Select neighbourhood"
              },
              status: "Resource status"
            },
            selected: "At least one resource must be selected",
            selete_selected: ["Delete the ", " selected resources?"],
            this_delete: "Delete this resource?"
          }
        },
        wayfinding_link: {
          header: "Wayfinding link",
          privacy_card: {
            header: "Privacy",
            toggle: "Logging in is required to see information about assigned resource and floormap"
          },
          layout_card: {
            header: "Layout",
            sub_header_descriprion: {
              first_line: ["When using the Outlook add-in and Chrome extension, a wayfinding link is added to the event itself. ", "This shows invitees where they need to be and to easily find their way."],
              second_line: "You can add a company logo OR company name, and define the colors for the banner, the company name and the titles."
            },
            logo: {
              label: "Company logo",
              sub_label: "(PNG, less than 4MB)"
            },
            image_info: ["max width 210px and ", "max height 50px"],
            company_name: {
              label: "Company name",
              placeholder: "Max 20 characters"
            },
            name_color_label: "Color company name",
            baner_color_label: "Color banner",
            titels_color_label: "Color titles"
          }
        }
      },
      todo: {
        navigation: {
          list: 'Reservation to-do list',
          requests: 'Requests',
          reservation_table: 'Reservation table',
          release_table: 'Release table'
        },
        main: {
          all_button_tooltip: "Assign resources automatically to current to-do's",
          dialog_asset: "Asset must be selected first",
          details_resources_button: "View resource reservations",
          legend: {
            header: "View legend",
            items: [
              {name: "Available", type_class: "green"},
              {name: "Available, but to be confirmed", type_class: "orange"},
              {name: "Available, but rules constraint", type_class: "blue"},
              {name: "Not available", type_class: "red"}
            ]
          }
        },
        list: {
          update: "Last updated: ",
          toggle: "Show only untreated request",
          toggle_visitor: "Show only visitor reservations",
          default_name_subject: "New reservation ",
          user_prefix: "by ",
          visitor_prefix: "for ",
          tooltip: "Reservation within selected time range",
          default_name_asset: "Reservation pending"
        },
        details: {
          site_change: {
            header: "Change site",
            tooltip: "Remove assigned space first"
          },
          request: {
            header: "Request",
            treated: ["Request treated", "Request untreated"],
            buttons: {
              add: "Add note",
              contact: "Contact user"
            }
          },
          details: {
            header: ["Show details", "Hide details"],
            delete_button: "Cancel reservation",
            default_name_subject: "New reservation ",
            user_prefix: "By ",
            visitor_prefix: "For ",
            seats: {
              counter: ["Requested: seats ","Requested: "],
              type: ['internal meeting', 'external meeting'],
              amenities: "Requested amenities: "
            }
          },
          popups: {
            delete: 'Delete this reservation?',
            assigned: "Space is assigned to reservation and confirmed",
            selected: "Asset must been selected",
            available: "Asset is not available",
            message_error: "error sending"
          }
        },
        work_stack: "Click here to select the stack item",
        message_popup: {
          header: "Add note",
          sub_header: "Request",
          treated: ["Request treated", "Request untreated"]
        },
        release_table: {
          info: 'Changing time range removes all filters',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "Assigned to" },
            level: { popup: 'Level assigned user', name: "Level assigned user" },
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            amenities: { popup: '', name: "Resource’s amenities" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            subject: { popup: '', name: "Subject" },
            email: { popup: '', name: "Email" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: ["Send message to ", " shown releases for "],
            remove_release: "Delete this release?",
            remove_releases: ["Delete ", " shown releases for "],
            error_message: "Error sending",
          }
        },
        reservaton_table: {
          info: 'Changing time range removes all filters',
          change_site_buton: ' Change site for all',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'User', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            level: { popup: 'Level of user', name: "Level of user" },
            group: { popup: 'User group in ', name: "User group in " },
            email: { popup: '', name: "User email" },
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            seats: { popup: 'Requested seats', name: "Requested seats" },
            internal_external: { popup: 'Resource type', name: "Resource type" },
            amenities: { popup: '', name: "Requested amenities" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resources', name: "Resource" },
            request: { popup: 'Request', name: "Request" },
            status: { popup: 'Statuses', name: "Status" },
            acknowledged: { popup: 'Acknowledged', name: "Acknowledged" }
          },
          tooltip: {
            lock: "lock all",
            unlock: "unlock all",
            acknowledged: "From reminder email: if the person clicked on the 'I agree' button and therefore acknowledges they have read the message",
            confirm: "confirm all",
            delete: "delete all",
            send_mail: "send message to all"
          },
          dialogues: {
            chang_site: 'Change site',
            confirm: ['Confirm ', ' shown reservations from '],
            send_all_message: ["Send message to ", " shown reservations for "],
            remove_reservation: "Delete this reservations?",
            remove_reservations: ["Delete ", " shown reservations for "],
            error_message: "Error sending",
            lock: ["Lock ", " shown reservations from "],
            unlock: ["Are you sure you want to unlock ", " shown reservations from "]
          }
        },
        requests_table: {
          info: 'Changing time range removes all filters',
          add_comment_buton: 'Add comment',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'User', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            request: { popup: 'Request', name: "Request" },
            comment: { popup: '', name: "Comment" },
            seats: { popup: '', name: "Request treated" },
            email: { popup: '', name: "Owner email" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: ["Send message to ", " shown reservations for "],
            error_message: "Error sending"
          }
        }
      },
      user_management: {
        navigation: {
          global_admin: "Global admins",
          admin: "Site admins",
          privileges: "User rules",
          user: "User management",
          users_table: "User table",
          groups: "User groups",
          sso: "SSO",
          provisioning: "User provisioning"
        },
        global_admin: {
          header: "Global admin management",
          admins_card: {
            header: "Define the global admins",
            sub_header: "These users have access to all sites and features",
            video_header: 'How to define the site & global administrators',
            search: "Search",
            global_admin_list_header: "Site admins that are global admin",
            admin_list_header: "Site admins",
            admin_list_header_tooltip: 'You need to be site admin first, before you can be global admin'
          },
        },
        privileges: {
          header: "User rules",
          site_card: {
            header: "Site",
            sub_header: "Select a site to define it's user rules."
          },
        },
        admin: {
          header: "Site admin management",
          site_card: {
            header: "Site",
            sub_header: 'Select a site for which you want to add or remove administrators.'
          },
          admins_card: {
            header: "Add administrators for this site",
            video_header: 'How to define the site & global administrators',
            search: "Search",
            admin_list_header: "Users that are admin for this site",
            user_list_header: "Users",
            popup: {
              title: "This user has global admin rights and therefore access to all sites.",
              descriptions: ["If you want to give this user only access to certain sites, remove them from the global admin list first."]
            }
          },
        },
        user: {
          rfid_modal: {
            header: "Assign RFID to user",
            step1: {
              header: ["Step 1", " - Enter device ID & click ‘Start’"],
              placeholder: "Device ID",
              button: "Start ",
              info: ["No resource found ", "Check or re-enter ID"]
            },
            step2: ["Step 2", " - On connected device, tap ‘Book now’ or ‘Book later’"],
            step3: {
              header: ["Step 3", " - Scan card on the device, the RFID code will appear below"],
              placeholder: "Card RFID"
            },
            step4: {
              header: ["Step 4", " - Click ‘Assign RFID’, to assign it to the user"],
              button: "Assign RFID"
            }
          },
          header: "User management",
          free_version_sub_header: ["Currently using the ", "FREE VERSION with a limit of ", " users.", " Contact ", " to upgrade."],
          site_card: {
            header: "Location",
            sub_header: "Select a location to add or remove users for this site."
          },
          create_edit_card: {
            list: {
              header: "Existing users",
              button: "Create new user",
              placeholder: "Search"
            },
            form: {
              header: ["Edit user", "Fill in details"],
              video_header: "How to create and edit a user",
              sub_header: "Select a user in the list to edit it's details.",
              name: {
                label: "Username",
                placeholder: "Name"
              },
              email: {
                label: "Email",
                placeholder: "Email"
              },
              level: {
                label: "User level ",
                placeholder: ""
              },
              group: {
                label: "User group",
                item: "Default"
              },
              site: {
                label: "Home site",
                item: "None"
              },
              resource: {
                label: "Resource",
                placeholder: ""
              },
              rfid: {
                label: "RFID",
                placeholder: "Enter RFID or click on icon on the right"
              },
              image: {
                label: "User image",
                sub_label: ["(square size, ", "less than 4MB)"]
              },
              button: {
                create: " Create user",
                reset_pin: "Reset PIN code",
                reset_password: "Reset user password",
                upload: "Upload image",
                delete: " Delete user",
                save: " Save changes"
              }
            },
            import: {
              header: "Batch upload users",
              cases: [
                "1. Download template",
                "2. Add users for this location to the template",
                "3. Upload the template file again to Tribeloo"
              ],
              button: {
                upload: "Upload file",
                download: "Download template"
              }
            },
            errors: {
              invalid_email: "Required field <email> is invalid.",
              missing_email: "Required field <email> is missing from request.",
              missing_name: "Required field <name> is missing from request.",
              missing_level: "Required field <level> is missing from request.",
            },
            popups: {
              save: 'Save changes?',
              create: 'Create user?',
              created: 'User created.',
              reset_pin: 'The reset was succesful! The user will receive an email with a new PIN code.',
              updated: 'User details have been updated.',
              delete: ' Delete this user?',
              removed: 'User removed.',
              reset_password: 'The reset was succesful! The user will receive an email with a new password.',
              send_pin: 'Send login PIN code for devices ?'
            }
          },
          pin_card: {
            header: "PIN code ",
            video_header: "When and how to use a PIN code",
            toggle: "Send PIN code by email to login on the meeting room and desk devices",
            info: "All new users and existing users that don’t have PIN code yet, will receive one."
          },
          global_settings_card: {
            header: "Global settings",
            info: "These settings are applicable for all sites"
          },
          image_card: {
            header: "Synchronise user images ",
            video_header: "How to synchronise user images of Microsoft 365 with Tribeloo",
            toggle: "Sync user images with Microsoft Office 365",
            info: "User image is synchronised once a user logs into the Tribeloo Outlook add-in"
          },
          subject_card: {
            header: "Hide reservation subject ",
            video_header: "",
            toggle: "Hide reservation subject for colleagues",
            info: 'Reservation subject shown in "who`s around" will be hidden'
          }
        },
        users_table: {
          batch_edit: {
            header: "Edit user details",
            sub_header: "Update",
            items: {
              privileges: "User group",
              level: "User level",
              site: "Home site",
              group: "User group",
              rfid: "Clear RFID for selected users"
            }
          },
          table: {
            toggle: 'Also show users from other sites',
            headers: {
              number: { popup: '', name: "Number" },
              site: { popup: 'Home site', name: "Home site" },
              home_site_privilege: { popup: 'Home site group', name: "Home site group" },
              user: { popup: '', name: "Name of user" },
              user_privilege: { popup: 'User group ', name: "User group in " },
              assigned_resource: { popup: 'Assigned resource in ', name: "Assigned resource in " },
              level: { popup: 'Level of user', name: "Level of user" },
              group: { popup: 'Group', name: "User group" },
              space: { popup: '', name: "Resource" },
              Owned_site: { popup: 'Owned_site', name: "Site of resource" },
              email: { popup: '', name: "User email" },
              rfid: { popup: 'RFID', name: "RFID" },
              override : {popup: 'Group assignment', name: "Group assignment"}
            },
            tooltip: {
              delete: "delete all selected users",
              send_mail: "send message to selected users"
            },
            dialogues: {
              error_message: "Error sending",
              email: 'Compose email for the selected users?',
              selected: "At least one user must be selected.",
              delete_all: ['Delete the ', ' selected users?'],
              delete: 'Delete this user?',
              rfid: "RFID cleared for selected users"
            }
          }
        },
        groups: {
          header: "Users groups",
          toggle_card: {
            header: "Enable user groups ",
            video_header: "How to set up user groups",
            toggle: "User groups are enabled",
            description: ["When ", "enabled", ", you can create user groups and define rules for visible sites or office days for each group.", "Assign a user to a group in user management or user table."]
          },
          creat_edit_card: {
            list: {
              header: "Existing users groups",
              button: "Add new user group",
              placeholder: "search",
              popups: {
                save: "Save changes?",
                create: "New user group is added.",
                update: "Changes saved.",
                delete: {
                  text: "Delete this user group?",
                  descriptions: ['Users from this group will be assigned to the default user group of their home site.']
                }
              }
            },
            form: {
              header: ["Create user group", "Edit user group"],
              select_button: "Select all",
              unselect_button: "Unselect all",
              create: " Create group",
              update: "Save changes",
              delete: " Delete group",
              name: {
                label: "Name ",
                placeholder: "Add name of user group"
              },
              rules: {
                toggle: ["‘Visible sites’ rule enabled", "‘Visible sites’ rule disabled"],
                tooltip: "When visible sites rule is enabled, a user has access to the active sites of his group, and is able to book a resource for these sites. When visible sites rule is disabled, this user group has access to all active sites.",
                placeholder: "Select visible sites",
              },
              days: {
                toggle: ["‘Office days’ rule enabled", "‘Office days’ rule disabled"],
                tooltip: "When office days rule is enabled, a user can make reservation only on the selected office days. When office days rule is disabled, this user group can make reservations on all days.",
                placeholder: "Select office days",
                week_days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
              }
            },
            sites_card: {
              header: "Default user group for each site",
              description_lines: [
                {start: "Users can be assigned to a specific group. In case a user doesn’t have a group assigned,", end: "the user will fall in the default group of his home site as defined below."},
                {start: "In case the user isn’t assigned to a specific group and no default group is defined for his", end: "home site, the user will only have access to his home site to book resources."}
              ],
              label: "No home site ",
              tooltipe: "This is the default group for users without a home site",
              placeholder: "group"
            }
          }
        },
        sso: {
          header: "Single Sign-On",
          description_card: {
            header: "What is it?",
            description: "Single Sign-On gives your users the ability to sign in to different applications using the same unique set of username and password. Users sign in once via the so-called Identity Provider (external to Tribeloo) and can then, from there access different apps (including Tribeloo) without logging in again. For SSO, Tribeloo supports the SAML 2.0 standard."
          },
          email_card: {
            header: "Enable welcome email",
            toggle: "Enable sending welcome email if SSO is enabled"
          },
          settings_card: {
            header: "Settings",
            items: {
              id: {
                name: "Company ID",
                tooltip: {
                  header: "",
                  descriptions: []
                }
              },
              issuer_url: {
                name: "Issuer URL",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: Identity Provider Issuer", "OneLogin: Issuer URL", "Azure: Azure AD Identifier", "Google: Entity ID"]
                }
              },
              login_url: {
                name: "SSO login URL",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: Identity Provider SSO URL", "OneLogin: SAML Endpoint", "Azure: Login URL", "Google: SSO URL"]
                }
              },
              certificate: {
                name: "Certificate",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: X.509 Certificate", "OneLogin: X.509 Certificate", "Azure: Certificate (Base64 or Raw)", "Google: Certificate 1"]
                }
              },
            },
            button: ["Disable SSO", "Enable SSO"]
          },
          popups: {
            saved: 'Configuration saved.',
            not_saved: 'Configuration not saved.',
            copied: 'Company ID copied.',
            change_sso: {
              text: ['Are you sure you want to disable Single Sign-On?', 'Are you sure you want to enable Single Sign-On?'],
              descriptions: ['All users will need to log in with a password, that they can request through ‘Forgot password?’ on the login page.', 'Users won’t be able to log in with their passwords and will need to log in with their SSO credentials.']
            }
          }
        },
        provisioning: {
          header: "User provisioning",
          description_card: {
            header: "What is it?",
            description: "User Provisioning refers to the automatic synchronization of users: people defined in your Active Directory (AD) will be automatically synchronized with the Users list in Tribeloo. E.g. new people in the AD will be added to the Users list in Tribeloo."
          },
          settings_card: {
            header: "Settings",
            items: [
              {name: "SCIM Base URL", key: "base_url", is_input: true},
              {name: "SCIM JSON Template", key: "json_template", is_input: false},
              {name: "Custom headers", key: "custom_headers", is_input: true},
              {name: "SCIM Bearer token", key: "bearer_token", is_input: false}
            ],
            button: ["Disable User provisioning", "Enable User provisioning"],
            popups: {
              copy: " copied",
              change_SCIM: {
                text: ['Are you sure you want to disable User provisioning?', 'Are you sure you want to enable User provisioning?'],
                description: ['All the current users will be kept, but won’t be updated by your external user provisioning system anymore.', 'All users will be managed externally.']
              }
            }
          }
        }
      },
      insights: {
        navigation: {
          heatmaps: "Heatmaps",
          utilization: "Utilization charts",
          history: "Reservation history",
          download: "Data download",
          statistics: "Statistics",
          tracker: "Time tracking",
          calc: "Calc resource"
        },
        calc: {
          type: "Calc resource",
          subHeader: "This page help you with a data about your price and amount of resources from 1 month to 5 years. Also it help you: if you want to change a count of recources, or move to new place, add or remove new site, wing and etc.",
          chartHeader: "Terms & summ",
          chartDescription: "Use top form to know how much you are spending for your resources"
        },
        heatmaps: {
          type: "Heatmaps",
          subHeader: "Which days and hours are most busy?",
          allText: "Average utilization rate of selected type",
          daysText: "Daily utilization rate of selected type",
          options: {
            average: "Show average utilization rate",
            daily: "Show daily utilization rate"
          },
          tooltipe: 'The average utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period. The daily utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period. For example, daily utilization of 80% and average utilization of 40% means that: 8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours. '
        },
        utilization_charts: {
          type: "Utilization charts",
          subHeader: "Which days and hours are most busy?",
          allText: "Average utilization rate of selected type",
          daysText: "Daily utilization rate of selected type",
          table_header: "Which days are most busy? Select a day to see the utilization by hour below for that day.",
          chart_header: "Which hours are most busy"
        },
        reservation_history: {
          info: 'Changing time range removes all filters',
          overlap: 'Find overlapping reservations with ',
          couter: " more...",
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            email: { popup: '', name: "User email" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resources', name: "Resource" },
            no_shows: { popup: 'No-shows', name: "No-shows" },
            subject: { popup: '', name: "Subject" },
            seats: { popup: 'Requested seats', name: "Requested seats" },
            internal_external: { popup: 'internal/external', name: "internal/ external" },
            status: { popup: 'Statuses', name: "Status" },
            messages: { popup: 'Messages', name: "Messages" },
            acknowledged: { popup: 'Acknowledged', name: "Acknowledged" }
          },
          tooltip: {
            acknowledged: "From reminder email: if the person clicked on the 'I agree' button and therefore acknowledges they have read the message",
            send_mail: "send message to all"
          },
          dialogues: {
            error_message: "Error sending"
          }
        },
        time_tracking: {
          check: "events",
          info: 'Changing time range removes all filters',
          info_date: 'Find overlapping reservations with ',
          headers: {
            user: { popup: 'User', name: "User" },
            email: { popup: 'User email', name: "Email" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            zone: { popup: '', name: "Time zone" },
            period: { popup: '', name: "Time period" },
          },
          dialogues: {
            error_message: "Error sending"
          }
        },
        data_download: {
          header: "Data download",
          popup: {
            big_titel: 'Update data retention?',
            big_descriptions: ['Older reservation data will be deleted and no longer accessible'
                              ]},
          retention_card: {
            header: "Data retention",
            toggle: ["Reservations & releases data is automatically deleted after", "days."],
            info: "There is a minimum of 7 days and a maximum of 400 days."
          },
          automade: {
            header: "Daily automated report via email",
            sub_header: "Receive data of the reservations & releases of the previous day",
            check: "Send daily email to",
            placeholder: "Email address",
            error: "Please enter a valid email address"
          },
          download: {
            header: "Download data for a specific time range",
            info: "Data of reservations & releases",
            button: {
              all: " Download all",
              one: " Download data range"
            }
          }
        },
        statistics: {
          header: "Statistics",
          sub_header: "The table shows you a current view of all the sites and their amount of resources and users.",
          card: {
            header: "Resources and users",
            button: "Download",
            table_headers: {
              type: "Resource type",
              resources: "Resources",
              users: "Users"
            }
          }
        },
        reusable_components: {
          main_header: {
            header: "Insights",
            day: " Move timeframe per day ",
            week: " Move timeframe per week "
          },
          scale_utilization_rate: "Scale utilization rate",
          card_table: {
            table_headers: {
              special: "utilization rate",
              average: "Average",
              daily: "Daily"
            },
            description_hightlight: [
              {start:"The ", hightlight: "average", end: " utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period."},
              {start:"The ", hightlight: "daily", end: " utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period."}
            ],
            description_example: ["  For example, daily utilization of 80% and average utilization of 40% means that: ", "8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours."]
          },
          maps_cards: {
            button: {
              select: "Select",
              show_all: "Show all"
            },
            internal_external: [', Internal only', ', External allowed'],
            owner: "Owner: ",
            usage: "Usage",
            average: "average: ",
            daily: "daily: ",
            days: "days active: ",
          }
        }
      },
      space_issues: {
        navigation: ['Issue management','Cleaning', 'Issues table'],
        list: {
          main_header: 'Select a site',
          main_sub_header: 'Find resource',
          placeholder: "Please search or select a site",
          placeholder_asset: "Please search or select a resource",
          search: 'View all site issues',
          toggle: ['Click to show closed issues','Click to show open issues'],
          couter: [' open resource issues', ' closed resource issues'],
          img_link: 'See photo',
          popup_text: 'E-mail sent to your account with information about the issue. This can be forwarded to somebody that can solve it.',
        },
        table_cleaning: {
          main_header_warning: 'Changing time range removes all filters',
          table_headers: {
            id:'ID',
            data_time: 'Date & time',
            wing: 'Wing',
            resource: 'Resource',
            cleaned_at: 'Cleaned at',
            cleaned_by: 'Cleaned by'
          }
        }
      }
    },
    user: {
      external_events_list: {
        headers: ['Google events','Outlook events'],
        select: {
          header: 'Organised by',
          placeholder: 'Select colleague'
        },
        info: ['No reservations available from your calendar','Show only events with Tribeloo reservations','Selected event']
      },
      find_colleague: {
        email_select: {
          more: "more...",
        },
        user_select: {
          header: 'Find a colleague',
          placeholder: 'Select colleague',
          info: ['Request access from your colleagues in ','My Tribe',' to see their reservations']
        },
        list: {
          count: ' (non-private)',
          info: ['Select a colleague to see their reservations.', 'Click on reservation to view on map.']
        },
        details: {
          return_button: 'Return to list of reservations',
          info: 'Select an available asset on the map to create a reservation for yourself',
          modals: {
            delete_reservation: 'Reservation deleted',
            create_reservation: 'Create reservation?'
          }
        }
      },
      find_room_report: {
        headers: {
          main: 'Report an issue for a resource ',
          site: 'Select a site',
          resource: 'Find resource'
        },
        placeholders: {
          site: 'Please search or select a site',
          resource: 'Please search or select a resource',
          tooltip: 'When reporting an issue, it will be send to the site admin to be solved. Once solved, it will be removed from this list.',
          description: ['Select a resource to report an issue', 'Explain the issue here'],
        },
        loading: "Loading... ",
        image_description: "Image (< 4MB)",
        button: {
          photo: 'Add photo',
          send: 'Submit issue'
        },
        image_info: 'Image (< 4MB)',
        couter: ' open issues',
      },
      releases_list: {
        add_button: 'Add new release',
        user_select: 'Releases for',
        colleagues_info: "You don't have colleagues with visible releases",
        counter: 'No releases',
        right_panel_info: ['Select a colleague from the dropdown to see their releases.', "You don't have colleagues with visible release."]
      },
      release: {
        pageName: 'release',
        header: 'Release details',
        placeholder: 'Subject',
        buttons: {
          create: ['Release your ', 'Confirm release'],
          edit: ['Cancel this release?', 'Cancel release']
        },
        description: [' There are ', 'no ', 'active reservation(s) for this ', ' during this time range.'],
        modals: {
          user: 'Change user? Current release will be deleted.',
          not_available: "Release not available ",
          delete: 'Delete this release?'
        }
      },
      reservations_list: {
        add_button: 'Add new reservation',
        user_select: 'Reservations for',
        tabs: [
          {name:"Personal", state: false},
          {name:"Visitor", state: true}
        ],
        colleagues_info: "You don't have colleagues with visible reservations",
        counter: 'No reservations',
        right_panel_info: ['Select a colleague from the dropdown to see their reservations.','Edit a reservation or create a new one to see the available resources']
      },
      reservation: {
        pageName: 'reservations',
        header_requests: ['No reservation yet.', 'Fill in reservation details and check availability.'],
        details: ' details',
        end: 'End reservation',
        amenities_item: 'other request',
        snack_bars: {
          create: 'Reservation created',
          update: 'Reservation updated'
        },
        visitor: {
          text: "Reservation for visitor"
        },
        placeholders: {
          visitor: "Email address",
          amenities: 'Select amenities',
          no_amenities: 'Not items found',
          subject: 'Subject',
          neighbourhood: "Select neighbourhood"
        },
        public: {
          text:'Make private for everybody',
          tooltip: 'Your reservation is always visible for members of your Tribe. Activating the toggle will make it invisible for everybody, including your Tribe.'
        },
        right_panel_info: {
          no_checked_availability: ['Fill in the details and check', 'availability to book a resource'],
          checked_availability: ['Request details changed, ', 'check availability again']
        },
        modals: {
          amenity: {
            text: "Your current reservation doesn’t fit all the amenities!",
            descriptions: [
              "But no worries, you can continue with the current reservation.",
            ]
          },
          error_404: "Reservation not available",
          requested_capacity: {
            text: "Your assigned space doesn’t have enough seats!",
            descriptions: [
              "Continue, to keep the new amount of seats and look for a new space.",
              "Or cancel the seat change and keep your current space.",
            ]
          },
          internal: {
            text: "Your assigned space is only for internal meetings!",
            descriptions: [
              "Continue, to keep the change and look for a new space.",
              "Or cancel the change and keep your current space.",
            ]
          },
          time: {
            text: "Your reservation is not available for this time!",
            descriptions: [
              "Continue, to keep the new time and your reservation will be updated.",
              "Or cancel the time change and keep your current reservation."
            ]
          },
          space: {
            text: "Your assigned space is only for internal meetings!",
            descriptions: [
              "Continue, to keep the change and look for a new space.",
              "Or cancel the change and keep your current space.",
            ]
          },
          invalid_email: "You should enter valid visitor email or turn off visitor toggle to create a reservation.",
          delete: {
            simple: {text:'Delete this reservation?'},
            ongoing: {text:'End this reservation?'},
            visitor_change: {
              text:{
                toggle: 'Switching between personal and visitor reservation will remove the current resevation!',
                email: "Changing the visitor's email address will remove your current reservation!",
              },
              descriptions: [
                "Continue, to keep the changes and your reservation will be updated.",
                "Or cancel the change and keep your current reservation."
              ]
            },
            user_change: {
              text:'Changing the user, will remove your current reservation!',
              descriptions: [
                "Continue, to keep the new user and your reservation will be updated.",
                "Or cancel the change and keep your current reservation."
              ]
            },
            site_change: {
              text:'Changing site, will remove your current reservation!',
              descriptions: [
                "Continue, to keep the new site and your reservation will be updated.",
                "Or cancel the change and keep your current reservation."
              ]
            }
          }
        }
      },
      home: {
        nwesfeed_list: 'No news today!',
        pageName: 'home',
        emergency: {
          header: 'EMERGENCY MESSAGE',
          by: 'by'
        },
        reservation: {
          headers: {
            upcoming: 'Your upcoming reservation',
            current: 'Your current reservation',
            no_upcoming: 'No upcoming reservations'
          },
          descriptions: ['Here to help you!', 'Make your choice with the buttons below.'],
          loading: 'Loading ...'
        },
        newsfeed_header: 'Newsfeed'
      },
      settings: {
        pageName: 'settings',
        h1: 'User settings',
        password: {
          errors: ["At least 1 number, 1 symbol and 8 long", 'Passwords are not the same'],
          rules: 'Password must contain at least: 8 characters, 1 upper case, 1 lower case, 1 digit',
          rules_v2: 'Must contain at least: 8 characters, 1 upper case, 1 lower case, 1 digit',
          placeholders: ['Enter new password', 'Re-enter new password'],
          info_ok:"Password is changed",
          info_not_ok: "The password didn't change"
        },
        h3_sections: {
          password: 'Change password',
          image: 'Add user image',
          site: 'Default site for reservation',
          wing: 'Default wing for reservation',
          time: ' Time format',
          language: 'Language',
          preferences: 'Preferences',
          preferences_v2: 'User Preferences',
          delegate: 'Delegate',
          calendar: 'Calendar invites',
          wants_asset_details_popup: 'Maps info'
        },
        subsections: {
          app: "App version",
          sites: "Visible sites",
          language: "Language",
          time: "Time Format",
          calendar: "Calendar Invites"
        },
        user_image_info: ['Please contact your office ', 'manager to change user image'],
        user_image: ['User image', '(square size, less than 4MB)', 'Upload a square sized image that is less than 4MB'],
        toggle: 'Use 24h time format',
        app_version: "Use the new Tribeloo (restart required)",
        calendar_toggle: 'Send calendar invites for reservations made in web application',
        wants_asset_details_popup_toggle: 'Show details popup when selecting a resource on the map',
        no_delegates: 'No delegates',
        delegates_action_list: {
          search: 'Search',
          edit: 'Colleagues that can create or edit my reservations',
          add: 'Add colleagues'
        },
        delegates_modal: "By checking this box the below table will be discarded and everybody will be able to see your public meetings."
      },
      who_is_arround: {
        header: 'Who is around',
        tooltip: 'Check who of your Tribe is at the office at a certain time. Don’t have a Tribe yet? Create one in ‘My Tribe’.',
        date_time: {
          main: 'Date & time',
          helper: 'HOUR'
        },
        info: 'Select an available resource on the map to create a reservation for yourself',
        users_list: {
          header: {hide: 'Hide', show: 'Show', postfix:' colleague list'},
          placeholder: 'Username'
        },
        right_panel_info: 'Choose a location to see the occupancy',
        modal: 'Create reservation?',
      },
      my_tribe: {
        header: 'My Tribe',
        tabs: ['Followers', 'Following'],
        info: ['Colleagues that can see your non-private reservations', 'Requests to follow you', 'Colleagues whose non-private reservations you can see'],
        serach_header: 'Search results',
        no_results: 'No results',
        placeholders: ['Search', 'Search for colleagues to follow'],
        modals: {
          confirm: {
            text:"Do you want to follow this colleague aswell?",
            cancel: "Don't follow",
            confirm: "Follow"
          },
          delete: {
            text:"Do you want to delete the request of this colleague?",
            cancel: "Cancel",
            confirm: "Delete request"
          },
          remove: {
            text:"Do you want to remove this colleague?",
            cancel: "Cancel",
            confirm: "Remove"
          },
          cancel: {
            text: 'Do you want to cancel your request?',
            cancel: 'No',
            confirm: 'Cancel request'
          },
          unfollow: {
            text: 'Do you want to unfollow this colleague?',
            description: ['If you change your mind, you’ll have to request to follow again.'],
            cancel: "Cancel",
            confirm: 'Unfollow'
          }
        }
      },
      squads: {
        header: 'My Squads',
      },
      qr: {
        header: 'QR check-in or walk-in',
        checkout: "Want to check out?",
        checkedout: "Checked out",
        photo_description: 'Scan the QR code to check in for your current reservation or to create a walk-in reservation',
        start_scan_description: 'Point at QR code and touch here',
        errors: {
          camera: "Camera not supported in Chrome, please use Safari",
          app: "Please try to use special qr-scanner app!",
          device: "Please use the mobile or tablet device with back-camera!"
        },
        modals: {
          info: 'Access to the camera is needed to scan QR code.',
          check_in: {
            header: 'Checked in for:',
            from_to: ['From: ',', to '],
            subject_default: 'Reservation'
          }
        }
      },
      help_center: {
        nav: {
          demo: {
            header: 'Quick demo',
            card: {
              headers: [],
              descriptions: ['Watch this quick demo to get a good overview of all the Tribeloo features.'],
              links: []
            }
          },
          get_started: {
            header: 'Getting started',
            sub_headers: ['Setup video', 'Single-Sign-On' ,'User provisioning'],
            card: {
              headers: [],
              descriptions: ['Learn how to get started with Tribeloo and do the initial setup of the platform.', 'Check the guidelines on how to install the Single-Sign-On feature ', 'Check the guidelines on how to install the User provisioning feature '],
              links: ['here.']
            }
          },
          site_manager: {
            header: 'Site manager',
            sub_headers: ['Run-through video', 'Setup devices'],
            card: {
              headers: ['Setup devices'],
              descriptions: ['Learn how to use Tribeloo as an office manager.', 'Learn how to configure the desk and meeting room devices.'],
              links: []
            }
          },
          user: {
            header: 'User',
            sub_headers: ['Run-through video', 'Using Outlook add-in', 'Install Outlook add-in', 'Using Chrome extension' ,'Install Chrome extension', 'Install app on iPhone' ,'Install app on Android'],
            card: {
              mobile: 'Learn how to use the the Tribeloo mobile app and web booking solution.',
              outlook: {
                headers: ['How to use the Outlook add-in', 'How to install the Outlook add-in'],
                descriptions: ['Learn how to use the Tribeloo add-in for Outlook.', 'Learn how to install the Tribeloo add-in for Outlook. ', 'Go to the ', 'Microsoft store', ' to download it.',  ]
              },
              google: {
                headers: ['How to use the Chrome extension', 'How to install the Chrome extension'],
                descriptions: ['Learn how to use the Tribeloo extension for Chrome, and the integration with your Google Calendar', 'Learn how to install the Tribeloo extension for Chrome, integrated with your Google calendar. ', 'Go to the ', 'Chrome store', ' to download it.']
              },
              pwa: {
                headers: [],
                descriptions: ['Check the video below or ', 'download the PDF', ' on how to install the Tribeloo app on your iPhone', ' on how to install the Tribeloo app on your Android']
              }
            }
          },
          feedback: {
            header: 'Contact us',
            sub_headers: [],
            card: {
              headers: ["Can't find what you're looking for? Or want to give feedback?"],
              descriptions: ['Let us help you! Please share as many details as you can so we can help you faster.'],
              links: []
            }
          }
        },
        main: {
          header: 'Help center',
          subheaders: ['Welcome!', 'Find useful information on how to get started with Tribeloo.', 'You can always come back here by clicking ‘Help Center’ on the homepage or in settings.'],
          orange_card: ['Click on the ', ' icons in the software for more information about a specific topic.']
        }
      }
    }
  },
  shared_components: {
    wings_select: "All wings",
    activity_select: {},
    assets_map:{
      types_external_internal: [', Internal only', ', External allowed'],
      owner: 'Assigned to: ',
      time_slots: "Available: ",
      no_time_slots: "No timeslots available",
      not_reservable: "You can't book this resource"
    },
    attendees_modal: {
      header_text: {
        share: 'Share this with your colleagues',
        attendees: 'Are attending this event'
      },
      info: ['Select colleagues from the dropdown to send them an email.', 'Please ask your colleagues to add you to their tribe first'],
      placeholdrs: {
        colleagues: 'Select colleague',
        message: 'Type your text here'
      },
      button_attendees: ['Not going after all?', 'Are you going?'],
      more: ' more...',
      send: 'Send',
      send_all: 'Send mail to all',
      counter: 'attendees'
    },
    total_counter_download_table_csv: {
      batch_buton: 'Batch edit',
      button: 'Download',
      text: ' shown',
      selected: " selected",
      info: 'No data for selected parameters'
    },
    confirm_dialog: {},
    date_time_range_modal: {
      headers:{
        main: 'Set date & time',
        from: 'From',
        to: 'To'
      },
      tooltip: 'Click for display calendar',
      info: "'To' time must come after 'From' time"
    },
    date_time_range_view: {
      headers:{from: 'From', to: 'to', start: 'Starts', end: 'Ends'}
    },
    day_time_mini: {
      header: ["Set ", " date & time"],
      tooltip: {
        calendar: "Click for display calendar",
        time: "Click for display time"
      }
    },
    download_xlsx: {},
    expanded_filter_assets_list: {
      modal: {
        header: "Resource info",
        report_button: "Report an issue"
      },
      header: ' availability list',
      description: 'Only show with all amenities',
      nospace: 'Nothing available',
      types_external_internal: [', internal', ', external'],
      placeholder: 'Search',
      tooltip: 'Space doesn’t contain all the requested amenities'
    },
    filter_dialog: {},
    icon_video: "More info",
    info_dialog: {},
    kiosk_logout_info_modal: {
      message: "Are you still working here?",
      info: ["Your session will end in ", " seconds. "]
    },
    newsfeed_create_edit_item: {
      popup: "Discard unsaved changes?",
      markdown: "Styling with Markdown is supported",
      link: "Check guidelines",
      image_info: "Image (< 4MB, size 335 x 75 px)",
      radio: ["Type:", "News", "Event"],
      placeholder: {
        event: "New event",
        location: "Location",
      }
    },
    newsfeed_item: {
      default_subject: '',
      actions: ['more', 'close']
    },
    loading_table_spinner: 'fetching data',
    reservation_header_info: {
      state: ['Reservation pending', 'Assigned'],
      info: "Check availability to assign one",
      internal_external: [ ', internal', ', external']
    },
    reservation_tipe_modal: 'No options to add, please edit existing reservation',
    site_dialog: {},
    site_select: {
      placeholder: 'Select site',
      tooltip: 'Click here to select the site',
      options: ['All sites', 'No home site']
    },
    site_wings_reservation_view_external_data_set: "Click to go to this wing",
    table_datetime_range_helper: {},
    time_range_helper: {},
  },
  user_shared_components: {
    capcity_internal_block: {
      placeholder: 'seats',
      // tooltip: 'External meeting: with external visitors &#13;Internal meeting: only with internal employees',
      tooltip: 'External meeting: with external visitors. Internal meeting: only with internal employees',
      select: ['Single space', 'Internal meeting', 'External meeting', 'Room']
    },
    list_item: {
      internal_external: [', internal',', external'],
      info_reservation: ['Reservation pending, you will soon be assigned one.', 'Or check availability to choose one yourself.'],
      subject: ['New release', 'New reservation '],
      status: 'released',
      header: 'Your '
    },
    message_details: {
      toggle:'Add request',
      info: ['Request untreated', 'Request treated'],
      placeholder: "Leave a request for the office manager if you have additional requests"
    },
    weeks_repeater: {
      title: ' Repeat weekly until',
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    },
    repeater_modal: {
      header: 'Customize reoccurence',
      repeat_until: 'Repeat until',
      repeat_on: 'Repeat on'
    }
  },
  reusable_data: {
    time_tracking_popup: {
      big_titel: "You worked for ",
      descriptions: ["Do you want to end your workday?"]
    },
    tables:{
      from: "From",
      to: "To",
      date_time: "Date & time",
      // ID: "ID",
      // Number: "Number",
      // User: "User",
      // User: "Name of user",
      // to: "Assigned to",
      // to: "Level assigned user",
      // to: "Level of user",
      // Subject: "Subject",
      // to: "Requested seats",
      // to: "internal/ external",
      // Amenities: "Amenities",
      // to: "Requested amenities",
      // to: "Resource’s amenities",
      // Wing: "Wing",
      // Wings: "Wings",
      // Resource: "Resource",
      // Resource: "Site of resource",
      // Resource: "Resource email",
      // Resource: "Resource type",
      // Resource: "Resource level",
      // Resource: "Request",
      // Status: "Status",
      // to: "Asset level",
      // to: "Seat capacity",
      // to: "Requested seats",
      // to: "Cleaned at",
      // to: "Cleaned by",
      // to: "Device ID",
      // to: "Check-in required",
      // Email: "Email",
      // RFID: "RFID",
      // to: "No-shows",
      // to: "",
      // to: "",
    },
    feedback: {
      header: 'Give feedback',
      placeholders: ['Select type of feedback', 'Add a description'],
      types: ['bug', 'idea', 'question', 'other'],
      buttons: ['Cancel', ' Send'],
      issue_text: "There was an issue sending your feedback. Please try again later. Thank you!"
    },
    user_navigation: {
      no_actions_nav: {
        home: 'Home',
        my_tribe: 'My Tribe',
        squads: 'My Squads',
        outlook_events: 'Outlook events',
        google_events: 'Google events',
        qr: 'QR check-in or walk-in',
        tracker: ["Play workday, ", "End workday, "],
        reservations: 'Manage reservations',
        releases: 'Manage releases',
        who_is_arround: 'Who is around',
        find_colleague: 'Find a colleague',
        report_issue: 'Report an issue',
        user_settings: 'User settings',
        help_center: 'Help center'
      },
      actions_nav: {
        book_desk: 'Book a desk',
        book_meeting_room: 'Book a meeting room',
        add_new_release: 'Add new release'
      }
    },
    button: {
      ok: 'OK',
      add: 'Add',
      show: 'Show',
      hide: 'Hide',
      send: 'Send',
      save: 'Save',
      edit: 'Edit',
      more: 'More',
      close: 'Close',
      email: 'Email',
      login: 'Log in',
      logout: 'Log Out',
      check_in: 'Check in',
      submit: 'Submit',
      delete: 'Delete',
      select: 'Select',
      cancel: 'Cancel',
      upload: 'Upload',
      remove: 'Remove',
      reopen: 'Reopen',
      comment: 'Comment',
      confirm: 'Confirm',
      inverse: 'Inverse',
      refresh: 'Refresh',
      continue: 'Continue',
      download: 'Download',
      show_all: 'Show all',
      checked_in: 'Checked in',
      select_all: 'Select all',
      batch_edit: 'Batch edit',
      upload_image: 'Upload image',
      remove_image: 'Remove Image',
      assign_me_one: 'Assign me one',
      back_to_dashboard: '< Back to Dashboard',
      cancel_reservation: 'Cancel reservation',
      check_availability: 'Check availability',
      retutn_releases_list: 'Return to list of releases',
      retutn_reservations_list: 'Return to list of reservations',
      retutn_reservations_releases_events_list: [ 'Return to ', ' events']
    },
    release_reservation_text: {
      outlook: 'Change time in Outlook calendar',
      google: 'Change time in Google calendar'
    },
  },
  v2_shared_components: {
    calc: {
      sum: "Total summ: ",
      count: "count: ",
      price: "price: "
    },
    tribeloo_asset_info: {
      user_info: " Assigned to you",
      user_release_info: "This resource has been assigned to you. Book it if you want to use it.",
      user_booked_info: "This resource has been assigned to you and is available anytime for you to use.",
      invited_users: " invited colleagues and subject",
      time_slot: "Unavailable today",
      buttons: {
        invite: 'Invite colleagues',
        book: 'Confirm booking',
        update: 'Confirm changes',
        delete: 'Cancel booking',
        edit: 'Edit booking'
      }
    },
    tribeloo_asset_list: {
      headers: {
        menu: "Tribeloo tools",
        favorites: "Favorites"
      },
      filter: {
        search: 'Search',
        all: "All",
        users_only: "Users only",
        resources_only: "Resources only"
      },
      menu_items: {
        colleagues: {
          subtitle: "Find a colleague",
          description: "Easily collaborate with your colleagues"
        },
        bookings: {
          subtitle: "My Bookings",
          description: "An overview of all your bookings"
        },
        releases: {
          subtitle: "Releases",
          description: "Release your dedicated desk for others to use"
        },
        qr: {
          subtitle: "QR Scanner",
          description: "Book or check-in using the QR Scanner"
        }
      }
    },
    tribeloo_asset_list_item: {
      check_in: ["Checked In", "Check In"]
    },
    tribeloo_asset_release_popper: {
      buttons: {
        create: "Create Release",
        cancel: "Cancel Release"
      }
    },
    tribeloo_assets_popper: {
      title: "Available Resources"
    },
    tribeloo_component_filter: {
      title: "Filter Options",
      date_range: ["Date Range", "From", "To"],
      type: "Resource Type",
      location: "Place"
    },
    tribeloo_bookings_list: {
      subtitle: ['Find Colleagues', 'My Bookings'],
      description: ['Search for colleagues to find their bookings and optimise collaboration', 'An overview of all your bookings. Manage all your bookings in one place.'],
      search: " search results.",
      not_found: "No Colleagues found."
    },
    tribeloo_camera: {
      camera_error_message: "Please give access to camera",
      camera_not_found: "No camera found."
    },
    tribeloo_date_picker: {
      max_selection_info: "Max multi-day selection reached",
      days_selected_text: "days selected",
      toggle_text_info: ["Multi-day select ", "Multi-day select"]
    },
    tribeloo_download_image: {
      info_dialogue: "Your image is too large.\nPlease select image with size less than 4MB."
    },
    tribeloo_edit_invites: {
      title: "Edit Booking Invitation",
      subtitle: "An email will only be send to newly added colleagues.",
      buttons: {
        main_invite: "Invite Colleagues",
        send_invite: ['Resend Invite', 'Send Invite']
      },
      placeholder: "Invites Updated"
    },
    tribeloo_favorites_list_item: {
      other_info: " Active Bookings"
    },
    tribeloo_map_legend: {
      button: "Center Map",
      map_legend_title: "Map Legend",
      map_legend_items: {
        available: "Available",
        confirm: "Needs Confirmation",
        not_for_user: "Not for user",
        unavailable: "Unavailable"
      }
    },
    tribeloo_qr_scanner: {
      title: "Scan QR Code",
      subtitle: "Place the QR code in front of the camera to book or check-in.",
      error_message: "Error in QR Code scanning",
      processing_message: "Scanned decoding in progress"
    },
    tribeloo_releases_sidebar: {
      title_resources: "My Resources",
      title_releases: "My Releases",
      subtitle: "Releases",
      description: "Configure the time, date and choose the resource you want to release.",
    },
    tribeloo_type_location_neighbourhood: {
      labels: {
        type: "Resource type",
        site: "Site",
        wing: "Wing",
        neighbourhoods: "Neighbourhoods"
      }
    },
    tribeloo_upload_profile_pic: {
      title: "Upload Image",
      subtitle: "Choose a profile picture to show on the map",
      info_dialog_component: "Your image is too large.\nPlease select image with size less than 4MB.",
      support_text: "Supports JPG or PNG",
      dd_text: "Drag and drop image or ",
      browse_text: "browse",
      buttons: {
        save: "Save image",
        upload: "Upload image",
        capture: "Take picture using webcam",
      },
      img_alt: "New profile picture"
    },
    tribeloo_whoisaround: {
      title: "Who Is Around?"
    },
    tribeloo_more_settings: {
      title: "More Settings",
      labels: {
        seats: "Seats",
        amenities: "Amenities",
        visitor: "Invite visitor"
      },
      toggles: {
        amenities: "Only show with all amenities",
        booking: "Booking not visible for others"
      },
      placeholders: {
        visitor: "Visitor Email",
        amenities: "No amenities selected"
      },
      info: "More settings Unavailable"
    },
    tribeloo_time_range: {
      labels: {
        select: "Quick Select",
        start: "Start Time",
        end: "End Time"
      },
      rangeOptions: {
        fullDay: 'Full Day',
        morning: 'Morning',
        afternoon: 'Afternoon',
        customTime: 'Custom Time'
      }
    },
    tribeloo_time_picker: {
      tooltip_error: "Invalid time format",
    },
    tribeloo_single_file_upload: {
      button_save: "Save Cropped Image"
    },
    tribeloo_pop_up_confirmation: {
      titles: {
        cancel: "Booking Cancellation",
        edit: "Edit Booking Successful",
        invite: "Edit Booking Invitation",
        booked: "Booking Successful!",
        options: "Optional booking options",
        addSubject: "Add Subject",
      },
      subtitles: {
        edit_booked: "An email will be sent to you with information about your booking.",
        invite: "An email will only be send to newly added colleagues.",
        old: "Old Booking",
        new: "New Booking",
        cancel: "Once a booking is cancelled, it cannot be undone."
      },
      input: ['Invites Updated', 'Colleagues Invited'],
      subjectInput: {
        saved: 'Subject saved',
        placeholder: 'Subject name'
      },
      buttons: {
        confirm: 'Confirm',
        main: "Invite Colleagues",
        booKingSubjectInvitations: "Booking Subject & Invitations",
        send: "Send Invite",
        update: "Update Invites",
        cancel: "Cancel booking",
        nevermind: "Nevermind",
        saveSubject: "Save subject"
      }
    },
    tags_modal: {
      title: "tags",
      subtitle: "Use tags to easily search, group and invite other users. Your tags are only visible to you.",
      small_title: ['Suggestions', 'All Tags'],
      colleagues: "colleagues",
      placeholder: "Search or Add Tags",
      add_button: ['Add', 'Add New Tag'],
      save_button: "Save Changes"
    },
    tribeloo_base_user_settings: {
      settings: "User Settings",
      tribe: "My Tribe"
    },
    tribeloo_filter_tribe_members: {
      title: "Filter Options",
      tag: "Tags",
      site: "Home Site"
    },
    tribeloo_my_tribe: {
      description: " Tag colleagues to easily find them when creating a reservation and define your own groups.",
      search: "Search"
    },
    tribeloo_tribe_member: {
      title: "Tags",
      not_found: "No Tags Found",
      edit_button: "Edit tags"
    }
  },
  v2_pages: {
    tribeloo_default_page_holder: {
      messages: {
        update_success: 'Updated Booking Successfully',
        update_failed: 'Failed to Update Booking.',
        cancel_failed: "Failed to cancel release",
        error408: "Do you wont to force book?"
      }
    },
    tribeloo_external_invites: {
      info: "Your Invite is Invalid",
      button: "Go home"
    }
  }
};
