<mat-form-field *ngIf="!showUser" class="select" matTooltip="{{languages.tooltip}}" matTooltipPosition="above">
  <mat-select [(ngModel)]="selectedSite" placeholder="{{languages.placeholder}}">
    <mat-option *ngIf="displayALL" style="color: #4a4a4a !important" [value]="-2" (click)="itemSiteCahnge(-2)">{{languages.options[0]}}</mat-option>
    <mat-option *ngIf="is_super_admin && displayNone" style="color: #4a4a4a !important" [value]="-1" (click)="itemSiteCahnge(-1)">{{languages.options[1]}}</mat-option>
    <mat-option style="color: #4a4a4a !important" *ngFor="let site of sitesData" [value]="site.id" (click)="itemSiteCahnge(site.id)">
      {{ site.name_with_tz }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field *ngIf="showUser" class="select" matTooltip="{{languages.tooltip}}" matTooltipPosition="above">
  <mat-select [(ngModel)]="selectedSite" placeholder="{{languages.placeholder}}">
    <mat-option style="color: #4a4a4a !important" *ngFor="let site of sitesData" [value]="site.id" (click)="itemSiteCahnge(site.id)">
      {{ site.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
