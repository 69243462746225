import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import * as moment from 'moment';
import * as _ from "lodash";

@Component({
  selector: 'time-range-helper',
  templateUrl: './time-range-helper.component.html',
  styleUrls: ['./time-range-helper.component.css']
})
export class TimeRangeHelperComponent implements OnInit {

  ngOnInit() { }

  dateTime: any;

  @Input() set setDateTimeRange(value) {
    if (value != undefined) {
      this.dateTime = _.cloneDeep(value);
    }
  }
  @Input() minDate: any = null;

  @Output() dateTimeRangeChange : EventEmitter<any>;

  constructor() {
    this.dateTimeRangeChange = new EventEmitter<any>();
  }

  dayIncreaseDecrease(isIncrease) {
    if (isIncrease) {
      this.dateTime.from = moment(this.dateTime.from).add(1,'days').toDate();
      this.dateTime.to = moment(this.dateTime.to).add(1,'days').toDate();
    } else {
      console.log(this.minDate)
      console.log(moment(this.dateTime.from).add(-1,'days').toDate())
      console.log(moment(this.minDate).isBefore(moment(this.dateTime.from).add(-1,'days').toDate()))
      
      if(this.minDate && !moment(this.minDate).isBefore(moment(this.dateTime.from).add(-1,'days').toDate())) {
      // if(this.minDate && moment(moment(this.dateTime.from).add(-1,'days').toDate()).isBefore(this.minDate)) {
        return;
      }
      this.dateTime.from = moment(this.dateTime.from).add(-1,'days').toDate();
      this.dateTime.to = moment(this.dateTime.to).add(-1,'days').toDate();
    }

    this.dateTimeRangeChange.emit(this.dateTime);
  }

  toDay() {
    this.dateTime.from = moment().hour(0).minute(0).second(0).toDate();
    this.dateTime.to = moment().hour(23).minute(59).second(0).toDate();

    this.dateTimeRangeChange.emit(this.dateTime);
  }

}
