<div class="uploader" *ngIf="!isLight">
  <label for="file-upload{{editItemId}}" class="custom-file-upload" fxLayoutAlign="center center"><i class="fa fa-cloud-upload" aria-hidden="true"></i> <span>{{nameBtn}}</span></label>
  <!-- <input id="file-upload{{editItemId}}" type="file" onclick="this.value = null" (change)="fileChange($event)" multiple="false" placeholder="Upload image" accept=".png,.jpeg,.jpg,.gif,.bmp,.tiff,.svg"> -->
  <input id="file-upload{{editItemId}}" type="file" onclick="this.value = null" (change)="fileChange($event)" multiple="false" placeholder="{{nameBtn}}" accept=".png,.jpeg,.jpg,.gif,.bmp,.tiff">
</div>
<div class="uploader" *ngIf="isLight">
  <label for="file-upload{{editItemId}}" class="custom-file-upload-light" fxLayoutAlign="center center"><i class="fa fa-cloud-upload" aria-hidden="true"></i> <span>{{nameBtn}}</span></label>
  <!-- <input id="file-upload{{editItemId}}" type="file" onclick="this.value = null" (change)="fileChange($event)" multiple="false" placeholder="Upload image" accept=".png,.jpeg,.jpg,.gif,.bmp,.tiff,.svg"> -->
  <input id="file-upload{{editItemId}}" type="file" onclick="this.value = null" (change)="fileChange($event)" multiple="false" placeholder="{{nameBtn}}" accept=".png,.jpeg,.jpg,.gif,.bmp,.tiff">
</div>
