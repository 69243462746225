import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SitesService } from "app/services/sites/sites.service";
import { LocalizationService } from "./../../services/localization/localization.service";

@Component({
  selector: 'reservation-tipe-modal',
  templateUrl: './reservation-tipe-modal.component.html',
  styleUrls: ['./reservation-tipe-modal.component.css'],
  providers: [SitesService]
})
export class ReservationTipeModalComponent implements OnInit {

  special_items: any;
  isShowAddRelease: boolean = false;

  hide_meeting_rooms: boolean = JSON.parse(localStorage.getItem("currentUser")).user.features.hide_meeting_rooms;
  hide_desks: boolean = JSON.parse(localStorage.getItem("currentUser")).user.features.hide_desks;
  info: any;
  button: any;
  actions_nav: any;

  constructor(
    public dialogRef: MatDialogRef<ReservationTipeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sites: SitesService,
    private localizationService: LocalizationService
  ) { }

  ngOnInit() {
    if(this.localizationService.getReusableDataText('user_navigation').isSuccess) {
      this.actions_nav = this.localizationService.getReusableDataText('user_navigation').data.actions_nav;
    }
    if(this.localizationService.getReusableDataText('button').isSuccess) {
      this.button = this.localizationService.getReusableDataText('button').data;
    }
    if(this.localizationService.getComponentText('shared_components', 'reservation_tipe_modal').isSuccess) {
      this.info = this.localizationService.getComponentText('shared_components', 'reservation_tipe_modal').data;
    }
    this.sites.getActiveSiteTypes(this.data)
    .subscribe(res => {
      if(res.isSuccess) {
        this.special_items = res.data;
        console.log(this.data.external_id)
        console.log(this.special_items.can_create_release)
        console.log(this.data.external_id && this.special_items.can_create_release)
        this.data.external_id && this.special_items.can_create_release ? this.isShowAddRelease = true : this.isShowAddRelease = false;
      }
    })
  }

  onClick(res): void {
    this.dialogRef.close(res);
  }

}
