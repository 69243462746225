<pinch-zoom #myPinch [transition-duration]="200" [disableZoomControl]="'disable'" (dblclick)="toggleZoom()">
  <div class="child-container" id="{{'wingID' + wingID}}">
    <img loading="lazy" class="child-img" src="{{imageData}}"/>

    <span #allTheseThings id="{{asset.id}}" class="child-asset"
    [ngClass]="{
      'asset-free': asset.status == 1 && asset.is_active,
      'asset-rezerved': (asset.status == 0 && asset.is_active) || (!asset.has_requested_amenities && !showAssetsWithoutAllAmenities && idAsset != asset.id),
      'asset-confirmed': asset.status == 2 && asset.is_active,
      'asset-not-for-user': asset.status == 4 && asset.is_active,
      'asset-selected': asset.isSelected || idAsset == asset.id,
      'asset-static-selected': (asset.isSelected || idAsset == asset.id) && asset.status == -1
    }"
    *ngFor = "let asset of assets"
    [ngStyle]="{
      'width': asset.width + '%',
      'height': asset.height + '%',
      'max-width': asset.width + '%',
      'max-height': asset.height + '%',
      'left': asset.x + '%',
      'top': asset.y + '%',
      'border-radius': asset.colleague_info && !asset.is_zone ? '50%' : asset.border_radius + '%',
      'border': asset.colleague_info && !asset.is_zone ? '2px solid red' : '',
      'background': asset.colleague_info && !asset.is_zone ? 'url(' + (isRelative ? backend_url + asset.colleague_info[0].colleague_image : asset.colleague_info[0].colleague_image) + ') center center / cover' : ''
    }"
    (click)="checkSelection(asset)"
    (mouseenter)="mouseEnter(asset, $event)"
    (mouseleave)="mouseLeave(asset)"
    >
      <!-- <div class="tooltipe" [ngStyle]="{
        'left': parentdiv.width + 'px',
        'top': parentdiv.height + 'px'
      }">
        <asset-details [asset]="asset" [idHoverAsset]="idHoverAsset" [disabledZoom]="true"></asset-details>
      </div> -->
    </span>
  </div>
</pinch-zoom>
<div *ngIf="hoverAsset && !isPopup" class="tooltipe" [ngStyle]="{'left': parentdiv.width + 'px', 'top': parentdiv.height + 'px'}">
  <asset-details [isShowSlotsInfo]="isShowSlotsInfo" [showAssetsWithoutAllAmenities]="showAssetsWithoutAllAmenities" [asset]="hoverAsset" [idHoverAsset]="idHoverAsset" [disabledZoom]="true"></asset-details>
</div>
<!-- <div class="" fxLayout="row" fxLayoutAlign="center start" (click)="details()">
  <i class="fa fa-ellipsis-h fa-2x" aria-hidden="true"></i>
</div> -->
