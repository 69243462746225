
<!-- <div class="header">
  <h3>{{languages.modal.header}}</h3>
  <i class="fa fa-times" (click)="close(undefined)"></i>
</div> -->


  <!-- <div class="header" *ngIf="data.assets">
    <div class="search-container">
      <input class="search-input" type="text" placeholder="{{languages.placeholder}}"  [(ngModel)]="assetsSearch">
      <i class="fa fa-search"></i>
    </div>
  </div> -->
  <!-- <div class="header check-counter-container"> -->

  <!-- <asset-list-item *ngFor="let asset of data.assets | filterAssetsByObjectNames: assetsSearch | assetsWithoutAmenities: !roomsWithoutAllAmenities as result" [asset]="asset" (selectNewAsset)="assignAsset($event)"></asset-list-item> -->

  <!-- <div class="select-container" *ngIf="data.assets">
   <ng-select class="custom" [items]="data.assets | filterAssetsByObjectNames: assetsSearch | assetsWithoutAmenities: !roomsWithoutAllAmenities" placeholder="Please select resource" [searchFn]="filterAssetsByObjectNames" [clearable]="false" [searchable]="false" bindLabel="name" [(ngModel)]="asset" (change)="onChange($event)">
     <ng-template ng-header-tmp>
       <div class="toggle-container">
         <label class="toggle" >
           <input type="checkbox" [(ngModel)]="roomsWithoutAllAmenities">
           <div class="slide-toggle"></div>
           <span>{{languages.description}}</span>
         </label>

         <span class="assets-counter">{{data.assets | filterAssetsCount: assetsSearch: !roomsWithoutAllAmenities}}</span>
       </div>
     </ng-template>
     <ng-template ng-label-tmp let-item="item">
       <asset-list-item [asset]="item"></asset-list-item>
     </ng-template>
     <ng-template ng-option-tmp let-item="item">
       <asset-list-item [asset]="item"></asset-list-item>
     </ng-template>
   </ng-select>
  </div> -->



<div class="assets-list">
  <asset-details *ngIf="asset" [isShowSlotsInfo]="data.isShowSlotsInfo" [asset]="asset" [idHoverAsset]="asset.id" [isPopup]="true" [showAssetsWithoutAllAmenities]="showAssetsWithoutAllAmenities"></asset-details>
  <i class="fa fa-times" (click)="close(undefined)"></i>
</div>


<div class="actions-container" *ngIf="asset && !isReportingIssue">
  <div class="button-confirm" *ngIf="!isReportingIssue" (click)="close(asset)">{{languages.modal.report_button}}</div>
</div>
<div class="actions-container" *ngIf="asset && isReportingIssue && showAssetsWithoutAllAmenities">
  <div class="button-confirm" *ngIf="asset.status && asset.is_active" (click)="close(asset)">{{button.confirm}}</div>
</div>
<div class="actions-container" *ngIf="asset && isReportingIssue && !showAssetsWithoutAllAmenities">
  <div class="button-confirm" *ngIf="asset.status && asset.is_active && asset.has_requested_amenities" (click)="close(asset)">{{button.confirm}}</div>
</div>
