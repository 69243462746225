import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBySettedObjectProperty'
})
export class FilterBySettedObjectPropertyPipe implements PipeTransform {

  transform(items: any[], filter: any[]): any {
     if (!items || !filter) {
         return items;
     }

     return items.filter(item => item[filter[0]].toLowerCase().indexOf(filter[1].toLowerCase()) !== -1);
   }

}
