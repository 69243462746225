export const pt = {
  type:'pt',
  code:'pt-PT',
  name:'Portuguese',
  img:'flag-icon-pt',
  pages: {
    login_main_navigation: {
      login: {
        pageName: 'login',
        callback: 'Feche esta janela e continue no complemento Tribeloo para o Outlook',
        email_dialog: {
          header: 'Por favor, insira o seu e-mail',
          input: 'E-mail'
        },
        sso_info:{
          text: "Seu navegador está bloqueando a abertura de uma nova guia para login. Altere as configurações do seu navegador ou clique no botão 'Forçar abertura'",
          button: "Forçar abertura"
        },
        text: {
          loading: ['Trabalhando para você', 'Se estiver demorando muito,', 'por favor, recarregue'],
          header: 'Bem-vindo',
          sub_header: 'Reserve suas mesas favoritas, encontre seus colegas e muito mais.',
          form_headers: {
            login: 'Entrar',
            email: 'Por favor, verifique o e-mail',
            password: 'Esqueceu a senha'
          },
          password_info: ["Sem problemas! Vamos enviar um link de recuperação para você." , 'Uma nova senha foi enviada para você.'],
          inputs: ['Nome de usuário', 'Senha', 'E-mail'],
          remember_action: ['Lembrar-me.', 'Não é você?', 'Esqueceu a senha?', 'Voltar para Entrar'],
          button: ['Entrar com SSO', 'Entrar', 'Redefinir Senha'],
          info_modal: 'Verifique seu e-mail para alterar sua senha'
        }
      },
      delegates_modal: {
        header: "Selecionar perfil de delegado",
        sub_header: "Selecione a pessoa para a qual deseja fazer uma reserva",
        button_select: "Selecionar"
      },
      main_navigation: {
        pageName: 'barra lateral de navegação, componente da barra de ferramentas principal',
        nav_tabs_names: {user: 'Usuário', admin: 'Administrador'},
        nav_dropdown_corg: {
          about: 'Sobre',
          history: "History",
          feedback: 'Dar feedback',
          logout: 'Sair',
          user_settings: "Configurações de usuário",
          delegate: "Trocar perfis de delegado",
          user: "Trocar para o perfil de usuário",
          admin: "Trocar para o perfil de administrador"
        },
        admin_nav_items: {
          dashboard: 'Painel de Controle',
          reservation_management: 'Gerenciamento de reservas',
          newsfeed: 'Feed de notícias',
          user_management: 'Gerenciamento de usuários',
          site_configuration: 'Configuração do local',
          kiosk_configuration: 'Configuração do quiosque',
          issue_management: 'Gerenciamento de problemas',
          insights: 'Análises'
        }
      }
    },
    external: {
      close_issue_by_hash: 'Obrigado por fechar este problema!',
      hash_reservation: 'Reserva não disponível',
      google: 'Carregando...',
      outlook_reservation: {
        popup: 'Excluir esta reserva?',
        login: "Entrar",
        no_space_info: ['Nenhum espaço reservado,', ' por favor, entre em contato com o organizador da reunião'],
        site_headr: 'Local do site',
        date_time: 'O horário é mostrado no fuso horário do local solicitado',
        space: {
          header: 'Espaço',
          internal_external: ['interno', 'externo']
        },
        amenities_header: 'Comodidades'
      },
      reset_password: {
        info_modal: 'Senha alterada.',
        button_update: 'Atualizar senha',
        placeholders: ['Digite a nova senha', 'Digite a nova senha novamente'],
        errors: ["A senha não atende aos requisitos", 'As senhas não são iguais'],
        rules: 'A senha deve conter pelo menos: 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 dígito'
      }
    },
    admin: {
      kiosk: {
        main_header: 'Set up a kiosk',
        card: {
          headers: {
            site: 'Select a site',
            wing: ['Select a wing ', '(optional)']
          },
          placeholders: {
            site: 'Please search or select a site',
            wing: 'Please search or select a wing'
          },
          warning: 'Not running in kiosk, PWA or full-screen mode.',
          button: 'Launch kiosk mode',
        },
        info: {
          kiosk: 'For Kiosk - please logout, close browser and than run a following command: ',
          pwa: 'For PWA or full-screen browser - use Google chrome browser and',
          button: 'Click here'
        }
      },
      dashboard: {
        main: {
          refresh: "Refresh",
          site_card: {
            header: " Dashboard",
            action: "Help center",
            sub_header: "Select a site to get an overview"
          },
          small_card: {
            header: " Meeting rooms",
            total: "TOTAL",
            assigned: "Assigned"
          },
          utilization_cards: {
            header: " Max utilization rate ",
            special_header: "Max utilization rate special types",
            tooltip: "The max utilization rate is the max ratio during that day of requested over available resources of the selected type",
            table: {
              headers: [
                { name: "All types", tag_name: "all_types" },
                { name: "Desks", tag_name: "desks" },
                { name: "Meeting rooms", tag_name: "rooms" },
                { name: "Meeting rooms - Large ", tag_name: "big_rooms" },
                { name: "Meeting rooms - External", tag_name: "external" },
                { name: "Released %", tag_name: "releases" },
              ],
              hover: ["Requested ", "Available "]
            },
            info: "Click on a number in the table to see the chart",
            zoom: ['zoom out', 'zoom in']
          },
          to_do_card: {
            header: " To-do’s",
            link: "Go to to-do list",
            table: [
              { name: "To be confirmed", tag_name: "to_be_confirmed" },
              { name: "To be assigned", tag_name: "to_be_assigned" },
              { name: "Untreated request", tag_name: "with_message" },
              { name: "Total", tag_name: "total" }
            ]
          },
          not_enough_card: {
            header: "Not enough resources",
            info_default: "Click on a number in the table to see the solutions.",
            info_selected: "Let’s see how we can solve this.",
            button_reservation: "Remind people about their reservations",
            button_release: "Request more releases"
          }
        },
        releases_table: {
          info: "You can ask people who have not released their resource for this day to release it.",
          headers: {
            user: { popup: 'owners', name: "Assigned to" },
            level: { popup: 'Level of user', name: "Level assigned user" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            asset_level: { popup: '', name: "Asset level" },
            seat: { popup: '', name: "Seat capacity" },
            amenities: { popup: '', name: "Resource’s amenities" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: "Send message to all selected releases?",
            select_owner_message: "At least one user must be selected.",
            error_message: "Error sending",
          }
        },
        reservations_table: {
          info: "You can remind people who have a reservation for this day to cancel their reservation if no longer needed.",
          headers: {
            user: { popup: 'Owners', name: "User" },
            level: { popup: 'Level', name: "Level of user" },
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            rc: { popup: 'Requested seats', name: "Requested seats" },
            ie: { popup: 'internal external', name: "internal/ external" },
            amenities: { popup: '', name: "Requested amenities" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            status: { popup: 'Statuses', name: "Status" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: "Send message to all selected reservations?",
            select_owner_message: "At least one user must be selected.",
            error_message: "Error sending",
          }
        }
      },
      newsfeed: {
        navigation: ['Newsfeed', 'Emergency message'],
        list: {
          main_header: 'Newsfeed',
          site_header: 'Select a site to check its newsfeed.',
          i_tooltipe: 'The newsfeed of a site is shown on the homepage for all sites selected by the user',
          card: {
            header: 'Settings',
            toggle: 'Show newsfeed of site',
            i_tooltipe: 'You can choose to show the newsfeed of another site',
            select: {
              toggle: 'Click here to select the site',
              placeholder: 'Select site'
            },
            auto_remove: {
              text: ['Automatically delete news items that are older than ', ' days. '],
              i_tooltipe: 'Choose how long news items are shown on the newsfeed before they get deleted. Events are removed from the newsfeed once ended.'
            }
          },
          add_item: ' Add new item',
          popups: {
            info: 'Please select a location!',
            confirm: 'Delete all existing newsitems and events of ',
            button: 'OK'
          }
        },
        form: {
          main_header: 'Emergency message ',
          site_header: 'Select a site for which you want to write an emergency message.',
          i_tooltipe: 'An emergency message, once turned on, is visible for all users of that site on the Tribeloo homepage and on the devices of that site',
          message: "Haven't message",
          placeholder: 'Emergency message line ',
          save: 'Save',
          emergency: ['Turn on emergency message', 'Turn off emergency message']
        }
      },
      site_config: {
        navigation: {
          site: "Site configuration",
          rules: "Site rules",
          table: "Resource configuration table",
          types: "Resource types",
          wayfinding: "Wayfinding"
        },
        edit_site: {
          header: "Editing site ",
          video_header: "How to add, edit and delete a site",
          buttons: {
            add: "Add new site",
            delete: "Delete site"
          },
          toggle: "Site is active & visible for users",
          toggle_warning: ["Site is currently ", "inactive & invisible"," for users"],
          base: {
            header: "Basic details",
            video_header: "How to set up a site: basic details",
            placeholder: {
              name: "site name",
              code: "plus code",
              address: "address of this site"
            },
            label: {
              code: "Define coordinates of site (plus code) ",
              address: "Address of site "
            },
            tooltip: {
              code: "Plus code can be found on Google maps and is used to show a map of the location on the wayfinding link",
              address: "Address is shown on the wayfinding link"
            },
            position: {
              header: "Position of wing",
              info: "Select a wing, by clicking on its title, to change its position.",
            },
          },
          amenities: {
            header: "Amenities",
            video_header: "How to set up a site: amenities",
            info: "Define amenities ",
            tooltip: "Amenities are additional features, like a beamer or a flipchart, which can be selected when looking for a resource. Select amenities in the list below, to activate them on the current site, so they can be assigned to a specific resource from this site.",
            counter: [" in total - ", " active"],
            placeholder: "new amenity"
          },
          manager: {
            header: "Site manager settings",
            video_header: "How to set up a site: site manager settings",
            info: "Add e-mail address office manager ",
            tooltip: "Office manager will receive emails when new issues are reported ",
            placeholder: "e-mail",
            toggle: ["Send email to-do's every ", "hours"],
          },
          device: {
            header: "Device settings",
            video_header: "How to set up a site: device settings",
            label: {
              time: "Use 24h time for devices ",
              password: "Device password (0-9 A-E) "
            },
            tooltip: {
              time: "Select time format for meeting room and desk devices: AM/PM or 24h format",
              password: "Password used to access configuration screen on devices"
            },
            placeholder: "code 0-9 A-E"
          },
          insights: {
            header: "Insight settings",
            video_header: "How to set up a site: insight settings",
            labels: {
              days: "Define working days ",
              hours: "Define working hours ",
              room: ["Define a large meeting room", "people or more"],
            },
            tooltip: {
              days: "Working days are used to calculate average utilization rate in insights",
              hours: "Working hours are used to calculate average utilization rate in insights"
            },
            range: ["from", "to"]
          },
          popups: {
            online: {
              text: 'Bring this site online?',
              descriptions: ['This will make the site visible for regular users and ready for reservations.']
            },
            offline: {
              text: 'Bring this site offline?',
              descriptions: ['This will make the site invisible for regular users. Existing reservations will be kept.']
            },
            delete_site: {
              text: 'Delete this site?',
              big_descriptions: ['This will delete all wings, resources and reservations for this site. \nUsers with this home site will be moved to having no home site.']
            },
            cant_delete_site: 'Site can not be deleted.',
            amenity: {
              exists: 'Amenity already exists.',
              cant_updated: 'Amenity cannot be updated. Used by resources on other sites.',
              cant_delete: 'Amenity cannot be deleted, because it is assigned to some resources.',
              cant_disabled: 'Amenity cannot be disabled, because it is assigned to some resources. Remove it first from all resources.'
            }
          },
          edit_amenity_dialog: "Edit amenity",
          edit_wings_sorted: {
            add_button: " Add Wing",
            position: "Position: ",
          },
          wing_position: {
            header: "Selected wing: ",
            position: "Position ",
            move: "Move to position "
          }
        },
        rules: {
          header:"Site rules",
          site_card: {
            header: "Site",
            sub_header: "Select a site for which you want to define user privileges and rules."
          },
          default_reservation_time_card: {
            header: "Default reservation time",
            sub_header_desk: "Desk",
            sub_header_room: "Meeting room",
            label_start: "Start date",
            label_end: "Duration"
          },
          auto_assign_card: {
            header: "Auto-assign resources ",
            video_header: 'How to auto-assign resources to reservations',
            sub_header: "Reservations can get a resource auto-assigned if the user requests one, if none is available it will appear in the to-do list of the office manager",
            toggle: "Activate auto-assign functionality for ‘to assign’ reservations",
          },
          check_in_reminder_email_card: {
            header: "Check-in reminder email ",
            video_header: 'How to use the check-in reminder emails & auto-cancel reservations',
            send_toggle: "Send out reminder email 15 minutes before the start of a reservation that requires check-in",
            add_button_toggle: ["Add ", "‘Cancel reservation’", " button to reminder email"],
            add_desck_toggle: ["For ", "desk", " reservations: add ", "‘Check-in’", " button to reminder email"],
            add_room_toggle: ["For ", "meeting room", " reservations: add ", "‘Check-in’", " button to reminder email"],
            auto_cancel_header: "Auto-cancel reservations that require check-in",
            cancel_desk_toggle: ["Cancel ", "desk", " reservation after", "min, if a check-in is required."],
            cancel_room_toggle: ["Cancel ", "meeting room", " reservation after", "min, if a check-in is required."],
          },
          reminder_email_card: {
            header: ["Reminder email", "for desks"],
            video_header: 'How to use the general reminder emails',
            send_toggle: ["Send reminder email ", "hours before the start of a reservation"],
            subject: {
              label: "Subject:",
              placeholder: "Reminder about your upcoming reservation"
            },
            message: {
              label: ["Message: (Markdown supported, for example to add links. ", "Check guidelines"],
              placeholder: "Add message"
            },
            agree_toggle: ["Add ", "‘I agree’", " button: by agreeing the person acknowledges they have read the message"],
            cancel_toggle: ["Add ", "‘Cancel reservation’", " button"],
            header_special: "Reminder email for meeting rooms",
            send_toggle_special: ["Send reminder email ", "hours before the start of a reservation"]
          },
          time_limit_card: {
            header: "Time limit for future reservations",
            video_header: 'How to use the time limit for future reservations',
            sub_header: "Reservations can only be made a certain time ahead by non-admin users",
            desck_toggle: ["Desk", " reservations can be created in the next"],
            room_toggle: ["Meeting room", " reservations can be created in the next"],
            special_toggle: "Reservations can be created in the next",
            postfix: "working days"
          },
          utilization_limit_card: {
            header: "Utilization limit",
            sub_header: ["Utilization of active ", " can be limited.", "Example: a site has 40 active ", ". When utilization is limited to 50%, only 20 concurrent ", " reservations can be made."],
            sub_header_types: ['desks', 'resources', 'desk'],
            toggle: ["Utilization is limited to", "percent"]
          },
          multi_day_reservation_card: {
            header: "Multi-day reservation",
            sub_header: ["Define if an employee is allowed to create one reservation for several consecutive days at once.", "When allowed, an employee will only receive the reminder emails once and only needs to check-in once at the start of his multi-day reservation, if this is required."],
            toggle: "Multi-day reservations are allowed"
          },
          cleaning_time_card: {
            header: "Cleaning",
            sub_header: "Cleaning time will be foreseen after the end of every reservation. During this period the resource cannot be booked.",
            desk_toggle: ["Time available to clean a ", "desk", "min"],
            room_toggle: ["Time available to clean a ", "meeting room", "min"]
          },
          user_message_card: {
            header: "User message when creating reservation ",
            toggle: 'Show this message when a user creates a reservation for a resource on this site',
            label: 'Message:',
            placeholder: 'Type your message here',
          },
          user_privileges_card: {
            header: "User privileges",
            video_header: 'How to set up user privileges',
            sub_header: "Define which users have access to which resources and have to get confirmation for a reservation",
            levels: {
              header: "1. Define the names of the different user levels and resource levels:",
              toggle: "User level names are the same across all sites",
              description_simple: {start: "User & resource level names", center: " can be set by site admin and are ", end: "specific for the site."},
              description_global: [
                {start: "User level names", center: " can only be set by global admin and are the ", end: "same for all sites."},
                {start: "Resource level names", center: " can be set by site admin and are ", end: "specific for the site."},
              ],
              user_label: "User level ",
              resource_label: "Resource level ",
              placeholder: "Text"
            },
            privileges: {
              header: "2. Define the privileges per user and resource level:",
              cols: "Resource level",
              rows: "User level"
            },
            rules: [
              {id: 0, line1: "Resource not available", line2: "(can be assigned by admin)", class: "red"},
              {id: 2, line1: "Resource only available ", line2: "after confirmation", class: "orange"},
              {id: 1, line1: "Resource available for", line2: "user level", class: "green"}
            ]
          },
        },
        edit_wing: {
          header: "Editing wing ",
          sub_header: "wing details",
          video_header: "How to add, edit and delete a wing",
          placeholder: "wing name",
          button: {
            back: "Back to site edit",
            add: "Add new wing",
            delete: "Delete wing"
          },
          popup: {
            text: 'Delete this wing?',
            big_descriptions:['This will delete all resources of this wing and could affect current reservations. \nCheck your to-do list if proceeding.']
          },
          assets_list: {
            header: "Resources",
            add_button: " Add resource",
            counter: " in total",
            popup: {
              text: 'Delete this resource?',
              big_descriptions:['This could affect current reservations. \nCheck your to-do list if proceeding.']
            }
          }
        },
        edit_asset: {
          buttons:{
            back: "Back to wing edit",
            add: "Add resource",
            clone: "Duplicate resource",
            delete: "Delete resource"
          },
          base: {
            header: "Basic details ",
            video_header: "How to add, edit and delete a resource & define the basic details",
            internal_external: [
              {name:"Internal only", value: true},
              {name:"External allowed", value: false}
            ],
            type_tooltipe: 'INTERNAL ROOM: used for a meeting with colleagues from within the company.\n EXTERNAL ROOM: used when also people of outside the company are invited (e.g. client).\n ZONE: contains multiple single desks that can be booked, without selecting a particular one.',
            user_tooltipe: 'Assigning a resource to a specific person, means that this person has a fixed desk and they alone can use it. They can still choose to release it for others to use, when they aren’t around.',
            zoning_tooltipe: 'Semicolon separated list of zoning names, useful for HVAC/BM integration.\n E.g.:\n lighting_7;heating_3',
            amenity_type: "other asset type",
            placeholders: {
              name: "asset name",
              user: "Select user",
              neighbourhood: "Select neighbourhood",
              amenities: "Select amenities"
            },
            labels: {
              status: "Resource status ",
              neighbourhood: "Neighbourhood ",
              user: "Assigned to ",
              level: "Resource level ",
              resource_zoning: "Resource zoning ",
              capacity: ['Seat capacity', 'Capacity'],
              type: "Resource type ",
              check_in: "Check-in required",
              amenities: "Amenities available ",
              image: "Add image (< 4MB)"
            }
          },
          size: {
            header: "Size, Shape & position ",
            video_header: "How to set up a resource: size, shape & position",
            size: {
              header: "Size & shape",
              sub_header: "Choose a shape",
              width: "Width",
              height: "Height",
              round: "Round corners"
            },
            position: {
              header: "Map position",
              horizontal: "Horizontal",
              vertical: "Vertical"
            }
          },
          calendar: {
            header: "Calendar & approval settings",
            video_header: "How to set up a resource: calendar & approval settings",
            resource: {
              label: "Resource email ",
              placeholder: "Resource email",
              tooltip:"Fill in an email to create a calendar for this resource. Tribeloo will keep the calendar up to date with reservations for this resource."
            },
            approvers: {
              label: "Approvers email ",
              placeholder: "Approvers email",
              tooltip:"Add the email address of the person who will confirm reservations for this resource. Multipe email addresses can be added separated by a semicolon ; and without any spaces before or after the semicolon"
            },
          },
          device: {
            header: "Device configuration",
            video_header: "How to set up a resource: device configuration",
            code: {
              label: "Device code ",
              placeholder: "Code"
            },
            id: {
              label: "Device ID ",
              placeholder: "ID"
            }
          },
          qr_nfc: {
            header: "QR & NFC configuration",
            video_header: "How to set up a resource: QR & NFC configuration",
            qr: {
              header: "Generate QR code",
              info: "Click on the button to generate a QR code of this resource.",
              button: "Generate QR"
            },
            nfc: "NFC configuration"
          },
          popups: {
            nfc_copy: "NFC url copied.",
            set_capacity: {
              text: 'Reduce seat capacity?',
              big_descriptions: ['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            select_owner: {
              text: 'Assign user to resource?',
              big_descriptions: ['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            internal_change: {
              text: "Change resource type to ‘Internal only’?",
              big_descriptions:["This could affect current reservations. \nCheck your to-do list if proceeding."]
            },
            remove: {
              text: 'Delete this resource?',
              big_descriptions:['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            check_card_id: {
              header: "",
              cases: [
                '1. Click "Start test" below',
                '2. On selected device, tap "Book now" or "Book later"',
                '3. Scan your card on the device',
                '4. The RFID code of the scanned card appears below',
                '5. To test another card, go to step 2'
              ],
              start: "Start test",
              stop: "Stop"
            }
          },
          assets_dragging_view: [" - click for edit", "drag & drop", "click to select"]
        },
        edit_info: {
          create: "Created on: ",
          update: "Last updated on: ",
          resource: "Resources: ",
        },
        type: {
          header: "Resource types",
          sub_header_descriprion: {
            first_line: "Standard sites have the type 'room or desk' automatically assigned.",
            second_line: ["If you want to create sites that contain another type of resource, define them here.", "These other resource types will also become visible on the Tribeloo homepage and in the tab 'More' in Outlook."]
          },
          video_header: 'How to define different resource types to book',
          card: {
            list: {
              header: "Existing resource types",
              button: "Add new resource type"
            },
            form: {
              header: ["Create resource type", "Edit resource type"],
              type: {
                label: "Resource type",
                placeholder: "Name of resource type"
              },
              icon: {
                label: "Icon",
                placeholder: "fa fa-archive",
                button: "Check icon list"
              },
              preview: {
                label: "Preview",
                text: ["Book a ", "Book a desk", "Book a meeting room"]
              },
              booking: {
                label: "Double booking by user",
                sub_label: "(desks only)"
              },
              button: {
                create: " Create type",
                delete: " Delete type",
                save: " Save changes",
              }
            }
          },
          popups: {
            unique_name: "You alredy have same type name",
            create: 'Create type?',
            save: 'Save changes?',
            add: "New resource type is added",
            saved: "Changes saved",
            delete: 'Delete this resource type?',
            cant_delete: "This resource can't be deleted, because there are still sites of this type"
          },
        },
        assets_table: {
          buttons: {
            batch: "Batch edit",
            qr: ["QR PDF selected", "QR PDF all"]
          },
          headers: {
            number: {name: "Number", popup: ""},
            resource: {name: "Resource", popup: ""},
            wing: {name: "Wing", popup: "Wings"},
            user: {name: "Assigned to", popup: ""},
            level: {name: "Resource level", popup: "Resource level"},
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            capacity: {name: "Seat capacity", popup: "Seat capacity"},
            type: {name: "Resource type", popup: "Resource type"},
            check_in: {name: "Check-in required", popup: "Check-in required"},
            amenities: {name: "Amenities", popup: ""},
            device_id: {name: "Device ID", popup: ""},
            email: {name: "Resource email", popup: ""},
            status: {name: "Status", popup: "Status"},
            from: {name: "From", popup: ""},
            to: {name: "To", popup: ""}
          },
          popups: {
            batch: {
              header: "Edit resource details",
              sub_header: "Update",
              level: "Resource level",
              capacity: "Seat capacity",
              type: {
                label: "Resource type",
                values: [
                  { name: 'Internal only', value: true },
                  { name: 'External allowed', value: false }
                ],
              },
              check_in: {
                label: "Check-in required",
                values: [
                  { name: 'yes', value: true },
                  { name: 'no', value: false }
                ],
              },
              add_amenities: {
                label: "Add amenities",
                placeholder: "Select amenities"
              },
              remove_amenities: {
                label: "Remove amenities",
                placeholder: "Select amenities"
              },
              approvers: {
                label: "Set approvers",
                placeholder: "approvers email"
              },
              neighbourhood: {
                label: "Neighbourhoods",
                placeholder: "Select neighbourhood"
              },
              status: "Resource status"
            },
            selected: "At least one resource must be selected",
            selete_selected: ["Delete the ", " selected resources?"],
            this_delete: "Delete this resource?"
          }
        },
        wayfinding_link: {
          header: "Wayfinding link",
          privacy_card: {
            header: "Privacy",
            toggle: "Logging in is required to see information about assigned resource and floormap"
          },
          layout_card: {
            header: "Layout",
            sub_header_descriprion: {
              first_line: ["When using the Outlook add-in and Chrome extension, a wayfinding link is added to the event itself. ", "This shows invitees where they need to be and to easily find their way."],
              second_line: "You can add a company logo OR company name, and define the colors for the banner, the company name and the titles."
            },
            logo: {
              label: "Company logo",
              sub_label: "(PNG, less than 4MB)"
            },
            image_info: ["max width 210px and ", "max height 50px"],
            company_name: {
              label: "Company name",
              placeholder: "Max 20 characters"
            },
            name_color_label: "Color company name",
            baner_color_label: "Color banner",
            titels_color_label: "Color titles"
          }
        }
      },
      todo: {
        navigation: {
          list: 'Reservation to-do list',
          requests: 'Requests',
          reservation_table: 'Reservation table',
          release_table: 'Release table'
        },
        main: {
          all_button_tooltip: "Assign resources automatically to current to-do's",
          dialog_asset: "Asset must be selected first",
          details_resources_button: "View resource reservations",
          legend: {
            header: "View legend",
            items: [
              {name: "Available", type_class: "green"},
              {name: "Available, but to be confirmed", type_class: "orange"},
              {name: "Available, but rules constraint", type_class: "blue"},
              {name: "Not available", type_class: "red"}
            ]
          }
        },
        list: {
          update: "Last updated: ",
          toggle: "Show only untreated request",
          toggle_visitor: "Show only visitor reservations",
          default_name_subject: "New reservation ",
          user_prefix: "by ",
          visitor_prefix: "for ",
          tooltip: "Reservation within selected time range",
          default_name_asset: "Reservation pending"
        },
        details: {
          site_change: {
            header: "Change site",
            tooltip: "Remove assigned space first"
          },
          request: {
            header: "Request",
            treated: ["Request treated", "Request untreated"],
            buttons: {
              add: "Add note",
              contact: "Contact user"
            }
          },
          details: {
            header: ["Show details", "Hide details"],
            delete_button: "Cancel reservation",
            default_name_subject: "New reservation ",
            user_prefix: "By ",
            visitor_prefix: "For ",
            seats: {
              counter: ["Requested: seats ","Requested: "],
              type: ['internal meeting', 'external meeting'],
              amenities: "Requested amenities: "
            }
          },
          popups: {
            delete: 'Delete this reservation?',
            assigned: "Space is assigned to reservation and confirmed",
            selected: "Asset must been selected",
            available: "Asset is not available",
            message_error: "error sending"
          }
        },
        work_stack: "Click here to select the stack item",
        message_popup: {
          header: "Add note",
          sub_header: "Request",
          treated: ["Request treated", "Request untreated"]
        },
        release_table: {
          info: 'Changing time range removes all filters',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "Assigned to" },
            level: { popup: 'Level assigned user', name: "Level assigned user" },
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            amenities: { popup: '', name: "Resource’s amenities" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            subject: { popup: '', name: "Subject" },
            email: { popup: '', name: "Email" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: ["Send message to ", " shown releases for "],
            remove_release: "Delete this release?",
            remove_releases: ["Delete ", " shown releases for "],
            error_message: "Error sending",
          }
        },
        reservaton_table: {
          info: 'Changing time range removes all filters',
          change_site_buton: ' Change site for all',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'User', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            level: { popup: 'Level of user', name: "Level of user" },
            group: { popup: 'User group in ', name: "User group in " },
            email: { popup: '', name: "User email" },
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            seats: { popup: 'Requested seats', name: "Requested seats" },
            internal_external: { popup: 'Resource type', name: "Resource type" },
            amenities: { popup: '', name: "Requested amenities" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resources', name: "Resource" },
            request: { popup: 'Request', name: "Request" },
            status: { popup: 'Statuses', name: "Status" },
            acknowledged: { popup: 'Acknowledged', name: "Acknowledged" }
          },
          tooltip: {
            lock: "lock all",
            unlock: "unlock all",
            acknowledged: "From reminder email: if the person clicked on the 'I agree' button and therefore acknowledges they have read the message",
            confirm: "confirm all",
            delete: "delete all",
            send_mail: "send message to all"
          },
          dialogues: {
            chang_site: 'Change site',
            confirm: ['Confirm ', ' shown reservations from '],
            send_all_message: ["Send message to ", " shown reservations for "],
            remove_reservation: "Delete this reservations?",
            remove_reservations: ["Delete ", " shown reservations for "],
            error_message: "Error sending",
            lock: ["Lock ", " shown reservations from "],
            unlock: ["Are you sure you want to unlock ", " shown reservations from "]
          }
        },
        requests_table: {
          info: 'Changing time range removes all filters',
          add_comment_buton: 'Add comment',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'User', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            request: { popup: 'Request', name: "Request" },
            comment: { popup: '', name: "Comment" },
            seats: { popup: '', name: "Request treated" },
            email: { popup: '', name: "Owner email" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: ["Send message to ", " shown reservations for "],
            error_message: "Error sending"
          }
        }
      },
      user_management: {
        navigation: {
          global_admin: "Global admins",
          admin: "Site admins",
          privileges: "User rules",
          user: "User management",
          users_table: "User table",
          groups: "User groups",
          sso: "SSO",
          provisioning: "User provisioning"
        },
        global_admin: {
          header: "Global admin management",
          admins_card: {
            header: "Define the global admins",
            sub_header: "These users have access to all sites and features",
            video_header: 'How to define the site & global administrators',
            search: "Search",
            global_admin_list_header: "Site admins that are global admin",
            admin_list_header: "Site admins",
            admin_list_header_tooltip: 'You need to be site admin first, before you can be global admin'
          },
        },
        privileges: {
          header: "User rules",
          site_card: {
            header: "Site",
            sub_header: "Select a site to define it's user rules."
          },
        },
        admin: {
          header: "Site admin management",
          site_card: {
            header: "Site",
            sub_header: 'Select a site for which you want to add or remove administrators.'
          },
          admins_card: {
            header: "Add administrators for this site",
            video_header: 'How to define the site & global administrators',
            search: "Search",
            admin_list_header: "Users that are admin for this site",
            user_list_header: "Users",
            popup: {
              title: "This user has global admin rights and therefore access to all sites.",
              descriptions: ["If you want to give this user only access to certain sites, remove them from the global admin list first."]
            }
          },
        },
        user: {
          rfid_modal: {
            header: "Assign RFID to user",
            step1: {
              header: ["Step 1", " - Enter device ID & click ‘Start’"],
              placeholder: "Device ID",
              button: "Start ",
              info: ["No resource found ", "Check or re-enter ID"]
            },
            step2: ["Step 2", " - On connected device, tap ‘Book now’ or ‘Book later’"],
            step3: {
              header: ["Step 3", " - Scan card on the device, the RFID code will appear below"],
              placeholder: "Card RFID"
            },
            step4: {
              header: ["Step 4", " - Click ‘Assign RFID’, to assign it to the user"],
              button: "Assign RFID"
            }
          },
          header: "User management",
          free_version_sub_header: ["Currently using the ", "FREE VERSION with a limit of ", " users.", " Contact ", " to upgrade."],
          site_card: {
            header: "Location",
            sub_header: "Select a location to add or remove users for this site."
          },
          create_edit_card: {
            list: {
              header: "Existing users",
              button: "Create new user",
              placeholder: "Search"
            },
            form: {
              header: ["Edit user", "Fill in details"],
              video_header: "How to create and edit a user",
              sub_header: "Select a user in the list to edit it's details.",
              name: {
                label: "Username",
                placeholder: "Name"
              },
              email: {
                label: "Email",
                placeholder: "Email"
              },
              level: {
                label: "User level ",
                placeholder: ""
              },
              group: {
                label: "User group",
                item: "Default"
              },
              site: {
                label: "Home site",
                item: "None"
              },
              resource: {
                label: "Resource",
                placeholder: ""
              },
              rfid: {
                label: "RFID",
                placeholder: "Enter RFID or click on icon on the right"
              },
              image: {
                label: "User image",
                sub_label: ["(square size, ", "less than 4MB)"]
              },
              button: {
                create: " Create user",
                reset_pin: "Reset PIN code",
                reset_password: "Reset user password",
                upload: "Upload image",
                delete: " Delete user",
                save: " Save changes"
              }
            },
            import: {
              header: "Batch upload users",
              cases: [
                "1. Download template",
                "2. Add users for this location to the template",
                "3. Upload the template file again to Tribeloo"
              ],
              button: {
                upload: "Upload file",
                download: "Download template"
              }
            },
            errors: {
              invalid_email: "Required field <email> is invalid.",
              missing_email: "Required field <email> is missing from request.",
              missing_name: "Required field <name> is missing from request.",
              missing_level: "Required field <level> is missing from request.",
            },
            popups: {
              save: 'Save changes?',
              create: 'Create user?',
              created: 'User created.',
              reset_pin: 'The reset was succesful! The user will receive an email with a new PIN code.',
              updated: 'User details have been updated.',
              delete: ' Delete this user?',
              removed: 'User removed.',
              reset_password: 'The reset was succesful! The user will receive an email with a new password.',
              send_pin: 'Send login PIN code for devices ?'
            }
          },
          pin_card: {
            header: "PIN code ",
            video_header: "When and how to use a PIN code",
            toggle: "Send PIN code by email to login on the meeting room and desk devices",
            info: "All new users and existing users that don’t have PIN code yet, will receive one."
          },
          global_settings_card: {
            header: "Global settings",
            info: "These settings are applicable for all sites"
          },
          image_card: {
            header: "Synchronise user images ",
            video_header: "How to synchronise user images of Microsoft 365 with Tribeloo",
            toggle: "Sync user images with Microsoft Office 365",
            info: "User image is synchronised once a user logs into the Tribeloo Outlook add-in"
          },
          subject_card: {
            header: "Hide reservation subject ",
            video_header: "",
            toggle: "Hide reservation subject for colleagues",
            info: 'Reservation subject shown in "who`s around" will be hidden'
          }
        },
        users_table: {
          batch_edit: {
            header: "Edit user details",
            sub_header: "Update",
            items: {
              privileges: "User group",
              level: "User level",
              site: "Home site",
              group: "User group",
              rfid: "Clear RFID for selected users"
            }
          },
          table: {
            toggle: 'Also show users from other sites',
            headers: {
              number: { popup: '', name: "Number" },
              site: { popup: 'Home site', name: "Home site" },
              home_site_privilege: { popup: 'Home site group', name: "Home site group" },
              user: { popup: '', name: "Name of user" },
              user_privilege: { popup: 'User group ', name: "User group in " },
              assigned_resource: { popup: 'Assigned resource in ', name: "Assigned resource in " },
              level: { popup: 'Level of user', name: "Level of user" },
              group: { popup: 'Group', name: "User group" },
              space: { popup: '', name: "Resource" },
              Owned_site: { popup: 'Owned_site', name: "Site of resource" },
              email: { popup: '', name: "User email" },
              rfid: { popup: 'RFID', name: "RFID" },
              override : {popup: 'Group assignment', name: "Group assignment"}
            },
            tooltip: {
              delete: "delete all selected users",
              send_mail: "send message to selected users"
            },
            dialogues: {
              error_message: "Error sending",
              email: 'Compose email for the selected users?',
              selected: "At least one user must be selected.",
              delete_all: ['Delete the ', ' selected users?'],
              delete: 'Delete this user?',
              rfid: "RFID cleared for selected users"
            }
          }
        },
        groups: {
          header: "Users groups",
          toggle_card: {
            header: "Enable user groups ",
            video_header: "How to set up user groups",
            toggle: "User groups are enabled",
            description: ["When ", "enabled", ", you can create user groups and define rules for visible sites or office days for each group.", "Assign a user to a group in user management or user table."]
          },
          creat_edit_card: {
            list: {
              header: "Existing users groups",
              button: "Add new user group",
              placeholder: "search",
              popups: {
                save: "Save changes?",
                create: "New user group is added.",
                update: "Changes saved.",
                delete: {
                  text: "Delete this user group?",
                  descriptions: ['Users from this group will be assigned to the default user group of their home site.']
                }
              }
            },
            form: {
              header: ["Create user group", "Edit user group"],
              select_button: "Select all",
              unselect_button: "Unselect all",
              create: " Create group",
              update: "Save changes",
              delete: " Delete group",
              name: {
                label: "Name ",
                placeholder: "Add name of user group"
              },
              rules: {
                toggle: ["‘Visible sites’ rule enabled", "‘Visible sites’ rule disabled"],
                tooltip: "When visible sites rule is enabled, a user has access to the active sites of his group, and is able to book a resource for these sites. When visible sites rule is disabled, this user group has access to all active sites.",
                placeholder: "Select visible sites",
              },
              days: {
                toggle: ["‘Office days’ rule enabled", "‘Office days’ rule disabled"],
                tooltip: "When office days rule is enabled, a user can make reservation only on the selected office days. When office days rule is disabled, this user group can make reservations on all days.",
                placeholder: "Select office days",
                week_days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
              }
            },
            sites_card: {
              header: "Default user group for each site",
              description_lines: [
                {start: "Users can be assigned to a specific group. In case a user doesn’t have a group assigned,", end: "the user will fall in the default group of his home site as defined below."},
                {start: "In case the user isn’t assigned to a specific group and no default group is defined for his", end: "home site, the user will only have access to his home site to book resources."}
              ],
              label: "No home site ",
              tooltipe: "This is the default group for users without a home site",
              placeholder: "group"
            }
          }
        },
        sso: {
          header: "Single Sign-On",
          description_card: {
            header: "What is it?",
            description: "Single Sign-On gives your users the ability to sign in to different applications using the same unique set of username and password. Users sign in once via the so-called Identity Provider (external to Tribeloo) and can then, from there access different apps (including Tribeloo) without logging in again. For SSO, Tribeloo supports the SAML 2.0 standard."
          },
          email_card: {
            header: "Enable welcome email",
            toggle: "Enable sending welcome email if SSO is enabled"
          },
          settings_card: {
            header: "Settings",
            items: {
              id: {
                name: "Company ID",
                tooltip: {
                  header: "",
                  descriptions: []
                }
              },
              issuer_url: {
                name: "Issuer URL",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: Identity Provider Issuer", "OneLogin: Issuer URL", "Azure: Azure AD Identifier", "Google: Entity ID"]
                }
              },
              login_url: {
                name: "SSO login URL",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: Identity Provider SSO URL", "OneLogin: SAML Endpoint", "Azure: Login URL", "Google: SSO URL"]
                }
              },
              certificate: {
                name: "Certificate",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: X.509 Certificate", "OneLogin: X.509 Certificate", "Azure: Certificate (Base64 or Raw)", "Google: Certificate 1"]
                }
              },
            },
            button: ["Disable SSO", "Enable SSO"]
          },
          popups: {
            saved: 'Configuration saved.',
            not_saved: 'Configuration not saved.',
            copied: 'Company ID copied.',
            change_sso: {
              text: ['Are you sure you want to disable Single Sign-On?', 'Are you sure you want to enable Single Sign-On?'],
              descriptions: ['All users will need to log in with a password, that they can request through ‘Forgot password?’ on the login page.', 'Users won’t be able to log in with their passwords and will need to log in with their SSO credentials.']
            }
          }
        },
        provisioning: {
          header: "User provisioning",
          description_card: {
            header: "What is it?",
            description: "User Provisioning refers to the automatic synchronization of users: people defined in your Active Directory (AD) will be automatically synchronized with the Users list in Tribeloo. E.g. new people in the AD will be added to the Users list in Tribeloo."
          },
          settings_card: {
            header: "Settings",
            items: [
              {name: "SCIM Base URL", key: "base_url", is_input: true},
              {name: "SCIM JSON Template", key: "json_template", is_input: false},
              {name: "Custom headers", key: "custom_headers", is_input: true},
              {name: "SCIM Bearer token", key: "bearer_token", is_input: false}
            ],
            button: ["Disable User provisioning", "Enable User provisioning"],
            popups: {
              copy: " copied",
              change_SCIM: {
                text: ['Are you sure you want to disable User provisioning?', 'Are you sure you want to enable User provisioning?'],
                description: ['All the current users will be kept, but won’t be updated by your external user provisioning system anymore.', 'All users will be managed externally.']
              }
            }
          }
        }
      },
      insights: {
        navigation: {
          heatmaps: "Heatmaps",
          utilization: "Utilization charts",
          history: "Reservation history",
          download: "Data download",
          statistics: "Statistics",
          tracker: "Time tracking",
          calc: "Calc resource"
        },
        calc: {
          type: "Calc helpe",
          subHeader: "This page help you with a data about your price and amount of resources from 1 month to 5 years. Also it help you: if you want to change a count of recources, or move to new place, add or remove new site, wing and etc.",
          chartHeader: "Terms & summ",
          chartDescription: "Use top form to know how much you are spending for your resources"
        },
        heatmaps: {
          type: "Heatmaps",
          subHeader: "Which days and hours are most busy?",
          allText: "Average utilization rate of selected type",
          daysText: "Daily utilization rate of selected type",
          options: {
            average: "Show average utilization rate",
            daily: "Show daily utilization rate"
          },
          tooltipe: 'The average utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period. The daily utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period. For example, daily utilization of 80% and average utilization of 40% means that: 8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours. '
        },
        utilization_charts: {
          type: "Utilization charts",
          subHeader: "Which days and hours are most busy?",
          allText: "Average utilization rate of selected type",
          daysText: "Daily utilization rate of selected type",
          table_header: "Which days are most busy? Select a day to see the utilization by hour below for that day.",
          chart_header: "Which hours are most busy"
        },
        reservation_history: {
          info: 'Changing time range removes all filters',
          overlap: 'Find overlapping reservations with ',
          couter: " more...",
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            email: { popup: '', name: "User email" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resources', name: "Resource" },
            no_shows: { popup: 'No-shows', name: "No-shows" },
            subject: { popup: '', name: "Subject" },
            seats: { popup: 'Requested seats', name: "Requested seats" },
            internal_external: { popup: 'internal/external', name: "internal/ external" },
            status: { popup: 'Statuses', name: "Status" },
            messages: { popup: 'Messages', name: "Messages" },
            acknowledged: { popup: 'Acknowledged', name: "Acknowledged" }
          },
          tooltip: {
            acknowledged: "From reminder email: if the person clicked on the 'I agree' button and therefore acknowledges they have read the message",
            send_mail: "send message to all"
          },
          dialogues: {
            error_message: "Error sending"
          }
        },
        time_tracking: {
          check: "events",
          info: 'Changing time range removes all filters',
          info_date: 'Find overlapping reservations with ',
          headers: {
            user: { popup: 'User', name: "User" },
            email: { popup: 'User email', name: "Email" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            zone: { popup: '', name: "Time zone" },
            period: { popup: '', name: "Time period" },
          },
          dialogues: {
            error_message: "Error sending"
          }
        },
        data_download: {
          header: "Data download",
          popup: {
            big_titel: 'Update data retention?',
            big_descriptions: ['Older reservation data will be deleted and no longer accessible'
            ]},
          retention_card: {
            header: "Data retention",
            toggle: ["Reservations & releases data is automatically deleted after", "days."],
            info: "There is a minimum of 7 days and a maximum of 400 days."
          },
          automade: {
            header: "Daily automated report via email",
            sub_header: "Receive data of the reservations & releases of the previous day",
            check: "Send daily email to",
            placeholder: "Email address",
            error: "Please enter a valid email address"
          },
          download: {
            header: "Download data for a specific time range",
            info: "Data of reservations & releases",
            button: {
              all: " Download all",
              one: " Download data range"
            }
          }
        },
        statistics: {
          header: "Statistics",
          sub_header: "The table shows you a current view of all the sites and their amount of resources and users.",
          card: {
            header: "Resources and users",
            button: "Download",
            table_headers: {
              type: "Resource type",
              resources: "Resources",
              users: "Users"
            }
          }
        },
        reusable_components: {
          main_header: {
            header: "Insights",
            day: " Move timeframe per day ",
            week: " Move timeframe per week "
          },
          scale_utilization_rate: "Scale utilization rate",
          card_table: {
            table_headers: {
              special: "utilization rate",
              average: "Average",
              daily: "Daily"
            },
            description_hightlight: [
              {start:"The ", hightlight: "average", end: " utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period."},
              {start:"The ", hightlight: "daily", end: " utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period."}
            ],
            description_example: ["  For example, daily utilization of 80% and average utilization of 40% means that: ", "8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours."]
          },
          maps_cards: {
            button: {
              select: "Select",
              show_all: "Show all"
            },
            internal_external: [', Internal only', ', External allowed'],
            owner: "Owner: ",
            usage: "Usage",
            average: "average: ",
            daily: "daily: ",
            days: "days active: ",
          }
        }
      },
      space_issues: {
        navigation: ['Issue management','Cleaning', 'Issues table'],
        list: {
          main_header: 'Select a site',
          main_sub_header: 'Find resource',
          placeholder: "Please search or select a site",
          placeholder_asset: "Please search or select a resource",
          search: 'View all site issues',
          toggle: ['Click to show closed issues','Click to show open issues'],
          couter: [' open resource issues', ' closed resource issues'],
          img_link: 'See photo',
          popup_text: 'E-mail sent to your account with information about the issue. This can be forwarded to somebody that can solve it.',
        },
        table_cleaning: {
          main_header_warning: 'Changing time range removes all filters',
          table_headers: {
            id:'ID',
            data_time: 'Date & time',
            wing: 'Wing',
            resource: 'Resource',
            cleaned_at: 'Cleaned at',
            cleaned_by: 'Cleaned by'
          }
        }
      }
    },
    user: {
      external_events_list: {
        headers: ['Eventos do Google', 'Eventos do Outlook'],
        select: {
          header: 'Organizado por',
          placeholder: 'Selecionar colega'
        },
        info: ['Nenhuma reserva disponível em seu calendário', 'Mostrar apenas eventos com reservas da Tribeloo', 'Evento selecionado']
      },
      find_colleague: {
        email_select: {
          more: "mais..."
        },
        user_select: {
          header: 'Encontrar um colega',
          placeholder: 'Selecionar colega',
          info: ['Solicitar acesso aos seus colegas em', 'Minha Tribo', ' para ver as suas reservas']
        },
        list: {
          count: ' (não privado)',
          info: ['Selecione um colega para ver as suas reservas.', 'Clique na reserva para visualizar no mapa.']
        },
        details: {
          return_button: 'Voltar à lista de reservas',
          info: 'Selecione um recurso disponível no mapa para criar uma reserva para você mesmo',
          modals: {
            delete_reservation: 'Reserva excluída',
            create_reservation: 'Criar reserva?'
          }
        }
      },
      find_room_report: {
        headers: {
          main: 'Relatar um problema para um recurso',
          site: 'Selecione um local',
          resource: 'Encontrar recurso'
        },
        placeholders: {
          site: 'Por favor, pesquise ou selecione um local',
          resource: 'Por favor, pesquise ou selecione um recurso',
          tooltip: 'Ao relatar um problema, ele será enviado ao administrador do local para ser resolvido. Uma vez resolvido, será removido desta lista.',
          description: ['Selecione um recurso para relatar um problema', 'Explique o problema aqui'],
        },
        loading: "Carregando... ",
        image_description: "Imagem (< 4MB)",
        button: {
          photo: 'Adicionar foto',
          send: 'Enviar problema'
        },
        image_info: 'Imagem (< 4MB)',
        couter: ' problemas abertos',
      },
      releases_list: {
        add_button: 'Adicionar nova versão',
        user_select: 'Versões de',
        colleagues_info: "Você não tem colegas com versões visíveis",
        counter: 'Nenhuma versão',
        right_panel_info: ['Selecione um colega no menu suspenso para ver suas versões.', "Você não tem colegas com versão visível."]
      },
      release: {
        pageName: 'versao',
        header: 'Detalhes da versão',
        placeholder: 'Assunto',
        buttons: {
          create: ['Lançar sua ', 'Confirmar lançamento'],
          edit: ['Cancelar esta versão?', 'Cancelar lançamento']
        },
        description: [' Não há ', 'nenhuma ', 'reserva ativa para este ', ' durante este intervalo de tempo.'],
        modals: {
          user: 'Trocar usuário? A versão atual será excluída.',
          not_available: "Versão não disponível ",
          delete: 'Excluir esta versão?'
        }
      },
      reservations_list: {
        add_button: 'Adicionar nova reserva',
        user_select: 'Reservas para',
        tabs: [
          { name: "Pessoal", state: false },
          { name: "Visitante", state: true }
        ],
        colleagues_info: "Você não tem colegas com reservas visíveis",
        counter: 'Nenhuma reserva',
        right_panel_info: ['Selecione um colega no menu suspenso para ver suas reservas.', 'Edite uma reserva ou crie uma nova para ver os recursos disponíveis.']
      },
      reservation: {
        pageName: 'reservas',
        header_requests: ['Nenhuma reserva ainda.', 'Preencha os detalhes da reserva e verifique a disponibilidade.'],
        details: ' detalhes',
        end: 'Finalizar reserva',
        amenities_item: 'outro pedido',
        snack_bars: {
          create: 'Reserva criada',
          update: 'Reserva atualizada'
        },
        visitor: {
          text: "Reserva para visitante"
        },
        placeholders: {
          visitor: "Endereço de e-mail",
          amenities: 'Selecionar comodidades',
          no_amenities: 'Nenhum item encontrado',
          subject: 'Assunto',
          neighbourhood: "Selecionar bairro"
        },
        public: {
          text: 'Tornar privado para todos',
          tooltip: 'Sua reserva é sempre visível para os membros da sua Tribo. Ativar a alternância a tornará invisível para todos, incluindo sua Tribo.'
        },
        right_panel_info: {
          no_checked_availability: ['Preencha os detalhes e verifique', 'a disponibilidade para reservar um recurso'],
          checked_availability: ['Detalhes da solicitação alterados, ', 'verifique a disponibilidade novamente']
        },
        modals: {
          amenity: {
            text: "Sua reserva atual não atende a todas as comodidades!",
            descriptions: [
              "Mas não se preocupe, você pode continuar com a reserva atual.",
            ]
          },
          error_404: "Reserva não disponível",
          requested_capacity: {
            text: "Seu espaço atribuído não tem assentos suficientes!",
            descriptions: [
              "Continue, para manter a nova quantidade de assentos e procure um novo espaço.",
              "Ou cancele a mudança de assento e mantenha seu espaço atual.",
            ]
          },
          internal: {
            text: "Seu espaço atribuído é apenas para reuniões internas!",
            descriptions: [
              "Continue, para manter a mudança e procurar um novo espaço.",
              "Ou cancele a mudança e mantenha seu espaço atual.",
            ]
          },
          time: {
            text: "Sua reserva não está disponível para este horário!",
            descriptions: [
              "Continue, para manter o novo horário e sua reserva será atualizada.",
              "Ou cancele a mudança de horário e mantenha sua reserva atual."
            ]
          },
          space: {
            text: "Seu espaço atribuído é apenas para reuniões internas!",
            descriptions: [
              "Continue, para manter a mudança e procurar um novo espaço.",
              "Ou cancele a mudança e mantenha seu espaço atual.",
            ]
          },
          invalid_email: "Você deve inserir um e-mail válido do visitante ou desativar a alternância de visitante para criar uma reserva.",
          delete: {
            simple: { text: 'Excluir esta reserva?' },
            ongoing: { text: 'Finalizar esta reserva?' },
            visitor_change: {
              text: {
                toggle: 'Alternar entre reserva pessoal e de visitante removerá a reserva atual!',
                email: "Alterar o endereço de e-mail do visitante removerá sua reserva atual!",
              },
              descriptions: [
                "Continue, para manter as alterações e sua reserva será atualizada.",
                "Ou cancele a mudança e mantenha sua reserva atual."
              ]
            },
            user_change: {
              text: 'Alterar o usuário removerá sua reserva atual!',
              descriptions: [
                "Continue, para manter o novo usuário e sua reserva será atualizada.",
                "Ou cancele a mudança e mantenha sua reserva atual."
              ]
            },
            site_change: {
              text: 'Alterar o local removerá sua reserva atual!',
              descriptions: [
                "Continue, para manter o novo local e sua reserva será atualizada.",
                "Ou cancele a mudança e mantenha sua reserva atual."
              ]
            }
          }
        }
      },
      home: {
        nwesfeed_list: 'Nenhuma notícia hoje!',
        pageName: 'início',
        emergency: {
          header: 'MENSAGEM DE EMERGÊNCIA',
          by: 'por'
        },
        reservation: {
          headers: {
            upcoming: 'Sua próxima reserva',
            current: 'Sua reserva atual',
            no_upcoming: 'Sem reservas futuras'
          },
          descriptions: ['Aqui para te ajudar!', 'Faça sua escolha com os botões abaixo.'],
          loading: 'Carregando ...'
        },
        newsfeed_header: 'Feed de notícias'
      },
      settings: {
        pageName: 'configurações',
        h1: 'Configurações do usuário',
        password: {
          errors: ["Pelo menos 1 número, 1 símbolo e 8 caracteres de comprimento", 'Senhas não são iguais'],
          rules: 'A senha deve conter pelo menos: 8 caracteres, 1 maiúscula, 1 minúscula, 1 dígito',
          rules_v2: 'Deve conter pelo menos: 8 caracteres, 1 maiúscula, 1 minúscula, 1 dígito',
          placeholders: ['Digite a nova senha', 'Digite novamente a nova senha'],
          info_ok: "Senha alterada com sucesso",
          info_not_ok: "A senha não foi alterada"
        },
        h3_sections: {
          password: 'Alterar senha',
          image: 'Adicionar imagem do usuário',
          site: 'Local padrão para reserva',
          wing: 'Ala padrão para reserva',
          time: 'Formato de hora',
          language: 'Idioma',
          preferences: 'Preferências',
          preferences_v2: 'Preferências do usuário',
          delegate: 'Delegado',
          calendar: 'Convites de calendário',
          wants_asset_details_popup: 'Informações do mapa'
        },
        subsections: {
          app: "Versão",
          sites: "Locais visíveis",
          language: "Idioma",
          time: "Formato de hora",
          calendar: "Convites de calendário"
        },
        user_image_info: ['Entre em contato com o seu escritório ', 'gerente para alterar a imagem do usuário'],
        user_image: ['Imagem do usuário', '(tamanho quadrado, menos de 4 MB)', 'Faça o upload de uma imagem de tamanho quadrado com menos de 4 MB'],
        toggle: 'Usar formato de hora 24h',
        app_version: "Use nova versão (reinício necessário)",
        calendar_toggle: 'Enviar convites de calendário para reservas feitas na aplicação web',
        wants_asset_details_popup_toggle: 'Mostrar janela pop-up com detalhes ao selecionar um recurso no mapa',
        no_delegates: 'Nenhum delegado',
        delegates_action_list: {
          search: 'Pesquisar',
          edit: 'Colegas que podem criar ou editar minhas reservas',
          add: 'Adicionar colegas'
        },
        delegates_modal: "Ao marcar esta caixa, a tabela abaixo será descartada e todos poderão ver suas reuniões públicas."
      },
      who_is_arround: {
        header: 'Quem está por perto',
        tooltip: 'Verifique quem da sua Tribo está no escritório em determinado momento. Ainda não tem uma Tribo? Crie uma em ‘Minha Tribo’.',
        date_time: {
          main: 'Data e hora',
          helper: 'HORA'
        },
        info: 'Selecione um recurso disponível no mapa para criar uma reserva para você mesmo',
        users_list: {
          header: { hide: 'Esconder', show: 'Mostrar', postfix: ' lista de colegas' },
          placeholder: 'Nome de usuário'
        },
        right_panel_info: 'Escolha um local para ver a ocupação',
        modal: 'Criar reserva?',
      },
      my_tribe: {
        header: 'Minha Tribo',
        tabs: ['Seguidores', 'Seguindo'],
        info: ['Colegas que podem ver suas reservas não privadas', 'Pedidos para segui-lo', 'Colegas cujas reservas não privadas você pode ver'],
        serach_header: 'Resultados da busca',
        no_results: 'Sem resultados',
        placeholders: ['Buscar', 'Buscar colegas para seguir'],
        modals: {
          confirm: {
            text: "Você deseja seguir também este colega?",
            cancel: "Não seguir",
            confirm: "Seguir"
          },
          delete: {
            text: "Você deseja excluir o pedido deste colega?",
            cancel: "Cancelar",
            confirm: "Excluir pedido"
          },
          remove: {
            text: "Você deseja remover este colega?",
            cancel: "Cancelar",
            confirm: "Remover"
          },
          cancel: {
            text: 'Você deseja cancelar seu pedido?',
            cancel: 'Não',
            confirm: 'Cancelar pedido'
          },
          unfollow: {
            text: 'Você deseja deixar de seguir este colega?',
            description: ['Se mudar de ideia, terá que solicitar para seguir novamente.'],
            cancel: "Cancelar",
            confirm: 'Deixar de seguir'
          }
        }
      },
      squads: {
        header: 'Meus Esquadrões',
      },
      qr: {
        header: 'Check-in ou Walk-in com QR',
        checkout: "Quer fazer check-out?",
        checkedout: "Check-out concluído",
        photo_description: 'Digitalize o código QR para fazer check-in na sua reserva atual ou para criar uma reserva walk-in',
        start_scan_description: 'Aponte para o código QR e toque aqui',
        errors: {
          camera: "A câmera não é suportada no Chrome, por favor, use o Safari",
          app: "Tente usar um aplicativo especial de leitura de QR code!",
          device: "Use um dispositivo móvel ou tablet com câmera traseira!"
        },
        modals: {
          info: 'O acesso à câmera é necessário para digitalizar o código QR.',
          check_in: {
            header: 'Check-in para:',
            from_to: ['De: ',', para '],
            subject_default: 'Reserva'
          }
        }
      },
      help_center: {
        nav: {
          demo: {
            header: 'Demonstração rápida',
            card: {
              headers: [],
              descriptions: ['Assista a esta demonstração rápida para ter uma boa visão geral de todas as funcionalidades da Tribeloo.'],
              links: []
            }
          },
          get_started: {
            header: 'Primeiros passos',
            sub_headers: ['Vídeo de configuração', 'Autenticação única (Single-Sign-On)', 'Provisionamento de usuário'],
            card: {
              headers: [],
              descriptions: ['Saiba como começar com a Tribeloo e faça a configuração inicial da plataforma.', 'Consulte as diretrizes sobre como instalar a funcionalidade de Autenticação única ', 'Consulte as diretrizes sobre como instalar a funcionalidade de Provisionamento de usuário '],
              links: ['aqui.']
            }
          },
          site_manager: {
            header: 'Gerente de local',
            sub_headers: ['Vídeo de apresentação', 'Configuração de dispositivos'],
            card: {
              headers: ['Configuração de dispositivos'],
              descriptions: ['Aprenda a usar a Tribeloo como gerente de escritório.', 'Aprenda a configurar os dispositivos de mesa e sala de reuniões.'],
              links: []
            }
          },
          user: {
            header: 'Usuário',
            sub_headers: ['Vídeo de apresentação', 'Usando o complemento do Outlook', 'Instalar complemento do Outlook', 'Usando a extensão do Chrome', 'Instalar extensão do Chrome', 'Instalar aplicativo no iPhone', 'Instalar aplicativo no Android'],
            card: {
              mobile: 'Saiba como usar o aplicativo móvel da Tribeloo e a solução de reserva na web.',
              outlook: {
                headers: ['Como usar o complemento do Outlook', 'Como instalar o complemento do Outlook'],
                descriptions: ['Aprenda a usar o complemento da Tribeloo para Outlook.', 'Aprenda a instalar o complemento da Tribeloo para Outlook. ', 'Vá para a ', 'Microsoft Store', ' para baixá-lo.']
              },
              google: {
                headers: ['Como usar a extensão do Chrome', 'Como instalar a extensão do Chrome'],
                descriptions: ['Aprenda a usar a extensão da Tribeloo para Chrome e a integração com o seu Google Calendar', 'Aprenda a instalar a extensão da Tribeloo para Chrome, integrada ao seu calendário do Google. ', 'Vá para a ', 'Chrome Store', ' para baixá-lo.']
              },
              pwa: {
                headers: [],
                descriptions: ['Confira o vídeo abaixo ou ', 'baixe o PDF', ' sobre como instalar o aplicativo da Tribeloo no seu iPhone', ' sobre como instalar o aplicativo da Tribeloo no seu Android']
              }
            }
          },
          feedback: {
            header: 'Entre em contato conosco',
            sub_headers: [],
            card: {
              headers: ["Não encontra o que procura? Ou quer dar feedback?"],
              descriptions: ['Deixe-nos ajudar! Compartilhe o máximo de detalhes possível para que possamos ajudar você mais rapidamente.'],
              links: []
            }
          }
        },
        main: {
          header: 'Centro de Ajuda',
          subheaders: ['Bem-vindo!', 'Encontre informações úteis sobre como começar com a Tribeloo.', 'Você sempre pode voltar aqui clicando em ‘Centro de Ajuda’ na página inicial ou em configurações.'],
          orange_card: ['Clique nos ', ' ícones no software para obter mais informações sobre um tópico específico.']
        }
      }
    }
  },
  shared_components: {
    wings_select: "Todas as alas",
    activity_select: {},
    assets_map: {
      types_external_internal: [', Somente Interno', ', Permite Externo'],
      owner: 'Atribuído a: ',
      time_slots: "Disponível: ",
      no_time_slots: "Nenhum intervalo de tempo disponível",
      not_reservable: "Você não pode reservar este recurso"
    },
    attendees_modal: {
      header_text: {
        share: 'Compartilhe isso com seus colegas',
        attendees: 'Estão participando deste evento'
      },
      info: ['Selecione colegas no menu suspenso para enviar um e-mail a eles.', 'Por favor, peça aos seus colegas para adicioná-lo à sua tribo primeiro'],
      placeholdrs: {
        colleagues: 'Selecionar colega',
        message: 'Digite seu texto aqui'
      },
      button_attendees: ['Não vai mais?', 'Você vai?'],
      more: ' mais...',
      send: 'Enviar',
      send_all: 'Enviar e-mail para todos',
      counter: 'participantes'
    },
    total_counter_download_table_csv: {
      batch_buton: 'Editar em lote',
      button: 'Baixar',
      text: ' mostrado',
      selected: " selecionado",
      info: 'Sem dados para os parâmetros selecionados'
    },
    confirm_dialog: {},
    date_time_range_modal: {
      headers: {
        main: 'Definir data e hora',
        from: 'De',
        to: 'Para'
      },
      tooltip: 'Clique para exibir o calendário',
      info: "A hora 'Para' deve vir depois da hora 'De'"
    },
    date_time_range_view: {
      headers: { from: 'De', to: 'Para', start: 'Início', end: 'Fim' }
    },
    day_time_mini: {
      header: ["Definir ", " data e hora"],
      tooltip: {
        calendar: "Clique para exibir o calendário",
        time: "Clique para exibir a hora"
      }
    },
    download_xlsx: {},
    expanded_filter_assets_list: {
      modal: {
        header: "Informações do recurso",
        report_button: "Relatar um problema"
      },
      header: ' lista de disponibilidade',
      description: 'Mostrar apenas os com todas as comodidades',
      nospace: 'Nada disponível',
      types_external_internal: [', interno', ', externo'],
      placeholder: 'Pesquisar',
      tooltip: 'O espaço não contém todas as comodidades solicitadas'
    },
    filter_dialog: {},
    icon_video: "Mais informações",
    info_dialog: {},
    kiosk_logout_info_modal: {
      message: "Você ainda está trabalhando aqui?",
      info: ["Sua sessão terminará em ", " segundos. "]
    },
    newsfeed_create_edit_item: {
      popup: "Descartar alterações não salvas?",
      markdown: "A estilização com Markdown é suportada",
      link: "Verificar diretrizes",
      image_info: "Imagem (< 4MB, tamanho 335 x 75 px)",
      radio: ["Tipo:", "Notícia", "Evento"],
      placeholder: {
        event: "Novo evento",
        location: "Localização",
      }
    },
    newsfeed_item: {
      default_subject: '',
      actions: ['mais', 'fechar']
    },
    loading_table_spinner: 'buscando dados',
    reservation_header_info: {
      state: ['Reserva pendente', 'Atribuída'],
      info: "Verifique a disponibilidade para atribuir uma",
      internal_external: [', interno', ', externo']
    },
    reservation_tipe_modal: 'Sem opções para adicionar, por favor, edite uma reserva existente',
    site_dialog: {},
    site_select: {
      placeholder: 'Selecionar local',
      tooltip: 'Clique aqui para selecionar o local',
      options: ['Todos os locais', 'Sem local de origem']
    },
    site_wings_reservation_view_external_data_set: "Clique para ir para esta ala",
    table_datetime_range_helper: {},
    time_range_helper: {},
  },
  user_shared_components: {
    capcity_internal_block: {
      placeholder: 'lugares',
      tooltip: 'Reunião externa: com visitantes externos. Reunião interna: apenas com funcionários internos',
      select: ['Espaço único', 'Reunião interna', 'Reunião externa', 'Sala']
    },
    list_item: {
      internal_external: [', interno', ', externo'],
      info_reservation: ['Reserva pendente, em breve será atribuída.', 'Ou verifique a disponibilidade para escolher uma você mesmo.'],
      subject: ['Nova versão', 'Nova reserva'],
      status: 'lançada',
      header: 'Sua '
    },
    message_details: {
      toggle: 'Adicionar solicitação',
      info: ['Solicitação não tratada', 'Solicitação tratada'],
      placeholder: "Deixe uma solicitação para o gerente do escritório se tiver pedidos adicionais"
    },
    weeks_repeater: {
      title: ' Repetir semanalmente até',
      weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
    },
    repeater_modal: {
      header: 'Personalizar recorrência',
      repeat_until: 'Repetir até',
      repeat_on: 'Repetir em'
    }
  },
  reusable_data: {
    time_tracking_popup: {
      big_titel: "Você trabalhou por ",
      descriptions: ["Deseja encerrar o seu dia de trabalho?"]
    },
    tables: {
      from: "De",
      to: "Para",
      date_time: "Data e hora",
      // ID: "ID",
      // Number: "Número",
      // User: "Usuário",
      // User: "Nome do usuário",
      // to: "Atribuído a",
      // to: "Nível atribuído ao usuário",
      // to: "Nível do usuário",
      // Subject: "Assunto",
      // to: "Assentos solicitados",
      // to: "interno/externo",
      // Amenities: "Comodidades",
      // to: "Comodidades solicitadas",
      // to: "Comodidades do recurso",
      // Wing: "Asa",
      // Wings: "Asas",
      // Resource: "Recurso",
      // Resource: "Local do recurso",
      // Resource: "E-mail do recurso",
      // Resource: "Tipo de recurso",
      // Resource: "Nível do recurso",
      // Resource: "Solicitação",
      // Status: "Status",
      // to: "Nível do ativo",
      // to: "Capacidade do assento",
      // to: "Assentos solicitados",
      // to: "Limpo em",
      // to: "Limpo por",
      // to: "ID do dispositivo",
      // to: "Check-in obrigatório",
      // Email: "E-mail",
      // RFID: "RFID",
      // to: "Não compareceu",
      // to: "",
      // to: "",
    },
    feedback: {
      header: 'Dar feedback',
      placeholders: ['Selecionar tipo de feedback', 'Adicionar uma descrição'],
      types: ['erro', 'ideia', 'pergunta', 'outro'],
      buttons: ['Cancelar', 'Enviar'],
      issue_text: "Houve um problema ao enviar seu feedback. Por favor, tente novamente mais tarde. Obrigado!"
    },
    user_navigation: {
      no_actions_nav: {
        home: 'Início',
        my_tribe: 'Minha Tribo',
        squads: 'Meus Esquadrões',
        outlook_events: 'Eventos do Outlook',
        google_events: 'Eventos do Google',
        qr: 'Check-in QR ou presencial',
        tracker: ["Iniciar dia de trabalho, ", "Encerrar dia de trabalho, "],
        reservations: 'Gerenciar Reservas',
        releases: 'Gerenciar Lançamentos',
        who_is_arround: 'Quem está por perto',
        find_colleague: 'Encontrar um colega',
        report_issue: 'Reportar um problema',
        user_settings: 'Configurações do Usuário',
        help_center: 'Central de Ajuda'
      },
      actions_nav: {
        book_desk: 'Reservar uma mesa',
        book_meeting_room: 'Reservar uma sala de reuniões',
        add_new_release: 'Adicionar novo lançamento'
      }
    },
    button: {
      ok: 'OK',
      add: 'Adicionar',
      show: 'Mostrar',
      hide: 'Esconder',
      send: 'Enviar',
      save: 'Salvar',
      edit: 'Editar',
      more: 'Mais',
      close: 'Fechar',
      email: 'E-mail',
      login: 'Entrar',
      logout: 'Sair',
      check_in: 'Check-in',
      submit: 'Enviar',
      delete: 'Excluir',
      select: 'Selecionar',
      cancel: 'Cancelar',
      upload: 'Enviar',
      remove: 'Remover',
      reopen: 'Reabrir',
      comment: 'Comentar',
      confirm: 'Confirmar',
      inverse: 'Inverter',
      refresh: 'Atualizar',
      continue: 'Continuar',
      download: 'Baixar',
      show_all: 'Mostrar todos',
      checked_in: 'Check-in realizado',
      select_all: 'Selecionar todos',
      batch_edit: 'Editar em lote',
      upload_image: 'Enviar imagem',
      remove_image: 'Remover imagem',
      assign_me_one: 'Atribuir-me um',
      back_to_dashboard: '< Voltar ao Painel',
      cancel_reservation: 'Cancelar reserva',
      check_availability: 'Verificar disponibilidade',
      retutn_releases_list: 'Voltar à lista de lançamentos',
      retutn_reservations_list: 'Voltar à lista de reservas',
      retutn_reservations_releases_events_list: [ 'Voltar para ', ' eventos']
    },
    release_reservation_text: {
      outlook: 'Alterar horário no calendário do Outlook',
      google: 'Alterar horário no calendário do Google'
    },
  },
  v2_shared_components: {
    calc: {
      sum: "Total soma: ",
      count: "contagem: ",
      price: "preço: "
    },
    tribeloo_asset_info: {
      calc: {
        sum: "Total summ: ",
        count: "count: ",
        price: "price: "
      },
      user_info: " Atribuído a você",
      user_release_info: "Este recurso foi atribuído a você. Reserve-o se quiser usá-lo.",
      user_booked_info: "Este recurso foi atribuído a você e está disponível a qualquer momento para você usar.",
      invited_users: " colegas convidados e assunto",
      time_slot: "Indisponível hoje",
      buttons: {
        invite: 'Convidar colegas',
        book: 'Confirmar reserva',
        update: 'Confirmar alterações',
        delete: 'Cancelar reserva',
        edit: 'Editar reserva'
      }
    },
    tribeloo_asset_list: {
      headers: {
        menu: "Ferramentas Tribeloo",
        favorites: "Favoritos"
      },
      filter: {
        search: "Pesquisar",
        all: "Todos",
        users_only: "Apenas usuários",
        resources_only: "Apenas recursos"
      },
      menu_items: {
        colleagues: {
          subtitle: "Encontrar um colega",
          description: "Colabore facilmente com seus colegas"
        },
        bookings: {
          subtitle: "Minhas Reservas",
          description: "Visão geral de todas as suas reservas"
        },
        releases: {
          subtitle: "Liberações",
          description: "Libere sua mesa dedicada para que outros possam usar"
        },
        qr: {
          subtitle: "Scanner QR",
          description: "Reserve ou faça check-in usando o Scanner QR"
        }
      }
    },
    tribeloo_asset_list_item: {
      check_in: ["Check-in realizado", "Fazer check-in"]
    },
    tribeloo_asset_release_popper: {
      buttons: {
        create: "Criar Liberação",
        cancel: "Cancelar Liberação"
      }
    },
    tribeloo_assets_popper: {
      title: "Recursos Disponíveis"
    },
    tribeloo_component_filter: {
      title: "Opções de Filtro",
      date_range: ["Intervalo de Datas", "De", "Para"],
      type: "Tipo de Recurso",
      location: "Local"
    },
    tribeloo_bookings_list: {
      subtitle: ['Encontrar Colegas', 'Minhas Reservas'],
      description: ['Pesquise colegas para encontrar suas reservas e otimizar a colaboração', 'Visão geral de todas as suas reservas. Gerencie todas as suas reservas em um só lugar.'],
      search: " resultados da pesquisa.",
      not_found: "Nenhum colega encontrado."
    },
    tribeloo_camera: {
      camera_error_message: "Por favor, dê acesso à câmera",
      camera_not_found: "Nenhuma câmera encontrada."
    },
    tribeloo_date_picker: {
      max_selection_info: "Limite máximo de seleção de vários dias atingido",
      days_selected_text: "dias selecionados",
      toggle_text_info: ["Seleção de vários dias ", "Seleção de vários dias"]
    },
    tribeloo_download_image: {
      info_dialogue: "Sua imagem é muito grande.\nPor favor, selecione uma imagem com tamanho inferior a 4MB."
    },
    tribeloo_edit_invites: {
      title: "Editar Convite de Reserva",
      subtitle: "Um e-mail será enviado apenas aos colegas recém-adicionados.",
      buttons: {
        main_invite: "Convidar Colegas",
        send_invite: ['Reenviar Convite', 'Enviar Convite']
      },
      placeholder: "Convites Atualizados"
    },
    tribeloo_favorites_list_item: {
      other_info: " Reservas Ativas"
    },
    tribeloo_map_legend: {
      button: "Centralizar Mapa",
      map_legend_title: "Legenda do Mapa",
      map_legend_items: {
        available: "Disponível",
        confirm: "Necessita Confirmação",
        not_for_user: "Não para o usuário",
        unavailable: "Indisponível"
      }
    },
    tribeloo_qr_scanner: {
      title: "Scanner de Código QR",
      subtitle: "Coloque o código QR na frente da câmera para reservar ou fazer check-in.",
      error_message: "Erro na leitura do Código QR",
      processing_message: "Decodificação da leitura em andamento"
    },
    tribeloo_releases_sidebar: {
      title_resources: "Meus Recursos",
      title_releases: "Minhas Liberações",
      subtitle: "Liberações",
      description: "Configure a hora, data e escolha o recurso que deseja liberar.",
    },
    tribeloo_type_location_neighbourhood: {
      labels: {
        type: "Tipo de Recurso",
        site: "Local",
        wing: "Ala",
        neighbourhoods: "Bairros"
      }
    },
    tribeloo_upload_profile_pic: {
      title: "Carregar Imagem",
      subtitle: "Escolha uma foto de perfil para exibir no mapa",
      info_dialog_component: "Sua imagem é muito grande.\nPor favor, selecione uma imagem com tamanho inferior a 4MB.",
      support_text: "Compatível com JPG ou PNG",
      dd_text: "Arraste e solte a imagem ou ",
      browse_text: "navegue",
      buttons: {
        save: "Salvar imagem",
        upload: "Carregar imagem",
        capture: "Tirar foto usando a webcam",
      },
      img_alt: "Nova foto de perfil"
    },
    tribeloo_whoisaround: {
      title: "Quem Está Por Perto?"
    },
    tribeloo_more_settings: {
      title: "Mais Configurações",
      labels: {
        seats: "Assentos",
        amenities: "Comodidades",
        visitor: "Convidar visitante"
      },
      toggles: {
        amenities: "Mostrar apenas com todas as comodidades",
        booking: "Reserva invisível para os outros"
      },
      placeholders: {
        visitor: "E-mail do visitante",
        amenities: "Nenhuma comodidade selecionada"
      },
      info: "Mais configurações Indisponíveis"
    },
    tribeloo_time_range: {
      labels: {
        select: "Seleção Rápida",
        start: "Hora de Início",
        end: "Hora de Término"
      },
      rangeOptions: {
        fullDay: 'Dia inteiro',
        morning: 'Manhã',
        afternoon: 'Tarde',
        customTime: 'Horário personalizado'
      }
    },
    tribeloo_time_picker: {
      tooltip_error: "Formato de hora inválido",
    },
    tribeloo_single_file_upload: {
      button_save: "Salvar Imagem Cortada"
    },
    tribeloo_pop_up_confirmation: {
      titles: {
        cancel: "Cancelamento de Reserva",
        edit: "Edição de Reserva Bem-sucedida",
        invite: "Editar Convite de Reserva",
        booked: "Reserva Bem-sucedida!",
        options: "Opções de reserva opcionais",
        addSubject: "Adicionar assunto",
      },
      subtitles: {
        edit_booked: "Um e-mail será enviado para você com informações sobre a sua reserva.",
        invite: "Um e-mail será enviado apenas para os colegas recém-adicionados.",
        old: "Antiga Reserva",
        new: "Nova Reserva",
        cancel: "Uma vez que uma reserva é cancelada, não pode ser desfeita."
      },
      input: ['Convites Atualizados', 'Colegas Convidados'],
      subjectInput: {
        saved: "Assunto salvo",
        placeholder: "Área temática"
      },
      buttons: {
        confirm: 'Confirmar',
        main: "Convidar Colegas",
        booKingSubjectInvitations: "Assunto da reserva e convites",
        send: "Enviar Convite",
        update: "Atualizar Convites",
        cancel: "Cancelar reserva",
        nevermind: "Deixa pra lá",
        saveSubject: "Salvar assunto"
      }
    },
    tags_modal: {
      title: "etiquetas",
      subtitle: "Pequena explicação sobre como as etiquetas funcionam",
      small_title: ['Sugestões', 'Todas as Etiquetas'],
      colleagues: "colegas",
      placeholder: "Pesquisar ou Adicionar Etiquetas",
      add_button: ['Adicionar', 'Adicionar Nova Etiqueta'],
      save_button: "Salvar Alterações"
    },
    tribeloo_base_user_settings: {
      settings: "Configurações do Usuário",
      tribe: "Minha Tribo"
    },
    tribeloo_filter_tribe_members: {
      title: "Opções de Filtro",
      tag: "Etiquetas",
      site: "Site Principal"
    },
    tribeloo_my_tribe: {
      description: "Marque colegas para encontrá-los facilmente ao criar uma reserva e defina seus próprios grupos.",
      search: "Buscar"
    },
    tribeloo_tribe_member: {
      title: "Etiquetas",
      not_found: "Nenhuma etiqueta encontrada",
      edit_button: "Editar etiquetas"
    }
  },
  v2_pages: {
    tribeloo_default_page_holder: {
      messages: {
        update_success: 'Reserva Atualizada com Sucesso',
        update_failed: 'Falha ao Atualizar a Reserva.',
        cancel_failed: "Falha ao cancelar a liberação",
        error408: "Do you wont to force book?"
      }
    },
    tribeloo_external_invites: {
      info: "Seu Convite é Inválido",
      button: "Ir para a Página Inicial"
    }
  }
};
