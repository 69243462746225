import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as _ from "lodash";
import * as moment from 'moment';

import { ImageService } from "./../../services/image/image.service";
import { AggregationService } from "./../../services/aggregation/aggregation.service";
import { LocalizationService } from "./../../services/localization/localization.service";

@Component({
  selector: 'date-time-range-modal',
  templateUrl: './date-time-range-modal.component.html',
  styleUrls: ['./date-time-range-modal.component.css'],
  providers: [ AggregationService, ImageService ]
})
export class DateTimeRangeModalComponent implements OnInit {

  text: string = "";
  descriptions: string[] = [];

  dateTimeRange = {
    "from": moment().hour(8).minute(0).second(0).toDate(),
    "to":  moment().hour(19).minute(0).second(0).toDate()
  };
  isBefore: boolean = true;

  fromDate: any;
  fromHour: string = '';
  fromMinute: number = 0;

  toDate: any;
  toHour: string = '';
  toMinute: number = 0;

  hoursSelect: any = [];
  minutesSelect: any = [];
  wants_24h_time_format: boolean = JSON.parse(localStorage.getItem("currentUser")).user.wants_24h_time_format;
  isDaysOnly: boolean = false;
  fromOnly: boolean = false;
  minDate: any = null;;
  maxDate: any = null;;

  languages: any;
  button: any;

  constructor(
    public dialogRef: MatDialogRef<DateTimeRangeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private aggregationService: AggregationService,
    private localizationService: LocalizationService
  ) { }

  ngOnInit() {
    if(this.localizationService.getComponentText('shared_components','date_time_range_modal').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','date_time_range_modal').data;
    }

    if(this.localizationService.getReusableDataText('button').isSuccess) {
      this.button = this.localizationService.getReusableDataText('button').data;
    }

    this.dateTimeRange = _.cloneDeep(this.data.range);

    this.text = this.data.text ? this.data.text : "";
    this.descriptions = this.data.descriptions ? this.data.descriptions : [];

    this.fromDate = moment(this.data.range.from).clone().toDate();
    this.toDate = moment(this.data.range.to).clone().toDate();

    this.minDate = !this.data.minDate || moment(this.data.minDate).clone().toDate();
    this.maxDate = !this.data.maxDate || moment(this.data.maxDate).clone().toDate();

    this.fromHour = moment(this.data.range.from).clone().format(this.wants_24h_time_format ? 'H' : 'hA');
    this.toHour = moment(this.data.range.to).clone().format(this.wants_24h_time_format ? 'H' : 'hA');

    this.fromMinute = moment(this.data.range.from).clone().minutes();
    this.toMinute = moment(this.data.range.to).clone().minutes();

    if(this.data.fromOnly) {
      this.fromOnly = this.data.fromOnly;
    }
    if(this.data.isDaysOnly) {
      this.isDaysOnly = this.data.isDaysOnly;
      if(!this.maxDate) {
        this.maxDate =  moment().add(-1, 'd').hour(23).minute(59).second(0).toDate()
      }
    }

    for (let i = 0; i < 24; i++) {
    	this.hoursSelect.push(moment().startOf('day').add(i, 'h').format(this.wants_24h_time_format ? 'H' : 'hA'))
    }
    console.log(	this.hoursSelect)
    if(!this.data.is5MinutesInterval) {
      for (let i = 0; i < 60; i++) {
        this.minutesSelect.push(i);
      }
    } else {
      for (let i = 0; i < 12; i++) {
        this.minutesSelect.push(i*5);
      }
    }

  }


  // select
  selectHourFrom(hour) {
    console.log('hour: ' + hour)
    this.fromDate = moment(this.fromDate).hours(parseInt(moment(hour, [this.wants_24h_time_format ? 'H' : 'hA']).format("H"), 10)).toDate();
    console.log('var: ' + this.fromDate)
    this.setNewDateTimeRange({from: this.fromDate, to:this.toDate}, true)
  }

  selectMinuteFrom(minute) {
    this.fromDate = moment(this.fromDate).minutes(minute).toDate();
    this.setNewDateTimeRange({from: this.fromDate, to:this.toDate}, true)
  }
  selectHourTo(hour) {
    this.toDate = moment(this.toDate).hours(parseInt(moment(hour, [this.wants_24h_time_format ? 'H' : 'hA']).format("H"), 10)).toDate();
    this.setNewDateTimeRange({from: this.fromDate, to:this.toDate})
  }

  selectMinuteTo(minute) {
    this.toDate = moment(this.toDate).minutes(minute).toDate();
    this.setNewDateTimeRange({from: this.fromDate, to:this.toDate})
  }


  fromTimeChange(event) {
    this.fromDate = moment(this.fromDate).hours(parseInt(moment(this.fromHour, [this.wants_24h_time_format ? 'H' : 'hA']).format("H"), 10)).minutes(this.fromMinute).toDate();
    this.setNewDateTimeRange({from: this.fromDate, to:this.toDate}, true)
  }

  toTimeChange(event) {
    this.toDate = moment(this.toDate).hours(parseInt(moment(this.toHour, [this.wants_24h_time_format ? 'H' : 'hA']).format("H"), 10)).minutes(this.toMinute).toDate();
    this.setNewDateTimeRange({from: this.fromDate, to:this.toDate})
  }

  setNewDateTimeRange(newRange, isFrom: boolean = false) {
    if(!moment(this.fromDate).isBefore(this.toDate) && isFrom) {
      this.dateTimeRange = _.cloneDeep(this.aggregationService.checkDateTimeRange(true, _.cloneDeep(this.dateTimeRange), {from:_.cloneDeep(this.fromDate), to: _.cloneDeep(this.dateTimeRange.to)}));
    } else {
      this.dateTimeRange = _.cloneDeep(newRange);
    }
    this.fromDate = _.cloneDeep(this.dateTimeRange.from);
    this.toDate = _.cloneDeep(this.dateTimeRange.to);
    this.fromHour = moment(this.dateTimeRange.from).clone().format(this.wants_24h_time_format ? 'H' : 'hA');
    this.toHour = moment(this.dateTimeRange.to).clone().format(this.wants_24h_time_format ? 'H' : 'hA');
    this.fromMinute = moment(this.dateTimeRange.from).clone().minutes();
    this.toMinute = moment(this.dateTimeRange.to).clone().minutes();
    this.isBefore = moment(this.fromDate).isBefore(this.toDate)
  }

  dateTimeChangeFrom() {
    this.setNewDateTimeRange(this.aggregationService.checkDateTimeRange(true, _.cloneDeep(this.dateTimeRange), {from:_.cloneDeep(this.fromDate), to: _.cloneDeep(this.dateTimeRange.to)}));
  }
  //
  // dateTimeChangeTo() {
  //   this.setNewDateTimeRange(this.aggregationService.checkDateTimeRange(false, _.cloneDeep(this.dateTimeRange), {from:_.cloneDeep(this.dateTimeRange.from), to: _.cloneDeep(this.toDate)}));
  // }

  timeChange() {
    this.dialogRef.close(_.cloneDeep(this.dateTimeRange));
    // this.dialogRef.close(_.cloneDeep({from: this.fromDate, to:this.toDate}));
  }

}
