import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from './../info-dialog/info-dialog.component';

@Component({
  selector: 'download-image',
  templateUrl: './download-image.component.html',
  styleUrls: ['./download-image.component.css']
})
export class DownloadImageComponent {

  @Input() editItemId : number = null;
  @Input() id : number = 1;
  @Input() nameBtn : string = '';
  @Input() isLight : boolean = false;
  @Input() sendType : string = '';
  @Output() onImageLoading = new EventEmitter<any>();
  @Output() onImageLoadingPreview = new EventEmitter<any>();

  imageSrc: string | ArrayBuffer;

  constructor(public dialog: MatDialog) { }

  fileChange(event) {
    let fileList: FileList = event.target.files;

    if(fileList.length > 0) {
      if(fileList[0].size < 12*1024*1024) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = reader.result
          this.onImageLoadingPreview.emit(this.imageSrc)
        };
        reader.readAsDataURL(fileList[0]);

        let formData:FormData = new FormData();
        if(this.id) {
          formData.append(this.sendType, this.id.toString())
        }
        formData.append('image', fileList[0], fileList[0].name);
        this.onImageLoading.emit(formData);
      } else {
        this.dialog.open(InfoDialogComponent, { data: { text: 'Your image is too large.\nPlease select image with size less than 12MB.', button: 'OK', isBlue: true} });
      }
    }
  }

}
