<div *ngIf="filteredItems" class="container" fxLayout="row" fxLayoutAlign=" center">
  <p class="total-counter" *ngIf="isSelected && items">{{filteredItems.length + '/' + items.length + languages.text + ' - ' + selected + languages.selected}}</p>
  <p class="total-counter" *ngIf="!isSelected && items">{{filteredItems.length + '/' + items.length + languages.text}}</p>
  <input type="text" name="Search" placeholder="Search" [(ngModel)]="search" (keyup)="changeValue()">
  <div *ngIf="showButton" class="download-button" fxLayout="row" fxLayoutAlign="center center" (click)="downloadCSV()">
    <i class="fa fa-cloud-download"></i>
    <span>{{languages.button}}</span>
  </div>
  <div *ngIf="showBatchButton" class="download-button" fxLayout="row" fxLayoutAlign="center center" (click)="batchClick()">
    <i class="fa fa-pencil"></i>
    <span>{{languages.batch_buton}}</span>
  </div>
  <p class="total-counter red" *ngIf="!filteredItems.length">{{languages.info}}</p>
  <!-- <p class="total-counter red" *ngIf="!sortedData.length">No info for this site{{isSiteOnly ? '' : ' or timeframe'}} yet.</p> -->
</div>
