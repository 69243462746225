import {
  Component,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizationService } from "./../../services/localization/localization.service";

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {

  button: any;

  constructor(
     public dialogRef: MatDialogRef<ConfirmDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private localizationService: LocalizationService
   ) {
     if(this.localizationService.getReusableDataText('button').isSuccess) {
       this.button = this.localizationService.getReusableDataText('button').data;
     }
   }

   onClick(res): void {
     this.dialogRef.close(res);
   }

}
