<div fxLayout="column" fxLayoutAlign=" center">
  <h3>{{languages.header[0]}} {{data.text}} {{languages.header[1]}}</h3>
  <!-- <span class="date">{{data | date: 'dd.MM.yyyy'}}</span> -->

  <div class="date-container">
    <mat-form-field class="date-space">
      <input matInput [(ngModel)]="date" [matDatepicker]="from" (dateChange)="onChange($event)" disabled>
      <mat-datepicker #from [startAt]="date" disabled="false"></mat-datepicker>
    </mat-form-field>
    <div class="date" [ngStyle]="{'background': '#f0f0f0'}" fxLayoutAlign="space-between center" (click)="from.open()"  matTooltip="{{languages.tooltipe.calendar}}">
      <span>{{date | datex: 'DD MMMM YYYY'}}</span>
      <i class="fa fa-calendar"></i>
    </div>
  </div>
  <div class="time-container" fxLayout="row" fxLayoutAlign="center center">
    <div class="timeWidth" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="" fxLayout="column" fxLayoutAlign=" center">
        <i class="fa fa-angle-up timeArrow timeArrowTop" (click)="increaseHours()"></i>

        <mat-form-field class="select">
          <mat-select [(ngModel)]="hour">
            <mat-option style="color: #4a4a4a !important" *ngFor="let HT of hoursSelect" [value]="HT" (click)="selectHour(HT)">{{ HT < 10 ? '0' + HT : HT }}</mat-option>
          </mat-select>
        </mat-form-field>

        <i class="fa fa-angle-down timeArrow" (click)="decreaseHours()"></i>
      </div>

      <span class="time">:</span>

      <div class="" fxLayout="column" fxLayoutAlign=" center">
        <i class="fa fa-angle-up timeArrow timeArrowTop" (click)="increaseMinutes()"></i>

        <mat-form-field class="select">
          <mat-select [(ngModel)]="minute">
            <mat-option style="color: #4a4a4a !important" *ngFor="let MT of minutesSelect" [value]="MT" (click)="selectMinute(MT)">{{ MT < 10 ? '0' + MT : MT }}</mat-option>
          </mat-select>
        </mat-form-field>

        <i class="fa fa-angle-down timeArrow" (click)="decreaseMinutes()"></i>
      </div>
    </div>
    <i class="fa fa-clock-o fa-4x"></i>
  </div>

  <div fxLayout="row">
    <div class="button-accent" (click)="dialogRef.close()">Cancel</div>
    <div class="button-confirm" (click)="timeChange()">Confirm</div>
  </div>
</div>
