export const fr = {
  type:'fr',
  code:'fr-FR',
  name:'Français',
  img:'flag-icon-fr',
  pages: {
    login_main_navigation: {
      login: {
        pageName: 'login',
        callback: 'Fermez cette fenêtre et continuez dans le add-in Outlook de Tribeloo',
        email_dialog: {
          header: 'Votre Email',
          input: 'Email'
        },
        sso_info: {
          text: "Votre navigateur bloque l'ouverture d'un nouvel onglet pour la connexion SSO. Modifiez les paramètres de votre navigateur ou cliquez sur le bouton « Forcer l'ouverture »",
          button: "Forcer l'ouverture"
        },
        text: {
          loading: ['On travaille pour vous', 'Si ça prend trop de temps,', 'veuillez recharger'],
          header: 'Bienvenue',
          sub_header: 'Réservez vos bureaux préférés, trouvez vos collègues et bien plus encore.',
          form_headers: {
            login: 'Se connecter',
            email: 'Veuillez vérifier l\'e-mail',
            password: 'Mot de passe oublié'
          },
          password_info: ["Pas d'inquiétude ! Nous vous enverrons un lien de récupération.", 'Un nouveau mot de passe vous a été envoyé.'],
          inputs: ['Nom d\'utilisateur', 'Mot de passe', 'E-mail'],
          remember_action: ['Se souvenir de moi.', 'Pas vous ?', 'Mot de passe oublié ?', 'Retour à la connexion'],
          button: ['Connexion avec SSO', 'Se connecter', 'Réinitialiser le mot de passe'],
          info_modal: 'Verifiez votre e-mail pour changer votre mot de passe'
        }
      },
      delegates_modal: {
        header: "Sélectionnez le profil du délégué",
        sub_header: "Sélectionnez la personne pour laquelle vous souhaitez effectuer une réservation",
        button_select: "Sélectionner"
      },
      main_navigation: {
        pageName: 'side nav, main tool bar component',
        nav_tabs_names: {user: 'Utilisateur', admin: 'Administrator'},
        nav_dropdown_corg: {
          about: 'À propos',
          history: "History",
          feedback: 'Donner un retour',
          logout: 'Se déconnecter',
          user_settings: "Paramètres utilisateur",
          delegate: "Changer de profil délégué",
          user: "Profil utilisateur",
          admin: "Profil administrateur"
        },
        admin_nav_items: {
          dashboard: 'Dashboard',
          reservation_management: 'Reservation management',
          newsfeed: 'Newsfeed',
          user_management: 'User management',
          site_configuration: 'Site configuration',
          kiosk_configuration: 'Kiosk configuration',
          issue_management: 'Issue management',
          insights: 'Insights'
        }
      },
    },
    external: {
      close_issue_by_hash: 'Merci de fermer ce problème!',
      hash_reservation: 'Réservation pas disponible',
      google: 'Chargement...',
      outlook_reservation: {
        popup: 'Supprimer cette réservation ?',
        login: "Se connecter",
        no_space_info: ['Pas d\'espace réservé,', ' contactez l\'organisateur de la réunion'],
        site_headr: 'Endroit',
        date_time: 'L\'heure est affichée pour le fuseau horaire local',
        space: {
          header: 'Espace',
          internal_external: ['interne', 'externe']
        },
        amenities_header: 'Équipements'
      },
      reset_password: {
        info_modal: 'Mot de passe changé.',
        button_update: 'Mettre à jour',
        placeholders: ['Nouveau mot de passe', 'Re-entrez le mot de passe'],
        errors: ["Le mot de passe ne répond pas aux exigences", 'Les mots de passes ne sont pas identiques'],
        rules: 'Le mot de passe doit contenir au moins: 8 charactères, 1 majuscule, 1 minuscule, 1 chiffre'
      }
    },
    kiosk: {},
    admin: {
      kiosk: {
        main_header: 'Set up a kiosk',
        card: {
          headers: {
            site: 'Select a site',
            wing: ['Select a wing ', '(optional)']
          },
          placeholders: {
            site: 'Please search or select a site',
            wing: 'Please search or select a wing'
          },
          warning: 'Not running in kiosk, PWA or full-screen mode.',
          button: 'Launch kiosk mode',
        },
        info: {
          kiosk: 'For Kiosk - please logout, close browser and than run a following command: ',
          pwa: 'For PWA or full-screen browser - use Google chrome browser and',
          button: 'Click here'
        }
      },
      dashboard: {
        main: {
          refresh: "Refresh",
          site_card: {
            header: " Dashboard",
            action: "Help center",
            sub_header: "Select a site to get an overview"
          },
          small_card: {
            header: " Meeting rooms",
            total: "TOTAL",
            assigned: "Assigned"
          },
          utilization_cards: {
            header: " Max utilization rate ",
            special_header: "Max utilization rate special types",
            tooltip: "The max utilization rate is the max ratio during that day of requested over available resources of the selected type",
            table: {
              headers: [
                { name: "All types", tag_name: "all_types" },
                { name: "Desks", tag_name: "desks" },
                { name: "Meeting rooms", tag_name: "rooms" },
                { name: "Meeting rooms - Large ", tag_name: "big_rooms" },
                { name: "Meeting rooms - External", tag_name: "external" },
                { name: "Released %", tag_name: "releases" },
              ],
              hover: ["Requested ", "Available "]
            },
            info: "Click on a number in the table to see the chart",
            zoom: ['zoom out', 'zoom in']
          },
          to_do_card: {
            header: " To-do’s",
            link: "Go to to-do list",
            table: [
              { name: "To be confirmed", tag_name: "to_be_confirmed" },
              { name: "To be assigned", tag_name: "to_be_assigned" },
              { name: "Untreated request", tag_name: "with_message" },
              { name: "Total", tag_name: "total" }
            ]
          },
          not_enough_card: {
            header: "Not enough resources",
            info_default: "Click on a number in the table to see the solutions.",
            info_selected: "Let’s see how we can solve this.",
            button_reservation: "Remind people about their reservations",
            button_release: "Request more releases"
          }
        },
        releases_table: {
          info: "You can ask people who have not released their resource for this day to release it.",
          headers: {
            user: { popup: 'owners', name: "Assigned to" },
            level: { popup: 'Level of user', name: "Level assigned user" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            asset_level: { popup: '', name: "Asset level" },
            seat: { popup: '', name: "Seat capacity" },
            amenities: { popup: '', name: "Resource’s amenities" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: "Send message to all selected releases?",
            select_owner_message: "At least one user must be selected.",
            error_message: "Error sending",
          }
        },
        reservations_table: {
          info: "You can remind people who have a reservation for this day to cancel their reservation if no longer needed.",
          headers: {
            user: { popup: 'Owners', name: "User" },
            level: { popup: 'Level', name: "Level of user" },
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            rc: { popup: 'Requested seats', name: "Requested seats" },
            ie: { popup: 'internal external', name: "internal/ external" },
            amenities: { popup: '', name: "Requested amenities" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            status: { popup: 'Statuses', name: "Status" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: "Send message to all selected reservations?",
            select_owner_message: "At least one user must be selected.",
            error_message: "Error sending",
          }
        }
      },
      newsfeed: {
        navigation: ['Newsfeed', 'Emergency message'],
        list: {
          main_header: 'Newsfeed',
          site_header: 'Select a site to check its newsfeed.',
          i_tooltipe: 'The newsfeed of a site is shown on the homepage for all sites selected by the user',
          card: {
            header: 'Settings',
            toggle: 'Show newsfeed of site',
            i_tooltipe: 'You can choose to show the newsfeed of another site',
            select: {
              toggle: 'Click here to select the site',
              placeholder: 'Select site'
            },
            auto_remove: {
              text: ['Automatically delete news items that are older than ', ' days. '],
              i_tooltipe: 'Choose how long news items are shown on the newsfeed before they get deleted. Events are removed from the newsfeed once ended.'
            }
          },
          add_item: ' Add new item',
          popups: {
            info: 'Please select a location!',
            confirm: 'Delete all existing newsitems and events of ',
            button: 'OK'
          }
        },
        form: {
          main_header: 'Emergency message ',
          site_header: 'Select a site for which you want to write an emergency message.',
          i_tooltipe: 'An emergency message, once turned on, is visible for all users of that site on the Tribeloo homepage and on the devices of that site',
          message: "Haven't message",
          placeholder: 'Emergency message line ',
          save: 'Save',
          emergency: ['Turn on emergency message', 'Turn off emergency message']
        }
      },
      site_config: {
        navigation: {
          site: "Site configuration",
          rules: "Site rules",
          table: "Resource configuration table",
          types: "Resource types",
          wayfinding: "Wayfinding"
        },
        edit_site: {
          header: "Editing site ",
          video_header: "How to add, edit and delete a site",
          buttons: {
            add: "Add new site",
            delete: "Delete site"
          },
          toggle: "Site is active & visible for users",
          toggle_warning: ["Site is currently ", "inactive & invisible"," for users"],
          base: {
            header: "Basic details",
            video_header: "How to set up a site: basic details",
            placeholder: {
              name: "site name",
              code: "plus code",
              address: "address of this site"
            },
            label: {
              code: "Define coordinates of site (plus code) ",
              address: "Address of site "
            },
            tooltip: {
              code: "Plus code can be found on Google maps and is used to show a map of the location on the wayfinding link",
              address: "Address is shown on the wayfinding link"
            },
            position: {
              header: "Position of wing",
              info: "Select a wing, by clicking on its title, to change its position.",
            },
          },
          amenities: {
            header: "Amenities",
            video_header: "How to set up a site: amenities",
            info: "Define amenities ",
            tooltip: "Amenities are additional features, like a beamer or a flipchart, which can be selected when looking for a resource. Select amenities in the list below, to activate them on the current site, so they can be assigned to a specific resource from this site.",
            counter: [" in total - ", " active"],
            placeholder: "new amenity"
          },
          manager: {
            header: "Site manager settings",
            video_header: "How to set up a site: site manager settings",
            info: "Add e-mail address office manager ",
            tooltip: "Office manager will receive emails when new issues are reported ",
            placeholder: "e-mail",
            toggle: ["Send email to-do's every ", "hours"],
          },
          device: {
            header: "Device settings",
            video_header: "How to set up a site: device settings",
            label: {
              time: "Use 24h time for devices ",
              password: "Device password (0-9 A-E) "
            },
            tooltip: {
              time: "Select time format for meeting room and desk devices: AM/PM or 24h format",
              password: "Password used to access configuration screen on devices"
            },
            placeholder: "code 0-9 A-E"
          },
          insights: {
            header: "Insight settings",
            video_header: "How to set up a site: insight settings",
            labels: {
              days: "Define working days ",
              hours: "Define working hours ",
              room: ["Define a large meeting room", "people or more"],
            },
            tooltip: {
              days: "Working days are used to calculate average utilization rate in insights",
              hours: "Working hours are used to calculate average utilization rate in insights"
            },
            range: ["from", "to"]
          },
          popups: {
            online: {
              text: 'Bring this site online?',
              descriptions: ['This will make the site visible for regular users and ready for reservations.']
            },
            offline: {
              text: 'Bring this site offline?',
              descriptions: ['This will make the site invisible for regular users. Existing reservations will be kept.']
            },
            delete_site: {
              text: 'Delete this site?',
              big_descriptions: ['This will delete all wings, resources and reservations for this site. \nUsers with this home site will be moved to having no home site.']
            },
            cant_delete_site: 'Site can not be deleted.',
            amenity: {
              exists: 'Amenity already exists.',
              cant_updated: 'Amenity cannot be updated. Used by resources on other sites.',
              cant_delete: 'Amenity cannot be deleted, because it is assigned to some resources.',
              cant_disabled: 'Amenity cannot be disabled, because it is assigned to some resources. Remove it first from all resources.'
            }
          },
          edit_amenity_dialog: "Edit amenity",
          edit_wings_sorted: {
            add_button: " Add Wing",
            position: "Position: ",
          },
          wing_position: {
            header: "Selected wing: ",
            position: "Position ",
            move: "Move to position "
          }
        },
        rules: {
          header:"Site rules",
          site_card: {
            header: "Site",
            sub_header: "Select a site for which you want to define user privileges and rules."
          },
          default_reservation_time_card: {
            header: "Default reservation time",
            sub_header_desk: "Desk",
            sub_header_room: "Meeting room",
            label_start: "Start date",
            label_end: "Duration"
          },
          auto_assign_card: {
            header: "Auto-assign resources ",
            video_header: 'How to auto-assign resources to reservations',
            sub_header: "Reservations can get a resource auto-assigned if the user requests one, if none is available it will appear in the to-do list of the office manager",
            toggle: "Activate auto-assign functionality for ‘to assign’ reservations",
          },
          check_in_reminder_email_card: {
            header: "Check-in reminder email ",
            video_header: 'How to use the check-in reminder emails & auto-cancel reservations',
            send_toggle: "Send out reminder email 15 minutes before the start of a reservation that requires check-in",
            add_button_toggle: ["Add ", "‘Cancel reservation’", " button to reminder email"],
            add_desck_toggle: ["For ", "desk", " reservations: add ", "‘Check-in’", " button to reminder email"],
            add_room_toggle: ["For ", "meeting room", " reservations: add ", "‘Check-in’", " button to reminder email"],
            auto_cancel_header: "Auto-cancel reservations that require check-in",
            cancel_desk_toggle: ["Cancel ", "desk", " reservation after", "min, if a check-in is required."],
            cancel_room_toggle: ["Cancel ", "meeting room", " reservation after", "min, if a check-in is required."],
          },
          reminder_email_card: {
            header: ["Reminder email", "for desks"],
            video_header: 'How to use the general reminder emails',
            send_toggle: ["Send reminder email ", "hours before the start of a reservation"],
            subject: {
              label: "Subject:",
              placeholder: "Reminder about your upcoming reservation"
            },
            message: {
              label: ["Message: (Markdown supported, for example to add links. ", "Check guidelines"],
              placeholder: "Add message"
            },
            agree_toggle: ["Add ", "‘I agree’", " button: by agreeing the person acknowledges they have read the message"],
            cancel_toggle: ["Add ", "‘Cancel reservation’", " button"],
            header_special: "Reminder email for meeting rooms",
            send_toggle_special: ["Send reminder email ", "hours before the start of a reservation"]
          },
          time_limit_card: {
            header: "Time limit for future reservations",
            video_header: 'How to use the time limit for future reservations',
            sub_header: "Reservations can only be made a certain time ahead by non-admin users",
            desck_toggle: ["Desk", " reservations can be created in the next"],
            room_toggle: ["Meeting room", " reservations can be created in the next"],
            special_toggle: "Reservations can be created in the next",
            postfix: "working days"
          },
          utilization_limit_card: {
            header: "Utilization limit",
            sub_header: ["Utilization of active ", " can be limited.", "Example: a site has 40 active ", ". When utilization is limited to 50%, only 20 concurrent ", " reservations can be made."],
            sub_header_types: ['desks', 'resources', 'desk'],
            toggle: ["Utilization is limited to", "percent"]
          },
          multi_day_reservation_card: {
            header: "Multi-day reservation",
            sub_header: ["Define if an employee is allowed to create one reservation for several consecutive days at once.", "When allowed, an employee will only receive the reminder emails once and only needs to check-in once at the start of his multi-day reservation, if this is required."],
            toggle: "Multi-day reservations are allowed"
          },
          cleaning_time_card: {
            header: "Cleaning",
            sub_header: "Cleaning time will be foreseen after the end of every reservation. During this period the resource cannot be booked.",
            desk_toggle: ["Time available to clean a ", "desk", "min"],
            room_toggle: ["Time available to clean a ", "meeting room", "min"]
          },
          user_message_card: {
            header: "User message when creating reservation ",
            toggle: 'Show this message when a user creates a reservation for a resource on this site',
            label: 'Message:',
            placeholder: 'Type your message here',
          },
          user_privileges_card: {
            header: "User privileges",
            video_header: 'How to set up user privileges',
            sub_header: "Define which users have access to which resources and have to get confirmation for a reservation",
            levels: {
              header: "1. Define the names of the different user levels and resource levels:",
              toggle: "User level names are the same across all sites",
              description_simple: {start: "User & resource level names", center: " can be set by site admin and are ", end: "specific for the site."},
              description_global: [
                {start: "User level names", center: " can only be set by global admin and are the ", end: "same for all sites."},
                {start: "Resource level names", center: " can be set by site admin and are ", end: "specific for the site."},
              ],
              user_label: "User level ",
              resource_label: "Resource level ",
              placeholder: "Text"
            },
            privileges: {
              header: "2. Define the privileges per user and resource level:",
              cols: "Resource level",
              rows: "User level"
            },
            rules: [
              {id: 0, line1: "Resource not available", line2: "(can be assigned by admin)", class: "red"},
              {id: 2, line1: "Resource only available ", line2: "after confirmation", class: "orange"},
              {id: 1, line1: "Resource available for", line2: "user level", class: "green"}
            ]
          },
        },
        edit_wing: {
          header: "Editing wing ",
          sub_header: "wing details",
          video_header: "How to add, edit and delete a wing",
          placeholder: "wing name",
          button: {
            back: "Back to site edit",
            add: "Add new wing",
            delete: "Delete wing"
          },
          popup: {
            text: 'Delete this wing?',
            big_descriptions:['This will delete all resources of this wing and could affect current reservations. \nCheck your to-do list if proceeding.']
          },
          assets_list: {
            header: "Resources",
            add_button: " Add resource",
            counter: " in total",
            popup: {
              text: 'Delete this resource?',
              big_descriptions:['This could affect current reservations. \nCheck your to-do list if proceeding.']
            }
          }
        },
        edit_asset: {
          buttons:{
            back: "Back to wing edit",
            add: "Add resource",
            clone: "Duplicate resource",
            delete: "Delete resource"
          },
          base: {
            header: "Basic details ",
            video_header: "How to add, edit and delete a resource & define the basic details",
            internal_external: [
              {name:"Internal only", value: true},
              {name:"External allowed", value: false}
            ],
            type_tooltipe: 'INTERNAL ROOM: used for a meeting with colleagues from within the company.\n EXTERNAL ROOM: used when also people of outside the company are invited (e.g. client).\n ZONE: contains multiple single desks that can be booked, without selecting a particular one.',
            user_tooltipe: 'Assigning a resource to a specific person, means that this person has a fixed desk and they alone can use it. They can still choose to release it for others to use, when they aren’t around.',
            zoning_tooltipe: 'Semicolon separated list of zoning names, useful for HVAC/BM integration.\n E.g.:\n lighting_7;heating_3',
            amenity_type: "other asset type",
            placeholders: {
              name: "asset name",
              user: "Select user",
              neighbourhood: "Select neighbourhood",
              amenities: "Select amenities"
            },
            labels: {
              status: "Resource status ",
              neighbourhood: "Neighbourhood",
              user: "Assigned to ",
              level: "Resource level ",
              resource_zoning: "Resource zoning ",
              capacity: ['Seat capacity', 'Capacity'],
              type: "Resource type ",
              check_in: "Check-in required",
              amenities: "Amenities available ",
              image: "Add image (< 4MB)"
            }
          },
          size: {
            header: "Size, Shape & position ",
            video_header: "How to set up a resource: size, shape & position",
            size: {
              header: "Size & shape",
              sub_header: "Choose a shape",
              width: "Width",
              height: "Height",
              round: "Round corners"
            },
            position: {
              header: "Map position",
              horizontal: "Horizontal",
              vertical: "Vertical"
            }
          },
          calendar: {
            header: "Calendar & approval settings",
            video_header: "How to set up a resource: calendar & approval settings",
            resource: {
              label: "Resource email ",
              placeholder: "Resource email",
              tooltip:"Fill in an email to create a calendar for this resource. Tribeloo will keep the calendar up to date with reservations for this resource."
            },
            approvers: {
              label: "Approvers email ",
              placeholder: "Approvers email",
              tooltip:"Add the email address of the person who will confirm reservations for this resource. Multipe email addresses can be added separated by a semicolon ; and without any spaces before or after the semicolon"
            },
          },
          device: {
            header: "Device configuration",
            video_header: "How to set up a resource: device configuration",
            code: {
              label: "Device code ",
              placeholder: "Code"
            },
            id: {
              label: "Device ID ",
              placeholder: "ID"
            }
          },
          qr_nfc: {
            header: "QR & NFC configuration",
            video_header: "How to set up a resource: QR & NFC configuration",
            qr: {
              header: "Generate QR code",
              info: "Click on the button to generate a QR code of this resource.",
              button: "Generate QR"
            },
            nfc: "NFC configuration"
          },
          popups: {
            nfc_copy: "NFC url copied.",
            set_capacity: {
              text: 'Reduce seat capacity?',
              big_descriptions: ['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            select_owner: {
              text: 'Assign user to resource?',
              big_descriptions: ['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            internal_change: {
              text: "Change resource type to ‘Internal only’?",
              big_descriptions:["This could affect current reservations. \nCheck your to-do list if proceeding."]
            },
            remove: {
              text: 'Delete this resource?',
              big_descriptions:['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            check_card_id: {
              header: "",
              cases: [
                '1. Click "Start test" below',
                '2. On selected device, tap "Book now" or "Book later"',
                '3. Scan your card on the device',
                '4. The RFID code of the scanned card appears below',
                '5. To test another card, go to step 2'
              ],
              start: "Start test",
              stop: "Stop"
            }
          },
          assets_dragging_view: [" - click for edit", "drag & drop", "click to select"]
        },
        edit_info: {
          create: "Created on: ",
          update: "Last updated on: ",
          resource: "Resources: ",
        },
        type: {
          header: "Resource types",
          sub_header_descriprion: {
            first_line: "Standard sites have the type 'room or desk' automatically assigned.",
            second_line: ["If you want to create sites that contain another type of resource, define them here.", "These other resource types will also become visible on the Tribeloo homepage and in the tab 'More' in Outlook."]
          },
          video_header: 'How to define different resource types to book',
          card: {
            list: {
              header: "Existing resource types",
              button: "Add new resource type"
            },
            form: {
              header: ["Create resource type", "Edit resource type"],
              type: {
                label: "Resource type",
                placeholder: "Name of resource type"
              },
              icon: {
                label: "Icon",
                placeholder: "fa fa-archive",
                button: "Check icon list"
              },
              preview: {
                label: "Preview",
                text: ["Book a ", "Book a desk", "Book a meeting room"]
              },
              booking: {
                label: "Double booking by user",
                sub_label: "(desks only)"
              },
              button: {
                create: " Create type",
                delete: " Delete type",
                save: " Save changes",
              }
            }
          },
          popups: {
            unique_name: "You already have a type with the same name",
            create: 'Create type?',
            save: 'Save changes?',
            add: "New resource type is added",
            saved: "Changes saved",
            delete: 'Delete this resource type?',
            cant_delete: "This resource can't be deleted, because there are still sites of this type"
          },
        },
        assets_table: {
          buttons: {
            batch: "Batch edit",
            qr: ["QR PDF selected", "QR PDF all"]
          },
          headers: {
            number: {name: "Number", popup: ""},
            resource: {name: "Resource", popup: ""},
            wing: {name: "Wing", popup: "Wings"},
            user: {name: "Assigned to", popup: ""},
            level: {name: "Resource level", popup: "Resource level"},
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            capacity: {name: "Seat capacity", popup: "Seat capacity"},
            type: {name: "Resource type", popup: "Resource type"},
            check_in: {name: "Check-in required", popup: "Check-in required"},
            amenities: {name: "Amenities", popup: ""},
            device_id: {name: "Device ID", popup: ""},
            email: {name: "Resource email", popup: ""},
            status: {name: "Status", popup: "Status"},
            from: {name: "From", popup: ""},
            to: {name: "To", popup: ""}
          },
          popups: {
            batch: {
              header: "Edit resource details",
              sub_header: "Update",
              level: "Resource level",
              capacity: "Seat capacity",
              type: {
                label: "Resource type",
                values: [
                  { name: 'Internal only', value: true },
                  { name: 'External allowed', value: false }
                ],
              },
              check_in: {
                label: "Check-in required",
                values: [
                  { name: 'yes', value: true },
                  { name: 'no', value: false }
                ],
              },
              add_amenities: {
                label: "Add amenities",
                placeholder: "Select amenities"
              },
              remove_amenities: {
                label: "Remove amenities",
                placeholder: "Select amenities"
              },
              approvers: {
                label: "Set approvers",
                placeholder: "approvers email"
              },
              neighbourhood: {
                label: "Neighbourhoods",
                placeholder: "Select neighbourhood"
              },
              status: "Resource status"
            },
            selected: "At least one resource must be selected",
            selete_selected: ["Delete the ", " selected resources?"],
            this_delete: "Delete this resource?"
          }
        },
        wayfinding_link: {
          header: "Wayfinding link",
          privacy_card: {
            header: "Privacy",
            toggle: "Logging in is required to see information about assigned resource and floormap"
          },
          layout_card: {
            header: "Layout",
            sub_header_descriprion: {
              first_line: ["When using the Outlook add-in and Chrome extension, a wayfinding link is added to the event itself. ", "This shows invitees where they need to be and to easily find their way."],
              second_line: "You can add a company logo OR company name, and define the colors for the banner, the company name and the titles."
            },
            logo: {
              label: "Company logo",
              sub_label: "(PNG, less than 4MB)"
            },
            image_info: ["max width 210px and ", "max height 50px"],
            company_name: {
              label: "Company name",
              placeholder: "Max 20 characters"
            },
            name_color_label: "Color company name",
            baner_color_label: "Color banner",
            titels_color_label: "Color titles"
          }
        }
      },
      todo: {
        navigation: {
          list: 'Reservation to-do list',
          requests: 'Requests',
          reservation_table: 'Reservation table',
          release_table: 'Release table'
        },
        main: {
          all_button_tooltip: "Assign resources automatically to current to-do's",
          dialog_asset: "Asset must be selected first",
          details_resources_button: "View resource reservations",
          legend: {
            header: "View legend",
            items: [
              {name: "Available", type_class: "green"},
              {name: "Available, but to be confirmed", type_class: "orange"},
              {name: "Available, but rules constraint", type_class: "blue"},
              {name: "Not available", type_class: "red"}
            ]
          }
        },
        list: {
          update: "Last updated: ",
          toggle: "Show only untreated request",
          toggle_visitor: "Show only visitor reservations",
          default_name_subject: "New reservation ",
          user_prefix: "by ",
          visitor_prefix: "for ",
          tooltip: "Reservation within selected time range",
          default_name_asset: "Reservation pending"
        },
        details: {
          site_change: {
            header: "Change site",
            tooltip: "Remove assigned space first"
          },
          request: {
            header: "Request",
            treated: ["Request treated", "Request untreated"],
            buttons: {
              add: "Add note",
              contact: "Contact user"
            }
          },
          details: {
            header: ["Show details", "Hide details"],
            delete_button: "Cancel reservation",
            default_name_subject: "New reservation ",
            user_prefix: "By ",
            visitor_prefix: "For ",
            seats: {
              counter: ["Requested: seats ","Requested: "],
              type: ['internal meeting', 'external meeting'],
              amenities: "Requested amenities: "
            }
          },
          popups: {
            delete: 'Delete this reservation?',
            assigned: "Space is assigned to reservation and confirmed",
            selected: "Asset must been selected",
            available: "Asset is not available",
            message_error: "error sending"
          }
        },
        work_stack: "Click here to select the stack item",
        message_popup: {
          header: "Add note",
          sub_header: "Request",
          treated: ["Request treated", "Request untreated"]
        },
        release_table: {
          info: 'Changing time range removes all filters',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "Assigned to" },
            level: { popup: 'Level assigned user', name: "Level assigned user" },
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            amenities: { popup: '', name: "Resource’s amenities" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            subject: { popup: '', name: "Subject" },
            email: { popup: '', name: "Email" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: ["Send message to ", " shown releases for "],
            remove_release: "Delete this release?",
            remove_releases: ["Delete ", " shown releases for "],
            error_message: "Error sending",
          }
        },
        reservaton_table: {
          info: 'Changing time range removes all filters',
          change_site_buton: ' Change site for all',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'User', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            level: { popup: 'Level of user', name: "Level of user" },
            group: { popup: 'User group in ', name: "User group in " },
            email: { popup: '', name: "User email" },
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            seats: { popup: 'Requested seats', name: "Requested seats" },
            internal_external: { popup: 'Resource type', name: "Resource type" },
            amenities: { popup: '', name: "Requested amenities" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resources', name: "Resource" },
            request: { popup: 'Request', name: "Request" },
            status: { popup: 'Statuses', name: "Status" },
            acknowledged: { popup: 'Acknowledged', name: "Acknowledged" }
          },
          tooltip: {
            lock: "lock all",
            unlock: "unlock all",
            acknowledged: "From reminder email: if the person clicked on the 'I agree' button and therefore acknowledges they have read the message",
            confirm: "confirm all",
            delete: "delete all",
            send_mail: "send message to all"
          },
          dialogues: {
            chang_site: 'Change site',
            confirm: ['Confirm ', ' shown reservations from '],
            send_all_message: ["Send message to ", " shown reservations for "],
            remove_reservation: "Delete this reservations?",
            remove_reservations: ["Delete ", " shown reservations for "],
            error_message: "Error sending",
            lock: ["Lock ", " shown reservations from "],
            unlock: ["Are you sure you want to unlock ", " shown reservations from "]
          }
        },
        requests_table: {
          info: 'Changing time range removes all filters',
          add_comment_buton: 'Add comment',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "Owner" },
            visitor: { popup: '', name: "Visitor" },
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            request: { popup: 'Request', name: "Request" },
            comment: { popup: '', name: "Comment" },
            seats: { popup: '', name: "Request treated" },
            email: { popup: '', name: "Owner email" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: ["Send message to ", " shown reservations for "],
            error_message: "Error sending"
          }
        }
      },
      user_management: {
        navigation: {
          global_admin: "Global admins",
          admin: "Site admins",
          privileges: "User privileges",
          user: "User management",
          users_table: "User table",
          groups: "User groups",
          sso: "SSO",
          provisioning: "User provisioning"
        },
        global_admin: {
          header: "Global admin management",
          admins_card: {
            header: "Define the global admins",
            sub_header: "These users have access to all sites and features",
            video_header: 'How to define the site & global administrators',
            search: "Search",
            global_admin_list_header: "Site admins that are global admin",
            admin_list_header: "Site admins",
            admin_list_header_tooltip: 'You need to be site admin first, before you can be global admin'
          },
        },
        privileges: {
          header: "User rules",
          site_card: {
            header: "Site",
            sub_header: "Select a site to define it's user rules."
          },
        },
        admin: {
          header: "Site admin management",
          site_card: {
            header: "Site",
            sub_header: 'Select a site for which you want to add or remove administrators.'
          },
          admins_card: {
            header: "Add administrators for this site",
            video_header: 'How to define the site & global administrators',
            search: "Search",
            admin_list_header: "Users that are admin for this site",
            user_list_header: "Users",
            popup: {
              title: "This user has global admin rights and therefore access to all sites.",
              descriptions: ["If you want to give this user only access to certain sites, remove them from the global admin list first."]
            }
          },
        },
        user: {
          rfid_modal: {
            header: "Assign RFID to user",
            step1: {
              header: ["Step 1", " - Enter device ID & click ‘Start’"],
              placeholder: "Device ID",
              button: "Start ",
              info: ["No resource found ", "Check or re-enter ID"]
            },
            step2: ["Step 2", " - On connected device, tap ‘Book now’ or ‘Book later’"],
            step3: {
              header: ["Step 3", " - Scan card on the device, the RFID code will appear below"],
              placeholder: "Card RFID"
            },
            step4: {
              header: ["Step 4", " - Click ‘Assign RFID’, to assign it to the user"],
              button: "Assign RFID"
            }
          },
          header: "User management",
          free_version_sub_header: ["Currently using the ", "FREE VERSION with a limit of ", " users.", " Contact ", " to upgrade."],
          site_card: {
            header: "Location",
            sub_header: "Select a location to add or remove users for this site."
          },
          create_edit_card: {
            list: {
              header: "Existing users",
              button: "Add new user",
              placeholder: "search"
            },
            form: {
              header: ["Edit user", "Create user"],
              video_header: "How to create and edit a user",
              sub_header: "Select a user in the list to edit it's details.",
              name: {
                label: "Username",
                placeholder: "Name"
              },
              email: {
                label: "Email",
                placeholder: "Email"
              },
              level: {
                label: "User level ",
                placeholder: ""
              },
              group: {
                label: "User group",
                item: "Default"
              },
              site: {
                label: "Home site",
                item: "None"
              },
              resource: {
                label: "Resource",
                placeholder: ""
              },
              rfid: {
                label: "RFID",
                placeholder: "Enter RFID or click on icon on the right"
              },
              image: {
                label: "User image",
                sub_label: ["(square size, ", "less than 4MB)"]
              },
              button: {
                create: " Create user",
                reset_pin: "Reset PIN code",
                reset_password: "Reset user password",
                upload: "Upload image",
                delete: " Delete user",
                save: " Save changes"
              }
            },
            import: {
              header: "Batch upload users",
              cases: [
                "1. Download template",
                "2. Add users for this location to the template",
                "3. Upload the template file again to Tribeloo"
              ],
              button: {
                upload: "Upload file",
                download: "Download template"
              }
            },
            errors: {
              invalid_email: "Required field <email> is invalid.",
              missing_email: "Required field <email> is missing from request.",
              missing_name: "Required field <name> is missing from request.",
              missing_level: "Required field <level> is missing from request.",
            },
            popups: {
              save: 'Save changes?',
              create: 'Create user?',
              created: 'User created.',
              reset_pin: 'The reset was succesful! The user will receive an email with a new PIN code.',
              updated: 'User details have been updated.',
              delete: ' Delete this user?',
              removed: 'User removed.',
              reset_password: 'The reset was succesful! The user will receive an email with a new password.',
              send_pin: 'Send login PIN code for devices ?'
            }
          },
          pin_card: {
            header: "PIN code ",
            video_header: "When and how to use a PIN code",
            toggle: "Send PIN code by email to login on the meeting room and desk devices",
            info: "All new users and existing users that don’t have PIN code yet, will receive one."
          },
          global_settings_card: {
            header: "Global settings",
            info: "These settings are applicable for all sites"
          },
          image_card: {
            header: "Synchronise user images ",
            video_header: "How to synchronise user images of Microsoft 365 with Tribeloo",
            toggle: "Sync user images with Microsoft Office 365",
            info: "User image is synchronised once a user logs into the Tribeloo Outlook add-in"
          },
          subject_card: {
            header: "Hide reservation subject ",
            video_header: "",
            toggle: "Hide reservation subject for colleagues",
            info: 'Reservation subject shown in "who`s around" will be hidden'
          }
        },
        users_table: {
          batch_edit: {
            header: "Edit user details",
            sub_header: "Update",
            items: {
              privileges: "User group",
              level: "User level",
              site: "Home site",
              group: "User group",
              rfid: "Clear RFID for selected users"
            }
          },
          table: {
            toggle: 'Also show users from other sites',
            headers: {
              number: { popup: '', name: "Number" },
              site: { popup: 'Home site', name: "Home site" },
              home_site_privilege: { popup: 'Home site group', name: "Home site group" },
              user: { popup: '', name: "Name of user" },
              user_privilege: { popup: 'User group in ', name: "User group in " },
              assigned_resource: { popup: 'Assigned resource in ', name: "Assigned resource in " },
              level: { popup: 'Level of user', name: "Level of user" },
              group: { popup: 'Group', name: "User group" },
              space: { popup: '', name: "Resource" },
              Owned_site: { popup: 'Owned_site', name: "Site of resource" },
              email: { popup: '', name: "User email" },
              rfid: { popup: 'RFID', name: "RFID" },
              override : {popup: 'Group assignment', name: "Group assignment"}
            },
            tooltip: {
              delete: "delete all selected users",
              send_mail: "send message to selected users"
            },
            dialogues: {
              error_message: "Error sending",
              email: 'Compose email for the selected users?',
              selected: "At least one user must be selected.",
              delete_all: ['Delete the ', ' selected users?'],
              delete: 'Delete this user?',
              rfid: "RFID cleared for selected users"
            }
          }
        },
        groups: {
          header: "Users groups",
          toggle_card: {
            header: "Enable user groups ",
            video_header: "How to set up user groups",
            toggle: "User groups are enabled",
            description: ["When ", "enabled", ", you can create user groups and define rules for visible sites or office days for each group.", "Assign a user to a group in user management or user table."]
          },
          creat_edit_card: {
            list: {
              header: "Existing users groups",
              button: "Add new user group",
              placeholder: "search",
              popups: {
                save: "Save changes?",
                create: "New user group is added.",
                update: "Changes saved.",
                delete: {
                  text: "Delete this user group?",
                  descriptions: ['Users from this group will be assigned to the default user group of their home site.']
                }
              }
            },
            form: {
              header: ["Create user group", "Edit user group"],
              select_button: "Select all",
              unselect_button: "Unselect all",
              create: " Create group",
              update: "Save changes",
              delete: " Delete group",
              name: {
                label: "Name ",
                placeholder: "Add name of user group"
              },
              rules: {
                toggle: ["‘Visible sites’ rule enabled", "‘Visible sites’ rule disabled"],
                tooltip: "When visible sites rule is enabled, a user has access to the active sites of his group, and is able to book a resource for these sites. When visible sites rule is disabled, this user group has access to all active sites.",
                placeholder: "Select visible sites",
              },
              days: {
                toggle: ["‘Office days’ rule enabled", "‘Office days’ rule disabled"],
                tooltip: "When office days rule is enabled, a user can make reservation only on the selected office days. When office days rule is disabled, this user group can make reservations on all days.",
                placeholder: "Select office days",
                week_days: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']
              }
            },
            sites_card: {
              header: "Default user group for each site",
              description_lines: [
                {start: "Users can be assigned to a specific group. In case a user doesn’t have a group assigned,", end: "the user will fall in the default group of his home site as defined below."},
                {start: "In case the user isn’t assigned to a specific group and no default group is defined for his", end: "home site, the user will only have access to his home site to book resources."}
              ],
              label: "No home site ",
              tooltipe: "This is the default group for users without a home site",
              placeholder: "group"
            }
          }
        },
        sso: {
          header: "Single Sign-On",
          description_card: {
            header: "What is it?",
            description: "Single Sign-On gives your users the ability to sign in to different applications using the same unique set of username and password. Users sign in once via the so-called Identity Provider (external to Tribeloo) and can then, from there access different apps (including Tribeloo) without logging in again. For SSO, Tribeloo supports the SAML 2.0 standard."
          },
          email_card: {
            header: "Enable welcome email",
            toggle: "Enable sending welcome email if SSO is enabled"
          },
          settings_card: {
            header: "Settings",
            items: {
              id: {
                name: "Company ID",
                tooltip: {
                  header: "",
                  descriptions: []
                }
              },
              issuer_url: {
                name: "Issuer URL",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: Identity Provider Issuer", "OneLogin: Issuer URL", "Azure: Azure AD Identifier", "Google: Entity ID"]
                }
              },
              login_url: {
                name: "SSO login URL",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: Identity Provider SSO URL", "OneLogin: SAML Endpoint", "Azure: Login URL", "Google: SSO URL"]
                }
              },
              certificate: {
                name: "Certificate",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: X.509 Certificate", "OneLogin: X.509 Certificate", "Azure: Certificate (Base64 or Raw)", "Google: Certificate 1"]
                }
              },
            },
            button: ["Disable SSO", "Enable SSO"]
          },
          popups: {
            saved: 'Configuration saved.',
            not_saved: 'Configuration not saved.',
            copied: 'Company ID copied.',
            change_sso: {
              text: ['Are you sure you want to disable Single Sign-On?', 'Are you sure you want to enable Single Sign-On?'],
              descriptions: ['All users will need to log in with a password, that they can request through ‘Forgot password?’ on the login page.', 'Users won’t be able to log in with their passwords and will need to log in with their SSO credentials.']
            }
          }
        },
        provisioning: {
          header: "User provisioning",
          description_card: {
            header: "What is it?",
            description: "User Provisioning refers to the automatic synchronization of users: people defined in your Active Directory (AD) will be automatically synchronized with the Users list in Tribeloo. E.g. new people in the AD will be added to the Users list in Tribeloo."
          },
          settings_card: {
            header: "Settings",
            items: [
              {name: "SCIM Base URL", key: "base_url", is_input: true},
              {name: "SCIM JSON Template", key: "json_template", is_input: false},
              {name: "Custom headers", key: "custom_headers", is_input: true},
              {name: "SCIM Bearer token", key: "bearer_token", is_input: false}
            ],
            button: ["Disable User provisioning", "Enable User provisioning"],
            popups: {
              copy: " copied",
              change_SCIM: {
                text: ['Are you sure you want to disable User provisioning?', 'Are you sure you want to enable User provisioning?'],
                description: ['All the current users will be kept, but won’t be updated by your external user provisioning system anymore.', 'All users will be managed externally.']
              }
            }
          }
        }
      },
      insights: {
        navigation: {
          heatmaps: "Heatmaps",
          utilization: "Utilization charts",
          history: "Reservation history",
          download: "Data download",
          statistics: "Statistics",
          tracker: "Time tracking",
          calc: "Calc resource"
        },
        calc: {
          type: "Calc helpe",
          subHeader: "This page help you with a data about your price and amount of resources from 1 month to 5 years. Also it help you: if you want to change a count of recources, or move to new place, add or remove new site, wing and etc.",
          chartHeader: "Terms & summ",
          chartDescription: "Use top form to know how much you are spending for your resources"
        },
        heatmaps: {
          type: "Heatmaps",
          subHeader: "Which days and hours are most busy?",
          allText: "Average utilization rate of selected type",
          daysText: "Daily utilization rate of selected type",
          options: {
            average: "Show average utilization rate",
            daily: "Show daily utilization rate"
          },
          tooltipe: 'The average utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period. The daily utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period. For example, daily utilization of 80% and average utilization of 40% means that: 8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours. '
        },
        utilization_charts: {
          type: "Utilization charts",
          subHeader: "Which days and hours are most busy?",
          allText: "Average utilization rate of selected type",
          daysText: "Daily utilization rate of selected type",
          table_header: "Which days are most busy? Select a day to see the utilization by hour below for that day.",
          chart_header: "Which hours are most busy"
        },
        reservation_history: {
          info: 'Changing time range removes all filters',
          overlap: 'Find overlapping reservations with ',
          couter: " more...",
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            email: { popup: '', name: "User email" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resources', name: "Resource" },
            no_shows: { popup: 'No-shows', name: "No-shows" },
            subject: { popup: '', name: "Subject" },
            seats: { popup: 'Requested seats', name: "Requested seats" },
            internal_external: { popup: 'internal/external', name: "internal/ external" },
            status: { popup: 'Statuses', name: "Status" },
            messages: { popup: 'Messages', name: "Messages" },
            acknowledged: { popup: 'Acknowledged', name: "Acknowledged" }
          },
          tooltip: {
            acknowledged: "From reminder email: if the person clicked on the 'I agree' button and therefore acknowledges they have read the message",
            send_mail: "send message to all"
          },
          dialogues: {
            error_message: "Error sending"
          }
        },
        time_tracking: {
          check: "events",
          info: 'Changing time range removes all filters',
          info_date: 'Find overlapping reservations with ',
          headers: {
            user: { popup: 'User', name: "User" },
            email: { popup: 'User email', name: "Email" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            zone: { popup: '', name: "Time zone" },
            period: { popup: '', name: "Time period" },
          },
          dialogues: {
            error_message: "Error sending"
          }
        },
        data_download: {
          header: "Data download",
          popup: {
            big_titel: 'Update data retention?',
            big_descriptions: ['Older reservation data will be deleted and no longer accessible'
                              ]},
          retention_card: {
            header: "Data retention",
            toggle: ["Reservations & releases data is automatically deleted after", "days."],
            info: "There is a minimum of 7 days and a maximum of 400 days."
          },
          automade: {
            header: "Daily automated report via email",
            sub_header: "Receive data of the reservations & releases of the previous day",
            check: "Send daily email to",
            placeholder: "Email address",
            error: "Please enter a valid email address"
          },
          download: {
            header: "Download data for a specific time range",
            info: "Data of reservations & releases",
            button: {
              all: " Download all",
              one: " Download data range"
            }
          }
        },
        statistics: {
          header: "Statistics",
          sub_header: "The table shows you a current view of all the sites and their amount of resources and users.",
          card: {
            header: "Resources and users",
            button: "Download",
            table_headers: {
              type: "Resource type",
              resources: "Resources",
              users: "Users"
            }
          }
        },
        reusable_components: {
          main_header: {
            header: "Insights",
            day: " Move timeframe per day ",
            week: " Move timeframe per week "
          },
          scale_utilization_rate: "Scale utilization rate",
          card_table: {
            table_headers: {
              special: "utilization rate",
              average: "Average",
              daily: "Daily"
            },
            description_hightlight: [
              {start:"The ", hightlight: "average", end: " utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period."},
              {start:"The ", hightlight: "daily", end: " utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period."}
            ],
            description_example: ["  For example, daily utilization of 80% and average utilization of 40% means that: ", "8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours."]
          },
          maps_cards: {
            button: {
              select: "Select",
              show_all: "Show all"
            },
            internal_external: [', Internal only', ', External allowed'],
            owner: "Owner: ",
            usage: "Usage",
            average: "average: ",
            daily: "daily: ",
            days: "days active: ",
          }
        }
      },
      space_issues: {
        navigation: ['Gérer les problèmes','Nettoyage', 'Table de problèmes'],
        list: {
          main_header: 'Sélectionnez un site',
          main_sub_header: 'Cherchez une ressource',
          placeholder: "Cherchez ou sélectionner site",
          placeholder_asset: "Please search or select a resource",
          search: 'Voir tous les problèmes d\'un site',
          toggle: ['Cliquez pour montrer les problèmes résolus','Cliquez pour montrer les problèmes non résolus'],
          couter: [' problèmes non résolus', ' problèmes résolus'],
          img_link: 'Voir la photo',
          popup_text: 'E-mail envoyé.',
        },
        table_cleaning: {
          main_header_warning: 'Un changement d\'heure retire tous les filtres',
          table_headers: {
            id:'ID',
            data_time: 'Date & heure',
            wing: 'Etage',
            resource: 'Resource',
            cleaned_at: 'Nettoyé à',
            cleaned_by: 'Nettoyé par'
          }
        }
      }
    },
    user: {
      external_events_list: {
        headers: ['Evénements Google','Evénements Outlook'],
        select: {
          header: 'Organisé par',
          placeholder: 'Sélectionner collègue'
        },
        info: ['Pas de réservations de votre calendrier disponibles','Montrer seulement des événements avec des réservations Tribeloo','Evénement sélectionné']
      },
      find_colleague: {
        email_select: {
          more: "plus..."
        },
        user_select: {
          header: 'Trouver un collègue',
          placeholder: 'Sélectionner collègue',
          info: ['Demandez l\'accès à votre collègue dans ','>Ma Tribu',' pour voir leurs réservations']
        },
        list: {
          count: ' (non-privée)',
          info: ['Sélectionnez un collègue pour voir leur réservations.', 'Cliquez une réservation pour voir sur la carte.']
        },
        details: {
          return_button: 'Retour à la liste de réservations',
          info: 'Sélectionnez une resource disponible sur la carte pour créer une réservation pour vous',
          modals: {
            delete_reservation: 'Réservation annulée',
            create_reservation: 'Créer une réservation?'
          }
        }
      },
      find_room_report: {
        headers: {
          main: 'Rapporter un problème ',
          site: 'Sélectionnez un site',
          resource: 'Trouver une resource'
        },
        placeholders: {
          site: 'Sélectionnez un site',
          resource: 'Veuillez chercher ou sélectionnez une resource',
          tooltip: 'Quand vous rapportez un probleme, il sera envoyé au gestionnaire de site pour le résoudre. Après résolution, il sera supprimé de cette liste.',
          description: ['Sélectionnez une resource pour rapporter un problème', 'Rapporter un problème'],
        },
        loading: "Chargement...",
        image_description: "Image (< 4Mo)",
        button: {
          photo: 'Ajouter une photo',
          send: 'Envoyer le problème'
        },
        image_info: 'Image (< 4Mo)',
        couter: ' problèmes non traités',
      },
      releases_list: {
        add_button: 'Ajouter une absence',
        user_select: 'Absences pour',
        colleagues_info: "Vous n\'avez pas de collègues avec des absences visibles",
        counter: 'Pas d\'absences',
        right_panel_info: ['Sélectionnez un collègue de la liste pour voir ses absences.', "Vous n\'avez pas de collègues avec des absences visibles"]
      },
      release: {
        pageName: 'release',
        header: 'Détails de l\'absence',
        placeholder: 'Sujet',
        buttons: {
          create: ['Libérer votre ', 'Confirmer l\'absence'],
          edit: ['Annuler cette absence?', 'Annuler l\'absence']
        },
        description: [' Il n\'y a ', 'pas ', 'de(s) réservation(s) active(s) pour ce(tte) ', ' pendant cette période.'],
        modals: {
          user: 'Changer l\'utilisateur? L\'absence actuelle sera annulée.',
          not_available: "Absence non disponible ",
          delete: 'Annuler cette absence?'
        }
      },
      reservations_list: {
        add_button: 'Ajouter une réservation',
        user_select: 'Réservations pour',
        tabs: [
          {name:"Personelles", state: false},
          {name:"Pour visiteur", state: true}
        ],
        colleagues_info: "Vous n'avez pas de collègues avec des réservations visibles",
        counter: 'Pas de réservations',
        right_panel_info: ['Sélectionnez un collègue de la liste pour voir ses réservations.', 'Mettez à jour une réservation ou créez-en une pour voir les resources disponibles']
      },
      reservation: {
        pageName: 'reservations',
        header_requests: ['Pas encore de réservation.', 'Remplissez les détails de la réservation et vérifiez la disponibilité.'],
        details: ' détails',
        end: 'Finir la réservation',
        amenities_item: 'autre demande',
        snack_bars: {
          create: 'Réservation créée',
          update: 'Réservation mise à jour'
        },
        visitor: {
          text: "Réservation pour visiteur"
        },
        placeholders: {
          visitor: "Addresse mail",
          amenities: 'Sélection d\'équipement',
          no_amenities: 'Pas d\'équipement',
          subject: 'Sujet',
          neighbourhood: "Sélection le quartier"
        },
        public: {
          text:'Réservation privée' ,
          tooltip: 'Votre réservation n\'est visible que pour les collègues que vous avez accepté dans vos paramètres. En désactivant cette option, votre réservation ne sera pas visible.'
        },
        right_panel_info: {
          no_checked_availability: ['Remplissez les détails et', 'vérifiez la disponibilité'],
          checked_availability: ['Les détails ont changé, ', 're-vérifiez la disponibilité']
        },
        modals: {
          amenity: {
            text: "Votre réservation actuelle n\'a pas toutes les aménités demandées!",
            descriptions: [
              "Mais pas de problème, vous pouvez continuer avec votre réservation actuelle.",
            ]
          },
          error_404: "Réservation pas disponible",
          requested_capacity: {
            text: "Votre place sélectionnée n\'a pas assez de sièges!",
            descriptions: [
              "Continuer, pour garder votre nombre de sièges et pour chercher une autre place.",
              "Ou annuler le changement de sièges et garder votre place sélectionnée.",
            ]
          },
          internal: {
            text: "Votre place sélectionnée est seulement pour des réunions internes!",
            descriptions: [
              "Continuer, pour garder le changement et pour chercher une autre place.",
              "Ou annuler le changement et garder votre place sélectionnée.",
            ]
          },
          time: {
            text: "Votre réservation n\'est pas disponible pour cette période!",
            descriptions: [
              "Continuer, pour garder la nouvelle période et votre réservation sera mise à jour.",
              "Ou annuler le changement d\'heure et garder votre réservation actuelle."
            ]
          },
          space: {
            text: "Votre place sélectionnée est seulement pour des réunions internes!",
            descriptions: [
              "Continuer, pour garder le changement et pour chercher une autre place.",
              "Ou annuler le changement et garder votre place sélectionnée.",
            ]
          },
          invalid_email: "Vous devez entrer un e-mail de visiteur valide ou désactiver le commutateur de visiteur pour créer une réservation.",
          delete: {
            simple: {text:'Annuler cette réservation?'},
            ongoing: {text:'Mettre fin à cette réservation?'},
            visitor_change: {
              text:{
                toggle: "Basculer entre la réservation personnelle et la réservation visiteur supprimera la réservation actuelle!",
                email: "La modification de l'adresse e-mail du visiteur supprimera votre réservation actuelle!",
              },
              descriptions: [
                "Continuez, pour conserver les modifications et votre réservation sera mise à jour.",
                "Ou annulez le changement et conservez votre réservation actuelle.",
              ]
            },
            user_change: {
              text:'Changer l\'utilisateur, votre réservation actuelle sera annulée!',
              descriptions: [
                "Continuer, pour garder le nouvel utilisateur et votre réservation sera mise à jour.",
                "Ou annuler le changement pour garder votre réservation actuelle."
              ]
            },
            site_change: {
              text:'Changement de site, votre réservation actuelle sera annulée!',
              descriptions: [
                "Continuer, pour garder le nouveau site et votre réservation sera mise à jour.",
                "Ou annuler le changement et garder votre réservation actuelle."
              ]
            }
          }
        }
      },
      home: {
        nwesfeed_list: 'Pas de nouvelles aujourd\'hui!',
        pageName: 'home',
        emergency: {
          header: 'MESSAGE D\'URGENCE',
          by: 'par'
        },
        reservation: {
          headers: {
            upcoming: 'Votre prochaine réservation',
            current: 'Votre réservation actuelle',
            no_upcoming: 'Pas de réservations futures'
          },
          descriptions: ['Nous sommes ici pour vous aider!', 'Faites votre choix avec les boutons ci-dessous.'],
          loading: 'Chargement ...'
        },
        newsfeed_header: 'Actualité'
      },
      settings: {
        pageName: 'settings',
        h1: 'Paramètres de l\'utilisateur',
        password: {
          errors: ["Au moins 1 chiffre, 1 symbole et 8 caractères de long", 'Les mots de passe ne sont pas les mêmes'],
          rules: 'Le mot de passe doit contenir au moins : 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre',
          rules_v2: 'Doit contenir au moins : 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre',
          placeholders: ['Entrez le nouveau mot de passe', 'Retapez le nouveau mot de passe'],
          info_ok: "Le mot de passe a été modifié",
          info_not_ok: "Le mot de passe n'a pas été modifié"
        },
        h3_sections: {
          password: 'Changer le mot de passe',
          image: 'Télécharger une image',
          site: 'Site préféré pour une nouvelle réservation',
          wing: 'Aile par défaut pour nouvelle réservation',
          time: ' Format d\'affichage de l\'heure',
          language: 'Langue',
          preferences: 'Préférences',
          preferences_v2: 'Préférences utilisateur',
          delegate: 'Déléguer',
          calendar: 'Invités calendrier',
          wants_asset_details_popup: 'Information sur la carte'
        },
        subsections: {
          app: "Version",
          sites: "Sites visibles",
          language: "Langue",
          time: "Format de l'heure",
          calendar: "Invitations au calendrier"
        },
        user_image_info: ['Contactez le responsable administratif ', 'du site pour changer votre image'],
        user_image: ['Image utilisateur', '(taille carrée, moins de 4 Mo)', 'Téléchargez une image de taille carrée inférieure à 4 Mo'],
        toggle: 'Utiliser format 24h',
        app_version: "Utiliser la nouvelle version (redémarrage requis)",
        calendar_toggle: 'Envoyer des invitations dans le calendrier pour des reservations faites dans l\'application web',
        wants_asset_details_popup_toggle: "Afficher les détails contextuels lors de la sélection d'une ressource sur la carte",
        no_delegates: 'Pas de délégués',
        delegates_action_list: {
          search: 'Chercher',
          edit: 'Collègues qui peuvent créer ou éditer mes réservations',
          add: 'Ajouter des collègues'
        },
        delegates_modal: "En cochant cette case, la table ci-dessous ne sera plus prise en compte et tout le monde pourra voir vos réunions publiques."
      },
      who_is_arround: {
        header: 'Qui est là',
        tooltip: 'Trouvez qui de votre tribu est au travail à une certaine heure. Si personne n\'est dans votre tribu, créez la dans ‘Ma Tribu’.',
        date_time: {
          main: 'Date & heure',
          helper: 'HEURE'
        },
        info: 'Sélectionnez une resource disponible sur la carte pour créer une réservation',
        users_list: {
          header: {
            hide: 'Cacher',
            show: 'Montrer',
            postfix: ' Liste de collègues'
          },
          placeholder: 'Nom de l\'utilisateur'
        },
        right_panel_info: 'Choisissez un lieu pour voir la disponibilité',
        modal: 'Créer réservation?',
      },
      my_tribe: {
        header: 'Ma Tribu',
        tabs: ['Suivants', 'Suivis'],
        info: ['Collègues qui peuvent voir vos réservations non-privées', 'Demandes pour vous suivre', 'Collègues dont vous pouvez voir les réservations non-privées'],
        serach_header: 'Résultat de rechèrche',
        no_results: 'Pas de résultats',
        placeholders: ['Rechèrche', 'Trouvez des collègues à suivre'],
        modals: {
          confirm: {
            text:"Voulez vous également suivre ce collègue?",
            cancel: "Ne pas suivre",
            confirm: "Suivre"
          },
          delete: {
            text:"Voulez-vous supprimer la demande de ce collègue?",
            cancel: "Annuler",
            confirm: "Supprimer"
          },
          remove: {
            text:"Voulez-vous supprimer ce collègue?",
            cancel: "Annuler",
            confirm: "Supprimer"
          },
          cancel: {
            text: 'Voulez vous annuler votre demande?',
            cancel: 'Non',
            confirm: 'Oui'
          },
          unfollow: {
            text: 'Voulez vous ne plus suivre ce collègue?',
            description: ['Si vous changez d\'avis, vous devrez refaire la demande.'],
            cancel: "Annuler",
            confirm: 'Ne plus suivre'
          }
        }
      },
      squads: {
        header: 'Mes équipes',
      },
      qr: {
        header: 'Enregistrement ou walk-in QR',
        checkout: 'Voulez-vous quitter?',
        checkedout: 'Quitté',
        photo_description: 'Lisez le code QR pour vous enregistrer pour votre réservation actuelle ou pour créer une réservation walk-in',
        start_scan_description: 'Visez le code QR et touchez ici',
        errors: {
          camera:"La camera n\'est pas supportée par Chrome, veuillez utiliser Safari",
          app:"Veuillez utiliser une application pour lire un code QR!",
          device:"Veuillez utiliser la camera arrière!"
        },
        modals: {
          info: 'Acces à la camera est nécessaire pour lire le code QR.',
          check_in: {
            header: 'Reservé pour:',
            from_to: ['De: ',', à '],
            subject_default: 'Réservation'
          }
        }
      },
      help_center: {
        nav: {
          demo: {
            header: 'Démonstration rapide',
            card: {
              headers: [],
              descriptions: ['Regardez cette démo rapide pour avoir un bon aperçu de toutes les fonctionnalités de Tribeloo.'],
              links: []
            }
          },
          get_started: {
            header: 'Commencer',
            sub_headers: ['Vidéo de configuration', 'Authentification unique (SSO)' ,'Approvisionnement utilisateurs'],
            card: {
              headers: [],
              descriptions: ['Apprenez comment commencer à utiliser Tribeloo et pour faire la configuration initiale de la plateforme.', 'Consultez les instructions d\'installation de la fonction d\'authentification unique (SSO) ', 'Consultez les instructions sur la façon d\'installer la fonctionnalité de provisioning des utilisateurs '],
              links: ['ici.']
            }
          },
          site_manager: {
            header: 'Responsable de bureau',
            sub_headers: ['Vidéo de présentation', 'Configuration des appareils'],
            card: {
              headers: ['Configuration des appareils'],
              descriptions: ['Apprenez à utiliser Tribeloo en tant que responsable de bureau.', 'Apprenz à configurer les appareils pour les bureaux et salles de réunions.'],
              links: []
            }
          },
          user: {
            header: 'Utilisateur',
            sub_headers: ['Vidéo de présentation', 'Utiliser le complément Outlook', 'Installer le complément Outlook', 'Utiliser l\'extension Chrome' ,'Installer l\'extension Chrome', 'Installer l\'application sur iPhone' ,'Installer l\'application sur Android'],
            card: {
              mobile: 'Apprenez à utiliser l\'application mobile Tribeloo et la solution de réservation Web.',
              outlook: {
                headers: ['Comment utiliser le complément Outlook', 'Comment installer le complément Outlook'],
                descriptions: ['Aprrenez à utiliser le complément Outlook de Tribeloo.', 'Apprenez à installer le complément Outlook de Tribeloo. ', 'Visitez le ', 'Microsoft store', ' pour le télécharger.',  ]
              },
              google: {
                headers: ['Comment utiliser l\'extension Chrome', 'Comment installer l\'extension Chrome'],
                descriptions: ['Apprenez à utiliser l\'extension Chrome de Tribeloo, et l\'intégration avec votre calendrier Google', 'Apprenez comment installer l\'extension Chrome de Tribeloo, integrée avec votre calendrier Google. ', 'Visitez le ', 'Chrome store', ' pour le télécharger.']
              },
              pwa: {
                headers: [],
                descriptions: ['Regardez la vidéo ci-dessous ou ', 'télécharger le PDF', ' sur comment installer l\'application Tribeloo sur votre iPhone', ' sur comment installer l\'application sur votre téléphone Android']
              }
            }
          },
          feedback: {
            header: 'Contactez-nous',
            sub_headers: [],
            card: {
              headers: ["Vous ne trouvez pas ce que vous cherchez? Ou vous voulez donner des commentaires?"],
              descriptions: ['Laissez-nous vous aider! Veuillez donner autant de détails que possible pour que nous puissions vous aider plus rapidement.'],
              links: []
            }
          }
        },
        main: {
          header: 'Centre d\'aide',
          orange_card: ['Cliquez les icones', ' dans l\'application pour plus d\'information à propos d\'un sujet spécifique.'],
          subheaders: ['Bienvenue!', 'Trouvez de l\'information utile sur comment utiliser Tribeloo.', 'Vous pouvez toujours revenir ici en cliquant \'Centre d\'aide\' sur la page d\'accueil ou dans la configuration.']
        }
      }
    }
  },
  shared_components: {
    wings_select: "Toutes les ailes",
    activity_select: {},
    assets_map:{
      types_external_internal: [', Seulement interne', ', externe'],
      owner: 'Assigné à: ',
      time_slots: "Disponible: ",
      no_time_slots: "Pas de plages horaires disponibles",
      not_reservable: "Vous ne pouvez pas réserver cette ressource"

    },
    attendees_modal: {
      header_text: {
        share: 'Partager avec vos collègues',
        attendees: 'Seront présents'
      },
      info: ['Sélectionnez des collègues de la liste pour leur envoyer un mail.', 'Demandez à vos collègues de vous ajouter a leur tribu'],
      placeholdrs: {
        colleagues: 'Sélectionnez collègue',
        message: 'Ecrivez votre texte ici'
      },
      button_attendees: ['Ne pas y aller?', 'Vous y serez?'],
      more: ' plus...',
      send: 'Envoyer',
      send_all: 'Envoyer mail à tous',
      counter: 'participant(e)s'
    },
    total_counter_download_table_csv: {
      batch_buton: 'Batch edit',
      button: 'Télécharger',
      text: ' Total',
      selected: 'Sélectionné',
      info: 'Pas de données pour les paramètres selectionnés'
    },
    confirm_dialog: {},
    date_time_range_modal: {
      headers:{
        main:'Sélectionner la date et l\'heure',
        from: 'A partir de',
        to: 'Jusqu\'à'
      },
      tooltip: 'Cliquez pour le calendrier',
      info: "'Jusqu\'à' doit venir apres 'A partir de'"
    },
    date_time_range_view: {
      headers:{from:'A partir de', to: 'jusqu\'à', start: 'Début', end: 'Fin'}
    },
    day_time_mini: {
      header: ["Set ", " date & time"],
      tooltip: {
        calendar: "Click for display calendar",
        time: "Click for display time"
      }
    },
    download_xlsx: {},
    expanded_filter_assets_list: {
      modal: {
        header: "Information sur les resources",
        report_button:  "Rapporter un problème"
      },
      header: ' liste de disponibilités',
      description: 'Seulement avec toutes les aménités',
      nospace: 'Rien disponible',
      types_external_internal: [', interne', ', externe'],
      placeholder: 'Chercher',
      tooltip: 'Ne contient pas toutes les aménités demandées'
    },
    filter_dialog: {},
    icon_video: "More info",
    info_dialog: {},
    kiosk_logout_info_modal: {
      message: "Are you still working here?",
      info: ["Your session will end in ", " seconds. "]
    },
    newsfeed_create_edit_item: {
      popup: "Discard unsaved changes?",
      markdown: "Styling with Markdown is supported",
      link: "Check guidelines",
      image_info: "Image (< 4MB, size 335 x 75 px)",
      radio: ["Type:", "News", "Event"],
      placeholder: {
        event: "New event",
        location: "Location",
      }
    },
    newsfeed_item: {
      default_subject: '',
      actions: ['plus', 'fermer']
    },
    loading_table_spinner: 'fetching data',
    reservation_header_info: {
      state: ['Reservation pending', 'Assigned'],
      info: "Check availability to assign one",
      internal_external: [ ', internal', ', external']
    },
    reservation_tipe_modal: 'Pas d\'options à ajouter, veuillez modifier la réservation existante',
    site_dialog: {},
    site_select: {
      placeholder: 'Sélectionnez site',
      tooltip: 'Cliquez ici pour sélectionner le site',
      options: ['All sites', 'No site']
      // option: 'Aucun'
    },
    site_wings_reservation_view_external_data_set: "Cliquez pour acceder à cet étage",
    table_datetime_range_helper: {},
    time_range_helper: {},
  },
  user_shared_components: {
    capcity_internal_block: {
      placeholder: 'sièges',
      // tooltip: 'External meeting: with external visitors &#13;Internal meeting: only with internal employees',
      tooltip: 'Réunion externe: avec des visiteurs externes. Réunion interne: seulement avec des employés internes',
      select: ['Espace simple', 'Réunion interne', 'Réunion externe', 'Salle']
    },
    list_item: {
      internal_external: [', interne',', externe'],
      info_reservation: ['Réservation en attente, vous allez bientôt recevoir une place.', 'Ou vérifiez la disponibilité pour en choisir une vous-même.'],
      subject: ['Nouvelle absence', 'Nouvelle réservation '],
      status: 'libérée',
      header: 'Votre '
    },
    message_details: {
      toggle:'Ajouter une demande',
      info: ['Demande non traitée', 'Demande traitée'],
      placeholder: "Laisser une demande pour le responsable administratif si vous avez une requète"
    },
    weeks_repeater: {
      title:' Répéter jusqu\'à',
      weekdaysShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam']
    },
    repeater_modal: {
      header: 'Personaliser',
      repeat_until: 'Répéter jusqu\'à',
      repeat_on: 'Répéter les'
    }

  },
  reusable_data: {
    time_tracking_popup: {
      big_titel: "Vous avez travaillé ",
      descriptions: ["Voulez vous arreter de travailler?"]
    },
    tables: {
      from: "A partir de",
      to: "A",
      date_time: "Date & heure"
    },
    feedback: {
      header: 'Donner des commentaires',
      placeholders: ['Choisissez le genre commentaire', 'Ajouter une déscription'],
      types: ['bug', 'idée', 'question', 'autre'],
      buttons: ['Annuler', ' Envoyer'],
      issue_text: "Votre commentaire n'a pas été envoyé. Veuillez recommencer plus tard. Merci!"
    },
    user_navigation: {
      no_actions_nav: {
        home: 'Accueil',
        my_tribe: 'Ma Tribu',
        squads: 'Mes équipes',
        outlook_events: 'Evénements Outlook',
        google_events: 'Evénements Google',
        qr: 'Enregistrement ou walk-in QR',
        tracker: ["Debut du travail, ", "Fin du travail, "],
        reservations: 'Gestion des réservations',
        releases: 'Gestion des absences',
        who_is_arround: 'Qui est là?',
        find_colleague: 'Trouver un collègue',
        report_issue: 'Signaler un problème',
        user_settings: 'Paramètres',
        help_center: 'Centre d\'aide'
      },
      actions_nav: {
        book_desk: 'Réserver un bureau',
        book_meeting_room: 'Réserver salle de réunion',
        add_new_release: 'Absence'
      }
    },
    button: {
      ok: 'OK',
      add: 'Ajouter',
      show: 'Montrer',
      hide: 'Cacher',
      send: 'Envoyer',
      save: 'Sauvegarder',
      edit: 'Editer',
      more: 'Plus',
      close: 'Fermer',
      email: 'Email',
      login: 'Se connecter',
      logout: 'Déconnexion',
      check_in: 'Check in',
      submit: 'Envoyer',
      delete: 'Effacer',
      select: 'Sélectionner',
      cancel: 'Annuler',
      upload: 'Télécharger',
      remove: 'Retirer',
      reopen: 'Reopen',
      comment: 'Donner du commentaire',
      confirm: 'Confirmer',
      inverse: 'Inverser',
      refresh: 'Mettre à jour',
      continue: 'Continuer',
      download: 'Télécharger',
      show_all: 'Montrer tout',
      checked_in: 'Enregistré',
      select_all: 'Selectionner tout',
      batch_edit: 'Modification par lots',
      upload_image: 'Télécharger',
      remove_image: 'Supprimer',
      assign_me_one: 'Attribuez-en un(e)',
      back_to_dashboard: '< Back to Dashboard',
      cancel_reservation: 'Annuler',
      check_availability : 'Vérifier disponibilité',
      retutn_releases_list: 'Retour à la liste des absences',
      retutn_reservations_list: 'Retour à la liste des réservations',
      retutn_reservations_releases_events_list: [ 'Retour à la liste des réservations', ' événements']
    },
    release_reservation_text: {
      outlook: 'Changez l\'heure dans le calendrier Outlook',
      google: 'Changez l\'heure dans le calendrier Google'
    }
  },
  v2_shared_components: {
    calc: {
      sum: "Total summ: ",
      count: "count: ",
      price: "price: "
    },
    tribeloo_asset_info: {
      user_info: "Assigné à vous",
        user_release_info: "Cette ressource vous a été attribuée. Réservez-la si vous souhaitez l'utiliser.",
        user_booked_info: "Cette ressource vous a été attribuée et est disponible à tout moment pour votre usage.",
        invited_users: " collègues invités et sujet",
        time_slot: "Indisponible aujourd'hui",
        buttons: {
        invite: "Inviter des collègues",
          book: "Confirmer la réservation",
          update: "Confirmer les modifications",
          delete: "Annuler",
          edit: "Modifier"
      }
    },
    tribeloo_asset_list: {
      headers: {
        menu: "Outils Tribeloo",
          favorites: "Favoris"
      },
      filter: {
        search: "Recherche",
        all: "Tous",
        users_only: "Utilisateurs uniquement",
        resources_only: "Ressources uniquement"
      },
      menu_items: {
        colleagues: {
          subtitle: "Trouver un collègue",
            description: "Collaborez facilement avec vos collègues"
        },
        bookings: {
          subtitle: "Mes réservations",
            description: "Un aperçu de toutes vos réservations"
        },
        releases: {
          subtitle: "Libérations",
            description: "Libérez votre bureau dédié pour que d'autres puissent l'utiliser"
        },
        qr: {
          subtitle: "QR Scanner",
            description: "Réservez ou enregistrez-vous en utilisant le scanner QR"
        }
      }
    },
    tribeloo_asset_list_item: {
      check_in: ["Enregistré", "Enregistrer"]
    },
    tribeloo_asset_release_popper: {
      buttons: {
        create: "Créer",
          cancel: "Annuler"
      }
    },
    tribeloo_assets_popper: {
      title: "Ressources disponibles"
    },
    tribeloo_component_filter: {
      title: "Options de filtre",
        date_range: ["Plage de dates", "De", "À"],
        type: "Type de ressource",
        location: "Lieu"
    },
    tribeloo_bookings_list: {
      subtitle: ["Trouver des collègues", "Mes réservations"],
        description: [
        "Recherchez des collègues pour trouver leurs réservations et optimisez la collaboration",
        "Un aperçu de toutes vos réservations. Gérez toutes vos réservations en un seul endroit."
      ],
        search: " résultats de recherche.",
        not_found: "Aucun collègue trouvé."
    },
    tribeloo_camera: {
      camera_error_message: "Veuillez accorder l'accès à la caméra",
        camera_not_found: "Aucune caméra trouvée."
    },
    tribeloo_date_picker: {
      max_selection_info: "Sélection multi-jours maximale atteinte",
        days_selected_text: "jours sélectionnés",
        toggle_text_info: ["Sélection multi-jours", "Sélection multi-jours"]
    },
    tribeloo_download_image: {
      info_dialogue:
        "Votre image est trop grande.\nVeuillez sélectionner une image de taille inférieure à 4 Mo."
    },
    tribeloo_edit_invites: {
      title: "Modifier l'invitation à la réservation",
        subtitle:
      "Un e-mail ne sera envoyé qu'aux collègues récemment ajoutés.",
        buttons: {
        main_invite: "Inviter des collègues",
          send_invite: ["Renvoyer l'invitation", "Envoyer l'invitation"]
      },
      placeholder: "Invitations mises à jour"
    },
    tribeloo_favorites_list_item: {
      other_info: " Réservations actives"
    },
    tribeloo_map_legend: {
      button: "Centrer la carte",
        map_legend_title: "Légende de la carte",
        map_legend_items: {
        available: "Disponible",
          confirm: "Besoin de confirmation",
          not_for_user: "Pas pour l'utilisateur",
          unavailable: "Indisponible"
      }
    },
    tribeloo_qr_scanner: {
      title: "Scanner le code QR",
        subtitle:
      "Placez le code QR devant la caméra pour réserver ou vous enregistrer.",
        error_message: "Erreur lors du balayage du code QR",
        processing_message: "Décodage balayé en cours"
    },
    tribeloo_releases_sidebar: {
      title_resources: "Mes ressources",
        title_releases: "Mes libérations",
        subtitle:
      "Libérations",
        description:
      "Configurez l'heure, la date et choisissez la ressource que vous souhaitez libérer."
    },
    tribeloo_type_location_neighbourhood: {
      labels: {
        type: "Type de ressource",
          site: "Site",
          wing: "Aile",
          neighbourhoods: "Quartiers"
      }
    },
    tribeloo_upload_profile_pic: {
      title: "Télécharger l'image",
        subtitle:
      "Choisissez une image de profil à afficher sur la carte",
        info_dialog_component:
      "Votre image est trop grande.\nVeuillez sélectionner une image de taille inférieure à 4 Mo.",
        support_text: "Prend en charge les formats JPG ou PNG",
        dd_text: "Faites glisser et déposez l'image ou ",
        browse_text: "parcourir",
        buttons: {
        save: "Enregistrer l'image",
          upload: "Télécharger l'image",
          capture: "Prendre une photo avec la webcam"
      },
      img_alt: "Nouvelle photo de profil"
    },
    tribeloo_whoisaround: {
      title: "Qui est autour ?"
    },
    tribeloo_more_settings: {
      title: "Plus de paramètres",
        labels: {
        seats: "Places",
          amenities: "Équipements",
          visitor: "Inviter un visiteur"
      },
      toggles: {
        amenities: "Afficher uniquement ceux avec tous les équipements",
          booking: "La réservation n'est pas visible pour les autres"
      },
      placeholders: {
        visitor: "E-mail du visiteur",
          amenities: "Aucun équipement sélectionné"
      },
      info: "Plus de paramètres non disponibles"
    },
    tribeloo_time_range: {
      labels: {
        select: "Sélection rapide",
          start: "Heure de début",
          end: "Heure de fin"
      },
      rangeOptions: {
        fullDay: 'Journée entière',
        morning: 'Matin',
        afternoon: 'Après-midi',
        customTime: 'Heure personnalisée'
      }
    },
    tribeloo_time_picker: {
      tooltip_error: "Format d'heure invalide",
    },
    tribeloo_single_file_upload: {
      button_save: "Enregistrer l'image rognée"
    },
    tribeloo_pop_up_confirmation: {
      titles: {
        cancel: "Annulation de la réservation",
          edit: "Modification de la réservation réussie",
          invite: "Modification de l'invitation à la réservation",
          booked: "Réservation réussie !",
          options: "Options de réservation facultatives",
          addSubject: "Ajouter un sujet",
      },
      subtitles: {
        edit_booked:
        "Un e-mail vous sera envoyé avec des informations sur votre réservation",
            invite:
        "Un e-mail ne sera envoyé qu'aux collègues récemment ajoutés.",
          old: "Ancienne réservation",
          new: "Nouvelle réservation",
          cancel:
        "Une fois qu'une réservation est annulée, elle ne peut pas être annulée."
      },
      subjectInput: {
        saved: "Sujet enregistré",
        placeholder: "Nom du sujet"
      },
      input: ["Invitations mises à jour", "Collègues invités"],
        buttons: {
          confirm: 'Confirmer',
        main: "Inviter des collègues",
          booKingSubjectInvitations: "Sujet de réservation et invitations",
          send: "Envoyer l'invitation",
          update: "Mettre à jour les invitations",
          cancel: "Annuler la réservation",
          nevermind: "Peu importe",
          saveSubject: "Enregistrer le sujet"
      }
    },
    tags_modal: {
      title: "balises",
        subtitle: "Petite explication sur le fonctionnement des balises",
        small_title: ["Suggestions", "Toutes les balises"],
        colleagues: "collègues",
        placeholder: "Rechercher ou ajouter des balises",
        add_button: ["Ajouter", "Ajouter une nouvelle balise"],
        save_button: "Enregistrer les modifications"
    },
    tribeloo_base_user_settings: {
      settings: "Paramètres utilisateur",
        tribe: "Ma tribu"
    },
    tribeloo_filter_tribe_members: {
      title: "Options de filtre",
        tag: "Balises",
        site: "Site principal"
    },
    tribeloo_my_tribe: {
      description:
        "Marquez vos collègues pour les retrouver facilement lors de la création d'une réservation et définissez vos propres groupes.",
          search: "Rechercher"
    },
    tribeloo_tribe_member: {
      title: "Balises",
        not_found: "Aucun tag trouvé",
        edit_button: "Modifier les tags"
    }
  },
v2_pages: {
    tribeloo_default_page_holder: {
      messages: {
        update_success: 'Mise à jour de la réservation réussie',
        update_failed: 'Échec de la mise à jour de la réservation.',
        cancel_failed: "Échec de l'annulation de la libération",
        error408: "Do you wont to force book?"
      }
    },
    tribeloo_external_invites: {
      info: "Votre invitation n'est pas valide",
      button: "Retour à la page d'accueil"
    }
  }
};
