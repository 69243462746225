import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notReservedAssets'
})
export class NotReservedAssetsPipe implements PipeTransform {

  transform(items: any[], filter: number): any {
     if (!items || filter != 0) {
         return items;
     }
     return items.filter(item => item.status !== filter);
   }

}
