import { NgModule }              from '@angular/core';
import { RouterModule, Routes }  from '@angular/router';

const appRoutes: Routes = [
  { path: 'login', loadChildren: () => import('./login-module/login-module.module').then(m => m.LoginModuleModule) },
  { path: 'registration', loadChildren: () => import('./registration-module/registration-module.module').then(m => m.RegistrationModuleModule) },
  { path: 'kiosk-login', loadChildren: () => import('./login-module/login-module.module').then(m => m.LoginModuleModule) },
  { path: 'kiosk', loadChildren: () => import('./kiosk-module/kiosk-module.module').then(m => m.KioskModuleModule) },

  { path: 'external/reservations/outlook', loadChildren: () => import('./external/outlook-reservation-module/outlook-reservation-module.module').then(m => m.OutlookReservationModuleModule) },
  { path: 'external/reservations/calendar', loadChildren: () => import('./external/outlook-reservation-module/outlook-reservation-module.module').then(m => m.OutlookReservationModuleModule) },
  { path: 'external/resetpassword', loadChildren: () => import('./external/reset-password-module/reset-password-module.module').then(m => m.ResetPasswordModuleModule) },
  { path: 'external/closeissuebyhash', loadChildren: () => import('./external/close-issue-by-hash-module/close-issue-by-hash-module.module').then(m => m.CloseIssueByHashModuleModule) },
  { path: 'external', loadChildren: () => import('./main-module/main-module.module').then(m => m.MainModuleModule) },

  { path: '', loadChildren: () => import('./main-module/main-module.module').then(m => m.MainModuleModule) },

  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
