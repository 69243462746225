export const  de = {
  type: 'de',
  code: 'de-de',
  name: 'Deutsch',
  img: 'flag-icon-de',
  pages: {
    login_main_navigation: {
      login: {
        pageName: 'Anmeldung',
        callback: 'Schließen Sie dieses Fenster und fahren Sie mit dem Tribeloo-Add-In für Outlook fort',
        email_dialog: {
          header: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
          input: 'E-Mail'
        },
        sso_info:{
          text: "Ihr Browser blockiert das Öffnen eines neuen Tabs für die SSO-Anmeldung. Ändern Sie Ihre Browsereinstellungen oder klicken Sie auf die Schaltfläche „Öffnen erzwingen“.",
          button: "Öffnen erzwingen"
        },
        text: {
          loading: ['Wir arbeiten für Sie', 'Wenn es zu lange dauert,', 'bitte neu laden'],
          header: 'Willkommen',
          sub_header: 'Buchen Sie Ihre Lieblingsschreibtische, finden Sie Ihre Kollegen und vieles mehr.',
          form_headers: {
            login: 'Anmelden',
            email: 'Bitte E-Mail überprüfen',
            password: 'Passwort vergessen'
          },
          password_info: ["Keine Sorge! Wir senden Ihnen einen Wiederherstellungslink.", 'Ein neues Passwort wurde Ihnen zugesandt.'],
          inputs: ['Benutzername', 'Passwort', 'E-Mail'],
          remember_action: ['Erinnere dich an mich.', 'Nicht du?', 'Passwort vergessen?', 'Zurück zur Anmeldung'],
          button: ['Mit SSO anmelden', 'Anmelden', 'Passwort zurücksetzen'],
          info_modal: 'Überprüfen Sie Ihre E-Mails, um Ihr Passwort zu ändern'
        }
      },
      delegates_modal: {
        header: "Delegiertenprofil auswählen",
        sub_header: "Wählen Sie die Person aus, für die Sie eine Buchung vornehmen möchten",
        button_select: "Auswählen"
      },
      main_navigation: {
        pageName: 'Seiten-Navigation, Hauptwerkzeugleiste-Komponente',
        nav_tabs_names: {user: 'Benutzer', admin: 'Administrator'},
        nav_dropdown_corg: {
          about: 'Über',
          history: "History",
          feedback: 'Feedback geben',
          logout: 'Ausloggen',
          user_settings: "Benutzereinstellungen",
          delegate: "Delegiertenprofile wechseln",
          user: "Zum Benutzerprofil wechseln",
          admin: "Zum Administratorprofil wechseln"
        },
        admin_nav_items: {
          dashboard: 'Dashboard',
          reservation_management: 'Reservierungsmanagement',
          newsfeed: 'Newsfeed',
          user_management: 'Benutzermanagement',
          site_configuration: 'Standortkonfiguration',
          kiosk_configuration: 'Kiosk-Konfiguration',
          issue_management: 'Problemmanagement',
          insights: 'Einblicke'
        }
      }
    },
    external: {
      close_issue_by_hash: 'Vielen Dank für das Schließen des Tickets!',
      hash_reservation: 'Reservierung nicht verfügbar',
      google: 'Lade…',
      outlook_reservation: {
        popup: 'Diese Reservierung löschen?',
        login: 'Anmelden',
        site_headr: 'Standort',
        date_time: 'Lokale Zeitzone des Standortes',
        amenities_header: 'Ausrüstung',
        no_space_info: [
          'Kein Platz reserviert,',
          ' bitte kontaktieren Sie den Organisator des Meetings'
        ],
        space: {
          header: 'Raum',
          internal_external: [
            'intern',
            'extern'
          ]
        }
      },
      reset_password: {
        info_modal: 'Passwort geändert.',
        button_update: 'Passwort ändern',
        rules: 'Das Passwort muss mindestens enthalten: 8 Zeichen, 1 Großbuchstaben, 1 Kleinbuchstaben, 1 Zahl',
        placeholders: [
          'Neues Passwort',
          'Neues Passwort wiederholen'
        ],
        errors: [
          'Passwort entspricht nicht der Vorschrift',
          'Die beiden Passwörter sind nicht identisch'
        ]
      }
    },
    admin: {
      kiosk: {
        main_header: 'Set up a kiosk',
        card: {
          warning: 'Not running in kiosk, PWA or full-screen mode.',
          button: 'Launch kiosk mode',
          headers: {
            site: 'Select a site',
            wing: [
              'Select a wing ',
              '(optional)'
            ]
          },
          placeholders: {
            site: 'Please search or select a site',
            wing: 'Please search or select a wing'
          }
        },
        info: {
          kiosk: 'For Kiosk - please logout, close browser and than run a following command: ',
          pwa: 'For PWA or full-screen browser - use Google chrome browser and',
          button: 'Click here'
        }
      },
      dashboard: {
        main: {
          refresh: 'Refresh',
          site_card: {
            header: ' Dashboard',
            action: 'Help center',
            sub_header: 'Select a site to get an overview'
          },
          small_card: {
            header: ' Meeting rooms',
            total: 'TOTAL',
            assigned: 'Assigned'
          },
          utilization_cards: {
            header: ' Max utilization rate ',
            special_header: 'Max utilization rate special types',
            tooltip: 'The max utilization rate is the max ratio during that day of requested over available resources of the selected type',
            info: 'Click on a number in the table to see the chart',
            table: {
              headers: [
                {
                  name: 'All types',
                  tag_name: 'all_types'
                },
                {
                  name: 'Desks',
                  tag_name: 'desks'
                },
                {
                  name: 'Meeting rooms',
                  tag_name: 'rooms'
                },
                {
                  name: 'Meeting rooms - Large ',
                  tag_name: 'big_rooms'
                },
                {
                  name: 'Meeting rooms - External',
                  tag_name: 'external'
                },
                {
                  name: 'Released %',
                  tag_name: 'releases'
                }
              ],
              hover: [
                'Requested ',
                'Available '
              ]
            },
            zoom: [
              'zoom out',
              'zoom in'
            ]
          },
          to_do_card: {
            header: ' To-do’s',
            link: 'Go to to-do list',
            table: [
              {
                name: 'To be confirmed',
                tag_name: 'to_be_confirmed'
              },
              {
                name: 'To be assigned',
                tag_name: 'to_be_assigned'
              },
              {
                name: 'Untreated request',
                tag_name: 'with_message'
              },
              {
                name: 'Total',
                tag_name: 'total'
              }
            ]
          },
          not_enough_card: {
            header: 'Not enough resources',
            info_default: 'Click on a number in the table to see the solutions.',
            info_selected: 'Let’s see how we can solve this.',
            button_reservation: 'Remind people about their reservations',
            button_release: 'Request more releases'
          }
        },
        releases_table: {
          info: 'You can ask people who have not released their resource for this day to release it.',
          tooltip: 'send message to all',
          headers: {
            user: {
              popup: 'owners',
              name: 'Assigned to'
            },
            level: {
              popup: 'Level of user',
              name: 'Level assigned user'
            },
            wing: {
              popup: 'Wings',
              name: 'Wing'
            },
            space: {
              popup: 'Resource',
              name: 'Resource'
            },
            asset_level: {
              name: 'Asset level',
              popup: ''

            },
            seat: {
              name: 'Seat capacity',
              popup: ''
            },
            amenities: {
              name: 'Resource’s amenities',
              popup: ''
            }
          },
          dialogues: {
            send_all_message: 'Send message to all selected releases?',
            select_owner_message: 'At least one user must be selected.',
            error_message: 'Error sending'
          }
        },
        reservations_table: {
          info: 'You can remind people who have a reservation for this day to cancel their reservation if no longer needed.',
          tooltip: 'send message to all',
          headers: {
            user: {
              popup: 'Owners',
              name: 'User'
            },
            level: {
              popup: 'Level',
              name: 'Level of user'
            },
            subject: {
              name: 'Subject',
              popup: ''
            },
            from: {
              name: 'From',
              popup: ''
            },
            to: {
              name: 'To',
              popup: ''
            },
            rc: {
              popup: 'Requested seats',
              name: 'Requested seats'
            },
            ie: {
              popup: 'internal external',
              name: 'internal/ external'
            },
            amenities: {
              name: 'Requested amenities',
              popup: ''
            },
            wing: {
              popup: 'Wings',
              name: 'Wing'
            },
            space: {
              popup: 'Resource',
              name: 'Resource'
            },
            status: {
              popup: 'Statuses',
              name: 'Status'
            }
          },
          dialogues: {
            send_all_message: 'Send message to all selected reservations?',
            select_owner_message: 'At least one user must be selected.',
            error_message: 'Error sending'
          }
        }
      },
      newsfeed: {
        navigation: [
          'Newsfeed',
          'Emergency message'
        ],
        list: {
          main_header: 'Newsfeed',
          site_header: 'Select a site to check its newsfeed.',
          i_tooltipe: 'The newsfeed of a site is shown on the homepage for all sites selected by the user',
          add_item: ' Add new item',
          card: {
            header: 'Settings',
            toggle: 'Show newsfeed of site',
            i_tooltipe: 'You can choose to show the newsfeed of another site',
            select: {
              toggle: 'Click here to select the site',
              placeholder: 'Select site'
            },
            auto_remove: {
              i_tooltipe: 'Choose how long news items are shown on the newsfeed before they get deleted. Events are removed from the newsfeed once ended.',
              text: [
                'Automatically delete news items that are older than ',
                ' days. '
              ]
            }
          },
          popups: {
            info: 'Please select a location!',
            confirm: 'Delete all existing newsitems and events of ',
            button: 'OK'
          }
        },
        form: {
          main_header: 'Emergency message ',
          site_header: 'Select a site for which you want to write an emergency message.',
          i_tooltipe: 'An emergency message, once turned on, is visible for all users of that site on the Tribeloo homepage and on the devices of that site',
          message: 'Haven\'t message',
          placeholder: 'Emergency message line ',
          save: 'Save',
          emergency: [
            'Turn on emergency message',
            'Turn off emergency message'
          ]
        }
      },
      site_config: {
        navigation: {
          site: 'Site configuration',
          rules: 'Site rules',
          table: 'Resource configuration table',
          types: 'Resource types',
          wayfinding: 'Wayfinding'
        },
        edit_site: {
          header: 'Editing site ',
          video_header: 'How to add, edit and delete a site',
          toggle: 'Site is active & visible for users',
          edit_amenity_dialog: 'Edit amenity',
          buttons: {
            add: 'Add new site',
            delete: 'Delete site'
          },
          toggle_warning: [
            'Site is currently ',
            'inactive & invisible',
            ' for users'
          ],
          base: {
            header: 'Basic details',
            video_header: 'How to set up a site: basic details',
            placeholder: {
              name: 'site name',
              code: 'plus code',
              address: 'address of this site'
            },
            label: {
              code: 'Define coordinates of site (plus code) ',
              address: 'Address of site '
            },
            tooltip: {
              code: 'Plus code can be found on Google maps and is used to show a map of the location on the wayfinding link',
              address: 'Address is shown on the wayfinding link'
            },
            position: {
              header: 'Position of wing',
              info: 'Select a wing, by clicking on its title, to change its position.'
            }
          },
          amenities: {
            header: 'Amenities',
            video_header: 'How to set up a site: amenities',
            info: 'Define amenities ',
            tooltip: 'Amenities are additional features, like a beamer or a flipchart, which can be selected when looking for a resource. Select amenities in the list below, to activate them on the current site, so they can be assigned to a specific resource from this site.',
            placeholder: 'new amenity',
            counter: [
              ' in total - ',
              ' active'
            ]
          },
          manager: {
            header: 'Site manager settings',
            video_header: 'How to set up a site: site manager settings',
            info: 'Add e-mail address office manager ',
            tooltip: 'Office manager will receive emails when new issues are reported ',
            placeholder: 'e-mail',
            toggle: [
              'Send email to-do\'s every ',
              'hours'
            ]
          },
          device: {
            header: 'Device settings',
            video_header: 'How to set up a site: device settings',
            placeholder: 'code 0-9 A-E',
            label: {
              time: 'Use 24h time for devices ',
              password: 'Device password (0-9 A-E) '
            },
            tooltip: {
              time: 'Select time format for meeting room and desk devices: AM/PM or 24h format',
              password: 'Password used to access configuration screen on devices'
            }
          },
          insights: {
            header: 'Insight settings',
            video_header: 'How to set up a site: insight settings',
            labels: {
              days: 'Define working days ',
              hours: 'Define working hours ',
              room: [
                'Define a large meeting room',
                'people or more'
              ]
            },
            tooltip: {
              days: 'Working days are used to calculate average utilization rate in insights',
              hours: 'Working hours are used to calculate average utilization rate in insights'
            },
            range: [
              'from',
              'to'
            ]
          },
          popups: {
            cant_delete_site: 'Site can not be deleted.',
            online: {
              text: 'Bring this site online?',
              descriptions: [
                'This will make the site visible for regular users and ready for reservations.'
              ]
            },
            offline: {
              text: 'Bring this site offline?',
              descriptions: [
                'This will make the site invisible for regular users. Existing reservations will be kept.'
              ]
            },
            delete_site: {
              text: 'Delete this site?',
              big_descriptions: [
                'This will delete all wings, resources and reservations for this site. \nUsers with this home site will be moved to having no home site.'
              ]
            },
            amenity: {
              exists: 'Amenity already exists.',
              cant_updated: 'Amenity cannot be updated. Used by resources on other sites.',
              cant_delete: 'Amenity cannot be deleted, because it is assigned to some resources.',
              cant_disabled: 'Amenity cannot be disabled, because it is assigned to some resources. Remove it first from all resources.'
            }
          },
          edit_wings_sorted: {
            add_button: ' Add Wing',
            position: 'Position: '
          },
          wing_position: {
            header: 'Selected wing: ',
            position: 'Position ',
            move: 'Move to position '
          }
        },
        rules: {
          header: 'Site rules',
          site_card: {
            header: 'Site',
            sub_header: 'Select a site for which you want to define user privileges and rules.'
          },
          default_reservation_time_card: {
            header: 'Default reservation time',
            sub_header_desk: 'Desk',
            sub_header_room: 'Meeting room',
            label_start: 'Start date',
            label_end: 'Duration'
          },
          auto_assign_card: {
            header: 'Auto-assign resources ',
            video_header: 'How to auto-assign resources to reservations',
            sub_header: 'Reservations can get a resource auto-assigned if the user requests one, if none is available it will appear in the to-do list of the office manager',
            toggle: 'Activate auto-assign functionality for ‘to assign’ reservations'
          },
          check_in_reminder_email_card: {
            header: 'Check-in reminder email ',
            video_header: 'How to use the check-in reminder emails & auto-cancel reservations',
            send_toggle: 'Send out reminder email 15 minutes before the start of a reservation that requires check-in',
            auto_cancel_header: 'Auto-cancel reservations that require check-in',
            add_button_toggle: [
              'Add ',
              '‘Cancel reservation’',
              ' button to reminder email'
            ],
            add_desck_toggle: [
              'For ',
              'desk',
              ' reservations: add ',
              '‘Check-in’',
              ' button to reminder email'
            ],
            add_room_toggle: [
              'For ',
              'meeting room',
              ' reservations: add ',
              '‘Check-in’',
              ' button to reminder email'
            ],
            cancel_desk_toggle: [
              'Cancel ',
              'desk',
              ' reservation after',
              'min, if a check-in is required.'
            ],
            cancel_room_toggle: [
              'Cancel ',
              'meeting room',
              ' reservation after',
              'min, if a check-in is required.'
            ]
          },
          reminder_email_card: {
            video_header: 'How to use the general reminder emails',
            header_special: 'Reminder email for meeting rooms',
            header: [
              'Reminder email',
              'for desks'
            ],
            send_toggle: [
              'Send reminder email ',
              'hours before the start of a reservation'
            ],
            subject: {
              label: 'Subject:',
              placeholder: 'Reminder about your upcoming reservation'
            },
            message: {
              placeholder: 'Add message',
              label: [
                'Message: (Markdown supported, for example to add links. ',
                'Check guidelines'
              ]
            },
            agree_toggle: [
              'Add ',
              '‘I agree’',
              ' button: by agreeing the person acknowledges they have read the message'
            ],
            cancel_toggle: [
              'Add ',
              '‘Cancel reservation’',
              ' button'
            ],
            send_toggle_special: [
              'Send reminder email ',
              'hours before the start of a reservation'
            ]
          },
          time_limit_card: {
            header: 'Time limit for future reservations',
            video_header: 'How to use the time limit for future reservations',
            sub_header: 'Reservations can only be made a certain time ahead by non-admin users',
            special_toggle: 'Reservations can be created in the next',
            postfix: 'working days',
            desck_toggle: [
              'Desk',
              ' reservations can be created in the next'
            ],
            room_toggle: [
              'Meeting room',
              ' reservations can be created in the next'
            ]
          },
          utilization_limit_card: {
            header: 'Utilization limit',
            sub_header: [
              'Utilization of active ',
              ' can be limited.',
              'Example: a site has 40 active ',
              '. When utilization is limited to 50%, only 20 concurrent ',
              ' reservations can be made.'
            ],
            sub_header_types: [
              'desks',
              'resources',
              'desk'
            ],
            toggle: [
              'Utilization is limited to',
              'percent'
            ]
          },
          multi_day_reservation_card: {
            header: 'Multi-day reservation',
            toggle: 'Multi-day reservations are allowed',
            sub_header: [
              'Define if an employee is allowed to create one reservation for several consecutive days at once.',
              'When allowed, an employee will only receive the reminder emails once and only needs to check-in once at the start of his multi-day reservation, if this is required.'
            ]
          },
          cleaning_time_card: {
            header: 'Cleaning',
            sub_header: 'Cleaning time will be foreseen after the end of every reservation. During this period the resource cannot be booked.',
            desk_toggle: [
              'Time available to clean a ',
              'desk',
              'min'
            ],
            room_toggle: [
              'Time available to clean a ',
              'meeting room',
              'min'
            ]
          },
          user_message_card: {
            header: 'User message when creating reservation ',
            toggle: 'Show this message when a user creates a reservation for a resource on this site',
            label: 'Message:',
            placeholder: 'Type your message here'
          },
          user_privileges_card: {
            header: 'User privileges',
            video_header: 'How to set up user privileges',
            sub_header: 'Define which users have access to which resources and have to get confirmation for a reservation',
            levels: {
              header: '1. Define the names of the different user levels and resource levels:',
              toggle: 'User level names are the same across all sites',
              user_label: 'User level ',
              resource_label: 'Resource level ',
              placeholder: 'Text',
              description_simple: {
                start: 'User & resource level names',
                center: ' can be set by site admin and are ',
                end: 'specific for the site.'
              },
              description_global: [
                {
                  start: 'User level names',
                  center: ' can only be set by global admin and are the ',
                  end: 'same for all sites.'
                },
                {
                  start: 'Resource level names',
                  center: ' can be set by site admin and are ',
                  end: 'specific for the site.'
                }
              ]
            },
            privileges: {
              header: '2. Define the privileges per user and resource level:',
              cols: 'Resource level',
              rows: 'User level'
            },
            rules: [
              {
                id : 0,
                line1: 'Resource not available',
                line2: '(can be assigned by admin)',
                class: 'red'
              },
              {
                id : 2,
                line1: 'Resource only available ',
                line2: 'after confirmation',
                class: 'orange'
              },
              {
                id : 1,
                line1: 'Resource available for',
                line2: 'user level',
                class: 'green'
              }
            ]
          }
        },
        edit_wing: {
          header: 'Editing wing ',
          sub_header: 'wing details',
          video_header: 'How to add, edit and delete a wing',
          placeholder: 'wing name',
          button: {
            back: 'Back to site edit',
            add: 'Add new wing',
            delete: 'Delete wing'
          },
          popup: {
            text: 'Delete this wing?',
            big_descriptions: [
              'This will delete all resources of this wing and could affect current reservations. \nCheck your to-do list if proceeding.'
            ]
          },
          assets_list: {
            header: 'Resources',
            add_button: ' Add resource',
            counter: ' in total',
            popup: {
              text: 'Delete this resource?',
              big_descriptions: [
                'This could affect current reservations. \nCheck your to-do list if proceeding.'
              ]
            }
          }
        },
        edit_asset: {
          buttons: {
            back: 'Back to wing edit',
            add: 'Add resource',
            clone: 'Duplicate resource',
            delete: 'Delete resource'
          },
          base: {
            header: 'Basic details ',
            video_header: 'How to add, edit and delete a resource & define the basic details',
            type_tooltipe: 'INTERNAL ROOM: used for a meeting with colleagues from within the company.\n EXTERNAL ROOM: used when also people of outside the company are invited (e.g. client).\n ZONE: contains multiple single desks that can be booked, without selecting a particular one.',
            user_tooltipe: 'Assigning a resource to a specific person, means that this person has a fixed desk and they alone can use it. They can still choose to release it for others to use, when they aren’t around.',
            zoning_tooltipe: 'Semicolon separated list of zoning names, useful for HVAC/BM integration.\n E.g.:\n lighting_7;heating_3',
            amenity_type: 'other asset type',
            internal_external: [
              {
                name: 'Internal only',
                value: true
              },
              {
                name: 'External allowed',
                value: false
              }
            ],
            placeholders: {
              name: 'asset name',
              user: 'Select user',
              neighbourhood: 'Select neighbourhood',
              amenities: 'Select amenities'
            },
            labels: {
              status: 'Resource status ',
              neighbourhood: 'Neighbourhood ',
              user: 'Assigned to ',
              level: 'Resource level ',
              resource_zoning: 'Resource zoning ',
              type: 'Resource type ',
              check_in: 'Check-in required',
              amenities: 'Amenities available ',
              image: 'Add image (< 4MB)',
              capacity: [
                'Seat capacity',
                'Capacity'
              ]
            }
          },
          size: {
            header: 'Size, Shape & position ',
            video_header: 'How to set up a resource: size, shape & position',
            size: {
              header: 'Size & shape',
              sub_header: 'Choose a shape',
              width: 'Width',
              height: 'Height',
              round: 'Round corners'
            },
            position: {
              header: 'Map position',
              horizontal: 'Horizontal',
              vertical: 'Vertical'
            }
          },
          calendar: {
            header: 'Calendar & approval settings',
            video_header: 'How to set up a resource: calendar & approval settings',
            resource: {
              label: 'Resource email ',
              placeholder: 'Resource email',
              tooltip: 'Fill in an email to create a calendar for this resource. Tribeloo will keep the calendar up to date with reservations for this resource.'
            },
            approvers: {
              label: 'Approvers email ',
              placeholder: 'Approvers email',
              tooltip: 'Add the email address of the person who will confirm reservations for this resource. Multipe email addresses can be added separated by a semicolon ; and without any spaces before or after the semicolon'
            }
          },
          device: {
            header: 'Device configuration',
            video_header: 'How to set up a resource: device configuration',
            code: {
              label: 'Device code ',
              placeholder: 'Code'
            },
            id: {
              label: 'Device ID ',
              placeholder: 'ID'
            }
          },
          qr_nfc: {
            header: 'QR & NFC configuration',
            video_header: 'How to set up a resource: QR & NFC configuration',
            nfc: 'NFC configuration',
            qr: {
              header: 'Generate QR code',
              info: 'Click on the button to generate a QR code of this resource.',
              button: 'Generate QR'
            }
          },
          popups: {
            nfc_copy: 'NFC url copied.',
            set_capacity: {
              text: 'Reduce seat capacity?',
              big_descriptions: [
                'This could affect current reservations. \nCheck your to-do list if proceeding.'
              ]
            },
            select_owner: {
              text: 'Assign user to resource?',
              big_descriptions: [
                'This could affect current reservations. \nCheck your to-do list if proceeding.'
              ]
            },
            internal_change: {
              text: 'Change resource type to ‘Internal only’?',
              big_descriptions: [
                'This could affect current reservations. \nCheck your to-do list if proceeding.'
              ]
            },
            remove: {
              text: 'Delete this resource?',
              big_descriptions: [
                'This could affect current reservations. \nCheck your to-do list if proceeding.'
              ]
            },
            check_card_id: {
              header: '',
              start: 'Start test',
              stop: 'Stop',
              cases: [
                '1. Click "Start test" below',
                '2. On selected device, tap "Book now" or "Book later"',
                '3. Scan your card on the device',
                '4. The RFID code of the scanned card appears below',
                '5. To test another card, go to step 2'
              ]
            }
          },
          assets_dragging_view: [
            ' - click for edit',
            'drag & drop',
            'click to select'
          ]
        },
        edit_info: {
          create: 'Created on: ',
          update: 'Last updated on: ',
          resource: 'Resources: '
        },
        type: {
          header: 'Resource types',
          video_header: 'How to define different resource types to book',
          sub_header_descriprion: {
            first_line: 'Standard sites have the type \'room or desk\' automatically assigned.',
            second_line: [
              'If you want to create sites that contain another type of resource, define them here.',
              'These other resource types will also become visible on the Tribeloo homepage and in the tab \'More\' in Outlook.'
            ]
          },
          card: {
            list: {
              header: 'Existing resource types',
              button: 'Add new resource type'
            },
            form: {
              header: [
                'Create resource type',
                'Edit resource type'
              ],
              type: {
                label: 'Resource type',
                placeholder: 'Name of resource type'
              },
              icon: {
                label: 'Icon',
                placeholder: 'fa fa-archive',
                button: 'Check icon list'
              },
              preview: {
                label: 'Preview',
                text: [
                  'Buch ',
                  'Büro buchen',
                  'Raum buchen'
                ]
              },
              booking: {
                label: 'Double booking by user',
                sub_label: '(desks only)'
              },
              button: {
                create: ' Create type',
                delete: ' Delete type',
                save: ' Save changes'
              }
            }
          },
          popups: {
            unique_name: 'You alredy have same type name',
            create: 'Create type?',
            save: 'Save changes?',
            add: 'New resource type is added',
            saved: 'Changes saved',
            delete: 'Delete this resource type?',
            cant_delete: 'This resource can\'t be deleted, because there are still sites of this type'
          }
        },
        assets_table: {
          buttons: {
            batch: 'Batch edit',
            qr: [
              'QR PDF selected',
              'QR PDF all'
            ]
          },
          headers: {
            number: {
              popup: '',
              name: 'Number'
            },
            resource: {
              popup: '',
              name: 'Resource'
            },
            wing: {
              name: 'Wing',
              popup: 'Wings'
            },
            user: {
              popup: '',
              name: 'Assigned to'
            },
            level: {
              name: 'Resource level',
              popup: 'Resource level'
            },
            neighbourhood: {
              name: 'Neighbourhood',
              popup: 'Neighbourhood'
            },
            capacity: {
              name: 'Seat capacity',
              popup: 'Seat capacity'
            },
            type: {
              name: 'Resource type',
              popup: 'Resource type'
            },
            check_in: {
              name: 'Check-in required',
              popup: 'Check-in required'
            },
            amenities: {
              popup: '',
              name: 'Amenities'
            },
            device_id: {
              popup: '',
              name: 'Device ID'
            },
            email: {
              name: 'Resource email',
      	      popup: ''
            },
            status: {
              name: 'Status',
              popup: 'Status'
            },
            from: {
              popup: '',
              name: 'From'
            },
            to: {
              popup: '',
              name: 'To'
            }
          },
          popups: {
            selected: 'At least one resource must be selected',
            this_delete: 'Delete this resource?',
            batch: {
              header: 'Edit resource details',
              sub_header: 'Update',
              level: 'Resource level',
              capacity: 'Seat capacity',
              status: 'Resource status',
              type: {
                label: 'Resource type',
                values: [
                  {
      	            name: 'Internal only',
      	            value: true
                  },
                  {
      	            name: 'External allowed',
      	            value: false
                  }
                ]
              },
              check_in: {
                label: 'Check-in required',
                values: [
                  {
      	            name: 'yes',
      	            value:true
                  },
                  {
      	            name: 'no',
      	            value:false
                  }
                ]
              },
              add_amenities: {
                label: 'Add amenities',
                placeholder: 'Select amenities'
              },
              remove_amenities: {
                label: 'Remove amenities',
                placeholder: 'Select amenities'
              },
              approvers: {
                label: 'Set approvers',
                placeholder: 'approvers email'
              },
              neighbourhood: {
                label: 'Neighbourhoods',
                placeholder: 'Select neighbourhood'
              }
            },
            selete_selected: [
              'Delete the ',
              ' selected resources?'
            ]
          }
        },
        wayfinding_link: {
          header: 'Wayfinding link',
          privacy_card: {
            header: 'Privacy',
            toggle: 'Logging in is required to see information about assigned resource and floormap'
          },
          layout_card: {
            header: 'Layout',
            name_color_label: 'Color company name',
            baner_color_label: 'Color banner',
            titels_color_label: 'Color titles',
            sub_header_descriprion: {
              second_line: 'You can add a company logo OR company name, and define the colors for the banner, the company name and the titles.',
              first_line: [
                'When using the Outlook add-in and Chrome extension, a wayfinding link is added to the event itself. ',
                'This shows invitees where they need to be and to easily find their way.'
              ]
            },
            logo: {
              label: 'Company logo',
              sub_label: '(PNG, less than 4MB)'
            },
            image_info: [
              'max width 210px and ',
              'max height 50px'
            ],
            company_name: {
              label: 'Company name',
              placeholder: 'Max 20 characters'
            }
          }
        }
      },
      todo: {
        work_stack: 'Click here to select the stack item',
        navigation: {
          list: 'Reservation to-do list',
          requests: 'Requests',
          reservation_table: 'Reservation table',
          release_table: 'Release table'
        },
        main: {
          all_button_tooltip: 'Assign resources automatically to current to-do\'s',
          dialog_asset: 'Asset must be selected first',
          details_resources_button: 'View resource reservations',
          legend: {
            header: 'View legend',
            items: [
              {
                name: 'Available',
                type_class: 'green'
              },
              {
                name: 'Available, but to be confirmed',
                type_class: 'orange'
              },
              {
                name: 'Available, but rules constraint',
                type_class: 'blue'
              },
              {
                name: 'Not available',
                type_class: 'red'
              }
            ]
          }
        },
        list: {
          update: 'Last updated: ',
          toggle: 'Show only untreated request',
          toggle_visitor: 'Show only visitor reservations',
          default_name_subject: 'New reservation ',
          user_prefix: 'by ',
          visitor_prefix: 'for ',
          tooltip: 'Reservation within selected time range',
          default_name_asset: 'Reservation pending'
        },
        details: {
          site_change: {
            header: 'Change site',
            tooltip: 'Remove assigned space first'
          },
          request: {
            header: 'Request',
            treated: [
              'Request treated',
              'Request untreated'
            ],
            buttons: {
              add: 'Add note',
              contact: 'Contact user'
            }
          },
          details: {
            delete_button: 'Cancel reservation',
            default_name_subject: 'New reservation ',
            user_prefix: 'By ',
            visitor_prefix: 'For ',
            header: [
              'Show details',
              'Hide details'
            ],
            seats: {
              amenities: 'Requested amenities: ',
              counter: [
                'Requested: seats ',
                'Requested: '
              ],
              type: [
                'internal meeting',
                'external meeting'
              ]
            }
          },
          popups: {
            delete: 'Delete this reservation?',
            assigned: 'Space is assigned to reservation and confirmed',
            selected: 'Asset must been selected',
            available: 'Asset is not available',
            message_error: 'error sending'
          }
        },
        message_popup: {
          header: 'Add note',
          sub_header: 'Request',
          treated: [
            'Request treated',
            'Request untreated'
          ]
        },
        release_table: {
          info: 'Changing time range removes all filters',
          tooltip: 'send message to all',
          headers: {
            number: {
      	      popup: '',
              name: 'Number'
            },
            user: {
              popup: 'Owners',
              name: 'Assigned to'
            },
            level: {
              popup: 'Level assigned user',
              name: 'Level assigned user'
            },
            neighbourhood: {
              name: 'Neighbourhood',
              popup: 'Neighbourhood'
            },
            wing: {
              popup: 'Wings',
              name: 'Wing'
            },
            space: {
              popup: 'Resource',
              name: 'Resource'
            },
            amenities: {
      	      popup: '',
              name: 'Resource’s amenities'
            },
            from: {
      	      popup: '',
              name: 'From'
            },
            to: {
      	      popup: '',
              name: 'To'
            },
            subject: {
      	      popup: '',
              name: 'Subject'
            },
            email: {
      	      popup: '',
              name: 'Email'
            }
          },
          dialogues: {
            remove_release: 'Delete this release?',
            error_message: 'Error sending',
            send_all_message: [
              'Send message to ',
              ' shown releases for '
            ],
            remove_releases: [
              'Delete ',
              ' shown releases for '
            ]
          }
        },
        reservaton_table: {
          info: 'Changing time range removes all filters',
          change_site_buton: ' Change site for all',
          headers: {
            number: {
      	      popup: '',
              name: 'Number'
            },
            user: {
              popup: 'User',
              name: 'User'
            },
            visitor: {
              popup: 'Visitor',
              name: 'Visitor'
            },
            level: {
              popup: 'Level of user',
              name: 'Level of user'
            },
            group: {
              popup: 'User group in ',
              name: 'User group in '
            },
            email: {
      	      popup: '',
              name: 'User email'
            },
            neighbourhood: {
              name: 'Neighbourhood',
              popup: 'Neighbourhood'
            },
            subject: {
      	      popup: '',
              name: 'Subject'
            },
            from: {
      	      popup: '',
              name: 'From'
            },
            to: {
      	      popup: '',
              name: 'To'
            },
            seats: {
              popup: 'Requested seats',
              name: 'Requested seats'
            },
            internal_external: {
              popup: 'Resource type',
              name: 'Resource type'
            },
            amenities: {
      	      popup: '',
              name: 'Requested amenities'
            },
            wing: {
              popup: 'Wings',
              name: 'Wing'
            },
            space: {
              popup: 'Resources',
              name: 'Resource'
            },
            request: {
              popup: 'Request',
              name: 'Request'
            },
            status: {
              popup: 'Statuses',
              name: 'Status'
            },
            acknowledged: {
              popup: 'Acknowledged',
              name: 'Acknowledged'
            }
          },
          tooltip: {
            lock: 'lock all',
            unlock: 'unlock all',
            acknowledged: 'From reminder email: if the person clicked on the \'I agree\' button and therefore acknowledges they have read the message',
            confirm: 'confirm all',
            delete: 'delete all',
            send_mail: 'send message to all'
          },
          dialogues: {
            chang_site: 'Change site',
            remove_reservation: 'Delete this reservations?',
            error_message: 'Error sending',
            confirm: [
              'Confirm ',
              ' shown reservations from '
            ],
            send_all_message: [
              'Send message to ',
              ' shown reservations for '
            ],
            remove_reservations: [
              'Delete ',
              ' shown reservations for '
            ],
            lock: [
              'Lock ',
              ' shown reservations from '
            ],
            unlock: [
              'Are you sure you want to unlock ',
              ' shown reservations from '
            ]
          }
        },
        requests_table: {
          info: 'Changing time range removes all filters',
          add_comment_buton: 'Add comment',
          tooltip: 'send message to all',
          headers: {
            number: {
      	      popup: '',
              name: 'Number'
            },
            user: {
              popup: 'User',
              name: 'User'
            },
            visitor: {
              popup: 'Visitor',
              name: 'Visitor'
            },
            subject: {
      	      popup: '',
              name: 'Subject'
            },
            from: {
      	      popup: '',
              name: 'From'
            },
            to: {
      	      popup: '',
              name: 'To'
            },
            wing: {
              popup: 'Wings',
              name: 'Wing'
            },
            space: {
              popup: 'Resource',
              name: 'Resource'
            },
            request: {
              popup: 'Request',
              name: 'Request'
            },
            comment: {
      	      popup: '',
              name: 'Comment'
            },
            seats: {
      	      popup: '',
              name: 'Request treated'
            },
            email: {
      	      popup: '',
              name: 'Owner email'
            }
          },
          dialogues: {
            error_message: 'Error sending',
            send_all_message: [
              'Send message to ',
              ' shown reservations for '
            ]
          }
        }
      },
      user_management: {
        navigation: {
          global_admin: 'Global admins',
          admin: 'Site admins',
          privileges: 'User rules',
          user: 'User management',
          users_table: 'User table',
          groups: 'User groups',
          sso: 'SSO',
          provisioning: 'User provisioning'
        },
        global_admin: {
          header: 'Global admin management',
          admins_card: {
            header: 'Define the global admins',
            sub_header: 'These users have access to all sites and features',
            video_header: 'How to define the site & global administrators',
            search: 'Search',
            global_admin_list_header: 'Site admins that are global admin',
            admin_list_header: 'Site admins',
            admin_list_header_tooltip: 'You need to be site admin first, before you can be global admin'
          }
        },
        privileges: {
          header: 'User rules',
          site_card: {
            header: 'Site',
            sub_header: 'Select a site to define it\'s user rules.'
          }
        },
        admin: {
          header: 'Site admin management',
          site_card: {
            header: 'Site',
            sub_header: 'Select a site for which you want to add or remove administrators.'
          },
          admins_card: {
            header: 'Add administrators for this site',
            video_header: 'How to define the site & global administrators',
            search: 'Search',
            admin_list_header: 'Users that are admin for this site',
            user_list_header: 'Users',
            popup: {
              title: 'This user has global admin rights and therefore access to all sites.',
              descriptions: [
                'If you want to give this user only access to certain sites, remove them from the global admin list first.'
              ]
            }
          }
        },
        user: {
          header: 'User management',
          rfid_modal: {
            header: 'Assign RFID to user',
            step1: {
              placeholder: 'Device ID',
              button: 'Start ',
              header: [
                'Step 1',
                ' - Enter device ID & click ‘Start’'
              ],
              info: [
                'No resource found ',
                'Check or re-enter ID'
              ]
            },
            step2: [
              'Step 2',
              ' - On connected device, tap ‘Book now’ or ‘Book later’'
            ],
            step3: {
              placeholder: 'Card RFID',
              header: [
                'Step 3',
                ' - Scan card on the device, the RFID code will appear below'
              ]
            },
            step4: {
              button: 'Assign RFID',
              header: [
                'Step 4',
                ' - Click ‘Assign RFID’, to assign it to the user'
              ]
            }
          },
          free_version_sub_header: [
            'Currently using the ',
            'FREE VERSION with a limit of ',
            ' users.',
            ' Contact ',
            ' to upgrade.'
          ],
          site_card: {
            header: 'Location',
            sub_header: 'Select a location to add or remove users for this site.'
          },
          create_edit_card: {
            list: {
              header: 'Existing users',
              button: 'Create new user',
              placeholder: 'Search'
            },
            form: {
              video_header: 'How to create and edit a user',
              sub_header: 'Select a user in the list to edit it\'s details.',
              header: [
                'Edit user',
                'Fill in details'
              ],
              name: {
                label: 'Username',
                placeholder: 'Name'
              },
              email: {
                label: 'Email',
                placeholder: 'Email'
              },
      	      level: {
      	        placeholder:'',
                label: 'User level '
              },
              group: {
                label: 'User group',
                item: 'Default'
              },
              site: {
                label: 'Home site',
                item: ''
              },
              resource: {
      	        placeholder: '',
                label: 'Resource'
              },
              rfid: {
                label: 'RFID',
                placeholder: 'Enter RFID or click on icon on the right'
              },
              image: {
                label: 'User image',
                sub_label: [
                  '(square size, ',
                  'less than 4MB)'
                ]
              },
              button: {
                create: ' Create user',
                reset_pin: 'Reset PIN code',
                reset_password: 'Reset user password',
                upload: 'Upload image',
                delete: ' Delete user',
                save: ' Save changes'
              }
            },
            import: {
              header: 'Batch upload users',
              cases: [
                '1. Download template',
                '2. Add users for this location to the template',
                '3. Upload the template file again to Tribeloo'
              ],
              button: {
                upload: 'Upload file',
                download: 'Download template'
              }
            },
            errors: {
              invalid_email: 'Required field <email> is invalid.',
              missing_email: 'Required field <email> is missing from request.',
              missing_name: 'Required field <name> is missing from request.',
              missing_level: 'Required field <level> is missing from request.'
            },
            popups: {
              save: 'Save changes?',
              create: 'Create user?',
              created: 'User created.',
              reset_pin: 'The reset was succesful! The user will receive an email with a new PIN code.',
              updated: 'User details have been updated.',
              delete: ' Delete this user?',
              removed: 'User removed.',
              reset_password: 'The reset was succesful! The user will receive an email with a new password.',
              send_pin: 'Send login PIN code for devices ?'
            }
          },
          pin_card: {
            header: 'PIN code ',
            video_header: 'When and how to use a PIN code',
            toggle: 'Send PIN code by email to login on the meeting room and desk devices',
            info: 'All new users and existing users that don’t have PIN code yet, will receive one.'
          },
          global_settings_card: {
            header: 'Global settings',
            info: 'These settings are applicable for all sites'
          },
          image_card: {
            header: 'Synchronise user images ',
            video_header: 'How to synchronise user images of Microsoft 365 with Tribeloo',
            toggle: 'Sync user images with Microsoft Office 365',
            info: 'User image is synchronised once a user logs into the Tribeloo Outlook add-in'
          },
          subject_card: {
            header: 'Hide reservation subject ',
            toggle: 'Hide reservation subject for colleagues',
            info: 'Reservation subject shown in "who`s around" will be hidden',
            video_header: ''
          }
        },
        users_table: {
          batch_edit: {
            header: 'Edit user details',
            sub_header: 'Update',
            items: {
              privileges: 'User group',
              level: 'User level',
              site: 'Home site',
              group: 'User group',
              rfid: 'Clear RFID for selected users'
            }
          },
          table: {
            toggle: 'Also show users from other sites',
            headers: {
              number: {
      	        popup: '',
                name: 'Number'
              },
              site: {
                popup: 'Home site',
                name: 'Home site'
              },
              home_site_privilege: {
                popup: 'Home site group',
                name: 'Home site group'
              },
              user: {
      	        popup: '',
                name: 'Name of user'
              },
              user_privilege: {
                popup: 'User group ',
                name: 'User group in '
              },
              assigned_resource: {
                popup: 'Assigned resource in ',
                name: 'Assigned resource in '
              },
              level: {
                popup: 'Level of user',
                name: 'Level of user'
              },
              group: {
                popup: 'Group',
                name: 'User group'
              },
              space: {
      	        popup: '',
                name: 'Resource'
              },
              Owned_site: {
                popup: 'Owned_site',
                name: 'Site of resource'
              },
              email: {
      	        popup: '',
                name: 'User email'
              },
              rfid: {
                popup: 'RFID',
                name: 'RFID'
              },
              override: {
                popup: 'Group assignment',
                name: 'Group assignment'
              }
            },
            tooltip: {
              delete: 'delete all selected users',
              send_mail: 'send message to selected users'
            },
            dialogues: {
              error_message: 'Error sending',
              email: 'Compose email for the selected users?',
              selected: 'At least one user must be selected.',
              delete: 'Delete this user?',
              rfid: 'RFID cleared for selected users',
              delete_all: [
                'Delete the ',
                ' selected users?'
              ]
            }
          }
        },
        groups: {
          header: 'Users groups',
          toggle_card: {
            header: 'Enable user groups ',
            video_header: 'How to set up user groups',
            toggle: 'User groups are enabled',
            description: [
              'When ',
              'enabled',
              ', you can create user groups and define rules for visible sites or office days for each group.',
              'Assign a user to a group in user management or user table.'
            ]
          },
          creat_edit_card: {
            list: {
              header: 'Existing users groups',
              button: 'Add new user group',
              placeholder: 'search',
              popups: {
                save: 'Save changes?',
                create: 'New user group is added.',
                update: 'Changes saved.',
                delete: {
                  text: 'Delete this user group?',
                  descriptions: [
                    'Users from this group will be assigned to the default user group of their home site.'
                  ]
                }
              }
            },
            form: {
              select_button: 'Select all',
              unselect_button: 'Unselect all',
              create: ' Create group',
              update: 'Save changes',
              delete: ' Delete group',
              header: [
                'Create user group',
                'Edit user group'
              ],
              name: {
                label: 'Name ',
                placeholder: 'Add name of user group'
              },
              rules: {
                tooltip: 'When visible sites rule is enabled, a user has access to the active sites of his group, and is able to book a resource for these sites. When visible sites rule is disabled, this user group has access to all active sites.',
                placeholder: 'Select visible sites',
                toggle: [
                  '‘Visible sites’ rule enabled',
                  '‘Visible sites’ rule disabled'
                ]
              },
              days: {
                tooltip: 'When office days rule is enabled, a user can make reservation only on the selected office days. When office days rule is disabled, this user group can make reservations on all days.',
                placeholder: 'Select office days',
                toggle: [
                  '‘Office days’ rule enabled',
                  '‘Office days’ rule disabled'
                ],
                week_days: [
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                  'Sunday'
                ]
              }
            },
            sites_card: {
              header: 'Default user group for each site',
              label: 'No home site ',
              tooltipe: 'This is the default group for users without a home site',
              placeholder: 'group',
              description_lines: [
                {
                  start: 'Users can be assigned to a specific group. In case a user doesn’t have a group assigned,',
                  end: 'the user will fall in the default group of his home site as defined below.'
                },
                {
                  start: 'In case the user isn’t assigned to a specific group and no default group is defined for his',
                  end: 'home site, the user will only have access to his home site to book resources.'
                }
              ]
            }
          }
        },
        sso: {
          header: 'Single Sign-On',
          description_card: {
            header: 'What is it?',
            description: 'Single Sign-On gives your users the ability to sign in to different applications using the same unique set of username and password. Users sign in once via the so-called Identity Provider (external to Tribeloo) and can then, from there access different apps (including Tribeloo) without logging in again. For SSO, Tribeloo supports the SAML 2.0 standard.'
          },
          email_card: {
            header: 'Enable welcome email',
            toggle: 'Enable sending welcome email if SSO is enabled'
          },
          settings_card: {
            header: 'Settings',
            items: {
              id: {
      	        tooltip: {
      	          header:'',
      	          descriptions: []
      	        },
                name: 'Company ID'
              },
              issuer_url: {
                name: 'Issuer URL',
                tooltip: {
                  header: 'Also referenced to as',
                  descriptions: [
                    'Okta: Identity Provider Issuer',
                    'OneLogin: Issuer URL',
                    'Azure: Azure AD Identifier',
                    'Google: Entity ID'
                  ]
                }
              },
              login_url: {
                name: 'SSO login URL',
                tooltip: {
                  header: 'Also referenced to as',
                  descriptions: [
                    'Okta: Identity Provider SSO URL',
                    'OneLogin: SAML Endpoint',
                    'Azure: Login URL',
                    'Google: SSO URL'
                  ]
                }
              },
              certificate: {
                name: 'Certificate',
                tooltip: {
                  header: 'Also referenced to as',
                  descriptions: [
                    'Okta: X.509 Certificate',
                    'OneLogin: X.509 Certificate',
                    'Azure: Certificate (Base64 or Raw)',
                    'Google: Certificate 1'
                  ]
                }
              }
            },
            button: [
              'Disable SSO',
              'Enable SSO'
            ]
          },
          popups: {
            saved: 'Configuration saved.',
            not_saved: 'Configuration not saved.',
            copied: 'Company ID copied.',
            change_sso: {
              text: [
                'Are you sure you want to disable Single Sign-On?',
                'Are you sure you want to enable Single Sign-On?'
              ],
              descriptions: [
                'All users will need to log in with a password, that they can request through ‘Forgot password?’ on the login page.',
                'Users won’t be able to log in with their passwords and will need to log in with their SSO credentials.'
              ]
            }
          }
        },
        provisioning: {
          header: 'User provisioning',
          description_card: {
            header: 'What is it?',
            description: 'User Provisioning refers to the automatic synchronization of users: people defined in your Active Directory (AD) will be automatically synchronized with the Users list in Tribeloo. E.g. new people in the AD will be added to the Users list in Tribeloo.'
          },
          settings_card: {
            header: 'Settings',
            items: [
              {
      	        is_input:true,
                name: 'SCIM Base URL',
                key: 'base_url'
              },
              {
      	        is_input:false,
                name: 'SCIM JSON Template',
                key: 'json_template'
              },
              {
      	        is_input: true,
                name: 'Custom headers',
                key: 'custom_headers'
              },
              {
      	        is_input: false,
                name: 'SCIM Bearer token',
                key: 'bearer_token'
              }
            ],
            button: [
              'Disable User provisioning',
              'Enable User provisioning'
            ],
            popups: {
              copy: ' copied',
              change_SCIM: {
                text: [
                  'Are you sure you want to disable User provisioning?',
                  'Are you sure you want to enable User provisioning?'
                ],
                description: [
                  'All the current users will be kept, but won’t be updated by your external user provisioning system anymore.',
                  'All users will be managed externally.'
                ]
              }
            }
          }
        }
      },
      insights: {
        navigation: {
          heatmaps: 'Heatmaps',
          utilization: 'Utilization charts',
          history: 'Reservation history',
          download: 'Data download',
          statistics: 'Statistics',
          tracker: 'Time tracking',
          calc: "Calc resource"
        },
        calc: {
          type: "Calc helpe",
          subHeader: "This page help you with a data about your price and amount of resources from 1 month to 5 years. Also it help you: if you want to change a count of recources, or move to new place, add or remove new site, wing and etc.",
          chartHeader: "Terms & summ",
          chartDescription: "Use top form to know how much you are spending for your resources"
        },
        heatmaps: {
          type: 'Heatmaps',
          subHeader: 'Which days and hours are most busy?',
          allText: 'Average utilization rate of selected type',
          daysText: 'Daily utilization rate of selected type',
          tooltipe: 'The average utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period. The daily utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period. For example, daily utilization of 80% and average utilization of 40% means that: 8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours. ',
          options: {
            average: 'Show average utilization rate',
            daily: 'Show daily utilization rate'
          }
        },
        utilization_charts: {
          type: 'Utilization charts',
          subHeader: 'Which days and hours are most busy?',
          allText: 'Average utilization rate of selected type',
          daysText: 'Daily utilization rate of selected type',
          table_header: 'Which days are most busy? Select a day to see the utilization by hour below for that day.',
          chart_header: 'Which hours are most busy'
        },
        reservation_history: {
          info: 'Changing time range removes all filters',
          overlap: 'Find overlapping reservations with ',
          couter: ' more...',
          headers: {
            number: {
      	      popup: '',
              name: 'Number'
            },
            user: {
              popup: 'Owners',
              name: 'User'
            },
            visitor: {
              popup: 'Visitor',
              name: 'Visitor'
            },
            email: {
      	      popup: '',
              name: 'User email'
            },
            from: {
      	      popup: '',
              name: 'From'
            },
            to: {
      	      popup: '',
              name: 'To'
            },
            wing: {
              popup: 'Wings',
              name: 'Wing'
            },
            space: {
              popup: 'Resources',
              name: 'Resource'
            },
            no_shows: {
              popup: 'No-shows',
              name: 'No-shows'
            },
            subject: {
      	      popup: '',
              name: 'Subject'
            },
            seats: {
              popup: 'Requested seats',
              name: 'Requested seats'
            },
            internal_external: {
              popup: 'internal/external',
              name: 'internal/ external'
            },
            status: {
              popup: 'Statuses',
              name: 'Status'
            },
            messages: {
              popup: 'Messages',
              name: 'Messages'
            },
            acknowledged: {
              popup: 'Acknowledged',
              name: 'Acknowledged'
            }
          },
          tooltip: {
            acknowledged: 'From reminder email: if the person clicked on the \'I agree\' button and therefore acknowledges they have read the message',
            send_mail: 'send message to all'
          },
          dialogues: {
            error_message: 'Error sending'
          }
        },
        time_tracking: {
          check: 'events',
          info: 'Changing time range removes all filters',
          info_date: 'Find overlapping reservations with ',
          headers: {
            user: {
              popup: 'User',
              name: 'User'
            },
            email: {
              popup: 'User email',
              name: 'Email'
            },
            from: {
      	      popup: '',
              name: 'From'
            },
            to: {
      	      popup: '',
              name: 'To'
            },
            zone: {
      	      popup: '',
              name: 'Time zone'
            },
            period: {
      	      popup: '',
              name: 'Time period'
            }
          },
          dialogues: {
            error_message: 'Error sending'
          }
        },
        data_download: {
          header: 'Data download',
          popup: {
            big_titel: 'Update data retention?',
            big_descriptions: [
              'Older reservation data will be deleted and no longer accessible'
            ]
          },
          retention_card: {
            header: 'Data retention',
            info: 'There is a minimum of 7 days and a maximum of 400 days.',
            toggle: [
              'Reservations & releases data is automatically deleted after',
              'days.'
            ]
          },
          automade: {
            header: 'Daily automated report via email',
            sub_header: 'Receive data of the reservations & releases of the previous day',
            check: 'Send daily email to',
            placeholder: 'Email address',
            error: 'Please enter a valid email address'
          },
          download: {
            header: 'Download data for a specific time range',
            info: 'Data of reservations & releases',
            button: {
              all: ' Download all',
              one: ' Download data range'
            }
          }
        },
        statistics: {
          header: 'Statistics',
          sub_header: 'The table shows you a current view of all the sites and their amount of resources and users.',
          card: {
            header: 'Resources and users',
            button: 'Download',
            table_headers: {
              type: 'Resource type',
              resources: 'Resources',
              users: 'Users'
            }
          }
        },
        reusable_components: {
          scale_utilization_rate: 'Scale utilization rate',
          main_header: {
            header: 'Insights',
            day: ' Move timeframe per day ',
            week: ' Move timeframe per week '
          },
          card_table: {
            table_headers: {
              special: 'utilization rate',
              average: 'Average',
              daily: 'Daily'
            },
            description_hightlight: [
              {
                start: 'The ',
                hightlight: 'average',
                end: ' utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period.'
              },
              {
                start: 'The ',
                hightlight: 'daily',
                end: ' utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period.'
              }
            ],
            description_example: [
              '  For example, daily utilization of 80% and average utilization of 40% means that: ',
              '8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours.'
            ]
          },
          maps_cards: {
            owner: 'Owner: ',
            usage: 'Usage',
            average: 'average: ',
            daily: 'daily: ',
            days: 'days active: ',
            button: {
              select: 'Select',
              show_all: 'Show all'
            },
            internal_external: [
              ', Internal only',
              ', External allowed'
            ]
          }
        }
      },
      space_issues: {
        navigation: [
          'Issue management',
          'Cleaning',
          'Issues table'
        ],
        list: {
          main_header: 'Select a site',
          main_sub_header: 'Find resource',
          placeholder: 'Please search or select a site',
          placeholder_asset: 'Please search or select a resource',
          search: 'View all site issues',
          img_link: 'See photo',
          popup_text: 'E-mail sent to your account with information about the issue. This can be forwarded to somebody that can solve it.',
          toggle: [
            'Click to show closed issues',
            'Click to show open issues'
          ],
          couter: [
            ' open resource issues',
            ' closed resource issues'
          ]
        },
        table_cleaning: {
          main_header_warning: 'Changing time range removes all filters',
          table_headers: {
            id: 'ID',
            data_time: 'Date & time',
            wing: 'Wing',
            resource: 'Resource',
            cleaned_at: 'Cleaned at',
            cleaned_by: 'Cleaned by'
          }
        }
      }
    },
    user: {
      external_events_list: {
        headers: [
          'Google Events',
          'Outlook Events'
        ],
        select: {
          header: 'Organisiert von',
          placeholder: 'Kollegen auswählen'
        },
        info: [
          'Keine Reservierungen aus Ihrem Kalender verfügbar',
          'Nur Tribeloo Reservierungen anzeigen',
          'Ausgewählter Event'
        ]
      },
      find_colleague: {
        email_select: {
          more: "mehr..."
        },
        user_select: {
          header: 'Einen Kollegen finden',
          placeholder: 'Einen Kollegen auswählen',
          info: [
            'Zugriff von Ihren Kollegen aus … anfordern',
            'Mein Stamm',
            ' um Ihre Reservierungen zu sehen'
          ]
        },
        list: {
          count: ' (nicht privat)',
          info: [
            'Einen Kollegen auswählen um seine Reservierungen zu sehen',
            'Auf eine Reservierung klicken um diese in der Karte zu sehen.'
          ]
        },
        details: {
          return_button: 'Zurück zur Liste der Reservierungen',
          info: 'Klicken Sie auf ein Objekt in der Karte um eine Reservierung für sich zu erstellen',
          modals: {
            delete_reservation: 'Reservierung gelöscht',
            create_reservation: 'Neue Reservierung anlegen?'
          }
        }
      },
      find_room_report: {
        loading: 'Lade…',
        image_description: 'Bild (< 4 MB)',
        image_info: 'Bild (< 4 MB)',
        couter: ' ungelöste Probleme',
        headers: {
          main: 'Ein Problem melden',
          site: 'Einen Standort auswählen',
          resource: 'Ein Objekt finden'
        },
        placeholders: {
          site: 'Bitte einen Standort suchen oder auswählen',
          resource: 'Bitte ein Objekt suchen oder auswählen',
          tooltip: 'Wenn Sie ein Problem melden, wird es dem Standortadministrator geschickt. Sobald es gelöst ist, verschwindet es aus dieser Liste.',
          description: [
            'Zum Melden eines Problemes bitte ein Objekt auswählen',
            'Bitte das Problem erläuertn'
          ]
        },
        button: {
          photo: 'Bild hinzufügen',
          send: 'Problem abschicken'
        }
      },
      releases_list: {
        add_button: 'Eine Abwesenheit hinzufügen',
        user_select: 'Abwesenheiten für',
        colleagues_info: 'Sie haben keine Kollegen mit sichtbaren Abwesenheiten',
        counter: 'Keine Abwesenheiten',
        right_panel_info: [
          'Wählen Sie einen Kollegen um seine Abwesenheiten anzuzeigen',
          'Sie haben keine Kollegen mit sichtbaren Abwesenheiten'
        ]
      },
      release: {
        pageName: 'Abwesenheit',
        header: 'Details zur Abwesenheit',
        placeholder: 'Betreff',
        buttons: {
          create: [
            'Ihre … freigeben',
            'Abwesenheit bestätigen'
          ],
          edit: [
            'Diese Abwesenheit löschen?',
            'Abwesenheit löschen'
          ]
        },
        description: [
          'Es gibt',
          'keine',
          'aktiven Reservierungen für dieses',
          ' in diesem Zeitraum.'
        ],
        modals: {
          user: 'Benutzer wechseln? Die aktuelle Abwesenheit wird storniert.',
          not_available: 'Abwesenheit nicht verfügbar',
          delete: 'Diese Abwesenheit löschen?'
        }
      },
      reservations_list: {
        add_button: 'Neue Reservierung anlegen',
        user_select: 'Reservierungen für',
        colleagues_info: 'Sie haben keine Kollegen mit sichtbaren Abwesenheiten',
        counter: 'Keine Reservierungen',
        tabs: [
          {
            name: 'Persönlich',
            state: false
          },
          {
            name: 'Für Besucher',
            state: true
          }
        ],
        right_panel_info: [
          'Wählen Sie einen Kollegen um seine Reservierungen anzuzeigen',
          'Eine Reservierung aktualisieren oder eine neue anlegen um die verfügbaren Objekte zu sehen'
        ]
      },
      reservation: {
        pageName: 'Reservierungen',
        details: 'Details',
        end: 'Reservierung beenden',
        amenities_item: 'andere Anfrage',
        header_requests: [
          'Bisher noch keine Reservierung vorhanden.',
          'Füllen Sie die Details der Reservierung aus und überprüfen Sie die Verfügbarkeit.'
        ],
        snack_bars: {
          create: 'Reservierung angelegt',
          update: 'Reservierung aktualisiert'
        },
        visitor: {
          text: 'Reservierung für einen Besucher'
        },
        placeholders: {
          visitor: 'E-Mail Adresse',
          amenities: 'Auswahl der Ressourcen',
          no_amenities: 'Keine Ressourcen gefunden',
          subject: 'Thema',
          neighbourhood: 'Wählen Sie die Umgebung'
        },
        public: {
          text: 'Private Reservierung',
          tooltip: 'Ihre Reservierung ist nur für die Kollegen sichtbar, die Sie in den Einstellungen festgelegt haben.'
        },
        right_panel_info: {
          no_checked_availability: [
            'Füllen Sie die Details der Reservierung aus und',
            'überprüfen Sie die Verfügbarkeit'
          ],
          checked_availability: [
            'Die Details haben sich verändert,',
            'prüfen Sie erneut die Verfügbarkeit'
          ]
        },
        modals: {
          error_404: 'Reservierung nicht aufrufbar',
          invalid_email: 'Sie müssen eine gültige E-Mail des Besuchers eingeben oder den Besucherschalter deaktivieren um eine Reservierung anzulegen.',
          amenity: {
            text: 'Ihre aktuelle Reservierung enthält nicht alle vorausgesetzten Ressourcen!',
            descriptions: [
              'Kein Problem, Sie können mit der aktuellen Reservierung fortfahren.'
            ]
          },
          requested_capacity: {
            text: 'Der von Ihnen gewählt Raum hat nicht ausreichend Sitze!',
            descriptions: [
              'Suchen Sie weiter nach einem anderen Raum mit der entsprechenden Sitzanzahl.',
              'Oder verwerfen Sie den Sitzplatzwechsel und behalten den ausgewählten Platz.'
            ]
          },
          internal: {
            text: 'Der von Ihnen gewählt Raum ist nur für interne Meetings vorgesehen!',
            descriptions: [
              'Suchen Sie weiter nach einem neuen Raum.',
              'Oder verwerfen Sie den Wechsel und behalten den ausgewählten Raum.'
            ]
          },
          time: {
            text: 'Ihre Reservierung ist in diesem Zeitraum nicht verfügbar!',
            descriptions: [
              'Fahren Sie fort um den neuen Zeitraum zu behalten. Die Reservierung wird aktualisiert.',
              'Oder verwerfen Sie die Zeitänderung und behalten die aktuelle Reservierung.'
            ]
          },
          space: {
            text: 'Der zugewiesene Raum ist nur für interne Meetings!',
            descriptions: [
              'Fahren Sie fort um die Änderung zu behalten und nach einem neuen Raum zu suchen.',
              'Oder verwerfen Sie die Änderung und behalten damit den aktuellen Raum.'
            ]
          },
          delete: {
            simple: {
              text: 'Diese Reservierung löschen?'
            },
            ongoing: {
              text: 'Diese Reservierung beenden?'
            },
            visitor_change: {
              text: {
                toggle: 'Ein Wechsel von der persönlichen auf eine Besucherreservierung wird die aktuelle Reservierung löschen!',
                email: 'Ein Wechsel der E-Mail-Adresse des Besuchers wird die aktuelle Reservierung löschen!'
              },
              descriptions: [
                'Fahren Sie fort um die Änderungen zu behalten. Die Reservierung wird aktualisiert.',
                'Oder verwerfen Sie die Änderung und behalten damit die aktuelle Reservierung.'
              ]
            },
            user_change: {
              text: 'Ein Wechsel des Benutzers wird die aktuelle Reservierung löschen!',
              descriptions: [
                'Fahren Sie fort um den neuen Benutzer zu belassen. Die Reservierung wird aktualisiert.',
                'Oder verwerfen Sie die Änderung und behalten damit die aktuelle Reservierung.'
              ]
            },
            site_change: {
              text: 'Den Ort zu wechseln wird ihre aktuelle Reservierung löschen!',
              descriptions: [
                'Fahren Sie fort um den neuen Ort zu behalten. Die Reservierung wird aktualisiert.',
                'Oder verwerfen Sie die Änderung und behalten damit die aktuelle Reservierung.'
              ]
            }
          }
        }
      },
      home: {
        nwesfeed_list: 'Keine Nachrichten heute!',
        pageName: 'Start',
        newsfeed_header: 'Nachrichtenfeed',
        emergency: {
          header: 'DRINGENDE MITTEILUNG',
          by: 'durch'
        },
        reservation: {
          loading: 'Lade …',
          headers: {
            upcoming: 'Ihre nächste Reservierung',
            current: 'Ihre aktuelle Reservierung',
            no_upcoming: 'Keine zukünftigen Reservierungen vorhanden'
          },
          descriptions: [
            'Wir sind da um Ihnen zu helfen!',
            'Treffen Sie Ihre Wahl mit den Schaltflächen unten.'
          ]
        }
      },
      settings: {
        pageName: 'Einstellungen',
        h1: 'Benutzereinstellungen',
        password: {
          errors: ["Mindestens 1 Zahl, 1 Symbol und 8 Zeichen lang", 'Passwörter stimmen nicht überein'],
          rules: 'Passwort muss mindestens enthalten: 8 Zeichen, 1 Großbuchstaben, 1 Kleinbuchstaben, 1 Ziffer',
          rules_v2: 'Muss mindestens enthalten: 8 Zeichen, 1 Großbuchstaben, 1 Kleinbuchstaben, 1 Ziffer',
          placeholders: ['Neues Passwort eingeben', 'Neues Passwort erneut eingeben'],
          info_ok:"Passwort wurde geändert",
          info_not_ok: "Das Passwort wurde nicht geändert"
        },
        h3_sections: {
          password: 'Passwort ändern',
          image: 'Benutzerbild hinzufügen',
          site: 'Standardstandort für Reservierung',
          wing: 'Standardflügel für Reservierung',
          time: 'Zeitformat',
          language: 'Sprache',
          preferences: 'Einstellungen',
          preferences_v2: 'Benutzereinstellungen',
          delegate: 'Delegierter',
          calendar: 'Kalendereinladungen',
          wants_asset_details_popup: 'Karteninformationen'
        },
        subsections: {
          app: "Version",
          sites: "Sichtbare Standorte",
          language: "Sprache",
          time: "Zeitformat",
          calendar: "Kalendereinladungen"
        },
        user_image_info: ['Bitte kontaktieren Sie Ihr Büro ', 'Manager, um das Benutzerbild zu ändern'],
        user_image: ['Benutzerbild', '(quadratische Größe, weniger als 4 MB)', 'Laden Sie ein quadratisches Bild mit einer Größe von weniger als 4 MB hoch'],
        toggle: '24-Stunden-Zeitformat verwenden',
        app_version: 'Neue Version verwenden (Neustart erforderlich)',
        calendar_toggle: 'Kalendereinladungen für Reservierungen aus der Webanwendung senden',
        wants_asset_details_popup_toggle: 'Details-Popup beim Auswählen einer Ressource auf der Karte anzeigen',
        no_delegates: 'Keine Delegierten',
        delegates_action_list: {
          search: 'Suche',
          edit: 'Kollegen, die meine Reservierungen erstellen oder bearbeiten können',
          add: 'Kollegen hinzufügen'
        },
        delegates_modal: "Durch Ankreuzen dieses Kästchens wird die folgende Tabelle verworfen und jeder wird Ihre öffentlichen Meetings sehen können."
      },
      who_is_arround: {
        header: 'Wer ist anwesend',
        tooltip: 'Prüfen, wer aus Ihrem Stamm ist zu einer bestimmten Stunde bei der Arbeit. Wenn noch niemand zu Ihrem Stamm gehört, legen Sie einen unter \'Mein Stamm\' an.',
        info: 'Wählen Sie ein Objekt aus der Karte um eine Reservierung anzulegen',
        right_panel_info: 'Wählen Sie einen Ort um die Verfügbarkeit zu sehen',
        modal: 'Reservierung anlegen?',
        date_time: {
          main: 'Datum & Zeit',
          helper: 'STUNDE'
        },
        users_list: {
          placeholder: 'Benutzername',
          header: {
            hide: 'Verstecken',
            show: 'Anzeigen',
            postfix: ' Liste der Kollegen'
          }
        }
      },
      my_tribe: {
        header: 'Mein Stamm',
        serach_header: 'Suchergebnisse',
        no_results: 'Nichts gefunden',
        tabs: [
          'Follower',
          'Ich folge'
        ],
        info: [
          'Kollegen, die meine öffentlichen Reservierungen sehen können',
          'Anfragen mir zu folgen',
          'Kollegen, deren öffentlichen Reservierungen ich sehen kann'
        ],
        placeholders: [
          'Suche',
          'Finden Sie Kollegen um Ihnen zu folgen'
        ],
        modals: {
          confirm: {
            text: 'Wollen Sie diesem Kollegen ebenfalls folgen?',
            cancel: 'Nicht mehr folgen',
            confirm: 'Folgen'
          },
          delete: {
            text: 'Wollen Sie diese Anforderung dieses Kollegen löschen?',
            cancel: 'Abbrechen',
            confirm: 'Anforderung löschen'
          },
          remove: {
            text: 'Wollen Sie diesen Kollegen löschen?',
            cancel: 'Abbrechen',
            confirm: 'Löschen'
          },
          cancel: {
            text: 'Wollen Sie Ihre Anforderung abbrechen?',
            cancel: 'Nein',
            confirm: 'Anforderung abbrechen'
          },
          unfollow: {
            text: 'Wollen Sie diesem Kollegen nicht mehr folgen?',
            cancel: 'Abbrechen',
            confirm: 'Nicht mehr folgen',
            description: [
              'Wenn Sie Ihre Meinung ändern, müssen Sie erneut anfragen.'
            ]
          }
        }
      },
      squads: {
        header: 'Meine Arbeitsgruppen'
      },
      qr: {
        header: 'QR check-in oder walk-in',
        checkout: 'Wollen Sie auschecken?',
        checkedout: 'Ausgecheckt',
        photo_description: 'Scannen Sie den QR Code um für Ihre Reservierung einzuchecken oder um einen walk-in Reservierung anzulegen',
        start_scan_description: 'Zeigen Sie auf den QR Code und berühren Sie diese Stelle',
        errors: {
          camera: 'Kamera wird in Chrome nicht unterstützt, bitte verwenden Sie Safari',
          app: 'Bitte verwenden Sie die spezielle QR-Scanner Anwendung!',
          device: 'Bitte verwenden Sie ein Smartphone oder Tablet mit rückseitiger Kamera!'
        },
        modals: {
          info: 'Zugriff auf die Kamera ist nötig zum Scannen des QR Codes.',
          check_in: {
            header: 'Eingecheckt für:',
            subject_default: 'Reservierung',
            from_to: [
              'Von:',
              ', bis'
            ]
          }
        }
      },
      help_center: {
        nav: {
          demo: {
            header: 'Kurze Demo',
            card: {
      	      headers: [],
              descriptions: [
                'Schauen Sie sich dieses kurze Video an um eine Vorstellung aller Funktionen von Tribeloo zu erhalten.'
              ],
              links: []
            }
          },
          get_started: {
            header: 'Beginnen',
            sub_headers: [
              'Konfigurationsvideo',
              'Singe-Sign-On (SSO)',
              'Einrichten von Benutzern'
            ],
            card: {
      	      headers: [],
              descriptions: [
                'Lernen Sie, wie Sie Tribeloo verwenden können um die Erstkonfiguration durchzuführen.',
                'Lesen Sie die Anleitung zur Einrichtung von Single-Sign-On (SSO)',
                'Lesen Sie die Anleitung zum Hinzufügen von neuen Benutzern'
              ],
              links: [
                'hier.'
              ]
            }
          },
          site_manager: {
            header: 'Standortverantwortlicher',
            sub_headers: [
              'Schritt-für-Schritt Video',
              'Geräte konfigurieren'
            ],
            card: {
              headers: [
                'Geräte konfigurieren'
              ],
              descriptions: [
                'Lernen Sie, wie Sie Tribeloo als Büroverantwortlicher verwenden.',
                'Lernen Sie, wie Sie die Geräte in den Büros und Meetingräumen einrichten können.'
              ],
      	      links:[]
            }
          },
          user: {
            header: 'Benutzer',
            sub_headers: [
              'Schritt-für-Schritt Video',
              'Outlook add-in verwenden',
              'Outlook add-in installieren',
              'Chrome-Erweiterung verwenden',
              'Chrome-Erweiterung installieren',
              'App auf dem iPhone installieren',
              'App auf Android installieren'
            ],
            card: {
              mobile: 'Lernen Sie die App und die Tribeloo-Webseite zu nutzen',
              outlook: {
                headers: [
                  'Wie man das Outlook add-in verwendet',
                  'Wie man das Outlook add-in installiert'
                ],
                descriptions: [
                  'Lernen Sie das Tribeloo Outlook add-in zu verwenden',
                  'Lernen Sie wie man das Tribeloo Outlook add-in installiert',
                  'Besuchen Sie den',
                  'Microsoft Store',
                  ' um es herunterzuladen.'
                ]
              },
              google: {
                headers: [
                  'Wie man die Erweiterung für Chrome verwendet',
                  'Wie man die Erweiterung für Chrome installiert'
                ],
                descriptions: [
                  'Lernen Sie die Chrome-Erweiterung und die Google-Kalender-Integration zu nutzen',
                  'Lernen Sie die Chrome-Erweiterung, zur Integration in Ihren Google-Kalender, zu installieren',
                  'Besuchen Sie den',
                  'Chrome store',
                  ' um diese herunterzuladen.'
                ]
              },
              pwa: {
      	        headers:[],
                descriptions: [
                  'Schauen Sie sich das Video unten an oder',
                  'laden Sie das PDF herunter',
                  ' um zu Lernen wie man die Tribeloo-App auf Ihrem iPhone installiert',
                  ' um zu Lernen wie man die Tribeloo-App auf Ihrem Android-Smartphone installiert'
                ]
              }
            }
          },
          feedback: {
            header: 'Uns kontaktieren',
            sub_headers: [],
            card: {
              headers: [
                'Sie finden nicht, wonach Sie suchen? Wollen Sie uns Feedback geben?'
              ],
              descriptions: [
                'Helfen Sie uns Ihnen zu helfen! Geben Sie uns so viele Details wie möglich, damit wir Ihnen so schnell wie möglich helfen können.'
              ],
      	      links:[]
            }
          }
        },
        main: {
          header: 'Hilfezentrum',
          subheaders: [
            'Willkommen!',
            'Hier finden Sie nützliche Informationen um schnell mit Tribeloo beginnen zu können.',
            'Sie können jederzeit hierher zurückkehren, indem Sie auf \'Hilfezentrum\' auf der Startseite oder in der Konfiguration klicken.'
          ],
          orange_card: [
            'Klicken Sie auf die Symbole',
            ' in der Anwendung um mehr Infos zu einem spezifischen Thema zu erhalten.'
          ]
        }
      }
    }
  },
  shared_components: {
    wings_select: 'Alle Flügel',
    icon_video: 'Mehr Infos',
    loading_table_spinner: 'lade die Daten',
    reservation_tipe_modal: 'Es können keinen weiteren Optionen hinzugefügt werden, bitte ändern Sie die bestehende Reservierung',
    site_wings_reservation_view_external_data_set: 'Klicken Sie um in diesen Flügel zu betreten',
    assets_map: {
      owner: 'Zugewiesen an:',
      time_slots: 'Verfügbar:',
      no_time_slots: 'Keine Zeitslots verfügbar',
      not_reservable: 'Sie können diese Ressource nicht buchen',
      types_external_internal: [
        ', nur intern',
        ', Externe erlaubt'
      ]
    },
    attendees_modal: {
      more: ' mehr…',
      send: 'Abschicken',
      send_all: 'Allen eine E-Mail schicken',
      counter: 'Teilnehmer',
      header_text: {
        share: 'Teile dies mit Deinen Kollegen',
        attendees: 'nehmen an diesem Meeting teil'
      },
      info: [
        'Wählen Sie Kollegen aus der Liste aus um ihnen eine E-Mail zu schicken.',
        'Bitte bitten Sie zuerst Ihre Kollegen Sie zu Ihrem Stamm hinzuzufügen '
      ],
      placeholdrs: {
        colleagues: 'Kollege(n) auswählen',
        message: 'Schreiben Sie hier den gewünschten Text'
      },
      button_attendees: [
        'Nehmen Sie nicht teil?',
        'Nehmen Sie teil?'
      ]
    },
    total_counter_download_table_csv: {
      batch_buton: 'Anpassung im Batch',
      button: 'Herunterladen',
      text: ' Gesamt',
      selected: ' ausgewählt',
      info: 'Keine Daten für die gewählten Parameter verfügbar'
    },
    date_time_range_modal: {
      tooltip: 'Klicken um den Kalender anzuzeigen',
      info: 'Die Endzeit muss nach der Startzeit liegen',
      headers: {
        main: 'Datum & Zeit auswählen',
        from: 'Von',
        to: 'bis'
      }
    },
    date_time_range_view: {
      headers: {
        from: 'Von',
        to: 'bis',
        start: 'Anfang',
        end: 'Ende'
      }
    },
    day_time_mini: {
      header: [
        'Set',
        ' Datum & Uhrzeit'
      ],
      tooltip: {
        calendar: 'Klicken um den Kalender anzuzeigen',
        time: 'Klicken um die Uhrzeit anzuzeigen'
      }
    },
    expanded_filter_assets_list: {
      header: ' Liste der Verfügbarkeiten',
      description: 'Nur mit allen Assets',
      nospace: 'Nichts verfügbar',
      placeholder: 'Suche',
      tooltip: 'Der Raum enthält nicht alle gewählten Assets',
      modal: {
        header: 'Infos zu den Ressourcen',
        report_button: 'Ein Problem melden'
      },
      types_external_internal: [
        ', intern',
        ', extern'
      ]
    },
    kiosk_logout_info_modal: {
      message: 'Arbeiten Sie immer noch hier?',
      info: [
        'Ihre Session wird enden in',
        ' Sekunden.'
      ]
    },
    newsfeed_create_edit_item: {
      popup: 'Nicht gespeicherte Änderungen verwerfen?',
      markdown: 'Markdown wird unterstützt',
      link: 'Richtlinien überprüfen',
      image_info: 'Bild (< 4 MB, Größe 335 x 75 px)',
      radio: [
        'Typ:',
        'News',
        'Event'
      ],
      placeholder: {
        event: 'Neuer Event',
        location: 'Ort'
      }
    },
    newsfeed_item: {
      actions: [
        'mehr',
        'schließen'
      ],
      default_subject: '',
    },
    reservation_header_info: {
      info: 'Verfügbarkeit zur Zuweisung prüfen',
      state: [
        'Laufende Reservierung',
        'Zugewiesen'
      ],
      internal_external: [
        ', intern',
        ', extern'
      ]
    },
    site_select: {
      placeholder: 'Standort auswählen',
      tooltip: 'Hier klicken um den Standort auszuwählen',
      options: [
        'Alle Standorte',
        'Kein Standort vorhanden'
      ]
    },
    activity_select: {},
    confirm_dialog: {},
    download_xlsx: {},
    filter_dialog: {},
    info_dialog: {},
    site_dialog: {},
    table_datetime_range_helper: {},
    time_range_helper: {}
  },
  user_shared_components: {
    capcity_internal_block: {
      placeholder: 'Sitze',
      tooltip: 'Externes Meeting: Mit externen Mitarbeitern. Internes Meeting: Nur mit internen Mitarbeitern.',
      select: [
        'Einfacher Raum',
        'Internes Meeting',
        'Externes Meeting',
        'Raum'
      ]
    },
    list_item: {
      status: 'freigegeben',
      header: 'Ihre',
      internal_external: [
        ', intern',
        ', extern'
      ],
      info_reservation: [
        'Offene Reservierung, Sie erhalten demnächst einen Platz.',
        'Oder prüfen Sie die Verfügbarkeit um selbst einen Platz auszuwählen.'
      ],
      subject: [
        'Neue Abwesenheit',
        'Neue Reservierung'
      ]
    },
    message_details: {
      toggle: 'Eine Anfrage hinzufügen',
      placeholder: 'Schicken Sie eine Anfrage an den Verwaltungsverantwortlichen, wenn Sie eine zusätzliche Frage haben',
      info: [
        'Anfrage noch nicht bearbeitet',
        'Anfrage bearbeitet'
      ]
    },
    weeks_repeater: {
      title: ' Wöchentlich wiederholen bis',
      weekdaysShort: [
        'Son',
        'Mon',
        'Die',
        'Mit',
        'Don',
        'Fre',
        'Sam'
      ]
    },
    repeater_modal: {
      header: 'Individuelle Wiederholdung',
      repeat_until: 'Wiederholen bis',
      repeat_on: 'Wiederholen am'
    }
  },
  reusable_data: {
    time_tracking_popup: {
      big_titel: 'Sie haben gearbeitet für',
      descriptions: [
        'Möchten Sie Ihren Arbeitstag beenden?'
      ]
    },
    tables: {
      from: 'Von',
      to: 'Bis',
      date_time: 'Datum & Zeit'
    },
    feedback: {
      header: 'Feedback geben',
      issue_text: 'Beim Abschicken Ihres Feedbacks ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. Vielen Dank!',
      placeholders: [
        'Art des Feedbacks auswählen',
        'Eine Beschreibung hinzufügen'
      ],
      types: [
        'Fehler',
        'Vorschlag',
        'Frage',
        'andere Anfrage'
      ],
      buttons: [
        'Abbrechen',
        ' Abschicken'
      ]
    },
    user_navigation: {
      no_actions_nav: {
        home: 'Startseite',
        my_tribe: 'Mein Stamm',
        squads: 'Meine Arbeitsgruppen',
        outlook_events: 'Outlook Events',
        google_events: 'Google Events',
        qr: 'QR check-in oder walk-in',
        reservations: 'Reservierungen verwalten',
        releases: 'Abwesenheiten verwalten',
        who_is_arround: 'Wer ist anwesend?',
        find_colleague: 'Kollegen finden',
        report_issue: 'Problem melden',
        user_settings: 'Benutzereinstellungen',
        help_center: 'Hilfezentrum',
        tracker: [
          'Beginn der Arbeit,',
          'Ende der Arbeit,'
        ]
      },
      actions_nav: {
        book_desk: 'Einen Schreibtisch reservieren',
        book_meeting_room: 'Einen Meetingraum reservieren',
        add_new_release: 'Neue Abwesenheit'
      }
    },
    button: {
      ok: 'OK',
      add: 'Hinzufügen',
      show: 'Anzeigen',
      hide: 'Verstecken',
      send: 'Abschicken',
      save: 'Speichern',
      edit: 'Editieren',
      more: 'Mehr',
      close: 'Schließen',
      email: 'E-Mail',
      login: 'Einloggen',
      logout: 'Abmelden',
      check_in: 'Check in',
      submit: 'Abschicken',
      delete: 'Löschen',
      select: 'Auswählen',
      cancel: 'Abbrechen',
      upload: 'Hochladen',
      remove: 'Entfernen',
      reopen: 'Wiedereröffnen',
      comment: 'Kommentar hinzufügen',
      confirm: 'Bestätigen',
      inverse: 'Invertieren',
      refresh: 'Neu laden',
      continue: 'Weiter',
      download: 'Herunterladen',
      show_all: 'Alle anzeigen',
      checked_in: 'Eingecheckt',
      select_all: 'Alle auswählen',
      batch_edit: 'Änderung im Batch',
      upload_image: 'Bild hochladen',
      remove_image: 'Bild Entfernen',
      assign_me_one: 'Mir zuweisen',
      back_to_dashboard: '< Zurück zum Dashboard',
      cancel_reservation: 'Reservierung stornieren',
      check_availability: 'Verfügbarkeit prüfen',
      retutn_releases_list: 'Zurück zur Liste der Abwesenheiten',
      retutn_reservations_list: 'Zurück zur Liste der Reservierungen',
      retutn_reservations_releases_events_list: [
        'Zurück zur Liste der Reservierungen',
        ' Events'
      ]
    },
    release_reservation_text: {
      outlook: 'Zeit im Outlook-Kalender ändern',
      google: 'Zeit im Google-Kalender ändern'
    }
  },
  v2_shared_components: {
    calc: {
      sum: "Total summ: ",
      count: "count: ",
      price: "price: "
    },
    tribeloo_asset_info: {
      user_info: "Dir zugewiesen",
      user_release_info: "Dieses Ressource wurde dir zugewiesen. Buche es, wenn du es nutzen möchtest.",
      user_booked_info: "Diese Ressource wurde dir zugewiesen und steht dir jederzeit zur Verfügung.",
      invited_users: "eingeladene Kollegen und Thema",
      time_slot: "Heute nicht verfügbar",
      buttons: {
        invite: 'Kollegen einladen',
        book: 'Buchung bestätigen',
        update: 'Änderungen bestätigen',
        delete: 'Buchung abbrechen',
        edit: 'Buchung bearbeiten'
      }
    },
    tribeloo_asset_list: {
      headers: {
        menu: "Tribeloo-Tools",
        favorites: "Favoriten"
      },
      filter: {
        search: "Suchen",
        all: "Alle",
        users_only: "Nur Benutzers",
        resources_only: "Nur Ressourcen"
      },
      menu_items: {
        colleagues: {
          subtitle: "Einen Kollegen finden",
          description: "Arbeite einfach mit deinen Kollegen zusammen"
        },
        bookings: {
          subtitle: "Meine Buchungen",
          description: "Übersicht über alle deine Buchungen"
        },
        releases: {
          subtitle: "Freigaben",
          description: "Gib deinen festen Arbeitsplatz für andere frei"
        },
        qr: {
          subtitle: "QR-Scanner",
          description: "Buche oder checke mit dem QR-Scanner ein"
        }
      }
    },
    tribeloo_asset_list_item: {
      check_in: ["Eingecheckt", "Einchecken"]
    },
    tribeloo_asset_release_popper: {
      buttons: {
        create: "Freigabe erstellen",
        cancel: "Freigabe abbrechen"
      }
    },
    tribeloo_assets_popper: {
      title: "Verfügbare Ressourcen"
    },
    tribeloo_component_filter: {
      title: "Filteroptionen",
      date_range: ["Datumsbereich", "Von", "Bis"],
      type: "Ressourcentyp",
      location: "Ort"
    },
    tribeloo_bookings_list: {
      subtitle: ['Kollegen finden', 'Meine Buchungen'],
      description: ['Suche nach Kollegen, um ihre Buchungen zu finden und die Zusammenarbeit zu optimieren', 'Übersicht über alle deine Buchungen. Verwalte alle deine Buchungen an einem Ort.'],
      search: " Suchergebnisse.",
      not_found: "Keine Kollegen gefunden."
    },
    tribeloo_camera: {
      camera_error_message: "Bitte Zugriff auf die Kamera gewähren",
      camera_not_found: "Keine Kamera gefunden."
    },
    tribeloo_date_picker: {
      max_selection_info: "Maximale Auswahl für mehrere Tage erreicht",
      days_selected_text: "Tage ausgewählt",
      toggle_text_info: ["Mehrtägige Auswahl ", "Mehrtägige Auswahl"]
    },
    tribeloo_download_image: {
      info_dialogue: "Dein Bild ist zu groß. Bitte wähle ein Bild mit einer Größe von weniger als 4 MB aus."
    },
    tribeloo_edit_invites: {
      title: "Einladung zur Buchung bearbeiten",
      subtitle: "Eine E-Mail wird nur an neu hinzugefügte Kollegen gesendet.",
      buttons: {
        main_invite: "Kollegen einladen",
        send_invite: ['Einladung erneut senden', 'Einladung senden']
      },
      placeholder: "Einladungen aktualisiert"
    },
    tribeloo_favorites_list_item: {
      other_info: " Aktive Buchungen"
    },
    tribeloo_map_legend: {
      button: "Karte zentrieren",
      map_legend_title: "Legende",
      map_legend_items: {
        available: "Verfügbar",
        confirm: "Bestätigung erforderlich",
        not_for_user: "Nicht für Benutzer",
        unavailable: "Nicht verfügbar"
      }
    },
    tribeloo_qr_scanner: {
      title: "QR-Code scannen",
      subtitle: "Platziere den QR-Code vor die Kamera, um zu buchen oder einzuchecken.",
      error_message: "Fehler beim Scannen des QR-Codes",
      processing_message: "Dekodierung des Scans läuft"
    },
    tribeloo_releases_sidebar: {
      title_resources: "Meine Ressourcen",
      title_releases: "Meine Freigaben",
      subtitle: "Freigaben",
      description: "Konfiguriere die Zeit, das Datum und wähle die Ressource aus, die du freigeben möchtest.",
    },
    tribeloo_type_location_neighbourhood: {
      labels: {
        type: "Ressourcentyp",
        site: "Standort",
        wing: "Flügel",
        neighbourhoods: "Nachbarschaften"
      }
    },
    tribeloo_upload_profile_pic: {
      title: "Bild hochladen",
      subtitle: "Wähle ein Profilbild aus, das auf der Karte angezeigt werden soll",
      info_dialog_component: "Dein Bild ist zu groß. Bitte wähle ein Bild mit einer Größe von weniger als 4 MB aus.",
      support_text: "Unterstützt JPG oder PNG",
      dd_text: "Bild ziehen und ablegen oder ",
      browse_text: "durchsuchen",
      buttons: {
        save: "Bild speichern",
        upload: "Bild hochladen",
        capture: "Bild mit der Webcam aufnehmen",
      },
      img_alt: "Neues Profilbild"
    },
    tribeloo_whoisaround: {
      title: "Wer ist in der Nähe?"
    },
    tribeloo_more_settings: {
      title: "Weitere Einstellungen",
      labels: {
        seats: "Plätze",
        amenities: "Annehmlichkeiten",
        visitor: "Besucher einladen"
      },
      toggles: {
        amenities: "Nur mit allen Annehmlichkeiten anzeigen",
        booking: "Buchung für andere nicht sichtbar"
      },
      placeholders: {
        visitor: "Besucher-E-Mail",
        amenities: "Keine Annehmlichkeiten ausgewählt"
      },
      info: "Weitere Einstellungen nicht verfügbar"
    },
    tribeloo_time_range: {
      labels: {
        select: "Schnellauswahl",
        start: "Startzeit",
        end: "Endzeit"
      },
      rangeOptions: {
        fullDay: 'Ganzer Tag',
        morning: 'Morgen',
        afternoon: 'Nachmittag',
        customTime: 'Benutzerdefinierte Zeit'
      }
    },
    tribeloo_time_picker: {
      tooltip_error: "Ungültiges Zeitformat",
    },
    tribeloo_single_file_upload: {
      button_save: "Zugeschnittenes Bild speichern"
    },
    tribeloo_pop_up_confirmation: {
      titles: {
        cancel: "Buchungsstornierung",
        edit: "Buchung erfolgreich bearbeitet",
        invite: "Einladung zur Buchung bearbeiten",
        booked: "Buchung erfolgreich!",
        options: "Optionale Buchungsoptionen",
        addSubject: "Betreff hinzufügen",
      },
      subtitles: {
        edit_booked: "Eine E-Mail mit Informationen zu Ihrer Buchung wird Ihnen zugesendet.",
        invite: "Eine E-Mail wird nur an neu hinzugefügte Kollegen gesendet.",
        old: "Alte Buchung",
        new: "Neue Buchung",
        cancel: "Sobald eine Buchung storniert ist, kann sie nicht rückgängig gemacht werden."
      },
      input: ['Einladungen aktualisiert', 'Kollegen eingeladen'],
      subjectInput: {
        saved: 'Thema gespeichert',
        placeholder: 'Thema'
      },
      buttons: {
        confirm: 'Bestätigen',
        main: "Kollegen einladen",
        booKingSubjectInvitations: "Buchungsthema & Einladungen",
        send: "Einladung senden",
        update: "Einladungen aktualisieren",
        cancel: "Buchung abbrechen",
        nevermind: "Vergiss es",
        saveSubject: "Thema speichern"
      }
    },
    tags_modal: {
      title: "Schlagwörter",
      subtitle: "Kurze Erklärung, wie Schlagwörter funktionieren",
      small_title: ['Vorschläge', 'Alle Schlagwörter'],
      colleagues: "Kollegen",
      placeholder: "Suchen oder Schlagwörter hinzufügen",
      add_button: ['Hinzufügen', 'Neues Schlagwort hinzufügen'],
      save_button: "Änderungen speichern"
    },
    tribeloo_base_user_settings: {
      settings: "Benutzereinstellungen",
      tribe: "Mein Stamm"
    },
    tribeloo_filter_tribe_members: {
      title: "Filteroptionen",
      tag: "Schlagwörter",
      site: "Startseite"
    },
    tribeloo_my_tribe: {
      description: "Markiere Kollegen, um sie beim Erstellen einer Reservierung leicht zu finden, und definiere deine eigenen Gruppen.",
      search: "Suche"
    },
    tribeloo_tribe_member: {
      title: "Schlagwörter",
      not_found: "Keine Schlagwörter gefunden",
      edit_button: "Schlagwörter bearbeiten"
    }
  },
  v2_pages: {
    tribeloo_default_page_holder: {
      messages: {
        update_success: 'Buchung erfolgreich aktualisiert',
        update_failed: 'Aktualisierung der Buchung fehlgeschlagen.',
        cancel_failed: "Freigabe konnte nicht storniert werden",
        error408: "Do you wont to force book?"
      }
    },
    tribeloo_external_invites: {
      info: "Ihre Einladung ist ungültig",
      button: "Zurück zur Startseite"
    }
  }
};
