<div class="main" [ngClass]="{'save-main': type == 'share', 'share-small': type == 'share' && !users.length}">
  <div class="header-container">
    <i class="fa fa-user" *ngIf="type != 'share'"></i>
    <p>{{headerText}}</p>
    <i class="fa fa-download" *ngIf="type == 'atendeesAdmin'" (click)="downloadExele()"></i>
    <i class="fa fa-times" (click)="dialogRef.close()"></i>
  </div>
  <div class="info-conteinter" *ngIf="type == 'share'">
    <p>{{users.length ? languages.info[0] : languages.info[1]}}</p>
    <div *ngIf="users.length" class="users">
      <ng-select class="custom custom-multy" [items]="users" [multiple]="true" placeholder="{{languages.placeholdrs.colleagues}}" [clearable]="false" [searchable]="false"  bindLabel="name" bindValue="id" [(ngModel)]="selectedUsers" (change)="selectedUserChanged()">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items | slice:0:2">
            <span class="ng-value-label"><img loading="lazy" [src]="isRelative ? backend_url + item.image : item.image" width="20px" height="20px"> {{item.name}}</span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
          </div>
          <div class="ng-value" *ngIf="items.length > 2">
            <span class="ng-value-label">{{items.length - 2}} {{languages.more}}</span>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <img loading="lazy" [src]="isRelative ? backend_url + item.image : item.image" width="20px" height="20px"> {{item.name}}
        </ng-template>
      </ng-select>
    </div>
    <textarea *ngIf="users.length" name="message" [(ngModel)]="text" maxlength="500" placeholder="{{languages.placeholdrs.message}}"></textarea>
    <p *ngIf="users.length" class="counter-text">{{text.length}}/500</p>
    <div *ngIf="users.length" class="button-send save-button" (click)="share()">
      <i class="fa fa-paper-plane"></i>
      {{languages.send}}
    </div>
  </div>
  <div class="info-conteinter" *ngIf="type == 'atendeesUser'">
    <div class="centered-container">
      <div class="button-send button-attendees" [ngClass]="{'button-attendees-green': !user_is_attending}" (click)="attendToEvent()">
        {{user_is_attending ? languages.button_attendees[0] : languages.button_attendees[1]}}
      </div>
    </div>
    <div class="user-item" *ngFor="let user of users">
      <img loading="lazy" src="{{isRelative ? backend_url + user.image : user.image}}" alt="">
      <span>{{user.name}}</span>
    </div>
  </div>
  <div class="info-conteinter" *ngIf="type == 'atendeesAdmin'">
    <textarea name="message" [(ngModel)]="text" placeholder="{{languages.placeholdrs.message}}"></textarea>
    <div class="button-send" (click)="sendMessage()">
      <i class="fa fa-paper-plane"></i>
      {{languages.send_all}}
    </div>
    <!-- <div class="search">
      <input type="text" name="search" value="">
      <i class="fa fa-search"></i>
    </div> -->

    <div class="counter">{{users.length}} {{languages.counter}}</div>

    <div class="user-item" *ngFor="let user of users">
      <img loading="lazy" src="{{isRelative ? backend_url + user.image : user.image}}" alt="">
      <span>{{user.name}}</span>
    </div>

  </div>
</div>
