import { Component, Input } from '@angular/core';

@Component({
  selector: 'info-table',
  templateUrl: './info-table.component.html',
  styleUrls: ['./info-table.component.css']
})
export class InfoTableComponent {

  @Input() text: string = '';
  @Input() showAllText: string = '';
  @Input() isShowAllText: boolean = true;

  constructor() { }

}
