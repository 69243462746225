import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAssetsCount'
})
export class FilterAssetsCountPipe implements PipeTransform {

  transform(items: any[], names: string, has_requested_amenities: boolean): any {
    if (!items) {
      return items.length;
    }
    if(items && names == "" && has_requested_amenities) {
      return items.length;
    }

    if(names != "") {
      if(has_requested_amenities) {
        return items.filter(
          item => (item.name.toLowerCase().indexOf(names.toLowerCase()) !== -1 ||
          item.wing_name.toLowerCase().indexOf(names.toLowerCase()) !== -1)
        ).length;
      } else {
        return items.filter(
          item => item.has_requested_amenities == !has_requested_amenities &&
          (item.name.toLowerCase().indexOf(names.toLowerCase()) !== -1 ||
          item.wing_name.toLowerCase().indexOf(names.toLowerCase()) !== -1)
        ).length;
      }
    } else {
      if(has_requested_amenities) {
        return items.length;
      } else {
        return items.filter(
          item => item.has_requested_amenities == !has_requested_amenities
        ).length;
      }
    }
  }

}
