import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from "@angular/flex-layout";
import { PinchZoomModule } from 'ng-pinch-zoom-x';

import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { MarkdownModule } from 'ngx-markdown';
import 'prismjs';
import 'prismjs/components/prism-typescript.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { TimeDialogComponent } from './day-time-mini/time-dialog/time-dialog.component';
import { SiteDialogComponent } from './site-dialog/site-dialog.component';
import { DateTimeRangeModalComponent } from './date-time-range-modal/date-time-range-modal.component';
import { KioskLogoutInfoModalComponent } from './kiosk-logout-info-modal/kiosk-logout-info-modal.component';
import { ReservationTipeModalComponent } from './reservation-tipe-modal/reservation-tipe-modal.component';
import { SelectSettingsPopupComponent } from './select-settings-popup/select-settings-popup.component';

import { DayTimeMiniComponent } from './day-time-mini/day-time-mini.component';
import { TableDatetimeRangeHelperComponent } from './table-datetime-range-helper/table-datetime-range-helper.component';
import { TimeRangeHelperComponent } from './time-range-helper/time-range-helper.component';
import { SiteSelectComponent } from './site-select/site-select.component';
import { AssetsMapComponent } from './assets-map/assets-map.component';
import { SiteWingsReservationViewExternalDataSetComponent } from './site-wings-reservation-view-external-data-set/site-wings-reservation-view-external-data-set.component';
import { ExpandedFilterAssetsListComponent } from './expanded-filter-assets-list/expanded-filter-assets-list.component';
import { ReservationHeaderInfoComponent } from './reservation-header-info/reservation-header-info.component';
import { DownloadImageComponent } from './download-image/download-image.component';
import { SiteCardComponent } from './site-card/site-card.component';
import { DateTimeRangeViewComponent } from './date-time-range-view/date-time-range-view.component';

import { DatexPipe } from './pipes/datex/datex.pipe';
import { FilterByNamePipe } from './pipes/filter-by-name/filter-by-name.pipe';
import { FilterByProxyNamePipe } from './pipes/filter-by-proxy-name/filter-by-proxy-name.pipe';
import { FilterPipe } from './pipes/filter/filter.pipe';

import { FilterAssetsByObjectNamesPipe } from './filter-assets/filter-assets-by-object-names.pipe';
import { AssetsWithoutAmenitiesPipe } from './filter-assets/assets-without-amenities.pipe';
import { FilterAssetsCountPipe } from './filter-assets/filter-assets-count.pipe';
import { FilterAssetsCountBooleanPipe } from './filter-assets/filter-assets-count-boolean.pipe';
import { FilterUserSquadNamePipe } from './pipes/filter-user-squad-name/filter-user-squad-name.pipe';

import { NewsfeedItemComponent } from './newsfeed-item/newsfeed-item.component';
import { NewsfeedCreateEditItemComponent } from './newsfeed-create-edit-item/newsfeed-create-edit-item.component';
import { AttendeesModalComponent } from './attendees-modal/attendees-modal.component';
import { DownloadXlsxComponent } from './download-xlsx/download-xlsx.component';
import { ITooltipeComponent } from './i-tooltipe/i-tooltipe.component';
import { InfoTableComponent } from './info-table/info-table.component';
import { ActivitySelectComponent } from './activity-select/activity-select.component';
import { TotalCounterDownloadTableCsvComponent } from './total-counter-download-table-csv/total-counter-download-table-csv.component';
import { LoadingTableSpinnerComponent } from './loading-table-spinner/loading-table-spinner.component';
import { IconVideoModalComponent } from './icon-video-modal/icon-video-modal.component';
import { VideoModalComponent } from './video-modal/video-modal.component';
import { AssetListItemComponent } from './asset-list-item/asset-list-item.component';
import { AssetDetailsModalComponent } from './asset-details-modal/asset-details-modal.component';
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { WingSelectComponent } from './wing-select/wing-select.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { OrderByPipe } from './filter-assets/orderBy.filter.pipe';
import { FilterBookingObjectPipe } from './filter-assets/filter-booking-obj.pipe';
import { FilterWhoIsAroundPipe } from './filter-assets/filter-who-is-around.pipe';
import { CustomDateAdapterModule } from '../shared/custom-date-adapter/custom-date-adapter.module';

export * from './wing-select/wing-select.component';
export * from './icon-video-modal/icon-video-modal.component';
export * from './video-modal/video-modal.component';
export * from './activity-select/activity-select.component';
export * from './site-select/site-select.component';
export * from './confirm-dialog/confirm-dialog.component';
export * from './filter-dialog/filter-dialog.component';
export * from './site-dialog/site-dialog.component';
export * from './day-time-mini/day-time-mini.component';
export * from './table-datetime-range-helper/table-datetime-range-helper.component';
export * from './time-range-helper/time-range-helper.component';
export * from './info-dialog/info-dialog.component';
export * from './assets-map/assets-map.component';
export * from './site-wings-reservation-view-external-data-set/site-wings-reservation-view-external-data-set.component';
export * from './expanded-filter-assets-list/expanded-filter-assets-list.component';
export * from './reservation-header-info/reservation-header-info.component';
export * from './download-image/download-image.component';
export * from './site-card/site-card.component';
export * from './date-time-range-modal/date-time-range-modal.component';
export * from './date-time-range-view/date-time-range-view.component';
export * from './kiosk-logout-info-modal/kiosk-logout-info-modal.component';
export * from './reservation-tipe-modal/reservation-tipe-modal.component';
export * from './select-settings-popup/select-settings-popup.component';

export * from './pipes/datex/datex.pipe';
export * from './pipes/filter-by-name/filter-by-name.pipe';
export * from './pipes/filter-by-proxy-name/filter-by-proxy-name.pipe';
export * from './pipes/filter/filter.pipe';
export * from './pipes/filter-user-squad-name/filter-user-squad-name.pipe';

export * from './filter-assets/filter-assets-by-object-names.pipe';
export * from './filter-assets/assets-without-amenities.pipe';
export * from './filter-assets/filter-assets-count.pipe';
export * from './filter-assets/filter-assets-count-boolean.pipe';

export * from './newsfeed-item/newsfeed-item.component';
export * from './newsfeed-create-edit-item/newsfeed-create-edit-item.component';
export * from './attendees-modal/attendees-modal.component';
export * from './download-xlsx/download-xlsx.component';

export * from './i-tooltipe/i-tooltipe.component';
export * from './info-table/info-table.component';
export * from './loading-table-spinner/loading-table-spinner.component';
export * from './total-counter-download-table-csv/total-counter-download-table-csv.component';

export * from './asset-details-modal/asset-details-modal.component';
export * from './asset-details/asset-details.component';

@NgModule({
  declarations: [
    SiteSelectComponent,
    ConfirmDialogComponent,
    FilterDialogComponent,
    DayTimeMiniComponent,
    TableDatetimeRangeHelperComponent,
    TimeRangeHelperComponent,
    TimeDialogComponent,
    SiteDialogComponent,
    InfoDialogComponent,
    AssetsMapComponent,
    SiteWingsReservationViewExternalDataSetComponent,
    ExpandedFilterAssetsListComponent,
    FilterAssetsByObjectNamesPipe,
    AssetsWithoutAmenitiesPipe,
    FilterAssetsCountPipe,
    FilterAssetsCountBooleanPipe,
    FilterBookingObjectPipe,
    DatexPipe,
    FilterByNamePipe,
    FilterByProxyNamePipe,
    FilterUserSquadNamePipe,
    FilterPipe,
    OrderByPipe,
    FilterWhoIsAroundPipe,
    ReservationHeaderInfoComponent,
    DownloadImageComponent,
    SiteCardComponent,
    DateTimeRangeModalComponent,
    DateTimeRangeViewComponent,
    KioskLogoutInfoModalComponent,
    ReservationTipeModalComponent,
    SelectSettingsPopupComponent,
    NewsfeedItemComponent,
    NewsfeedCreateEditItemComponent,
    AttendeesModalComponent,
    DownloadXlsxComponent,
    ITooltipeComponent,
    InfoTableComponent,
    ActivitySelectComponent,
    TotalCounterDownloadTableCsvComponent,
    LoadingTableSpinnerComponent,
    IconVideoModalComponent,
    VideoModalComponent,
    AssetListItemComponent,
    AssetDetailsModalComponent,
    AssetDetailsComponent,
    WingSelectComponent,
  ],
  imports: [
    MatSidenavModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    PinchZoomModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    CustomDateAdapterModule,
    MarkdownModule.forRoot()
  ],
  providers: [
    MatDatepickerModule,
  ],
  exports: [
    MatSelectModule,
    FlexLayoutModule,
    PinchZoomModule,
    SiteSelectComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    SiteDialogComponent,
    FilterDialogComponent,
    DayTimeMiniComponent,
    TableDatetimeRangeHelperComponent,
    TimeRangeHelperComponent,
    AssetsMapComponent,
    SiteWingsReservationViewExternalDataSetComponent,
    ExpandedFilterAssetsListComponent,
    ReservationHeaderInfoComponent,
    DownloadImageComponent,
    SiteCardComponent,
    DatexPipe,
    FilterByNamePipe,
    FilterByProxyNamePipe,
    FilterPipe,
    OrderByPipe,
    FilterAssetsByObjectNamesPipe,
    AssetsWithoutAmenitiesPipe,
    FilterAssetsCountPipe,
    FilterAssetsCountBooleanPipe,
    FilterUserSquadNamePipe,
    FilterBookingObjectPipe,
    DateTimeRangeViewComponent,
    DateTimeRangeModalComponent,
    KioskLogoutInfoModalComponent,
    ReservationTipeModalComponent,
    SelectSettingsPopupComponent,
    NewsfeedItemComponent,
    NewsfeedCreateEditItemComponent,
    AttendeesModalComponent,
    DownloadXlsxComponent,
    ITooltipeComponent,
    InfoTableComponent,
    ActivitySelectComponent,
    TotalCounterDownloadTableCsvComponent,
    LoadingTableSpinnerComponent,
    IconVideoModalComponent,
    VideoModalComponent,
    AssetListItemComponent,
    AssetDetailsModalComponent,
    AssetDetailsComponent,
    WingSelectComponent,
    FilterWhoIsAroundPipe
  ],
  entryComponents: [
    ConfirmDialogComponent,
    FilterDialogComponent,
    InfoDialogComponent,
    TimeDialogComponent,
    SiteDialogComponent,
    DateTimeRangeModalComponent,
    KioskLogoutInfoModalComponent,
    ReservationTipeModalComponent,
    SelectSettingsPopupComponent,
    AttendeesModalComponent,
    VideoModalComponent,
    AssetDetailsModalComponent
  ]
})

export class SharedComponentsModule { }
