import {
  Component,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizationService } from "./../../services/localization/localization.service";

@Component({
  selector: 'info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.css']
})
export class InfoDialogComponent {

  button: any;

  constructor(
     public dialogRef: MatDialogRef<InfoDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private localizationService: LocalizationService
   ) {
     if(this.localizationService.getReusableDataText('button').isSuccess) {
       this.button = this.localizationService.getReusableDataText('button').data;
     }
   }

   close() {
     this.dialogRef.close(true);
   }
}
