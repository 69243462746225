<div class="item" *ngIf="newsItem" fxLayout="column" fxLayoutAlign="space-between ">
  <div class="action-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="">
      <h3 *ngIf="newsItem.type == 'Event'">{{newsItem.event_subject == '' ? languages.default_subject : newsItem.event_subject}}</h3>
      <span>{{newsItem.title}}</span>
    </div>
    <div class="">
      <i *ngIf="!isUser && newsItem.type == 'Event'" class="fa fa-user" (click)="onAttendeesAdmin()"></i>
      <i *ngIf="!isUser" class="fa fa-trash" (click)="removeItem()"></i>
      <i *ngIf="!isUser" class="fa fa-pencil" (click)="edit.emit()"></i>
      <i *ngIf="isUser && newsItem.type == 'Event' && !newsItem.user_is_attending" class="fa fa-user-plus" (click)="onAttendeesUser()"></i>
      <i *ngIf="isUser && newsItem.type == 'Event' && newsItem.user_is_attending" class="fa fa-user icon-attendees-green" (click)="onAttendeesUser()"></i>
      <i *ngIf="isUser" class="fa fa-share" (click)="onShare()"></i>
    </div>
  </div>
  <p [ngClass]="{'expanded': !collapse, 'margin-bottom-text': !isShowMoreButton}" id="{{newsItem.id}}" markdown [data]="newsItem.text"></p>
  <!-- <p [ngClass]="{'expanded': !collapse, 'margin-bottom-text': !isShowMoreButton}" id="{{newsItem.id}}">{{newsItem.text}}</p> -->
  <div class="info-container" *ngIf="newsItem.type == 'Event' && !collapse" fxLayout="row">
    <i class="fa fa-clock-o"></i>
    <date-time-range-view [disable]="true"[setDateTimeRange]="setDateTimeRange" [isReleaseReservationDetails]="true"></date-time-range-view>
  </div>
  <div class="info-container" *ngIf="newsItem.type == 'Event' && !collapse && newsItem.event_location != ''" fxLayout="row">
    <i class="fa fa-map-marker"></i>
    {{newsItem.event_location}}
  </div>
  <p class="collapse" *ngIf="newsItem.type == 'Event'" (click)="collapse = !collapse">{{collapse ? languages.actions[0] : languages.actions[1]}}</p>
  <p class="collapse" *ngIf="isShowMoreButton && newsItem.type != 'Event'" (click)="collapse = !collapse">{{collapse ? languages.actions[0] : languages.actions[1]}}</p>
  <img loading="lazy" *ngIf="newsItem.image" src="{{isRelative ? backend_url + newsItem.image : newsItem.image}}" alt="">
</div>
