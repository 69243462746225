import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assetsByWingId'
})
@Injectable()
export class AssetsByWingIdPipe implements PipeTransform {

  transform(items: any, args: Number): any {
    function itemFilter(item) {
        if (item.wing_id === args) {
          return item;
        }
    }
    return items.filter(itemFilter);
  }

}
