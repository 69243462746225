<div class=""  fxLayout="column" fxLayoutAlign="center center">
  <i class="fa fa-4x fa-info-circle"></i>
  <h3 class="message">{{languages.message}}</h3>
  <div class="message">
    <i>{{languages.info[0]}}{{iterator}}{{languages.info[1]}}</i>
  </div>
  <div class="actions" fxLayout="row" fxLayoutAlign="center center">
    <div class="button-accent blue-btn" (click)="close(true)">{{button.continue}}</div>
  </div>
</div>
