import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import * as _ from "lodash";
import * as moment from 'moment';

import { MatDialog } from '@angular/material/dialog';

import { InfoDialogComponent } from './../info-dialog/info-dialog.component';

import { ImageService } from "./../../services/image/image.service";
import { AggregationService } from "./../../services/aggregation/aggregation.service";
import { LocalizationService } from "app/services/localization/localization.service";

@Component({
  selector: 'table-datetime-range-helper',
  templateUrl: './table-datetime-range-helper.component.html',
  styleUrls: ['./table-datetime-range-helper.component.css'],
  providers: [ ImageService, AggregationService]
})
export class TableDatetimeRangeHelperComponent {

  showAllReleases: boolean = false;

  dateTimeRange = {
    "from": moment().hour(8).minute(0).second(0).toDate(),
    "to":  moment().hour(19).minute(0).second(0).toDate()
  };

  @Input() isShowAllCheckActive:boolean = true;
  @Input() name:string = '';
  @Input() setBackground:string = '#fff';
  button: any;

  @Input() set setDateTimeRange(value) {
    console.log(value)
    if (value.date.from != undefined && value.date.to != undefined) {
      this.showAllReleases = value.flag;
      this.dateTimeRange = value.date;
    }
  }

  @Output() tableDateTimeRangeChange : EventEmitter<any>;

  constructor(
    public dialog: MatDialog,
    private aggregationService: AggregationService,
    private localizationService: LocalizationService
  ) {
    if(this.localizationService.getReusableDataText('button').isSuccess) {
      this.button = this.localizationService.getReusableDataText('button').data;
    }
    this.tableDateTimeRangeChange = new EventEmitter<any>();
  }

  showAllReleasesChange() {
    this.tableDateTimeRangeChange.emit({flag: this.showAllReleases, date: this.dateTimeRange});
  }

  setNewDateTimeRange(newRange) {
    this.dateTimeRange = _.cloneDeep(newRange);
    this.tableDateTimeRangeChange.emit({flag: this.showAllReleases, date: this.dateTimeRange});
  }

  dateTimeChangeFrom(from) {
    this.setNewDateTimeRange(this.aggregationService.checkDateTimeRange(true, _.cloneDeep(this.dateTimeRange), {from:_.cloneDeep(from), to: _.cloneDeep(this.dateTimeRange.to)}));
  }

  dateTimeChangeTo(to) {
    this.setNewDateTimeRange(this.aggregationService.checkDateTimeRange(false, _.cloneDeep(this.dateTimeRange), {from:_.cloneDeep(this.dateTimeRange.from), to: _.cloneDeep(to)}));
  }

}
