import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable }                                           from '@angular/core';
import { Observable, throwError }                               from 'rxjs';
import { catchError }                                           from 'rxjs/operators';
import { allowedUrls }                                          from './forUserInterceptor/allowedurls';

@Injectable()
export class ForUserInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const for_user_id = JSON.parse(localStorage.getItem('for_user'))?.id;

    let modifiedRequest = request;

    if ((allowedUrls.includes(request.url.trim())) && !!for_user_id) {

      const customHeaders = {
        "for-user": String(JSON.parse(localStorage.getItem('for_user')).id) || '',
      };

      modifiedRequest = request.clone({
        setHeaders: customHeaders
      });

      return next.handle(modifiedRequest).pipe(catchError(err => {
        return throwError(err);
      }));

    } else {
      return next.handle(request);
    };


  }
}
