import { Injectable } from '@angular/core';

import { ImageService } from "./../image/image.service";

import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from './../../shared-components/info-dialog/info-dialog.component';

import * as _ from "lodash"
import * as moment from 'moment';

@Injectable()
export class AggregationService {

  constructor(
    private imageService: ImageService,
    private dialog: MatDialog
  ) { }

  getAllAssets(data) {
    let resUnic = []

    _.map(data, item => {
      resUnic =  _.concat(resUnic, item.assets)
    })

    return resUnic;
  }

  getAvailableAssets(data) {
    data = this.getAllAssets(data);
    return data.filter(item => item.status != 0)
  }

  structingImages(wings) {
    for (let i = 0; i < wings.length; i++) {
      wings[i].image = this.imageService.path(wings[i].image);
    }

    return wings;
  }

  checkDateTimeRange(isFrom, oldRange, newRange) {
    if (newRange.from == null || newRange.from == undefined) {
      newRange.from = moment().minute(0).second(0).millisecond(0).toDate();
    }
    if (newRange.to == null || newRange.to == undefined) {
      newRange.to = moment().hours(23).minute(59).second(0).millisecond(0).toDate();;
    }
    if(isFrom) {
      if(newRange.from >= newRange.to) {
        if (moment(newRange.from).format("DD.MM.YYYYY").toString() != moment(newRange.to).format("DD.MM.YYYYY").toString()) {
          newRange.to = moment(newRange.to).date(moment(newRange.from).date()).month(moment(newRange.from).month()).year(moment(newRange.from).year()).toDate();
          if(newRange.from >= newRange.to) {
            newRange.to = moment(newRange.from).add(30, 'minutes').toDate();
          }
        } else {
          newRange.to = moment(newRange.from).add(30, 'minutes').toDate();
        }
      }
    } else {
      if(newRange.from >= newRange.to) {
        newRange = _.cloneDeep(oldRange)
        this.dialog.open(InfoDialogComponent, { disableClose: true, data:{text:"'To' time must come after 'From' time", button: "OK"} });
      }
    }

    return newRange;
  }

}
