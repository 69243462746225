import { environment } from "environments/environment";

const myBookings = environment.single_url +  environment.api_prefix_v2 + '/user/mybookings'
const findColleagues = environment.single_url +  environment.api_prefix_v2 + '/user/findcolleagues'
const releases = environment.single_url +  environment.api_prefix_v2 + '/releasableassets'
const releasesOfReseource = environment.single_url +  environment.api_prefix_v2 + '/releasesofresource'
const releasesList = environment.single_url +  environment.api_prefix_v2 + '/releaseslist';
const favorites = environment.single_url +  environment.api_prefix_v2 + '/user/favourite/colleaguesandassets'
const bookableTypes = environment.single_url +  environment.api_prefix_v2 + '/user/bookabletypes'
const sitesOfType = environment.single_url +  environment.api_prefix_v2 + '/user/sitesoftype'
const wingsofsite = environment.single_url +  environment.api_prefix_v2 + '/user/wingsofsite'
const neighbourhoodsofwing = environment.single_url +  environment.api_prefix_v2 + '/user/neighbourhoodsofwing'
const userCapacity = environment.single_url +  environment.api_prefix_v2 + '/user/capacity'
const amenities = environment.single_url +  environment.api_prefix_v2 + '/user/amenities';
const getUsersList = environment.single_url +  environment.api_prefix_v2 + '/user/getuserlist';
const proxyusers = environment.single_url +  environment.api_prefix_v2 + '/user/proxyusers'
const assets = environment.single_url +  environment.api_prefix_v2 + '/assets'
const whoisaround = environment.single_url +  environment.api_prefix_v2 + '/user/whoisaround';
const bookAsset = environment.single_url +  environment.api_prefix_v2 + '/bookasset';
const setAssetAsFavorite = environment.single_url +  environment.api_prefix_v2 + '/user/setassetfavourite';
const setUserAsFavorite = environment.single_url +  environment.api_prefix_v2 + '/user/setuserfavourite';
const getInvites = environment.single_url +  environment.api_prefix_v2 + '/booking/getinvites';
const setInvites = environment.single_url +  environment.api_prefix_v2 + '/booking/setinvites';
const getlatestfilterassetinfo = environment.single_url +  environment.api_prefix_v2 + '/user/getlatestfilterassetinfo'
const listTags = environment.single_url +  environment.api_prefix_v2 + '/user/listtags'
const removeUserTags = environment.single_url +  environment.api_prefix_v2 + '/tribe/removeusertag'
const createTag = environment.single_url +  environment.api_prefix_v2 + '/user/createtag'
const getUserList = environment.single_url +  environment.api_prefix_v2 + '/user/getuserlist'
const setTags = environment.single_url +  environment.api_prefix_v2 + '/user/settags'
const removeTag = environment.single_url +  environment.api_prefix_v2 + '/user/removetag'
const updateTag = environment.single_url +  environment.api_prefix_v2 + '/user/updatetag'
const setDelegate = environment.single_url +  environment.api_prefix_v2 + '/user/setdelegates'
const assetsOfRelease = environment.single_url +  environment.api_prefix_v2 + '/assetsofrelease'
const releaseResource = environment.single_url +  environment.api_prefix_v2 + '/releaseresource'
const cancelRelease = environment.single_url +  environment.api_prefix_v2 + '/cancelrelease'
const assetsOfBooking = environment.single_url +  environment.api_prefix_v2 + '/assetsofbooking'
const sitesOfTypeFilter = environment.single_url +  environment.api_prefix_v2 + '/user/sitesoftype/filter'
const wantsUcsInvites = environment.single_url +  environment.api_prefix_v2 + '/user/wantsicsinvites'
const checkin = environment.single_url +  environment.api_prefix_v2 + '/user/booking/checkin';
const deleteBooking = environment.single_url +  environment.api_prefix_v2 + '/user/deletebooking';
const getBookingInfo = environment.single_url +  environment.api_prefix_v2 + '/user/getbookinginfo';
const uploadImage = environment.single_url +  environment.api_prefix_v2 + '/user/upload-image';
const removeImage = environment.single_url +  environment.api_prefix_v2 + '/user/remove-image';
const setPassword = environment.single_url +  environment.api_prefix_v2 + '/user/setpassword';
const settimeformat = environment.single_url +  environment.api_prefix_v2 + '/user/settimeformat';
const setlistedsites = environment.single_url +  environment.api_prefix_v2 + '/user/setlistedsites';
const getlistedsites = environment.single_url +  environment.api_prefix_v2 + '/user/getlistedsites';
const canreleaserooms = environment.single_url + environment.api_prefix + "/user/canreleaserooms";
const canreleaseroomsV2 = environment.single_url + environment.api_prefix_v2 + '/user/canreleaserooms';
const setCheckedNewsUpdate = environment.single_url + environment.api_prefix_v2 + '/setcheckednewsupdate';
const register = environment.single_url + environment.api_prefix_v2 + '/register';

export const allowedUrls = [
  myBookings, findColleagues, releases,
  releasesOfReseource, favorites, bookableTypes,
  sitesOfType, wingsofsite, neighbourhoodsofwing,
  userCapacity, amenities, proxyusers,
  assets, whoisaround, bookAsset,
  setAssetAsFavorite, setUserAsFavorite, getInvites,
  setInvites, getlatestfilterassetinfo, listTags,
  removeImage, getlistedsites, setlistedsites,
  settimeformat, setPassword, uploadImage,
  getBookingInfo, deleteBooking, checkin,
  wantsUcsInvites, removeUserTags, createTag,
  getUserList, setTags, removeTag,
  setDelegate, assetsOfRelease, releaseResource,
  cancelRelease, assetsOfBooking, sitesOfTypeFilter,
  canreleaserooms, updateTag, releasesList, getUsersList,
  canreleaseroomsV2, setCheckedNewsUpdate, register
];
