import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NeighbourhoodsService {

  constructor(
    private http: HttpClient
  ) { }

// GET /api/1.0/neighbourhood/id ==> neighbourhood object
//
// Populating neighbourhoods
// POST /api/1.0/neighbourhood/listassets {id:integer} ==> list of assets
// POST /api/1.0/neighbourhood/addasset {id:integer, asset_id:integer} ==> asset [complain if already in?]
// POST /api/1.0/neighbourhood/removeasset {id:integer, asset_id:integer} ==> asset [complain if not in]


//   Neighbourhood "actions"
// POST /api/1.0/site/neighbourhoods {site_id:integer} ==> list of neighbourhood objects
  getList(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/neighbourhoods', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }


  // POST /api/1.0/neighbourhood/set {id:integer, asset_id:integer} ==> asset required set or reset neighbourhood to asset
  setAsset(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/neighbourhoods/set', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  //   Neighbourhood "crud"
  // POST /api/1.0/neighbourhood/create {site_id:integer, name:string} ==> neighbourhood object [with complaints if name already in use for that site]
  create(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/neighbourhoods/create', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/1.0/neighbourhood/delete {id:integer} ==> null
  delete(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/neighbourhoods/delete', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/1.0/neighbourhood/rename {id:integer, name:string} ==>neighbourhood also complain if new name already exists
  rename(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/neighbourhoods/rename', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

}
