import { Component, Inject, OnInit } from '@angular/core';
import { NeighbourhoodsService } from 'app/services/neighbourhoods/neighbourhoods.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-select-settings-popup',
  templateUrl: './select-settings-popup.component.html',
  styleUrls: ['./select-settings-popup.component.css']
})
export class SelectSettingsPopupComponent implements OnInit {

  header: string = "Neighbourhoods";
  isBlocked: boolean = false;
  items: any[] = [];
  search: string = "";
  editName: string = "";
  indexSelected: number = -1;
  hasRedirectLink: boolean = false;
  linkURL = ""
  linkText = "";
  constructor(
    public dialogRef: MatDialogRef<SelectSettingsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private neighbourhoodsService: NeighbourhoodsService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getItems();
    if(this.data.hasRedirectLink) {
      this.hasRedirectLink = this.data.hasRedirectLink;
      this.linkURL = this.data.linkURL;
      this.linkText = this.data.linkText;
    }
  }

  getItems() {
    this.neighbourhoodsService.getList({site_id: this.data.site_id})
    .subscribe(res => {
      if(res.isSuccess) {
        this.items = res.data;
      }
    })
  }

  close() {
    this.dialogRef.close();
  }

  new() {
    this.indexSelected = -1;
    this.editName = "";
    this.search = "";
    this.isBlocked = true;
  }
  edit(item, i) {
    this.indexSelected = i;
    this.editName = item.name;
    this.isBlocked = true;
  }
  cancel() {
    this.indexSelected = -1;
    this.editName = "";
    this.search = "";
    this.isBlocked = false;
    this.getItems();
  }

  checkchanges() {
    if(this.editName == "") {
      return false;
    } else {
      return this.indexSelected == -1 ? true : this.editName != this.items[this.indexSelected].name;
    }
  }

  createEdit() {
    if(this.indexSelected == -1) {
      this.neighbourhoodsService.create({site_id: this.data.site_id, name: this.editName})
      .subscribe(res => this.cancel());
    } else {
      this.neighbourhoodsService.rename({id: this.items[this.indexSelected].id, name: this.editName})
      .subscribe(res => this.cancel());
    }
  }
  remove(item) {
    this.dialog.open(ConfirmDialogComponent, { data: {
      text: "Delete this neighbourhood?",
      big_descriptions: ["All resources from this neighbourhood will be kept and can be reassigned to another one if wanted."],
      icon: true
    } }).afterClosed().subscribe(result => {
      if(result) {
        this.neighbourhoodsService.delete({id: item.id})
        .subscribe(res => this.cancel());
      }
    });
  }

}
