import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class WingsService {

  constructor(
    private http: HttpClient
  ) { }

  getWingsList(siteID) {
    return this.http.get(environment.backend_url + environment.api_prefix + '/sites/' + siteID + '/wings')
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getWing(idWing) {
    return this.http.get(environment.backend_url + environment.api_prefix + '/wings/' + idWing)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getAssetsSpecificWing(idWing) {
    return this.http.get(environment.backend_url + environment.api_prefix + '/wings/' + idWing + '/assets')
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  createWing(wing) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/wings', wing)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/1.0/wing/setactive {wing_id:integer, is_active:boolean} ==> []
  setactive(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/wing/setactive', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  position(wing) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/wings/position', wing)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  updateWing(idWing, wing) {
    return this.http.put(environment.backend_url + environment.api_prefix + '/wings/' + idWing, wing)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  updateWingImage(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/wings/upload-image', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // {asset_id: id, type: 'owner'}
  // {wing_id: id, type: 'cleaner'}
  getListOfPossibleOwners(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/wing/getpossibleowners', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/1.0/wing/setcleaninguser {'wing_id', 'user_id'}
  setcleaninguser(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/wing/setcleaninguser', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/1.0/wing/removecleaninguser {'wing_id'}
  removecleaninguser(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/wing/removecleaninguser', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  deleteWing(idWing) {
    return this.http.delete(environment.backend_url + environment.api_prefix + '/wings/' + idWing)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
}
