import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from './../../../../environments/environment';

@Injectable()
export class UserManagementService {

  constructor(
    private http: HttpClient
  ) { }

  //   Get list of sites administered by admin:
  //
  // URL: /api/1.0/useradmin/administeredsites
  //
  // VERB: POST
  //
  // EXAMPLE RESULT:
  //
  // [
  //   {
  //     "id": 1,
  //     "name": "site1",
  //     "created_at": "2017-05-28 12:05:57",
  //     "updated_at": "2017-05-28 12:05:57"
  //   },
  //    ....
  // ]
  getListOfSitesAdministeredByAdmin() {
    return this.http.post(environment.backend_url + environment.api_prefix + '/useradmin/administeredsites', {})
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // /api/1.0/site/sendpincodeemail {site_id, send_pincode_mail}
  sendpincodeemail(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/sendpincodeemail', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
//   POST
// /api/1.0/admin/getfetchuserimagessetting
// RETURN
// {'fetchuserimages':boolean}
  getfetchuserimagessetting() {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/getfetchuserimagessetting', {})
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
//   POST
// /api/1.0/admin/setfetchuserimagessetting {'fetchuserimage':boolean}
// RETURN
// {'fetchuserimages':boolean}
  setfetchuserimagessetting(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/setfetchuserimagessetting', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // /api/1.0/site/setnoshowlimit {site_id, no_show_limit, no_show_enabled}
  setnoshowlimit(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/setnoshowlimit', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // /api/1.0/site/setsendnoshowreminder  {site_id, send_no_show_reminder:boolean}
  setnoshowreminder(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/setsendnoshowreminder', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // /api/1.0/site/setcleaningtime  {'site_id', 'minutes', 'room_minutes'}
  setCleaning(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/setcleaningtime', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // /api/1.0/site/autoassign {site_id, autoassign}
  autoassign(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/autoassign', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
// POST /api/1.0/admin/getfreeversioninfo ==> {'is_free_version':boolean, 'contact_email':string, 'user_limit':number}
// contact_email and user_limit are only there if is_free_version == true
  getfreeversioninfo() {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/getfreeversioninfo', {})
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/1.0/user/wantsicsinvites ARGUMENTS {wants_ics_invites:boolean}
  wantsICSinvites(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/wantsicsinvites', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // table section start /////////////////////

  // /api/1.0/users/possibleFilterValues
  possibleFilterValues(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/users/possibleFilterValues', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // /api/1.0/users/filter are there
  filter(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/users/filter', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // /api/1.0/users/emailurl
  emailurl(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/users/emailurl', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // table section end /////////////////////

  // Get list of users administered by admin for this site:
  //
  // URL: /api/1.0/useradmin/administeredusers
  //
  // VERB: POST
  //
  // PARAMETER:
  //
  // {
  //      "site_id":<site_id>
  // }
  // EXAMPLE RESULT:
  //
  // [
  //   {
  //     "id": 1,
  //     "name": "user1",
  //     "email": "user1@wasdmco.net",
  //     "level": 2,
  //     "has_personal_room": false
  //   },
  //    ....
  // ]
  getListOfUsersAdministeredByAdminForThisSite(site) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/useradmin/administeredusers', site)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Create a new user
  //
  // URL: /api/1.0/useradmin/createuser
  //
  // VERB: POST
  //
  // PARAMETERS:
  //
  // {
  //      "site_id":<site_id>,
  //      "name":<new user name>,
  //      "email":<new user email>
  //      "level":<new user level (integer)>
  // }
  // EXAMPLE RESULT:
  //
  // {
  //   "id": 1,
  //   "name": "user1",
  //   "email": "user1@wasdmco.net",
  //   "level": 2,
  //   "has_personal_room": false
  // }
  createNewUser(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/useradmin/createuser', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Delete a user
  //
  // URL: /api/1.0/useradmin/deleteuser
  //
  // VERB: POST
  //
  // PARAMETERS:
  //
  // {
  //    "user_id":<user_id>
  // }
  // EXAMPLE RESULT:
  //
  // {
  //   "id": 1,
  //   "name": "user1",
  //   "email": "user1@wasdmco.net",
  //   "level": 2,
  //   "has_personal_room": false
  // }
  deleteUser(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/useradmin/deleteuser', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Update a user
  //
  // URL: /api/1.0/useradmin/updateuser
  //
  // VERB: POST
  //
  // PARAMETERS:
  //
  // {
  //      "user_id":<user_id>,
  //      "name":<new user name>,
  //      "email":<new user email>
  //      "level":<new user level (integer)>
  // }
  // EXAMPLE RESULT:
  //
  // {
  //   "id": 1,
  //   "name": "user1",
  //   "email": "user1@wasdmco.net",
  //   "level": 2,
  //   "has_personal_room": false
  // }
  updateUser(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/useradmin/updateuser', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Reset user password (sends mail with link to user)
  //
  // URL: /api/1.0/useradmin/resetuserpassword
  //
  // VERB: POST
  //
  // PARAMETERS:
  //
  // {
  //    "user_id":<user_id>
  // }
  // EXAMPLE RESULT:
  //
  // {
  //   "id": 1,
  //   "name": "user1",
  //   "email": "user1@wasdmco.net",
  //   "level": 2,
  //   "has_personal_room": false
  // }
  resetUserPassword(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/useradmin/resetuserpassword', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Set new password
  //
  // URL: /api/1.0/useradmin/setnewpassword
  //
  // VERB: POST
  //
  // PARAMETERS:
  //
  // {
  //     "key":<key from url>,
  //     "password":<new password from user>
  // }
  setNewPassword(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/useradmin/setnewpassword', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Set new password
  //
  // URL: /userSetPassword
  //
  // VERB: POST
  //
  // PARAMETERS:
  //
  // {
  //    "password"= 'new password'
  // }
  userSetPassword(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/setpassword', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // userResettingPasswordByEmail
  //
  // URL: /api/1.0/user/setpasswordwithtoken
  //
  // VERB: POST
  //
  // PARAMETERS:
  //
  // {
  //    "password"= 'new password'
  // }
  userResettingPasswordByEmail(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/setpasswordwithtoken', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Check if user levels can be set by the current user (only super admin)
  // URL: /api/1.0/admin/cansetuserlevelinfos
  //
  // VERB: POST
  //
  // Request (BODY):
  //
  //  <empty>
  // EXAMPLE RESULT:
  //
  // {
  //   "cansetuserlevels":<boolean>
  // }
  checkIsLevelsOfUserEditable() {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/cansetuserlevelinfos', {})
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  getuserlevelsglobal () {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/getuserlevelsglobal ', {})
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  setuserlevelsglobal (data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/setuserlevelsglobal ', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Get information about the user levels
  // URL: /api/1.0/admin/getuserlevelinfos
  //
  // VERB: POST
  //
  // Request (BODY):
  //
  // <empty>
  // EXAMPLE RESULT:
  //
  // {
  //   "maxuserlevels":<int>,
  //   "levels":[userlevelinfo, userlevelinfo, ...]
  // }
  getInfoUserLevels(data: any = {}) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/getuserlevelinfos', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Set the name of a user level
  // URL: /api/1.0/admin/setuserlevelinfo
  //
  // VERB: POST
  //
  // Request (BODY):
  //
  //  {
  //    "id":<int>, // user level info to set
  //    "name":<string> // name to set
  //  }
  // EXAMPLE RESULT:
  //
  // {
  //   userlevelinfo
  // }
  setNameOfUserLevel(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/setuserlevelinfo', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  setUserLevel(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/setuserlevel', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  clearaccesscode(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/clearaccesscode', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Get information about the desk levels for a site
  // URL: /api/1.0/admin/getdesklevelinfos
  //
  // VERB: POST
  //
  // Request (BODY):
  //
  // {
  //    "site_id":<int>
  // }
  // EXAMPLE RESULT:
  //
  // {
  //   "maxdesklevels":<int>,
  //   "levels":[desklevelinfo, desklevelinfo, ...]
  // }
  getDesksLevels(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/getdesklevelinfos', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Set the name of a desk level for a site
  // URL: /api/1.0/admin/setdesklevelinfo
  //
  // VERB: POST
  //
  // Request (BODY):
  //
  //  {
  //    "site_desk_level_id":<int>, // desk level info to set
  //    "name":<string> // name to set
  //  }
  // EXAMPLE RESULT:
  //
  // {
  //   desklevelinfo
  // }
  setNameOfDeskLevel(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/setdesklevelinfo', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Get the values for all the rules of the user/desk level table
  // URL: /api/1.0/admin/getuseranddeskleveltable
  //
  // VERB: POST
  //
  // Request (BODY):
  //
  //  {
  //    "site_id":<int>
  //  }
  // EXAMPLE RESULT:
  //
  // {
  //   "maxuserlevels":<int>,
  //   "maxdesklevels":<int>,
  //   "rules":[[ruleInfo, ruleInfo, ...., ruleInfo], // for first userLevel
  //            [ruleInfo, ruleInfo, ...., ruleInfo], // for second userLevel
  //            ...]
  // }
  getRuleUserDeskLevel(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/getuseranddeskleveltable', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Set the rule for a user desk level combination
  //  URL: /api/1.0/admin/setuseranddeskrule
  //
  // VERB: POST
  //
  // Request (BODY):
  //
  //  {
  //    "site_id":<int>,
  //    "user_level_id":<int>, // as obtained from getuserlevelinfos
  //    "desk_level_id":<int>,  // as obtained from getdesklevelinfos
  //    "rule":<int>            // 0,1,2
  //
  //  }
  // EXAMPLE RESULT:
  //
  // {
  //      ruleinfo
  // }
  setRuleUserDeskLevel(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/setuseranddeskrule', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // /api/1.0/useradmin/resetuserpin {user_id}
  resetUserPIN(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/useradmin/resetuserpin', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/1.0/useradmin/setaccesscode {user_id, access_code}
  setAccessCode(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/useradmin/setaccesscode', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // /api/1.0/admin/setuserhomesite {user_id, site_id}
  setUserHomeSite(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/admin/setuserhomesite', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
 // /api/1.0/user/upload-image {user_id, image}
 uploadUserImage(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/user/upload-image', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }
 // /api/1.0/user/remove-image {user_id}
 removeUserImage(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/user/remove-image', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }

 // POST /api/1.0/useradmin/importcsv {'csv':, 'site_id':}
 importcsv(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/useradmin/importcsv', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }

 // POST /api/1.0/site/futurelimits ARGUMENTS {'site_id':integer} ==> RESULT: {'desk':integer, 'room':integer, 'enabled':boolean}
 getFuturelimits(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/site/futurelimits', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }
 // POST /api/1.0/site/setfuturelimits ARGUMENTS {'site_id':integer, 'desk':integer, 'room':integer, 'enabled':boolean} ==> RESULT: {'desk':integer, 'room':integer, 'enabled':boolean}
 setFuturelimits(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/site/setfuturelimits', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }

 // POST /api/1.0/site/setcapacitylimit
 // ARGUMENTS {'site_id': site id, 'enabled':boolean, 'limit':integer}
 // RESULT: site information
 setCapacityLimit(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/site/setcapacitylimit', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }

 // /api/1.0/site/allowmultiday {site_id:integer, allow_multi_day:boolean}
 setMultiDay(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/site/allowmultiday ', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }


//  POST
// /api/1.0/site/getacknowledgeconfig ARG {site_id}
// RESULT
//
// {
//     'enabled': boolean,
//     'enabled_rooms': boolean,
//     'cancel_action': boolean,
//     'cancel_action_rooms': boolean,
//     'confirm_action': boolean,
//     'confirm_action_rooms': boolean,
//     'delay_hours':integer,
//     'delay_hours_rooms':integer,
//     'subject':string,
//     'message':string,
//     'message_rooms':string,
//     'subject_rooms':string,
//     'link': string,
//     'link_rooms': string,
//     'link_text': string,
//     'link_text_rooms': string
// }
 getAcknowledgeConfig(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/site/getacknowledgeconfig', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }
//  POST
// /api/1.0/site/setacknowledgeconfig
// ARG
//
// {
//     'site_id': intgeger,
//     'enabled': boolean,
//     'enabled_rooms': boolean,
//     'cancel_action': boolean,
//     'cancel_action_rooms': boolean,
//     'confirm_action': boolean,
//     'confirm_action_rooms': boolean,
//     'delay_hours':integer,
//     'delay_hours_rooms':integer,
//     'subject':string,
//     'message':string,
//     'message_rooms':string,
//     'subject_rooms':string,
//     'link': string,
//     'link_rooms': string,
//     'link_text': string,
//     'link_text_rooms': string
// }
// RESULT: same as getacknowledgeconfig
 setAcknowledgeConfig(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/site/setacknowledgeconfig', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }
//  API:
// POST /api/1.0/admin/setsitecreatemessage {site_id:integer, enabled:boolean, message:string}
//
// site has 2 attributes:
//
// create_reservation_warning_message:string
// create_warning_message_enabled:boolean
 setsitecreatemessage(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/admin/setsitecreatemessage', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }
 // POST /api/1.0/admin/gethidereservationsubject => {hide_reservation_subject:boolean}
 gethidereservationsubject() {
   return this.http.post(environment.backend_url + environment.api_prefix + '/admin/gethidereservationsubject', {})
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }
 // POST /api/1.0/admin/sethidereservationsubject {hide_reservation_subject:boolean} => {hide_reservation_subject:boolean}
 sethidereservationsubject(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/admin/sethidereservationsubject', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }
 // POST admin/defaultreservation/getpossibledefaults {site_id:integer} ==>
 //  {
 //          single_start_possible_values:array of tstrings,
 //          single_start_selected_value: tstring.
 //          single_duration_possible_values:array of tstrings,
 //          single_duration_selected_value: tstring,
 //          multi_start_possible_values:array of tstrings,
 //          multi_start_selected_value: tstring.
 //          multi_duration_possible_values:array of tstrings,
 //          multi_duration_selected_value:tstring
 //  }
 getpossibledefaults(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/admin/defaultreservation/getpossibledefaults', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }
 //  strings passed here should be the "key" part of the tstring
 //  POST admin/defaultreservation/setdefaults {
 //      site_id:integer,
 //      single_start_selected:string,
 //      single_start_duration_string,
 //      multi_start_selected:string,
 //      multi_duration_selected:string
 //  } ==> same as getpossibledefaults
 setdefaults(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/admin/defaultreservation/setdefaults', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }
 // POST /api/1.0/site/getprivileges {site_id} ==> list of privileges
 getprivileges(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/site/getprivileges', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }

 // POST /api/1.0/user/setprivilege {user_id, privilege_id} ==> user [the privilege_id has to belong to the site_id, the backend will have to verify that there are no 2 privileges for the same site for a user]
 // user will have a new attribute privilege_id
 setprivilege(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/user/setprivilege', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }

 // POST /api/1.0/getcompanylogo ==> url
 getcompanylogo() {
   return this.http.post(environment.backend_url + environment.api_prefix + '/getcompanylogo', {})
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }
// POST /api/1.0/site/getdurationlimitconfig {"site_id":id}  => {"desk" : {"enabled":boolean, "hours": int, "minutes": int}, "room" :{"enabled":boolean, "hours": int, "minutes": int}}
 getDurationLimitConfig(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/site/getdurationlimitconfig', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }

 //  POST /api/1.0/site/setdurationlimitconfig {"site_id":id, "desk" : {"enabled":boolean, "hours": int, "minutes": int}, "room" :{"enabled":boolean, "hours": int, "minutes": int}}
 setDurationLimitConfig(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/site/setdurationlimitconfig', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }

 // POST /api/1.0/site/settimerangeconfig {
 //   "site_id":id,
 //    "desk": {"enabled":boolean, "config":string},
 //    "room" : {"enabled":boolean, "config":string}
 //  }
 setTimeRangeConfig(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/site/settimerangeconfig', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }
//  POST /api/1.0/site/gettimerangeconfig {"site_id":id}  => {
//    "desk": {"enabled":boolean, "config":string},
//    "room" : {"enabled":boolean, "config":string}
// }
 getTimeRangeConfig(data) {
   return this.http.post(environment.backend_url + environment.api_prefix + '/site/gettimerangeconfig', data)
   .pipe(
     tap(res => {console.log(res)}),
     switchMap(res => {return of(res)}),
     catchError(err => {return of(err)})
   )
 }

}
