import {
  Input,
  Output,
  Component,
  EventEmitter,
  ViewChild
} from '@angular/core';

import { SiteSelectComponent } from './../site-select/site-select.component';

@Component({
  selector: 'site-card',
  templateUrl: './site-card.component.html',
  styleUrls: ['./site-card.component.css']
})
export class SiteCardComponent {

  @ViewChild(SiteSelectComponent) siteSelectComponent: SiteSelectComponent;

  @Input() mainHeader : any;
  @Input() subHeader : any;
  @Input() isDisplayNone: boolean = false;

  @Output() siteCahnge = new EventEmitter<any>();
  @Output() siteInit = new EventEmitter<any>();

  constructor() { }

  siteCahngeInnerComponent() {
    this.siteCahnge.emit(this.siteSelectComponent.selectedSite)
  }
  siteInitInnerComponent() {
    this.siteInit.emit(this.siteSelectComponent.selectedSite)
  }

}
