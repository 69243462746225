import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { LocalizationService } from "./../../services/localization/localization.service";
import { MapsZoomToggleService } from "app/services/maps-zoom-toggle/maps-zoom-toggle.service";

@Component({
  selector: 'site-wings-reservation-view-external-data-set',
  templateUrl: './site-wings-reservation-view-external-data-set.component.html',
  styleUrls: ['./site-wings-reservation-view-external-data-set.component.css']
})
export class SiteWingsReservationViewExternalDataSetComponent {

  dateTimeRange: any;
  reservedWingOnly: boolean = false;

  selectedReservation: any;
  selectedSite: any;
  wingID: number = null;
  idSelect: number = null;

  wingsData: any = [];
  wingSelectedData: any = {id: -1, name:'All wings', assets:[], image: ''};
  isWingSelected: boolean = true;

  @Input() isShowSlotsInfo: boolean = false;
  @Input() isReportingIssue : boolean = true;
  @Input() showAssetsWithoutAllAmenities : boolean = true;
  @Input() assetsSelecteble : boolean = true;
  @Input() isTodoCheck: boolean = false; // admin todo only
  @Input() isTwoMaps: boolean = false; // admin todo only
  @Output() assetChanged : EventEmitter<any>;
  @Output() wingDetailsChanged : EventEmitter<any>;

  button: any;
  languages: any;

  constructor(
    private localizationService: LocalizationService,
    private mapsZoomToggleService: MapsZoomToggleService
  ) {
    if(this.localizationService.getReusableDataText('button').isSuccess) {
      this.button = this.localizationService.getReusableDataText('button').data;
    }

    if(this.localizationService.getComponentText('shared_components','site_wings_reservation_view_external_data_set').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','site_wings_reservation_view_external_data_set').data;
    }

    this.assetChanged = new EventEmitter<any>();
    this.wingDetailsChanged = new EventEmitter<any>();
  }

  checkWingAssetData() {
    console.log(this.wingsData)
    console.log(this.idSelect != null && this.idSelect != 0 && this.wingsData != undefined)
    if (this.idSelect != null && this.idSelect != 0 && this.wingsData != undefined) {
      console.log('++++')
      let data = this.wingsData.find(wing => {
        return wing.assets.find(asset => asset.id == this.idSelect)
      });
      console.log(data)
      if (data != undefined) {
        this.wingID = data.id;
        this.wingSelectedData = data;
        if (this.isWingSelected) {
          this.wingDetail(this.wingSelectedData);
        }
        console.log(this.wingSelectedData)
      }
    } else {
      console.log('-----')
      this.idSelect = 0;
      if(this.wingID)
      this.wingSelectedData = {id: -1, name:'All wings', assets:[], image: ''};
      this.isWingSelected = true;
      if(this.wingsData.length == 1) {
        this.wingDetail(this.wingsData[0])
      }
    }
  }

  onSelectedAsset(asset) {
    if (asset.id != this.idSelect) {
      this.idSelect = asset.id;
      this.assetChanged.emit(asset);
    }
  }

  wingDetail(wing) {
    if(this.isWingSelected) {
      this.wingID = wing.id;
      this.wingSelectedData = wing;
      this.isWingSelected = false;
    } else {
      this.wingID = undefined;
      this.wingSelectedData = {id: -1, name:'All wings', assets:[], image: ''};
      this.isWingSelected = true;
    }
    this.wingDetailsChanged.emit(this.wingSelectedData)
    console.log('wingDetail');
    this.mapsZoomToggleService.change();
  }

}
