import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { TimeDialogComponent } from "./time-dialog/time-dialog.component";

import * as moment from 'moment';
import * as _ from "lodash";
import { LocalizationService } from "app/services/localization/localization.service";

@Component({
  selector: 'day-time-mini',
  templateUrl: './day-time-mini.component.html',
  styleUrls: ['./day-time-mini.component.css']
})
export class DayTimeMiniComponent {

  dateTimeMini: any;
  timeMini: any;
  headerText: string;

  @Input() set setDateTime(value) {
    if (value != undefined) {
      console.log(this.dateTimeMini)
      console.log(value)
      this.dateTimeMini = _.cloneDeep(value.date);
      this.timeMini = _.cloneDeep(value.date);
      this.headerText = value.text;
    }
  }
  @Input() setBackground: any;
  @Output() dateTimeChange : EventEmitter<any>;

  languages: any;

  constructor(
    public dialog: MatDialog,
    private localizationService: LocalizationService
  ) {
    if(this.localizationService.getComponentText('shared_components','day_time_mini').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','day_time_mini').data;
    }

    this.dateTimeChange = new EventEmitter<any>();
    this.dateTimeMini = moment().hour(8).minute(0).second(0).toDate();
  }

  timeDialog() {
    let dialogData;
    if ((document.getElementsByTagName('body')[0] as HTMLElement).clientWidth > 600) {
      dialogData = {
        data: {
          date: this.dateTimeMini,
          text: this.headerText
        }
      };
    } else {
      dialogData = {
        data: {
          date: this.dateTimeMini,
          text: this.headerText
        },
        position: {
          top: '0px'
        }
      };
    }
    this.dialog.open(TimeDialogComponent,  dialogData )
    .afterClosed().subscribe(result => {
      if(result) {
        console.log(this.dateTimeMini)
        console.log(result)
        this.dateTimeMini =  _.cloneDeep(result);
        this.timeMini = _.cloneDeep(result);
        this.dateTimeChange.emit(this.dateTimeMini);
      }
    });
  }

  // onChange(value) {
  //   this.dateTimeMini = moment(this.dateTimeMini).hour(moment(this.timeMini).hour()).minute(moment(this.timeMini).minute()).second(moment(this.timeMini).second()).toDate();
  //   this.timeMini = _.cloneDeep(this.dateTimeMini)
  //   this.dateTimeChange.emit(this.dateTimeMini);
  // }

}
