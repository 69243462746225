<div *ngIf="!isReleaseReservationDetails" class="range-container" [ngClass]="{'range-container-column': isColumn, 'range-day-only-container': isDaysOnly, 'fromOnly': fromOnly}" fxLayoutAlign="space-between ">
  <div class="row-container" fxLayout="row" fxLayoutAlign=" center">
    <span *ngIf="isDaysOnly">{{languages.headers.from}}</span>
    <div class="row-date-time" *ngIf="!isDaysOnly" [ngClass]="{'disabled': disable, 'fromOnly': fromOnly}" (click)="rangeModal()">
      <div *ngIf="wants_24h_time_format">{{dateTimeRange.from | datex: 'ddd, DD MMM YYYY, HH:mm'}}</div>
      <div *ngIf="!wants_24h_time_format">{{dateTimeRange.from | datex: 'ddd, DD MMM YYYY, LT'}}</div>
    </div>
    <div class="row-date-time row-day-only" *ngIf="isDaysOnly" [ngClass]="{'disabled': disable}" (click)="rangeModal()">
      <div>{{dateTimeRange.from | datex: 'ddd, DD MMM YYYY'}}</div>
    </div>
    <span *ngIf="!isDaysOnly && !fromOnly">to</span>
  </div>
  <div class="row-container" *ngIf="!fromOnly" fxLayout="row" fxLayoutAlign=" center">
    <div class="row-date-time" *ngIf="!isDaysOnly" [ngClass]="{'disabled': disable}" (click)="rangeModal()">
      <div *ngIf="wants_24h_time_format">{{dateTimeRange.to | datex: 'ddd, DD MMM YYYY, HH:mm'}}</div>
      <div *ngIf="!wants_24h_time_format">{{dateTimeRange.to | datex: 'ddd, DD MMM YYYY, LT'}}</div>
    </div>
    <span *ngIf="isDaysOnly">{{languages.headers.to}}</span>
    <div class="row-date-time row-day-only" *ngIf="isDaysOnly" [ngClass]="{'disabled': disable}" (click)="rangeModal()">
      <div>{{dateTimeRange.to | datex: 'ddd, DD MMM YYYY'}}</div>
    </div>
  </div>
</div>
<div *ngIf="isReleaseReservationDetails" class="range-container range-container-column-2" fxLayoutAlign="space-between " (click)="rangeModal()">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{languages.headers.start}}</span>
    <div *ngIf="!isDaysOnly" class="row-date-time" fxLayout="row" fxLayoutAlign="end center" [ngClass]="{'disabled-reservation-release': disable}">
      <span>{{dateTimeRange.from | datex: 'ddd, DD MMM YYYY,'}}</span>
      <span *ngIf="wants_24h_time_format" class="time time-24">{{dateTimeRange.from | datex: 'HH:mm'}}</span>
      <span *ngIf="!wants_24h_time_format" class="time">{{dateTimeRange.from | datex: 'LT'}}</span>
    </div>
    <div class="row-date-time row-day-only" *ngIf="isDaysOnly" [ngClass]="{'disabled': disable}" (click)="rangeModal()">
      <div>{{dateTimeRange.from | datex: 'ddd, DD MMM YYYY'}}</div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{languages.headers.end}}</span>
    <div *ngIf="!isDaysOnly" class="row-date-time" fxLayout="row" fxLayoutAlign="end center" [ngClass]="{'disabled-reservation-release': disable}">
      <span *ngIf="!isSameDay">{{dateTimeRange.to | datex: 'ddd, DD MMM YYYY,'}}</span>
      <span *ngIf="wants_24h_time_format" class="time time-24">{{dateTimeRange.to | datex: 'HH:mm'}}</span>
      <span *ngIf="!wants_24h_time_format" class="time">{{dateTimeRange.to | datex: 'LT'}}</span>
    </div>
    <div class="row-date-time row-day-only" *ngIf="isDaysOnly" [ngClass]="{'disabled': disable}" (click)="rangeModal()">
      <div>{{dateTimeRange.to | datex: 'ddd, DD MMM YYYY'}}</div>
    </div>
  </div>
</div>
