import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBookingObject'
})
export class FilterBookingObjectPipe implements PipeTransform {

  transform(items: any[], names: string): any {
    if (!items || names=='') {
      return items;
    }

    return items.filter(
      item => item.name.toLowerCase().indexOf(names.toLowerCase()) !== -1 ||
      item.wing_name.toLowerCase().indexOf(names.toLowerCase()) !== -1 ||
      item.time_range.indexOf(names.toLowerCase()) !== -1 ||
      item.user.name.toLowerCase().indexOf(names.toLowerCase()) !== -1
    );

  }

}
