import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SnackBarComponent } from './snack-bar/snack-bar.component';

export * from './snack-bar/snack-bar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule
  ],
  declarations: [ SnackBarComponent ],
  exports: [ SnackBarComponent ],
  entryComponents: [ SnackBarComponent ]
})
export class SnackBarModuleModule { }
