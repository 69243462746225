// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    update_interval: 10800000,
    version: '24.10.30.4',
    api_prefix: 'api/1.0',
    api_prefix_v2: 'api/2.0',
    image_url_type: 'absolute',
    single_url: 'https://dev.tribeloo.com/',
    backend_url: "https://dev.tribeloo.com/",
    qrfrontend_url: "https://pwafrontend.wasdmco.net/",
    calendar_client_id: "445364193060-d5l3r2f7gsdpvlvsnt5pn3i6ardifa90.apps.googleusercontent.com",
    g_code: 'G-NBWR20XYEH',
    structure_checker: false,
    download_xlsx: false,
    example: false,
    is_v2: true,
    is_trial: false,
    has_registration: false
};
