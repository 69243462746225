<div class="main-container"  fxLayout="column" fxLayoutAlign="center center">
  <!-- <i class="fa fa-4x fa-info-circle" *ngIf="data.icon"></i> -->
  <i class="fa fa-exclamation-circle" *ngIf="data.icon"></i>
  <div class="message-header" *ngIf="data.text">
    {{data.text}}
  </div>
  <!-- <div class="message-header big-titel"> -->
  <div class="message-header" *ngIf="data.big_titel">
    {{data.big_titel}}
  </div>
  <div class="message-description-container-big" *ngIf="data.big_descriptions">
    <p *ngFor="let description of data.big_descriptions">{{description}}</p>
  </div>
  <div class="message-description-container" *ngIf="data.descriptions">
    <p *ngFor="let description of data.descriptions">{{description}}</p>
  </div>
  <div class="actions" fxLayout="row" fxLayoutAlign="center center" *ngIf="!data.cancel">
    <div class="button-accent" (click)="onClick(false)">{{button.cancel}}</div>
    <div class="button-confirm" (click)="onClick(true)">{{data.descriptions ? button.continue : button.confirm}}</div>
  </div>
  <div class="actions" fxLayout="row-reverse" *ngIf="data.cancel">
    <div class="button-confirm" (click)="onClick(true)"><i class="fa fa-check" *ngIf="!data.descriptions"></i>{{data.descriptions ? button.continue : button.confirm}}</div>
  </div>
</div>
