import {
  Input,
  Output,
  Component,
  EventEmitter
} from '@angular/core';
import { LocalizationService } from "./../../services/localization/localization.service";

@Component({
  selector: 'asset-list-item',
  templateUrl: './asset-list-item.component.html',
  styleUrls: ['./asset-list-item.component.css']
})
export class AssetListItemComponent {

  @Input() asset: any;
  @Output() selectNewAsset = new  EventEmitter<any>();

  has_internal_external_rooms: boolean = JSON.parse(localStorage.getItem("currentUser")).user.features.has_internal_external_rooms ? JSON.parse(localStorage.getItem("currentUser")).user.features.has_internal_external_rooms : false;
  has_neighbourhoods: boolean = JSON.parse(localStorage.getItem("currentUser")).user.features.has_neighbourhoods;

  languages: any;

  constructor(private localizationService: LocalizationService) {
    if(this.localizationService.getComponentText('shared_components','expanded_filter_assets_list').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','expanded_filter_assets_list').data;
    }
  }

  select() {
    this.selectNewAsset.emit(this.asset);
  }
}
