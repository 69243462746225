import {
  Component,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as moment from 'moment';
import * as _ from "lodash";
import { LocalizationService } from "app/services/localization/localization.service";

@Component({
  selector: 'time-dialog',
  templateUrl: './time-dialog.component.html',
  styleUrls: ['./time-dialog.component.css']
})
export class TimeDialogComponent {

  hoursSelect: any = [];
  minutesSelect: any = [];

  hour: number = 0;
  minute: number = 0;

  date: any;

  languages: any;
  button: any;

  constructor(
    public dialogRef: MatDialogRef<TimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localizationService: LocalizationService
  ) {
    if(this.localizationService.getComponentText('shared_components','day_time_mini').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','day_time_mini').data;
    }
    if(this.localizationService.getReusableDataText('button').isSuccess) {
      this.button = this.localizationService.getReusableDataText('button').data;
    }
  }

  ngOnInit() {
    this.date = _.cloneDeep(moment(this.data.date).toDate());
    this.hour = _.cloneDeep(moment(this.data.date).hours());
    this.minute = _.cloneDeep(moment(this.data.date).minutes());

    for (let i = 0; i < 24; i++) {
      this.hoursSelect.push(i);
    }
    for (let i = 0; i < 60; i++) {
      this.minutesSelect.push(i);
    }
  }

  onChange(date) {

  }

  timeChange() {
    this.date = moment(this.date).hours(this.hour).minutes(this.minute).toDate();
    this.dialogRef.close(this.date);
  }



// select
  selectHour(hour) {
    this.data.date = moment(this.data.date).hours(hour).toDate();
  }

  selectMinute(minute) {
    this.data.date = moment(this.data.date).minutes(minute).toDate();
  }


// hours
  increaseHours() {
    if (this.hour != 23) {
      this.data.date = moment(this.data.date).add(1, 'h').toDate();
      this.hour = moment(this.data.date).hours();
    } else {
      this.hour = 0;
      this.data.date = moment(this.data.date).hours(0).toDate()
    }
  }

  decreaseHours() {
    if (this.hour != 0) {
      this.data.date = moment(this.data.date).add(-1, 'h').toDate();
      this.hour = moment(this.data.date).hours();
    } else {
      this.hour = 23;
      this.data.date = moment(this.data.date).hours(23).toDate()
    }
  }


// minutes
  increaseMinutes() {
    if (this.hour == 23 && this.minute == 59) {
      this.data.date = moment(this.data.date).hours(0).minutes(0).toDate();
      this.hour = 0;
      this.minute = 0;
    } else {
      this.data.date = moment(this.data.date).add(1, 'm').toDate();
      this.minute = moment(this.data.date).minutes();
      this.hour = moment(this.data.date).hours();
    }
  }

  decreaseMinutes() {
    if (this.hour == 0 && this.minute == 0) {
      this.data.date = moment(this.data.date).hours(23).minutes(59).toDate();
      this.hour = 23;
      this.minute = 59;
    } else {
      this.data.date = moment(this.data.date).add(-1, 'm').toDate();
      this.minute = moment(this.data.date).minutes();
      this.hour = moment(this.data.date).hours();
    }
  }

}
