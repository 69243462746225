<div class="filter" [ngClass]="{'filter-disabled': disabled}" fxLayout="column" *ngIf="items.length">
  <div class="header">
    <div class="header-filter" fxLayout="row" fxLayoutAlign="space-between center">
      <p>{{name}}</p>
      <i class="fa fa-times-circle" (click)="close()"></i>
    </div>

    <div class="filter-actions" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="Button-blue" fxLayoutAlign="center center" (click)="inverceSelect()"><i class="fa fa-refresh"></i> {{button.inverse}}</div>
      <div class="Button-blue" fxLayoutAlign="center center" (click)="apply()" [ngClass]="{'unApllyChanges': isChange}">{{button.confirm}}</div>
    </div>
    <div class="action checkbox" (click)="selectOrUnselectAll(!selectedAll)" fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{button.select_all}}</span>
      <div fxLayout="row" fxLayoutAlign="center center">
        <i *ngIf="selectedAll" class="fa fa-check"></i>
      </div>
    </div>
  </div>

  <div class="filter-list" fxLayout="column" fxLayoutAlign="start end">
    <div class="filter-list-item" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let item of items; let i = index" (click)="itemClick(i)">
      <span>{{item.name}}</span>
      <div fxLayout="row" fxLayoutAlign="center center">
        <i *ngIf="item.isChecked" class="fa fa-check"></i>
      </div>
    </div>
  </div>
</div>
