import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsfeedService {

  constructor(private http: HttpClient) { }

  // post api/1.0/newsfeed/getitems
  // req { site_id, days }
  // resp.data: [{
  //   created_date, [string date]
  //   updated_date, [string date]
  //   available_days, [number]
  //   id, [number]
  //   image, [string path] || [empty string]
  //   text, [string]
  //   type, [string]
  // }...]
  getitems(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/newsfeed/getitems', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // post api/1.0/newsfeed/createitem
  // req {
  //   available_days, [number]
  //   image, [image data] || [empty string]
  //   text, [string]
  //   type, [string]
  // }
  createitem(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/newsfeed/createitem', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // post api/1.0/newsfeed/updateitem
  // req {
  //   available_days, [number]
  //   item_id, [number]
  //   image, [image data] || [string path] || [empty string]
  //   text, [string]
  //   type, [string]
  // }
  updateitem(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/newsfeed/updateitem', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // post api/1.0/newsfeed/deleteitem
  // req: {
  //    item_id [number]
  // }
  deleteitem(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/newsfeed/deleteitem', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // post api/1.0/newsfeed/shareitem
  // req: {
  //   item_id, [number]
  //   users: [numbers array]
  // }]
  shareitem(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/newsfeed/shareitem', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }


  // POST /api/1.0/newsfeed/getattendees
  // ARGUMENTS: {'event_id'}
  // RESULT {[user1,user2,....]}
  // users have email and name and image
  getattendees(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/newsfeed/getattendees', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // POST /api/1.0/newsfeed/sendmailtoattendees
  // ARGUMENTS: {'event_id', 'text'}
  // RESULT: none
  // mail will be sent to all users with the 'text' in the body
  sendmailtoattendees(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/newsfeed/sendmailtoattendees', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // POST /api/1.0/user/newsfeed/attend
  // ARGUMENTS: {'item_id','attend':boolean}
  // RESULT: none ?
  userAttend(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/newsfeed/attend', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // POST /api/1.0/user/newsfeed/getattendees
  // ARGUMENTS: {'item_id'}
  userGetattendees(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/newsfeed/getattendees', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // POST /api/1.0/user/newsfeed/getitems
  // ARGUMENTS: site_ids:array of site ids
  // RESULT: [iteminfo, iteminfo, ....]
  // iteminfo:
  // normal item info (site, site_name, subject, image, text, ...)
  // user_attends:boolean
  // attendees: [{name, email, image},...]
  // has_more_attendees:boolean (if more than 3 attendees, the first 3 will be in 'attendees')
  userGetitems(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/newsfeed/getitems', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // POST /api/1.0/user/newsfeed/getsites
  // ARGUMENTS: none
  // RESULT {'available':[site1,site2,....],'selected':[site1,site2,....]}
  userGetsites() {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/newsfeed/getsites', {})
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

//   POST /api/1.0/site/getnewsfeedavailabledays
// ARGUMENTS: {site_id}
  getnewsfeedavailabledays(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/getnewsfeedavailabledays', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
//   POST /api/1.0/site/setnewsfeedavailabledays
// ARGUMENTS { site_id:, available_days: }
// RESULT: site
  setnewsfeedavailabledays(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/setnewsfeedavailabledays', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // /api/1.0/newsfeed/haslinkedsite {site_id} ==> {has_linked_site:boolean, linked_site_id}
  haslinkedsite(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/newsfeed/haslinkedsite', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // /api/1.0/newsfeed/linksite {site_id, linked_site_id}
  linksite(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/newsfeed/linksite', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // /api/1.0/newsfeed/unlinksite {site_id}
  unlinksite(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/newsfeed/unlinksite', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

}
