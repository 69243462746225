<div class="" fxLayout="row" fxLayoutAlign=" center">
  <div class="action-arrow" fxLayoutAlign="center center" (click)="dayIncreaseDecrease(false)">
    <i class="fa fa-angle-left"  matTooltip="set previos day" matTooltipPosition="above" [matTooltipClass]="{'info-tooltip': true}"></i>
  </div>

  <div class="action-button" fxLayoutAlign="center center" (click)="toDay()" matTooltip="set today" matTooltipPosition="above" [matTooltipClass]="{'info-tooltip': true}">TODAY</div>

  <div class="action-arrow" fxLayoutAlign="center center" (click)="dayIncreaseDecrease(true)">
    <i class="fa fa-angle-right" matTooltip="set next day" matTooltipPosition="above" [matTooltipClass]="{'info-tooltip': true}"></i>
  </div>
</div>
