<div class="main-container" [ngClass]="{'time-only':isDaysOnly}" fxLayout="column" fxLayoutAlign=" center">
  <i class="fa fa-exclamation-circle" *ngIf="data.icon"></i>
  <h3>{{text != "" ? text : languages.headers.main}}</h3>
  <div class="" *ngIf="descriptions.length">
    <p *ngFor="let item of descriptions">{{item}}</p>
  </div>

  <span class="rangeFlag" [ngClass]="{'time-only':isDaysOnly}">{{languages.headers.from}}</span>
  <div class="date-time-container" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="date-container">
      <mat-form-field class="date-space">
        <input matInput [(ngModel)]="fromDate" [min]="minDate" [max]="maxDate" [matDatepicker]="from" (dateChange)="fromTimeChange($event)" disabled>
        <mat-datepicker #from [startAt]="fromDate" disabled="false"></mat-datepicker>
      </mat-form-field>
      <div class="date" [ngStyle]="{'background': '#f0f0f0'}" fxLayoutAlign="space-between center" (click)="from.open()"  matTooltip="{{languages.tooltip}}">
        <span>{{fromDate | datex: 'DD MMMM YYYY'}}</span>
        <i class="fa fa-calendar"></i>
      </div>
    </div>

    <!-- <span>:</span> -->

    <div *ngIf="!isDaysOnly" class="timeWidth">
      <mat-form-field class="select">
        <mat-select [(ngModel)]="fromHour">
          <mat-option style="color: #4a4a4a !important; font-size:14px;" *ngFor="let HT of hoursSelect" [value]="HT" (click)="selectHourFrom(HT)">{{ HT }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <span *ngIf="!isDaysOnly">:</span>

    <div *ngIf="!isDaysOnly" class="timeWidth timeMinutesWidth">
      <mat-form-field class="select">
        <mat-select [(ngModel)]="fromMinute">
          <mat-option style="color: #4a4a4a !important; font-size:14px;" *ngFor="let MT of minutesSelect" [value]="MT" (click)="selectMinuteFrom(MT)">{{ MT < 10 ? '0' + MT : MT }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>

  <span class="rangeFlag" [ngClass]="{'time-only':isDaysOnly}" *ngIf="!fromOnly">{{languages.headers.to}}</span>
  <div class="date-time-container" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!fromOnly">
    <div class="date-container">
      <mat-form-field class="date-space">
        <input matInput [(ngModel)]="toDate" [min]="minDate" [max]="maxDate" [matDatepicker]="to" (dateChange)="toTimeChange($event)" disabled>
        <mat-datepicker #to [startAt]="toDate" disabled="false"></mat-datepicker>
      </mat-form-field>
      <div class="date" [ngClass]="{'wrong-range': !isBefore}" [ngStyle]="{'background': '#f0f0f0'}" fxLayoutAlign="space-between center" (click)="to.open()"  matTooltip="{{languages.tooltip}}">
        <span>{{toDate | datex: 'DD MMMM YYYY'}}</span>
        <i class="fa fa-calendar"></i>
      </div>
    </div>

    <!-- <span>:</span> -->

    <div *ngIf="!isDaysOnly" class="timeWidth" [ngClass]="{'wrong-range': !isBefore}">
      <mat-form-field class="select">
        <mat-select [(ngModel)]="toHour">
          <mat-option style="color: #4a4a4a !important; font-size:14px;" *ngFor="let HT of hoursSelect" [value]="HT" (click)="selectHourTo(HT)">{{ HT }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <span *ngIf="!isDaysOnly">:</span>

    <div *ngIf="!isDaysOnly" class="timeWidth timeMinutesWidth" [ngClass]="{'wrong-range': !isBefore}">
      <mat-form-field class="select" [ngClass]="{'wrong-range': !isBefore}">
        <mat-select [(ngModel)]="toMinute">
          <mat-option style="color: #4a4a4a !important; font-size:14px;" *ngFor="let MT of minutesSelect" [value]="MT" (click)="selectMinuteTo(MT)">{{ MT < 10 ? '0' + MT : MT }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>

  <p *ngIf="!isBefore">{{languages.info}}</p>

  <div class="actionBtnGroup" fxLayout="row"  fxLayoutAlign="center center">
    <div class="button-accent" (click)="dialogRef.close()">{{button.cancel}}</div>
    <div class="button-confirm" *ngIf="isBefore" (click)="timeChange()">{{button.confirm}}</div>
    <div class="button-confirm disabled" *ngIf="!isBefore">{{button.confirm}}</div>
  </div>
</div>
