import {
  Input,
  Output,
  Component,
  EventEmitter,
  ViewChild
} from '@angular/core';

import { AssetsService } from "./../../services/assets/assets.service";
import { environment } from '../../../environments/environment';
import { LocalizationService } from "./../../services/localization/localization.service";
import { MapsZoomToggleService } from "app/services/maps-zoom-toggle/maps-zoom-toggle.service";
import * as bowser from 'bowser';

import { MatDialog } from '@angular/material/dialog';
import { AssetDetailsModalComponent } from "./../asset-details-modal/asset-details-modal.component"

@Component({
  selector: 'assets-map',
  templateUrl: './assets-map.component.html',
  styleUrls: ['./assets-map.component.css'],
  providers: [ AssetsService ]
})
export class AssetsMapComponent {

  @ViewChild('myPinch') myPinch;
  wants_asset_details_popup: boolean = JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser")).user.wants_asset_details_popup :  false;
  parentdiv: { height:Number, width:Number } = { height: 0, width: 0 };

  isRelative: boolean = environment.image_url_type != 'absolute';
  backend_url: string = environment.backend_url;

  searchbox: string = ""; // search asset by name (use pipe filter)

  assetData: any = {}; // selected asset data
  assets: any = [];
  idAsset: Number = 0;
  idHoverAsset: Number = -1;
  hoverAsset: any;

  selectedAsset: any = null; // reservation selected asset

  zoomBehavior;
  specialWingNumber: number = Math.floor(Math.random() * 100);
  isPopup: boolean = false;

  @Input() isShowSlotsInfo: boolean = false;
  @Input() isDoubleWingsView: boolean = false;
  @Input() wingID : number = -1;
  @Input() isReportingIssue : boolean = true;
  @Input() showAssetsWithoutAllAmenities : boolean = true;
  @Input() assetsSelecteble: boolean = true; // set outer flag is selectable assets
  @Input() isTodoCheck: boolean = false; // admin todo only
  @Input() imageData: string = ''; // set image of the wing map
  @Input() set assetsData(assetsData: any[]) { //set assets data
    console.log(assetsData)
    console.log(this.assetData)
    console.log(this.idAsset)
    if (assetsData != undefined) {
      this.assets = assetsData;
      if(!this.assetData) {
        this.assetData = {}
      }
      if (Object.keys(this.assetData).length != 0 && this.idAsset) {
        this.assetData = this.assets.find(asset => asset.id == this.idAsset);
      }
    }
  }
  @Input() set idSelect(idSelect: any ) { // set selected asset
    if(this.assetData) {
      this.assetData.isSelected = false;
    }
    this.assetData = {}
    this.idAsset = idSelect ? idSelect : null;
    let exists = this.assets.find(asset => asset.id == this.idAsset)
    if (exists) {
      this.setSelected(exists)
    }
  }

  @Output() onSelectedAsset = new EventEmitter<Number>(); // triger ivent to the parent after set or unset asset

  languages: any;

  constructor(
    private assetsService: AssetsService,
    private localizationService: LocalizationService,
    private mapsZoomToggleService: MapsZoomToggleService,
    public dialog: MatDialog,
  ) {
    this.popupCheck();
    this.zoomBehavior = this.mapsZoomToggleService.selectionChange.subscribe((event) => {
      if(this.myPinch) {
        if(this.myPinch.scale > 1) {
          this.toggleZoom();
        }
      }
    });

    if(this.localizationService.getComponentText('shared_components','assets_map').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','assets_map').data;
    }
  }

  ngOnDestroy() {
    this.zoomBehavior.unsubscribe();
  }

  popupCheck() {
    console.log(window.screen.width)
    console.log(window.screen.width < 960)
    this.isPopup = bowser.ios != undefined || bowser.android != undefined || bowser.windowsphone != undefined || window.screen.width < 960 || window.innerWidth < 960
  }
  checkSelection(asset) {
    this.popupCheck();
    // this.wants_asset_details_popup ? this.details(asset) : this.select(asset);
    if (this.isPopup) {
      this.details(asset)
    } else {
      this.select(asset)
    }
  }

// Asset selection -----------------
  select(asset) {
    // check asset status and user premission
    if(!this.showAssetsWithoutAllAmenities && !asset.has_requested_amenities) {
      return;
    }
    if (asset.status == 0) {
      if(this.isTodoCheck) {
        this.setSelected(asset);
      }
      // this.dialog.open(InfoDialogComponent, { disableClose: true, data: {text: "You hasn't acces. Asset reserved."}})
    } else {
      this.setSelected(asset);
    }
  }

// Check selected asset and try to set them
  setSelected(asset) {
    // check component outer settings is selectable assets
    if(this.assetsSelecteble) {
      // check Previous asset object selection
      if (Object.keys(this.assetData).length != 0) {
        this.assetData.isSelected = false;
      }
      // checking is new asset the same with old asset
      if (this.assetData.id != asset.id) {
        // reset asset
        this.assetData = asset;
        this.assetData.isSelected = true;
        this.onSelectedAsset.emit(asset);
      } else {
        // unset asset
        this.assetData = {};
        this.onSelectedAsset.emit(0);
      }
    }
  }

  mouseEnter(el, mousevent) {
    if(this.isPopup || !JSON.parse(localStorage.getItem("currentUser"))) {
      return;
    }

    this.idHoverAsset = el.id;
    if(!el["free_time_slots"]) {
      el.free_time_slots = [];
    }
    this.hoverAsset = el;
    let mouse = {clientX: mousevent.clientX, clientY: mousevent.clientY}
    let body = {clientWidth: document.getElementsByTagName('body')[0].clientWidth, clientHeight: document.getElementsByTagName('body')[0].clientHeight}
    let wingIndex = this.wingID != -1 && this.isDoubleWingsView ? 1 : 0;
    let wingContainer = {clientWidth: document.getElementsByClassName('wingContainer')[wingIndex].clientWidth, clientHeight: document.getElementsByClassName('wingContainer')[wingIndex].clientHeight}
    let checkValueMousePositionCenter = body.clientWidth - (wingContainer.clientWidth / 2);

    let tooltipeHeight = 100;
    if(el.colleague_info) {
      tooltipeHeight += 100;
    }
    if(el.all_amenities != '') {
      tooltipeHeight += 20;
    }
    if(el.owner_id) {
      tooltipeHeight += 20;
    }
    if(el.free_time_slots.length) {
      tooltipeHeight += 40;
      tooltipeHeight += 20 * el.free_time_slots.length + 1;
    }
    if(el.image) {
      tooltipeHeight += 150;
    }

    this.parentdiv.height = (mouse.clientY - tooltipeHeight) > 100 ? mouse.clientY - tooltipeHeight : 100;
    this.parentdiv.width = mouse.clientX < checkValueMousePositionCenter ? mouse.clientX + 20 : mouse.clientX - 220;
  }
  mouseLeave() {
    this.idHoverAsset = -1;
    this.hoverAsset = undefined;
    this.parentdiv = { height:0, width: 0 };
  }

  toggleZoom() {
    this.myPinch.toggleZoom();
  }

  details(asset: any = undefined) {
    if(!JSON.parse(localStorage.getItem("currentUser"))) {
      return;
    }
    this.dialog.open(AssetDetailsModalComponent, {
      disableClose: false,
      maxWidth: window.screen.width < 600 ? '100vW !important' : '',
      position: {top: window.screen.width < 600 ? 'calc(env(safe-area-inset-top) + 10px)': '', left: ''},
      panelClass: ['borders-7px','no-sides-paddings'],
      data:{isShowSlotsInfo: this.isShowSlotsInfo, assets: this.assets, selectedAsset: asset ? asset : this.assetData, isReportingIssue: this.isReportingIssue, showAssetsWithoutAllAmenities: this.showAssetsWithoutAllAmenities}
    }).afterClosed().subscribe(result => {
      if(result) {
        this.select(result);
      }
    })
  }

}
