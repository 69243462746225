<newsfeed-item *ngIf="!isEdit" [isUser]="false" [newsItem]="_newsItem" (edit)="editing()" (remove)="remove.emit($event)" (messageToAttendees)="messageToAttendees.emit($event)"></newsfeed-item>
<div class="" *ngIf="isEdit" fxLayout="column" fxLayoutAlign="space-between ">
  <div class="item" fxLayout="column" fxLayoutAlign="space-between ">
    <div class="action-header" fxLayout="row" fxLayoutAlign="space-between center">
      <span [ngClass]="{'isPublicDateUpdate' : !disable}" (click)="rangeModal()">{{editNewsItem.public_date}}</span>
      <i class="fa fa-times" (click)="cancelItem()"></i>
    </div>
    <div class="description-container">
      <textarea maxlength="500" [(ngModel)]="editNewsItem.text"></textarea>
      <div class="" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="" fxLayout="column" fxLayoutAlign="start start">
          <p>{{languages.markdown}}</p>
          <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank">{{languages.link}}</a>
        </div>
        <div class="" fxLayout="column"  fxLayoutAlign="end start">
          {{editNewsItem.text.length}}/500
        </div>
      </div>
    </div>

    <div class="upload-container" fxLayout="column" fxLayoutAlign="center center">
      <img loading="lazy" *ngIf="editNewsItem.image" [src]="editNewsItem.image" alt="">
      <div *ngIf="editNewsItem.image" class="remove-button" fxLayout="column" fxLayoutAlign="center center">
        <i  class="fa fa-trash" (click)="removeImage()"></i>
      </div>
      <p *ngIf="!editNewsItem.image">{{languages.image_info}}</p>
      <download-image *ngIf="!editNewsItem.image && !isEdit" [id]="null" [nameBtn]="button.upload_image" (onImageLoadingPreview)="onImageLoadingPreview($event)" (onImageLoading)="onImageLoading($event)"></download-image>
      <download-image *ngIf="!editNewsItem.image && isEdit" [id]="null" [editItemId]="editNewsItem.id" [nameBtn]="button.upload_image" (onImageLoadingPreview)="onImageLoadingPreview($event)" (onImageLoading)="onImageLoading($event)"></download-image>
    </div>
  </div>

  <div class="types-container" *ngIf="!_newsItem" fxLayout="row">
    <span>{{languages.radio[0]}}</span>
    <input type="radio" id="News" [(ngModel)]="editNewsItem.type" name="type" value="News">
    <label for="News">{{languages.radio[1]}}</label>
    <input type="radio"  id="Event" [(ngModel)]="editNewsItem.type" name="type" value="Event">
    <label for="Event">{{languages.radio[2]}}</label>
  </div>
  <!-- <div class="types-container" *ngIf="_newsItem" fxLayout="row">
    <span>Type:</span>
    <input type="radio" id="News{{editNewsItem.id}}" [(ngModel)]="editNewsItem.type" name="type{{editNewsItem.id}}" value="News">
    <label for="News{{editNewsItem.id}}">News</label>
    <input type="radio"  id="Event{{editNewsItem.id}}" [(ngModel)]="editNewsItem.type" name="type{{editNewsItem.id}}" value="Event">
    <label for="Event{{editNewsItem.id}}">Event</label>
  </div> -->

  <input class="subject" [ngClass]="{'edit-mode': _newsItem}" type="text" [(ngModel)]="editNewsItem.event_subject" placeholder="{{languages.placeholder.event}}" *ngIf="editNewsItem.type == 'Event'">

  <date-time-range-view *ngIf="editNewsItem.type == 'Event'" [setDateTimeRange]="dateTimeRangeEvent" [isReleaseReservationDetails]="true" (dateTimeChange)="setNewDateTimeRange($event)"></date-time-range-view>

  <input class="subject" type="text" [(ngModel)]="editNewsItem.event_location" placeholder="{{languages.placeholder.location}}" *ngIf="editNewsItem.type == 'Event'">

  <div class="blue-button save" fxLayout="row" fxLayoutAlign="center center" (click)="createUpdateItem()">
    <i class="fa fa-floppy-o" aria-hidden="true"></i> {{button.save}}
  </div>
</div>
