import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class UsersService {

  constructor(
    private http: HttpClient
  ) { }

  getUsers() {
    return this.http.get(environment.backend_url + environment.api_prefix + '/users')
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  geSites() {
    return this.http.get(environment.backend_url + environment.api_prefix + '/sites')
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/1.0/user/trusteverybody {trust_everybody:boolean}
  getTrustEverybody(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/trusteverybody', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  setVersion(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/setselectedversion', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  //  {user_id, format:}
  settimeformat(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/settimeformat', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  wantsassetdetails(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/settings/wantsassetdetails', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getProxyInfo(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/proxyinfo', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  addProxy(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/addproxy', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  removeProxy(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/removeproxy', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  setPreferredSite(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/setpreferredsite', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  setPreferredWing(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/setpreferredwing', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getPreferredSite(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/getpreferredsite', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Colleague info
  // Method: POST
  // URL: api/1.0/user/colleagueinfo
  // req: {user_id}
  // res: {isSucces: true,
  //       data: {possibleColleagues:[{user1}, {user2}, ...}],
  //              colleagues:[{colleague1}, {colleague2}, ...]},
  //       errors: []}
  getColleagueInfo(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/colleagueinfo', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Add colleague for user
  // Method: POST
  // URL: api/1.0/user/addcolleague
  // req: {user_id, colleague_id}
  // res: {isSucces: true, data: {added_colleague}, errors: []}
  addColleague(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/addcolleague', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // Remove colleague for user
  // Method: POST
  // URL: api/1.0/user/removecolleague
  // req: {user_id, proxy_id}
  // res: {isSucces: true, data: {removed_colleague}, errors: []}
  removeColleague(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/removecolleague', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // POST /api/v1.0/user/getlistedsites ==> {"selected_site_ids": [id,....], "sites":[{"site_id", "site_name"}, ....]}
  getListedSites() {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/getlistedsites', {})
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/v1.0/user/setlistedsites ARG [id,....] ==> same as getlistedsites
  setListedSites(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/setlistedsites', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
}
