export const  ua = {
  type:'ua',
  code:'uk',
  name:'Український',
  img:'flag-icon-ua',
  pages: {
    login_main_navigation: {
      login: {
        pageName: 'login',
        callback: 'Закрийте це вікно та продовжуйте в Outlook додатку Tribeloo',
        email_dialog: {
          header: 'Ваш Email',
          input: 'Email'
        },
        sso_info:{
          text: "Ваш браузер блокує відкриття нової вкладки для SSO входу. Змініть налаштування браузера або натисніть кнопку «Примусово відкрити».",
          button: "Примусово відкрити"
        },
        text: {
          loading: ['Ми працюємо для вас', 'Якщо це займає забагато часу,', 'будь ласка, перезавантажте'],
          header: 'Ласкаво просимо',
          sub_header: 'Бронюйте свої улюблені робочі місця, знаходьте колег та багато іншого.',
          form_headers: {
            login: 'Увійти',
            email: 'Будь ласка, перевірте електронну пошту',
            password: 'Забули пароль'
          },
          password_info: ["Не переймайтеся! Ми відправимо вам посилання для відновлення.", 'Новий пароль було відправлено вам.'],
          inputs: ["Ім'я користувача", "Пароль", "Електронна пошта"],
          remember_action: ['Запам\'ятати.', 'Це не ви?', 'Забули пароль?', 'Назад до входу'],
          button: ['Увійти за допомогою SSO', 'Увійти', 'Скинути пароль'],
          info_modal: 'Перевірте свій електронний лист, щоб змінити пароль'
        }
      },
      delegates_modal: {
        header: "Виберіть профіль делегата",
        sub_header: "Виберіть особу, для якої хочете зробити бронювання",
        button_select: "Вибрати"
      },
      main_navigation: {
        pageName: 'side nav, main tool bar component',
        nav_tabs_names: {user: 'Користувач', admin: 'Адміністратор'},
        nav_dropdown_corg: {
          about: 'Про нас',
          history: "History",
          feedback: 'Залишити відгук',
          logout: 'Вийти',
          user_settings: "Налаштування користувача",
          delegate: "Перемкнутися на профіль делегата",
          user: "Перемкнутися на користувацький профіль",
          admin: "Перемкнутися на адміністраторський профіль"
        },
        admin_nav_items: {
          dashboard: 'Панель керування',
          reservation_management: 'Керування резерваціями',
          newsfeed: 'Стрічка новин',
          user_management: 'Керування користувачами',
          site_configuration: 'Налаштування сайту',
          kiosk_configuration: 'Конфігурація кіоску',
          issue_management: 'Управління проблемами',
          insights: 'Аналітика'
        }
      },
    },
    external: {
      close_issue_by_hash: 'Будь ласка, закрийте цю проблему!',
      hash_reservation: 'Резервування недоступне',
      google: 'Завантаження...',
      outlook_reservation: {
        popup: 'Видалити це резервування?',
        login: "Увійти",
        no_space_info: ['Місце не зарезервовано,', ' зверніться до організатора зустрічі'],
        site_headr: 'Місце',
        date_time: 'Час відображається для місцевого часового поясу',
        space: {
          header: 'Простір',
          internal_external: ['внутрішній', 'зовнішній']
        },
        amenities_header: 'Зручності'
      },
      reset_password: {
        info_modal: 'Пароль змінено.',
        button_update: 'Оновити',
        placeholders: ['Новий пароль', 'Повторіть пароль'],
        errors: ["Пароль не відповідає вимогам", 'Паролі не співпадають'],
        rules: 'Пароль повинен містити принаймні: 8 символів, 1 велику літеру, 1 малу літеру, 1 цифру'
      }
    },
    admin: {
      kiosk: {
        main_header: 'Set up a kiosk',
        card: {
          headers: {
            site: 'Select a site',
            wing: ['Select a wing ', '(optional)']
          },
          placeholders: {
            site: 'Please search or select a site',
            wing: 'Please search or select a wing'
          },
          warning: 'Not running in kiosk, PWA or full-screen mode.',
          button: 'Launch kiosk mode',
        },
        info: {
          kiosk: 'For Kiosk - please logout, close browser and than run a following command: ',
          pwa: 'For PWA or full-screen browser - use Google chrome browser and',
          button: 'Click here'
        }
      },
      dashboard: {
        main: {
          refresh: "Refresh",
          site_card: {
            header: " Dashboard",
            action: "Help center",
            sub_header: "Select a site to get an overview"
          },
          small_card: {
            header: " Meeting rooms",
            total: "TOTAL",
            assigned: "Assigned"
          },
          utilization_cards: {
            header: " Max utilization rate ",
            special_header: "Max utilization rate special types",
            tooltip: "The max utilization rate is the max ratio during that day of requested over available resources of the selected type",
            table: {
              headers: [
                { name: "All types", tag_name: "all_types" },
                { name: "Desks", tag_name: "desks" },
                { name: "Meeting rooms", tag_name: "rooms" },
                { name: "Meeting rooms - Large ", tag_name: "big_rooms" },
                { name: "Meeting rooms - External", tag_name: "external" },
                { name: "Released %", tag_name: "releases" },
              ],
              hover: ["Requested ", "Available "]
            },
            info: "Click on a number in the table to see the chart",
            zoom: ['zoom out', 'zoom in']
          },
          to_do_card: {
            header: " To-do’s",
            link: "Go to to-do list",
            table: [
              { name: "To be confirmed", tag_name: "to_be_confirmed" },
              { name: "To be assigned", tag_name: "to_be_assigned" },
              { name: "Untreated request", tag_name: "with_message" },
              { name: "Total", tag_name: "total" }
            ]
          },
          not_enough_card: {
            header: "Not enough resources",
            info_default: "Click on a number in the table to see the solutions.",
            info_selected: "Let’s see how we can solve this.",
            button_reservation: "Remind people about their reservations",
            button_release: "Request more releases"
          }
        },
        releases_table: {
          info: "You can ask people who have not released their resource for this day to release it.",
          headers: {
            user: { popup: 'owners', name: "Assigned to" },
            level: { popup: 'Level of user', name: "Level assigned user" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            asset_level: { popup: '', name: "Asset level" },
            seat: { popup: '', name: "Seat capacity" },
            amenities: { popup: '', name: "Resource’s amenities" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: "Send message to all selected releases?",
            select_owner_message: "At least one user must be selected.",
            error_message: "Error sending",
          }
        },
        reservations_table: {
          info: "You can remind people who have a reservation for this day to cancel their reservation if no longer needed.",
          headers: {
            user: { popup: 'Owners', name: "User" },
            level: { popup: 'Level', name: "Level of user" },
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            rc: { popup: 'Requested seats', name: "Requested seats" },
            ie: { popup: 'internal external', name: "internal/ external" },
            amenities: { popup: '', name: "Requested amenities" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            status: { popup: 'Statuses', name: "Status" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: "Send message to all selected reservations?",
            select_owner_message: "At least one user must be selected.",
            error_message: "Error sending",
          }
        }
      },
      newsfeed: {
        navigation: ['Newsfeed', 'Emergency message'],
        list: {
          main_header: 'Newsfeed',
          site_header: 'Select a site to check its newsfeed.',
          i_tooltipe: 'The newsfeed of a site is shown on the homepage for all sites selected by the user',
          card: {
            header: 'Settings',
            toggle: 'Show newsfeed of site',
            i_tooltipe: 'You can choose to show the newsfeed of another site',
            select: {
              toggle: 'Click here to select the site',
              placeholder: 'Select site'
            },
            auto_remove: {
              text: ['Automatically delete news items that are older than ', ' days. '],
              i_tooltipe: 'Choose how long news items are shown on the newsfeed before they get deleted. Events are removed from the newsfeed once ended.'
            }
          },
          add_item: ' Add new item',
          popups: {
            info: 'Please select a location!',
            confirm: 'Delete all existing newsitems and events of ',
            button: 'OK'
          }
        },
        form: {
          main_header: 'Emergency message ',
          site_header: 'Select a site for which you want to write an emergency message.',
          i_tooltipe: 'An emergency message, once turned on, is visible for all users of that site on the Tribeloo homepage and on the devices of that site',
          message: "Haven't message",
          placeholder: 'Emergency message line ',
          save: 'Save',
          emergency: ['Turn on emergency message', 'Turn off emergency message']
        }
      },
      site_config: {
        navigation: {
          site: "Site configuration",
          rules: "Site rules",
          table: "Resource configuration table",
          types: "Resource types",
          wayfinding: "Wayfinding"
        },
        edit_site: {
          header: "Editing site ",
          video_header: "How to add, edit and delete a site",
          buttons: {
            add: "Add new site",
            delete: "Delete site"
          },
          toggle: "Site is active & visible for users",
          toggle_warning: ["Site is currently ", "inactive & invisible"," for users"],
          base: {
            header: "Basic details",
            video_header: "How to set up a site: basic details",
            placeholder: {
              name: "site name",
              code: "plus code",
              address: "address of this site"
            },
            label: {
              code: "Define coordinates of site (plus code) ",
              address: "Address of site "
            },
            tooltip: {
              code: "Plus code can be found on Google maps and is used to show a map of the location on the wayfinding link",
              address: "Address is shown on the wayfinding link"
            },
            position: {
              header: "Position of wing",
              info: "Select a wing, by clicking on its title, to change its position.",
            },
          },
          amenities: {
            header: "Amenities",
            video_header: "How to set up a site: amenities",
            info: "Define amenities ",
            tooltip: "Amenities are additional features, like a beamer or a flipchart, which can be selected when looking for a resource. Select amenities in the list below, to activate them on the current site, so they can be assigned to a specific resource from this site.",
            counter: [" in total - ", " active"],
            placeholder: "new amenity"
          },
          manager: {
            header: "Site manager settings",
            video_header: "How to set up a site: site manager settings",
            info: "Add e-mail address office manager ",
            tooltip: "Office manager will receive emails when new issues are reported ",
            placeholder: "e-mail",
            toggle: ["Send email to-do's every ", "hours"],
          },
          device: {
            header: "Device settings",
            video_header: "How to set up a site: device settings",
            label: {
              time: "Use 24h time for devices ",
              password: "Device password (0-9 A-E) "
            },
            tooltip: {
              time: "Select time format for meeting room and desk devices: AM/PM or 24h format",
              password: "Password used to access configuration screen on devices"
            },
            placeholder: "code 0-9 A-E"
          },
          insights: {
            header: "Insight settings",
            video_header: "How to set up a site: insight settings",
            labels: {
              days: "Define working days ",
              hours: "Define working hours ",
              room: ["Define a large meeting room", "people or more"],
            },
            tooltip: {
              days: "Working days are used to calculate average utilization rate in insights",
              hours: "Working hours are used to calculate average utilization rate in insights"
            },
            range: ["from", "to"]
          },
          popups: {
            online: {
              text: 'Bring this site online?',
              descriptions: ['This will make the site visible for regular users and ready for reservations.']
            },
            offline: {
              text: 'Bring this site offline?',
              descriptions: ['This will make the site invisible for regular users. Existing reservations will be kept.']
            },
            delete_site: {
              text: 'Delete this site?',
              big_descriptions: ['This will delete all wings, resources and reservations for this site. \nUsers with this home site will be moved to having no home site.']
            },
            cant_delete_site: 'Site can not be deleted.',
            amenity: {
              exists: 'Amenity already exists.',
              cant_updated: 'Amenity cannot be updated. Used by resources on other sites.',
              cant_delete: 'Amenity cannot be deleted, because it is assigned to some resources.',
              cant_disabled: 'Amenity cannot be disabled, because it is assigned to some resources. Remove it first from all resources.'
            }
          },
          edit_amenity_dialog: "Edit amenity",
          edit_wings_sorted: {
            add_button: " Add Wing",
            position: "Position: ",
          },
          wing_position: {
            header: "Selected wing: ",
            position: "Position ",
            move: "Move to position "
          }
        },
        rules: {
          header:"Site rules",
          site_card: {
            header: "Site",
            sub_header: "Select a site for which you want to define user privileges and rules."
          },
          default_reservation_time_card: {
            header: "Default reservation time",
            sub_header_desk: "Desk",
            sub_header_room: "Meeting room",
            label_start: "Start date",
            label_end: "Duration"
          },
          auto_assign_card: {
            header: "Auto-assign resources ",
            video_header: 'How to auto-assign resources to reservations',
            sub_header: "Reservations can get a resource auto-assigned if the user requests one, if none is available it will appear in the to-do list of the office manager",
            toggle: "Activate auto-assign functionality for ‘to assign’ reservations",
          },
          check_in_reminder_email_card: {
            header: "Check-in reminder email ",
            video_header: 'How to use the check-in reminder emails & auto-cancel reservations',
            send_toggle: "Send out reminder email 15 minutes before the start of a reservation that requires check-in",
            add_button_toggle: ["Add ", "‘Cancel reservation’", " button to reminder email"],
            add_desck_toggle: ["For ", "desk", " reservations: add ", "‘Check-in’", " button to reminder email"],
            add_room_toggle: ["For ", "meeting room", " reservations: add ", "‘Check-in’", " button to reminder email"],
            auto_cancel_header: "Auto-cancel reservations that require check-in",
            cancel_desk_toggle: ["Cancel ", "desk", " reservation after", "min, if a check-in is required."],
            cancel_room_toggle: ["Cancel ", "meeting room", " reservation after", "min, if a check-in is required."],
          },
          reminder_email_card: {
            header: ["Reminder email", "for desks"],
            video_header: 'How to use the general reminder emails',
            send_toggle: ["Send reminder email ", "hours before the start of a reservation"],
            subject: {
              label: "Subject:",
              placeholder: "Reminder about your upcoming reservation"
            },
            message: {
              label: ["Message: (Markdown supported, for example to add links. ", "Check guidelines"],
              placeholder: "Add message"
            },
            agree_toggle: ["Add ", "‘I agree’", " button: by agreeing the person acknowledges they have read the message"],
            cancel_toggle: ["Add ", "‘Cancel reservation’", " button"],
            header_special: "Reminder email for meeting rooms",
            send_toggle_special: ["Send reminder email ", "hours before the start of a reservation"]
          },
          time_limit_card: {
            header: "Time limit for future reservations",
            video_header: 'How to use the time limit for future reservations',
            sub_header: "Reservations can only be made a certain time ahead by non-admin users",
            desck_toggle: ["Desk", " reservations can be created in the next"],
            room_toggle: ["Meeting room", " reservations can be created in the next"],
            special_toggle: "Reservations can be created in the next",
            postfix: "working days"
          },
          utilization_limit_card: {
            header: "Utilization limit",
            sub_header: ["Utilization of active ", " can be limited.", "Example: a site has 40 active ", ". When utilization is limited to 50%, only 20 concurrent ", " reservations can be made."],
            sub_header_types: ['desks', 'resources', 'desk'],
            toggle: ["Utilization is limited to", "percent"]
          },
          multi_day_reservation_card: {
            header: "Multi-day reservation",
            sub_header: ["Define if an employee is allowed to create one reservation for several consecutive days at once.", "When allowed, an employee will only receive the reminder emails once and only needs to check-in once at the start of his multi-day reservation, if this is required."],
            toggle: "Multi-day reservations are allowed"
          },
          cleaning_time_card: {
            header: "Cleaning",
            sub_header: "Cleaning time will be foreseen after the end of every reservation. During this period the resource cannot be booked.",
            desk_toggle: ["Time available to clean a ", "desk", "min"],
            room_toggle: ["Time available to clean a ", "meeting room", "min"]
          },
          user_message_card: {
            header: "User message when creating reservation ",
            toggle: 'Show this message when a user creates a reservation for a resource on this site',
            label: 'Message:',
            placeholder: 'Type your message here',
          },
          user_privileges_card: {
            header: "User privileges",
            video_header: 'How to set up user privileges',
            sub_header: "Define which users have access to which resources and have to get confirmation for a reservation",
            levels: {
              header: "1. Define the names of the different user levels and resource levels:",
              toggle: "User level names are the same across all sites",
              description_simple: {start: "User & resource level names", center: " can be set by site admin and are ", end: "specific for the site."},
              description_global: [
                {start: "User level names", center: " can only be set by global admin and are the ", end: "same for all sites."},
                {start: "Resource level names", center: " can be set by site admin and are ", end: "specific for the site."},
              ],
              user_label: "User level ",
              resource_label: "Resource level ",
              placeholder: "Text"
            },
            privileges: {
              header: "2. Define the privileges per user and resource level:",
              cols: "Resource level",
              rows: "User level"
            },
            rules: [
              {id: 0, line1: "Resource not available", line2: "(can be assigned by admin)", class: "red"},
              {id: 2, line1: "Resource only available ", line2: "after confirmation", class: "orange"},
              {id: 1, line1: "Resource available for", line2: "user level", class: "green"}
            ]
          },
        },
        edit_wing: {
          header: "Editing wing ",
          sub_header: "wing details",
          video_header: "How to add, edit and delete a wing",
          placeholder: "wing name",
          button: {
            back: "Back to site edit",
            add: "Add new wing",
            delete: "Delete wing"
          },
          popup: {
            text: 'Delete this wing?',
            big_descriptions:['This will delete all resources of this wing and could affect current reservations. \nCheck your to-do list if proceeding.']
          },
          assets_list: {
            header: "Resources",
            add_button: " Add resource",
            counter: " in total",
            popup: {
              text: 'Delete this resource?',
              big_descriptions:['This could affect current reservations. \nCheck your to-do list if proceeding.']
            }
          }
        },
        edit_asset: {
          buttons:{
            back: "Back to wing edit",
            add: "Add resource",
            clone: "Duplicate resource",
            delete: "Delete resource"
          },
          base: {
            header: "Basic details ",
            video_header: "How to add, edit and delete a resource & define the basic details",
            internal_external: [
              {name:"Internal only", value: true},
              {name:"External allowed", value: false}
            ],
            type_tooltipe: 'INTERNAL ROOM: used for a meeting with colleagues from within the company.\n EXTERNAL ROOM: used when also people of outside the company are invited (e.g. client).\n ZONE: contains multiple single desks that can be booked, without selecting a particular one.',
            user_tooltipe: 'Assigning a resource to a specific person, means that this person has a fixed desk and they alone can use it. They can still choose to release it for others to use, when they aren’t around.',
            zoning_tooltipe: 'Semicolon separated list of zoning names, useful for HVAC/BM integration.\n E.g.:\n lighting_7;heating_3',
            amenity_type: "other asset type",
            placeholders: {
              name: "asset name",
              user: "Select user",
              neighbourhood: "Select neighbourhood",
              amenities: "Select amenities"
            },
            labels: {
              status: "Resource status ",
              neighbourhood: "Neighbourhood",
              user: "Assigned to ",
              level: "Resource level ",
              resource_zoning: "Resource zoning ",
              capacity: ['Seat capacity', 'Capacity'],
              type: "Resource type ",
              check_in: "Check-in required",
              amenities: "Amenities available ",
              image: "Add image (< 4MB)"
            }
          },
          size: {
            header: "Size, Shape & position ",
            video_header: "How to set up a resource: size, shape & position",
            size: {
              header: "Size & shape",
              sub_header: "Choose a shape",
              width: "Width",
              height: "Height",
              round: "Round corners"
            },
            position: {
              header: "Map position",
              horizontal: "Horizontal",
              vertical: "Vertical"
            }
          },
          calendar: {
            header: "Calendar & approval settings",
            video_header: "How to set up a resource: calendar & approval settings",
            resource: {
              label: "Resource email ",
              placeholder: "Resource email",
              tooltip:"Fill in an email to create a calendar for this resource. Tribeloo will keep the calendar up to date with reservations for this resource."
            },
            approvers: {
              label: "Approvers email ",
              placeholder: "Approvers email",
              tooltip:"Add the email address of the person who will confirm reservations for this resource. Multipe email addresses can be added separated by a semicolon ; and without any spaces before or after the semicolon"
            },
          },
          device: {
            header: "Device configuration",
            video_header: "How to set up a resource: device configuration",
            code: {
              label: "Device code ",
              placeholder: "Code"
            },
            id: {
              label: "Device ID ",
              placeholder: "ID"
            }
          },
          qr_nfc: {
            header: "QR & NFC configuration",
            video_header: "How to set up a resource: QR & NFC configuration",
            qr: {
              header: "Generate QR code",
              info: "Click on the button to generate a QR code of this resource.",
              button: "Generate QR"
            },
            nfc: "NFC configuration"
          },
          popups: {
            nfc_copy: "NFC url copied.",
            set_capacity: {
              text: 'Reduce seat capacity?',
              big_descriptions: ['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            select_owner: {
              text: 'Assign user to resource?',
              big_descriptions: ['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            internal_change: {
              text: "Change resource type to ‘Internal only’?",
              big_descriptions:["This could affect current reservations. \nCheck your to-do list if proceeding."]
            },
            remove: {
              text: 'Delete this resource?',
              big_descriptions:['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            check_card_id: {
              header: "",
              cases: [
                '1. Click "Start test" below',
                '2. On selected device, tap "Book now" or "Book later"',
                '3. Scan your card on the device',
                '4. The RFID code of the scanned card appears below',
                '5. To test another card, go to step 2'
              ],
              start: "Start test",
              stop: "Stop"
            }
          },
          assets_dragging_view: [" - click for edit", "drag & drop", "click to select"]
        },
        edit_info: {
          create: "Created on: ",
          update: "Last updated on: ",
          resource: "Resources: ",
        },
        type: {
          header: "Resource types",
          sub_header_descriprion: {
            first_line: "Standard sites have the type 'room or desk' automatically assigned.",
            second_line: ["If you want to create sites that contain another type of resource, define them here.", "These other resource types will also become visible on the Tribeloo homepage and in the tab 'More' in Outlook."]
          },
          video_header: 'How to define different resource types to book',
          card: {
            list: {
              header: "Existing resource types",
              button: "Add new resource type"
            },
            form: {
              header: ["Create resource type", "Edit resource type"],
              type: {
                label: "Resource type",
                placeholder: "Name of resource type"
              },
              icon: {
                label: "Icon",
                placeholder: "fa fa-archive",
                button: "Check icon list"
              },
              preview: {
                label: "Preview",
                text: ["Book a ", "Book a desk", "Book a meeting room"]
              },
              booking: {
                label: "Double booking by user",
                sub_label: "(desks only)"
              },
              button: {
                create: " Create type",
                delete: " Delete type",
                save: " Save changes",
              }
            }
          },
          popups: {
            unique_name: "You already have a type with the same name",
            create: 'Create type?',
            save: 'Save changes?',
            add: "New resource type is added",
            saved: "Changes saved",
            delete: 'Delete this resource type?',
            cant_delete: "This resource can't be deleted, because there are still sites of this type"
          },
        },
        assets_table: {
          buttons: {
            batch: "Batch edit",
            qr: ["QR PDF selected", "QR PDF all"]
          },
          headers: {
            number: {name: "Number", popup: ""},
            resource: {name: "Resource", popup: ""},
            wing: {name: "Wing", popup: "Wings"},
            user: {name: "Assigned to", popup: ""},
            level: {name: "Resource level", popup: "Resource level"},
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            capacity: {name: "Seat capacity", popup: "Seat capacity"},
            type: {name: "Resource type", popup: "Resource type"},
            check_in: {name: "Check-in required", popup: "Check-in required"},
            amenities: {name: "Amenities", popup: ""},
            device_id: {name: "Device ID", popup: ""},
            email: {name: "Resource email", popup: ""},
            status: {name: "Status", popup: "Status"},
            from: {name: "From", popup: ""},
            to: {name: "To", popup: ""}
          },
          popups: {
            batch: {
              header: "Edit resource details",
              sub_header: "Update",
              level: "Resource level",
              capacity: "Seat capacity",
              type: {
                label: "Resource type",
                values: [
                  { name: 'Internal only', value: true },
                  { name: 'External allowed', value: false }
                ],
              },
              check_in: {
                label: "Check-in required",
                values: [
                  { name: 'yes', value: true },
                  { name: 'no', value: false }
                ],
              },
              add_amenities: {
                label: "Add amenities",
                placeholder: "Select amenities"
              },
              remove_amenities: {
                label: "Remove amenities",
                placeholder: "Select amenities"
              },
              approvers: {
                label: "Set approvers",
                placeholder: "approvers email"
              },
              neighbourhood: {
                label: "Neighbourhoods",
                placeholder: "Select neighbourhood"
              },
              status: "Resource status"
            },
            selected: "At least one resource must be selected",
            selete_selected: ["Delete the ", " selected resources?"],
            this_delete: "Delete this resource?"
          }
        },
        wayfinding_link: {
          header: "Wayfinding link",
          privacy_card: {
            header: "Privacy",
            toggle: "Logging in is required to see information about assigned resource and floormap"
          },
          layout_card: {
            header: "Layout",
            sub_header_descriprion: {
              first_line: ["When using the Outlook add-in and Chrome extension, a wayfinding link is added to the event itself. ", "This shows invitees where they need to be and to easily find their way."],
              second_line: "You can add a company logo OR company name, and define the colors for the banner, the company name and the titles."
            },
            logo: {
              label: "Company logo",
              sub_label: "(PNG, less than 4MB)"
            },
            image_info: ["max width 210px and ", "max height 50px"],
            company_name: {
              label: "Company name",
              placeholder: "Max 20 characters"
            },
            name_color_label: "Color company name",
            baner_color_label: "Color banner",
            titels_color_label: "Color titles"
          }
        }
      },
      todo: {
        navigation: {
          list: 'Reservation to-do list',
          requests: 'Requests',
          reservation_table: 'Reservation table',
          release_table: 'Release table'
        },
        main: {
          all_button_tooltip: "Assign resources automatically to current to-do's",
          dialog_asset: "Asset must be selected first",
          details_resources_button: "View resource reservations",
          legend: {
            header: "View legend",
            items: [
              {name: "Available", type_class: "green"},
              {name: "Available, but to be confirmed", type_class: "orange"},
              {name: "Available, but rules constraint", type_class: "blue"},
              {name: "Not available", type_class: "red"}
            ]
          }
        },
        list: {
          update: "Last updated: ",
          toggle: "Show only untreated request",
          toggle_visitor: "Show only visitor reservations",
          default_name_subject: "New reservation ",
          user_prefix: "by ",
          visitor_prefix: "for ",
          tooltip: "Reservation within selected time range",
          default_name_asset: "Reservation pending"
        },
        details: {
          site_change: {
            header: "Change site",
            tooltip: "Remove assigned space first"
          },
          request: {
            header: "Request",
            treated: ["Request treated", "Request untreated"],
            buttons: {
              add: "Add note",
              contact: "Contact user"
            }
          },
          details: {
            header: ["Show details", "Hide details"],
            delete_button: "Cancel reservation",
            default_name_subject: "New reservation ",
            user_prefix: "By ",
            visitor_prefix: "For ",
            seats: {
              counter: ["Requested: seats ","Requested: "],
              type: ['internal meeting', 'external meeting'],
              amenities: "Requested amenities: "
            }
          },
          popups: {
            delete: 'Delete this reservation?',
            assigned: "Space is assigned to reservation and confirmed",
            selected: "Asset must been selected",
            available: "Asset is not available",
            message_error: "error sending"
          }
        },
        work_stack: "Click here to select the stack item",
        message_popup: {
          header: "Add note",
          sub_header: "Request",
          treated: ["Request treated", "Request untreated"]
        },
        release_table: {
          info: 'Changing time range removes all filters',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "Assigned to" },
            level: { popup: 'Level assigned user', name: "Level assigned user" },
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            amenities: { popup: '', name: "Resource’s amenities" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            subject: { popup: '', name: "Subject" },
            email: { popup: '', name: "Email" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: ["Send message to ", " shown releases for "],
            remove_release: "Delete this release?",
            remove_releases: ["Delete ", " shown releases for "],
            error_message: "Error sending",
          }
        },
        reservaton_table: {
          info: 'Changing time range removes all filters',
          change_site_buton: ' Change site for all',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'User', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            level: { popup: 'Level of user', name: "Level of user" },
            group: { popup: 'User group in ', name: "User group in " },
            email: { popup: '', name: "User email" },
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            seats: { popup: 'Requested seats', name: "Requested seats" },
            internal_external: { popup: 'Resource type', name: "Resource type" },
            amenities: { popup: '', name: "Requested amenities" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resources', name: "Resource" },
            request: { popup: 'Request', name: "Request" },
            status: { popup: 'Statuses', name: "Status" },
            acknowledged: { popup: 'Acknowledged', name: "Acknowledged" }
          },
          tooltip: {
            lock: "lock all",
            unlock: "unlock all",
            acknowledged: "From reminder email: if the person clicked on the 'I agree' button and therefore acknowledges they have read the message",
            confirm: "confirm all",
            delete: "delete all",
            send_mail: "send message to all"
          },
          dialogues: {
            chang_site: 'Change site',
            confirm: ['Confirm ', ' shown reservations from '],
            send_all_message: ["Send message to ", " shown reservations for "],
            remove_reservation: "Delete this reservations?",
            remove_reservations: ["Delete ", " shown reservations for "],
            error_message: "Error sending",
            lock: ["Lock ", " shown reservations from "],
            unlock: ["Are you sure you want to unlock ", " shown reservations from "]
          }
        },
        requests_table: {
          info: 'Changing time range removes all filters',
          add_comment_buton: 'Add comment',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "Owner" },
            visitor: { popup: '', name: "Visitor" },
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            request: { popup: 'Request', name: "Request" },
            comment: { popup: '', name: "Comment" },
            seats: { popup: '', name: "Request treated" },
            email: { popup: '', name: "Owner email" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: ["Send message to ", " shown reservations for "],
            error_message: "Error sending"
          }
        }
      },
      user_management: {
        navigation: {
          global_admin: "Global admins",
          admin: "Site admins",
          privileges: "User privileges",
          user: "User management",
          users_table: "User table",
          groups: "User groups",
          sso: "SSO",
          provisioning: "User provisioning"
        },
        global_admin: {
          header: "Global admin management",
          admins_card: {
            header: "Define the global admins",
            sub_header: "These users have access to all sites and features",
            video_header: 'How to define the site & global administrators',
            search: "Search",
            global_admin_list_header: "Site admins that are global admin",
            admin_list_header: "Site admins",
            admin_list_header_tooltip: 'You need to be site admin first, before you can be global admin'
          },
        },
        privileges: {
          header: "User rules",
          site_card: {
            header: "Site",
            sub_header: "Select a site to define it's user rules."
          },
        },
        admin: {
          header: "Site admin management",
          site_card: {
            header: "Site",
            sub_header: 'Select a site for which you want to add or remove administrators.'
          },
          admins_card: {
            header: "Add administrators for this site",
            video_header: 'How to define the site & global administrators',
            search: "Search",
            admin_list_header: "Users that are admin for this site",
            user_list_header: "Users",
            popup: {
              title: "This user has global admin rights and therefore access to all sites.",
              descriptions: ["If you want to give this user only access to certain sites, remove them from the global admin list first."]
            }
          },
        },
        user: {
          rfid_modal: {
            header: "Assign RFID to user",
            step1: {
              header: ["Step 1", " - Enter device ID & click ‘Start’"],
              placeholder: "Device ID",
              button: "Start ",
              info: ["No resource found ", "Check or re-enter ID"]
            },
            step2: ["Step 2", " - On connected device, tap ‘Book now’ or ‘Book later’"],
            step3: {
              header: ["Step 3", " - Scan card on the device, the RFID code will appear below"],
              placeholder: "Card RFID"
            },
            step4: {
              header: ["Step 4", " - Click ‘Assign RFID’, to assign it to the user"],
              button: "Assign RFID"
            }
          },
          header: "User management",
          free_version_sub_header: ["Currently using the ", "FREE VERSION with a limit of ", " users.", " Contact ", " to upgrade."],
          site_card: {
            header: "Location",
            sub_header: "Select a location to add or remove users for this site."
          },
          create_edit_card: {
            list: {
              header: "Existing users",
              button: "Add new user",
              placeholder: "search"
            },
            form: {
              header: ["Edit user", "Create user"],
              video_header: "How to create and edit a user",
              sub_header: "Select a user in the list to edit it's details.",
              name: {
                label: "Username",
                placeholder: "Name"
              },
              email: {
                label: "Email",
                placeholder: "Email"
              },
              level: {
                label: "User level ",
                placeholder: ""
              },
              group: {
                label: "User group",
                item: "Default"
              },
              site: {
                label: "Home site",
                item: "None"
              },
              resource: {
                label: "Resource",
                placeholder: ""
              },
              rfid: {
                label: "RFID",
                placeholder: "Enter RFID or click on icon on the right"
              },
              image: {
                label: "User image",
                sub_label: ["(square size, ", "less than 4MB)"]
              },
              button: {
                create: " Create user",
                reset_pin: "Reset PIN code",
                reset_password: "Reset user password",
                upload: "Upload image",
                delete: " Delete user",
                save: " Save changes"
              }
            },
            import: {
              header: "Batch upload users",
              cases: [
                "1. Download template",
                "2. Add users for this location to the template",
                "3. Upload the template file again to Tribeloo"
              ],
              button: {
                upload: "Upload file",
                download: "Download template"
              }
            },
            errors: {
              invalid_email: "Required field <email> is invalid.",
              missing_email: "Required field <email> is missing from request.",
              missing_name: "Required field <name> is missing from request.",
              missing_level: "Required field <level> is missing from request.",
            },
            popups: {
              save: 'Save changes?',
              create: 'Create user?',
              created: 'User created.',
              reset_pin: 'The reset was succesful! The user will receive an email with a new PIN code.',
              updated: 'User details have been updated.',
              delete: ' Delete this user?',
              removed: 'User removed.',
              reset_password: 'The reset was succesful! The user will receive an email with a new password.',
              send_pin: 'Send login PIN code for devices ?'
            }
          },
          pin_card: {
            header: "PIN code ",
            video_header: "When and how to use a PIN code",
            toggle: "Send PIN code by email to login on the meeting room and desk devices",
            info: "All new users and existing users that don’t have PIN code yet, will receive one."
          },
          global_settings_card: {
            header: "Global settings",
            info: "These settings are applicable for all sites"
          },
          image_card: {
            header: "Synchronise user images ",
            video_header: "How to synchronise user images of Microsoft 365 with Tribeloo",
            toggle: "Sync user images with Microsoft Office 365",
            info: "User image is synchronised once a user logs into the Tribeloo Outlook add-in"
          },
          subject_card: {
            header: "Hide reservation subject ",
            video_header: "",
            toggle: "Hide reservation subject for colleagues",
            info: 'Reservation subject shown in "who`s around" will be hidden'
          }
        },
        users_table: {
          batch_edit: {
            header: "Edit user details",
            sub_header: "Update",
            items: {
              privileges: "User group",
              level: "User level",
              site: "Home site",
              group: "User group",
              rfid: "Clear RFID for selected users"
            }
          },
          table: {
            toggle: 'Also show users from other sites',
            headers: {
              number: { popup: '', name: "Number" },
              site: { popup: 'Home site', name: "Home site" },
              home_site_privilege: { popup: 'Home site group', name: "Home site group" },
              user: { popup: '', name: "Name of user" },
              user_privilege: { popup: 'User group in ', name: "User group in " },
              assigned_resource: { popup: 'Assigned resource in ', name: "Assigned resource in " },
              level: { popup: 'Level of user', name: "Level of user" },
              group: { popup: 'Group', name: "User group" },
              space: { popup: '', name: "Resource" },
              Owned_site: { popup: 'Owned_site', name: "Site of resource" },
              email: { popup: '', name: "User email" },
              rfid: { popup: 'RFID', name: "RFID" },
              override : {popup: 'Group assignment', name: "Group assignment"}
            },
            tooltip: {
              delete: "delete all selected users",
              send_mail: "send message to selected users"
            },
            dialogues: {
              error_message: "Error sending",
              email: 'Compose email for the selected users?',
              selected: "At least one user must be selected.",
              delete_all: ['Delete the ', ' selected users?'],
              delete: 'Delete this user?',
              rfid: "RFID cleared for selected users"
            }
          }
        },
        groups: {
          header: "Users groups",
          toggle_card: {
            header: "Enable user groups ",
            video_header: "How to set up user groups",
            toggle: "User groups are enabled",
            description: ["When ", "enabled", ", you can create user groups and define rules for visible sites or office days for each group.", "Assign a user to a group in user management or user table."]
          },
          creat_edit_card: {
            list: {
              header: "Existing users groups",
              button: "Add new user group",
              placeholder: "search",
              popups: {
                save: "Save changes?",
                create: "New user group is added.",
                update: "Changes saved.",
                delete: {
                  text: "Delete this user group?",
                  descriptions: ['Users from this group will be assigned to the default user group of their home site.']
                }
              }
            },
            form: {
              header: ["Create user group", "Edit user group"],
              select_button: "Select all",
              unselect_button: "Unselect all",
              create: " Create group",
              update: "Save changes",
              delete: " Delete group",
              name: {
                label: "Name ",
                placeholder: "Add name of user group"
              },
              rules: {
                toggle: ["‘Visible sites’ rule enabled", "‘Visible sites’ rule disabled"],
                tooltip: "When visible sites rule is enabled, a user has access to the active sites of his group, and is able to book a resource for these sites. When visible sites rule is disabled, this user group has access to all active sites.",
                placeholder: "Select visible sites",
              },
              days: {
                toggle: ["‘Office days’ rule enabled", "‘Office days’ rule disabled"],
                tooltip: "When office days rule is enabled, a user can make reservation only on the selected office days. When office days rule is disabled, this user group can make reservations on all days.",
                placeholder: "Select office days",
                week_days: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']
              }
            },
            sites_card: {
              header: "Default user group for each site",
              description_lines: [
                {start: "Users can be assigned to a specific group. In case a user doesn’t have a group assigned,", end: "the user will fall in the default group of his home site as defined below."},
                {start: "In case the user isn’t assigned to a specific group and no default group is defined for his", end: "home site, the user will only have access to his home site to book resources."}
              ],
              label: "No home site ",
              tooltipe: "This is the default group for users without a home site",
              placeholder: "group"
            }
          }
        },
        sso: {
          header: "Single Sign-On",
          description_card: {
            header: "What is it?",
            description: "Single Sign-On gives your users the ability to sign in to different applications using the same unique set of username and password. Users sign in once via the so-called Identity Provider (external to Tribeloo) and can then, from there access different apps (including Tribeloo) without logging in again. For SSO, Tribeloo supports the SAML 2.0 standard."
          },
          email_card: {
            header: "Enable welcome email",
            toggle: "Enable sending welcome email if SSO is enabled"
          },
          settings_card: {
            header: "Settings",
            items: {
              id: {
                name: "Company ID",
                tooltip: {
                  header: "",
                  descriptions: []
                }
              },
              issuer_url: {
                name: "Issuer URL",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: Identity Provider Issuer", "OneLogin: Issuer URL", "Azure: Azure AD Identifier", "Google: Entity ID"]
                }
              },
              login_url: {
                name: "SSO login URL",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: Identity Provider SSO URL", "OneLogin: SAML Endpoint", "Azure: Login URL", "Google: SSO URL"]
                }
              },
              certificate: {
                name: "Certificate",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: X.509 Certificate", "OneLogin: X.509 Certificate", "Azure: Certificate (Base64 or Raw)", "Google: Certificate 1"]
                }
              },
            },
            button: ["Disable SSO", "Enable SSO"]
          },
          popups: {
            saved: 'Configuration saved.',
            not_saved: 'Configuration not saved.',
            copied: 'Company ID copied.',
            change_sso: {
              text: ['Are you sure you want to disable Single Sign-On?', 'Are you sure you want to enable Single Sign-On?'],
              descriptions: ['All users will need to log in with a password, that they can request through ‘Forgot password?’ on the login page.', 'Users won’t be able to log in with their passwords and will need to log in with their SSO credentials.']
            }
          }
        },
        provisioning: {
          header: "User provisioning",
          description_card: {
            header: "What is it?",
            description: "User Provisioning refers to the automatic synchronization of users: people defined in your Active Directory (AD) will be automatically synchronized with the Users list in Tribeloo. E.g. new people in the AD will be added to the Users list in Tribeloo."
          },
          settings_card: {
            header: "Settings",
            items: [
              {name: "SCIM Base URL", key: "base_url", is_input: true},
              {name: "SCIM JSON Template", key: "json_template", is_input: false},
              {name: "Custom headers", key: "custom_headers", is_input: true},
              {name: "SCIM Bearer token", key: "bearer_token", is_input: false}
            ],
            button: ["Disable User provisioning", "Enable User provisioning"],
            popups: {
              copy: " copied",
              change_SCIM: {
                text: ['Are you sure you want to disable User provisioning?', 'Are you sure you want to enable User provisioning?'],
                description: ['All the current users will be kept, but won’t be updated by your external user provisioning system anymore.', 'All users will be managed externally.']
              }
            }
          }
        }
      },
      insights: {
        navigation: {
          heatmaps: "Heatmaps",
          utilization: "Utilization charts",
          history: "Reservation history",
          download: "Data download",
          statistics: "Statistics",
          tracker: "Time tracking",
          calc: "Calc resource"
        },
        calc: {
          type: "Calc helpe",
          subHeader: "This page help you with a data about your price and amount of resources from 1 month to 5 years. Also it help you: if you want to change a count of recources, or move to new place, add or remove new site, wing and etc.",
          chartHeader: "Terms & summ",
          chartDescription: "Use top form to know how much you are spending for your resources"
        },
        heatmaps: {
          type: "Heatmaps",
          subHeader: "Which days and hours are most busy?",
          allText: "Average utilization rate of selected type",
          daysText: "Daily utilization rate of selected type",
          options: {
            average: "Show average utilization rate",
            daily: "Show daily utilization rate"
          },
          tooltipe: 'The average utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period. The daily utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period. For example, daily utilization of 80% and average utilization of 40% means that: 8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours. '
        },
        utilization_charts: {
          type: "Utilization charts",
          subHeader: "Which days and hours are most busy?",
          allText: "Average utilization rate of selected type",
          daysText: "Daily utilization rate of selected type",
          table_header: "Which days are most busy? Select a day to see the utilization by hour below for that day.",
          chart_header: "Which hours are most busy"
        },
        reservation_history: {
          info: 'Changing time range removes all filters',
          overlap: 'Find overlapping reservations with ',
          couter: " more...",
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            email: { popup: '', name: "User email" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resources', name: "Resource" },
            no_shows: { popup: 'No-shows', name: "No-shows" },
            subject: { popup: '', name: "Subject" },
            seats: { popup: 'Requested seats', name: "Requested seats" },
            internal_external: { popup: 'internal/external', name: "internal/ external" },
            status: { popup: 'Statuses', name: "Status" },
            messages: { popup: 'Messages', name: "Messages" },
            acknowledged: { popup: 'Acknowledged', name: "Acknowledged" }
          },
          tooltip: {
            acknowledged: "From reminder email: if the person clicked on the 'I agree' button and therefore acknowledges they have read the message",
            send_mail: "send message to all"
          },
          dialogues: {
            error_message: "Error sending"
          }
        },
        time_tracking: {
          check: "events",
          info: 'Changing time range removes all filters',
          info_date: 'Find overlapping reservations with ',
          headers: {
            user: { popup: 'User', name: "User" },
            email: { popup: 'User email', name: "Email" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            zone: { popup: '', name: "Time zone" },
            period: { popup: '', name: "Time period" },
          },
          dialogues: {
            error_message: "Error sending"
          }
        },
        data_download: {
          header: "Data download",
          popup: {
            big_titel: 'Update data retention?',
            big_descriptions: ['Older reservation data will be deleted and no longer accessible'
                              ]},
          retention_card: {
            header: "Data retention",
            toggle: ["Reservations & releases data is automatically deleted after", "days."],
            info: "There is a minimum of 7 days and a maximum of 400 days."
          },
          automade: {
            header: "Daily automated report via email",
            sub_header: "Receive data of the reservations & releases of the previous day",
            check: "Send daily email to",
            placeholder: "Email address",
            error: "Please enter a valid email address"
          },
          download: {
            header: "Download data for a specific time range",
            info: "Data of reservations & releases",
            button: {
              all: " Download all",
              one: " Download data range"
            }
          }
        },
        statistics: {
          header: "Statistics",
          sub_header: "The table shows you a current view of all the sites and their amount of resources and users.",
          card: {
            header: "Resources and users",
            button: "Download",
            table_headers: {
              type: "Resource type",
              resources: "Resources",
              users: "Users"
            }
          }
        },
        reusable_components: {
          main_header: {
            header: "Insights",
            day: " Move timeframe per day ",
            week: " Move timeframe per week "
          },
          scale_utilization_rate: "Scale utilization rate",
          card_table: {
            table_headers: {
              special: "utilization rate",
              average: "Average",
              daily: "Daily"
            },
            description_hightlight: [
              {start:"The ", hightlight: "average", end: " utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period."},
              {start:"The ", hightlight: "daily", end: " utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period."}
            ],
            description_example: ["  For example, daily utilization of 80% and average utilization of 40% means that: ", "8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours."]
          },
          maps_cards: {
            button: {
              select: "Select",
              show_all: "Show all"
            },
            internal_external: [', Internal only', ', External allowed'],
            owner: "Owner: ",
            usage: "Usage",
            average: "average: ",
            daily: "daily: ",
            days: "days active: ",
          }
        }
      },
      space_issues: {
        navigation: ['Gérer les problèmes','Nettoyage', 'Table de problèmes'],
        list: {
          main_header: 'Sélectionnez un site',
          main_sub_header: 'Cherchez une ressource',
          placeholder: "Cherchez ou sélectionner site",
          placeholder_asset: "Please search or select a resource",
          search: 'Voir tous les problèmes d\'un site',
          toggle: ['Cliquez pour montrer les problèmes résolus','Cliquez pour montrer les problèmes non résolus'],
          couter: [' problèmes non résolus', ' problèmes résolus'],
          img_link: 'Voir la photo',
          popup_text: 'E-mail envoyé.',
        },
        table_cleaning: {
          main_header_warning: 'Un changement d\'heure retire tous les filtres',
          table_headers: {
            id:'ID',
            data_time: 'Date & heure',
            wing: 'Etage',
            resource: 'Resource',
            cleaned_at: 'Nettoyé à',
            cleaned_by: 'Nettoyé par'
          }
        }
      }
    },
    user: {
      external_events_list: {
        headers: ['Події Google', 'Події Outlook'],
        select: {
          header: 'Організовано',
          placeholder: 'Виберіть колегу'
        },
        info: ['У вашому календарі немає доступних резервацій', 'Показувати лише події з резерваціями Tribeloo', 'Вибрана подія']
      },
      find_colleague: {
        email_select: {
          more: "ще..."
        },
        user_select: {
          header: 'Знайти колегу',
          placeholder: 'Виберіть колегу',
          info: ['Попросіть доступ до колеги у', '>Моя Племінна', 'щоб бачити їхні резервації']
        },
        list: {
          count: ' (неприватний)',
          info: ['Виберіть колегу, щоб побачити їхні резервації.', 'Натисніть на резервацію, щоб побачити її на карті.']
        },
        details: {
          return_button: 'Повернутися до списку резервацій',
          info: 'Виберіть доступний ресурс на карті, щоб створити резервацію для себе',
          modals: {
            delete_reservation: 'Резервацію скасовано',
            create_reservation: 'Створити резервацію?'
          }
        }
      },
      find_room_report: {
        headers: {
          main: 'Повідомити про проблему',
          site: 'Виберіть сайт',
          resource: 'Знайти ресурс'
        },
        placeholders: {
          site: 'Виберіть сайт',
          resource: 'Будь ласка, знайдіть або виберіть ресурс',
          tooltip: 'Коли ви повідомляєте про проблему, вона буде надіслана менеджеру сайту для вирішення. Після вирішення вона буде видалена з цього списку.',
          description: ['Виберіть ресурс, щоб повідомити про проблему', 'Повідомити про проблему'],
        },
        loading: "Завантаження...",
        image_description: "Зображення (< 4 Мб)",
        button: {
          photo: 'Додати фото',
          send: 'Надіслати проблему'
        },
        image_info: 'Зображення (< 4 Мб)',
        couter: ' невирішених проблем',
      },
      releases_list: {
        add_button: 'Додати відсутність',
        user_select: 'Відсутності для',
        colleagues_info: "У вас немає колег з видимими відсутностями",
        counter: 'Відсутностей немає',
        right_panel_info: ['Виберіть колегу зі списку, щоб побачити його відсутності.', "У вас немає колег з видимими відсутностями"]
      },
      release: {
        pageName: 'release',
        header: 'Деталі відсутності',
        placeholder: 'Тема',
        buttons: {
          create: ['Звільнити ваше ', 'Підтвердити відсутність'],
          edit: ['Скасувати цю відсутність?', 'Скасувати відсутність']
        },
        description: [' Тут немає ', 'жодної ', 'активної резервації для цього ', ' протягом цього періоду.'],
        modals: {
          user: 'Змінити користувача? Поточна відсутність буде скасована.',
          not_available: "Відсутність недоступна ",
          delete: 'Скасувати цю відсутність?'
        }
      },
      reservations_list: {
        add_button: 'Додати резервацію',
        user_select: 'Резервації для',
        tabs: [
          {name:"Особисті", state: false},
          {name:"Гостьові", state: true}
        ],
        colleagues_info: "У вас немає колег з видимими резерваціями",
        counter: 'Немає резервацій',
        right_panel_info: ['Виберіть колегу зі списку, щоб побачити його резервації.', 'Оновіть резервацію або створіть нову, щоб побачити доступні ресурси']
      },
      reservation: {
        pageName: 'бронювання',
        header_requests: ['Ще немає бронювань.', 'Заповніть деталі бронювання та перевірте доступність.'],
        details: ' деталі',
        end: 'Завершити бронювання',
        amenities_item: 'інший запит',
        snack_bars: {
          create: 'Бронювання створено',
          update: 'Бронювання оновлено'
        },
        visitor: {
          text: "Бронювання для гостя"
        },
        placeholders: {
          visitor: "Електронна адреса",
          amenities: 'Вибір обладнання',
          no_amenities: 'Немає обладнання',
          subject: 'Тема',
          neighbourhood: "Обрати посаду"
        },
        public: {
          text:'Приватне бронювання' ,
          tooltip: 'Ваше бронювання видно лише колегам, яких ви прийняли в налаштуваннях. Вимкнувши цю опцію, ваше бронювання не буде видно.'
        },
        right_panel_info: {
          no_checked_availability: ['Заповніть деталі та', 'перевірте доступність'],
          checked_availability: ['Деталі змінилися, ', 'перевірте доступність знову']
        },
        modals: {
          amenity: {
            text: "Ваше поточне бронювання не має всіх потрібних зручностей!",
            descriptions: [
              "Але немає проблем, ви можете продовжити з поточним бронюванням.",
            ]
          },
          error_404: "Бронювання недоступне",
          requested_capacity: {
            text: "Ваше обране місце не має достатньої кількості місць!",
            descriptions: [
              "Продовжуйте, щоб зберегти свою кількість місць та шукати інше місце.",
              "Або скасуйте зміну кількості місць та збережіть обране місце.",
            ]
          },
          internal: {
            text: "Ваше обране місце призначене лише для внутрішніх зустрічей!",
            descriptions: [
              "Продовжуйте, щоб зберегти зміну та шукати інше місце.",
              "Або скасуйте зміну та збережіть обране місце.",
            ]
          },
          time: {
            text: "Ваше бронювання недоступне на цей час!",
            descriptions: [
              "Продовжуйте, щоб зберегти новий період, і ваше бронювання буде оновлено.",
              "Або скасуйте зміну часу та збережіть поточне бронювання."
            ]
          },
          space: {
            text: "Ваше обране місце призначене лише для внутрішніх зустрічей!",
            descriptions: [
              "Продовжуйте, щоб зберегти зміну та шукати інше місце.",
              "Або скасуйте зміну та збережіть обране місце.",
            ]
          },
          invalid_email: "Ви повинні ввести дійсну електронну адресу відвідувача або вимкнути перемикач відвідувача для створення бронювання.",
          delete: {
            simple: {text:'Скасувати це бронювання?'},
            ongoing: {text:'Завершити це бронювання?'},
            visitor_change: {
              text:{
                toggle: "Переключення між особистим бронюванням та бронюванням для відвідувача видалить поточне бронювання!",
                email: "Зміна електронної адреси відвідувача видалить ваше поточне бронювання!",
              },
              descriptions: [
                "Продовжуйте, щоб зберегти зміни, і ваше бронювання буде оновлено.",
                "Або скасуйте зміну та збережіть поточне бронювання.",
              ]
            },
            user_change: {
              text:'Зміна користувача, ваше поточне бронювання буде скасовано!',
              descriptions: [
                "Продовжуйте, щоб зберегти нового користувача, і ваше бронювання буде оновлено.",
                "Або скасуйте зміну та збережіть поточне бронювання."
              ]
            },
            site_change: {
              text:'Зміна місця, ваше поточне бронювання буде скасовано!',
              descriptions: [
                "Продовжуйте,щоб зберегти нове місце, і ваше бронювання буде оновлено.",
                "Або скасуйте зміну та збережіть поточне бронювання."
              ]
            }
          }
        }
      },
      home: {
        nwesfeed_list: 'Немає новин сьогодні!',
        pageName: 'home',
        emergency: {
          header: 'ЕКСТРЕНЕ ПОВІДОМЛЕННЯ',
          by: 'від'
        },
        reservation: {
          headers: {
            upcoming: 'Ваш наступний запис',
            current: 'Ваш поточний запис',
            no_upcoming: 'Немає майбутніх записів'
          },
          descriptions: ['Ми тут, щоб допомогти вам!', 'Зробіть свій вибір за допомогою кнопок нижче.'],
          loading: 'Завантаження ...'
        },
        newsfeed_header: 'Новини'
      },
      settings: {
        pageName: 'settings',
        h1: 'Налаштування користувача',
        password: {
          errors: ["Принаймні 1 число, 1 символ і довжина 8 символів", 'Паролі не співпадають'],
          rules: "Пароль має містити принаймні: 8 символів, 1 велику літеру, 1 маленьку літеру, 1 цифру",
          rules_v2: 'Повинен містити принаймні: 8 символів, 1 велику літеру, 1 малу літеру, 1 цифру',
          placeholders: ['Введіть новий пароль', 'Повторно введіть пароль'],
          info_ok:"Пароль змінено",
          info_not_ok: "Пароль не змінено"
        },
        h3_sections: {
          password: 'Змінити пароль',
          image: 'Завантажити зображення',
          site: 'Уподобана локація для нового запису',
          wing: 'Типове крило для нового запису',
          time: 'Формат відображення часу',
          language: 'Мова',
          preferences: 'Параметри',
          preferences_v2: 'Уподобання користувача',
          delegate: 'Делегувати',
          calendar: 'Запрошення у календар',
          wants_asset_details_popup: 'Інформація на карті'
        },
        subsections: {
          app: "Версія",
          sites: "Видимі сайти",
          language: "Мова",
          time: "Формат часу",
          calendar: "Запрошення до календаря"
        },
        user_image_info: ['Зверніться до адміністратора', 'локації, щоб змінити ваше зображення'],
        user_image: ['Зображення користувача', '(квадратний розмір, менше 4 МБ)', 'Завантажте зображення квадратної форми розміром менше 4 МБ'],
        toggle: 'Використовувати 24-годинний формат',
        app_version: "Нова версія (потрібен перезапуск)",
        calendar_toggle: 'Надсилати запрошення у календар для резервувань, зроблених у веб-додатку',
        wants_asset_details_popup_toggle: "Показувати контекстні деталі при виборі ресурсу на карті",
        no_delegates: 'Делегатів немає',
        delegates_action_list: {
          search: 'Пошук',
          edit: 'Колеги, які можуть створювати або редагувати мої записи',
          add: 'Додати колег'
        },
        delegates_modal: "Поставивши прапорець, таблиця нижче не буде враховуватися, і всі зможуть бачити ваші публічні зустрічі."
      },
      who_is_arround: {
        header: 'Хто тут',
        tooltip: 'Знайдіть, хто з вашого племені працює в певний час. Якщо ніхто не є у вашому племені, створіть його у розділі "Моє Плем’я".',
        date_time: {
          main: 'Дата та час',
          helper: 'ЧАС'
        },
        info: 'Виберіть доступний ресурс на карті, щоб створити бронювання',
        users_list: {
          header: {
            hide: 'Сховати',
            show: 'Показати',
            postfix: ' Список колег'
          },
          placeholder: 'Ім\'я користувача'
        },
        right_panel_info: 'Виберіть місце, щоб побачити доступність',
        modal: 'Створити бронювання?',
      },
      my_tribe: {
        header: 'Моє Плем’я',
        tabs: ['Підписані', 'Підписники'],
        info: ['Колеги, які можуть бачити ваші не-приватні бронювання', 'Запити на підписку на вас', 'Колеги, резервування яких ви можете бачити'],
        serach_header: 'Результати пошуку',
        no_results: 'Результатів немає',
        placeholders: ['Пошук', 'Знайдіть колег для підписки'],
        modals: {
          confirm: {
            text:"Чи хочете ви також підписатися на цього колегу?",
            cancel: "Не підписуватися",
            confirm: "Підписатися"
          },
          delete: {
            text:"Ви дійсно хочете видалити запит цього колеги?",
            cancel: "Скасувати",
            confirm: "Видалити"
          },
          remove: {
            text:"Ви дійсно хочете видалити цього колегу?",
            cancel: "Скасувати",
            confirm: "Видалити"
          },
          cancel: {
            text: 'Ви дійсно хочете скасувати свій запит?',
            cancel: 'Ні',
            confirm: 'Так'
          },
          unfollow: {
            text: 'Ви дійсно хочете відписатися від цього колеги?',
            description: ['Якщо ви зміните думку, вам доведеться знову подати запит.'],
            cancel: "Скасувати",
            confirm: 'Відписатися'
          }
        }
      },
      squads: {
        header: 'Мої команди',
      },
      qr: {
        header: 'Реєстрація або QR-вхід',
        checkout: 'Бажаєте вийти?',
        checkedout: 'Залишено',
        photo_description: 'Зчитайте QR-код, щоб зареєструватися для поточного бронювання або створити вхід без попереднього бронювання',
        start_scan_description: 'Спрямуйте на QR-код та торкніться тут',
        errors: {
          camera: "Камера не підтримується в Chrome, будь ласка, використовуйте Safari",
          app: "Будь ласка, використовуйте додаток для зчитування QR-коду!",
          device: "Будь ласка, використовуйте задню камеру!"
        },
        modals: {
          info: 'Доступ до камери необхідний для зчитування QR-коду.',
          check_in: {
            header: 'Заброньовано для:',
            from_to: ['Від: ', ', до '],
            subject_default: 'Бронювання'
          }
        }
      },
      help_center: {
        nav: {
          demo: {
            header: 'Швидкий огляд',
            card: {
              headers: [],
              descriptions: ['Перегляньте цей швидкий огляд, щоб отримати загальне уявлення про всі можливості Tribeloo.'],
              links: []
            }
          },
          get_started: {
            header: 'Почати',
            sub_headers: ['Відео налаштування', 'Єдиний вхід (SSO)', 'Провізіонування користувачів'],
            card: {
              headers: [],
              descriptions: ['Дізнайтеся, як почати використовувати Tribeloo та налаштувати платформу.', 'Ознайомтеся з інструкціями зі встановлення функції єдиного входу (SSO)', 'Ознайомтеся з інструкціями щодо встановлення функціоналу провізіонування користувачів'],
              links: ['тут.']
            }
          },
          site_manager: {
            header: 'Керівник офісу',
            sub_headers: ['Відео презентації', 'Налаштування пристроїв'],
            card: {
              headers: ['Налаштування пристроїв'],
              descriptions: ['Дізнайтеся, як використовувати Tribeloo в якості керівника офісу.', 'Дізнайтеся, як налаштувати пристрої для офісів та конференц-залів.'],
              links: []
            }
          },
          user: {
            header: 'Користувач',
            sub_headers: ['Відео презентації', 'Використання плагіна Outlook', 'Встановлення плагіна Outlook', 'Використання розширення Chrome', 'Встановлення розширення Chrome', 'Встановлення додатка на iPhone', 'Встановлення додатка на Android'],
            card: {
              mobile: 'Дізнайтеся, як використовувати мобільний додаток Tribeloo та веб-систему бронювання.',
              outlook: {
                headers: ['Як використовувати плагін Outlook', 'Як встановити плагін Outlook'],
                descriptions: ['Дізнайтеся, як використовувати плагін Outlook для Tribeloo.', 'Дізнайтеся, як встановити плагін Outlook для Tribeloo.', 'Відвідайте ',
                               'Microsoft store', ' для його завантаження.']
              },
              google: {
                headers: ['Як використовувати розширення Chrome', 'Як встановити розширення Chrome'],
                descriptions: ['Дізнайтеся, як використовувати розширення Chrome для Tribeloo та інтеграцію з вашим календарем Google', 'Дізнайтеся, як встановити розширення Chrome для Tribeloo, інтегроване з вашим календарем Google.', 'Відвідайте ', 'Chrome store', ' для його завантаження.']
              },
              pwa: {
                headers: [],
                descriptions: ['Перегляньте відео нижче або ', 'завантажити PDF', ' про те, як встановити додаток Tribeloo на ваш iPhone', ' про те, як встановити додаток на ваш телефон Android']
              }
            }
          },
          feedback: {
            header: 'Зв\'язатися з нами',
            sub_headers: [],
            card: {
              headers: ["Не можете знайти те, що шукаєте? Або хочете залишити відгук?"],
              descriptions: ['Дозвольте нам допомогти! Будь ласка, надавайте якомога більше деталей, щоб ми могли допомогти вам швидше.'],
              links: []
            }
          }
        },
        main: {
          header: 'Центр допомоги',
          orange_card: ['Натисніть на значки', ' у додатку для отримання більше інформації про конкретну тему.'],
          subheaders: ['Ласкаво просимо!', 'Знайдіть корисну інформацію про те, як використовувати Tribeloo.', 'Ви завжди можете повернутися сюди, натиснувши на "Центр допомоги" на головній сторінці або у налаштуваннях.']
        }
      }
    },
  },
  shared_components: {
    wings_select: "Усі крила",
    activity_select: {},
    assets_map:{
      types_external_internal: [', Тільки внутрішні', ', зовнішні'],
      owner: 'Призначено для: ',
      time_slots: "Доступно: ",
      no_time_slots: "Немає доступних часових інтервалів",
      not_reservable: "Ви не можете забронювати цей ресурс"
    },
    attendees_modal: {
      header_text: {
        share: 'Поділіться з колегами',
        attendees: 'Будуть присутні'
      },
      info: ['Оберіть колег зі списку, щоб надіслати їм електронного листа.', 'Попросіть своїх колег додати вас до свого племені'],
      placeholdrs: {
        colleagues: 'Оберіть колегу',
        message: 'Напишіть свій текст тут'
      },
      button_attendees: ['Не йдете?', 'Ви будете?'],
      more: ' ще...',
      send: 'Надіслати',
      send_all: 'Надіслати лист усім',
      counter: 'учасник(ів)'
    },
    total_counter_download_table_csv: {
      batch_buton: 'Пакетне редагування',
      button: 'Завантажити',
      text: ' Всього',
      selected: 'Обрано',
      info: 'Немає даних для обраних параметрів'
    },
    confirm_dialog: {},
    date_time_range_modal: {
      headers:{
        main:'Оберіть дату та час',
        from: 'Початок',
        to: 'Кінець'
      },
      tooltip: 'Натисніть для календаря',
      info: "'Початок' має йти після 'Кінець'"
    },
    date_time_range_view: {
      headers: {from: 'Початок', to: 'Кінець', start: 'Початок', end: 'Кінець'}
    },
    day_time_mini: {
      header: ["Встановити ", " дату та час"],
      tooltip: {
        calendar: "Натисніть, щоб відобразити календар",
        time: "Натисніть, щоб відобразити час"
      }
    },
    download_xlsx: {},
    expanded_filter_assets_list: {
      modal: {
        header: "Інформація про ресурси",
        report_button: "Повідомити про проблему"
      },
      header: ' список ресурсів',
      description: 'Тільки з усім обладненням',
      nospace: 'Нічого доступно',
      types_external_internal: [', внутрішній', ', зовнішній'],
      placeholder: 'Шукати',
      tooltip: 'Не містить усіх потрібних зручностей'
    },
    filter_dialog: {},
    icon_video: "Додаткова інформація",
    info_dialog: {},
    kiosk_logout_info_modal: {
      message: "Ви все ще працюєте тут?",
      info: ["Ваша сесія завершиться через ", " секунд. "]
    },
    newsfeed_create_edit_item: {
      popup: "Відкинути незбережені зміни?",
      markdown: "Підтримка стилізації за допомогою Markdown",
      link: "Перевірити вимоги",
      image_info: "Зображення (< 4 МБ, розмір 335 x 75 пікселів)",
      radio: ["Тип:", "Новина", "Подія"],
      placeholder: {
        event: "Нова подія",
        location: "Місце проведення",
      }
    },
    newsfeed_item: {
      default_subject: '',
      actions: ['більше', 'закрити']
    },
    loading_table_spinner: 'завантаження даних',
    reservation_header_info: {
      state: ['Резервація очікує', 'Призначено'],
      info: "Перевірте доступність для призначення",
      internal_external: [', внутрішній', ', зовнішній']
    },
    reservation_tipe_modal: 'Опції для додавання відсутні, будь ласка, змініть існуюче бронювання',
    site_dialog: {},
    site_select: {
      placeholder: 'Виберіть сайт',
      tooltip: 'Натисніть тут, щоб вибрати сайт',
      options: ['Всі сайти', 'Немає сайту']
    },
    site_wings_reservation_view_external_data_set: "Натисніть, щоб отримати доступ до цього поверху",
    table_datetime_range_helper: {},
    time_range_helper: {},
  },
  user_shared_components: {
    capcity_internal_block: {
      placeholder: 'місця',
      tooltip: 'Зовнішнє засідання: з зовнішніми відвідувачами. Внутрішнє засідання: тільки з внутрішніми співробітниками',
      select: ['Простий простір', 'Внутрішнє засідання', 'Зовнішнє засідання', 'Зала']
    },
    list_item: {
      internal_external: [', внутрішнє', ', зовнішнє'],
      info_reservation: ['Резервування очікується, скоро вам призначать місце.', 'Або перевірте доступність, щоб вибрати самостійно.'],
      subject: ['Нова відсутність', 'Нове резервування '],
      status: 'звільнено',
      header: 'Ваш '
    },
    message_details: {
      toggle:'Додати запит',
      info: ['Запит не оброблено', 'Запит оброблено'],
      placeholder: "Залиште запит для адміністративного керівника, якщо у вас є питання"
    },
    weeks_repeater: {
      title:' Повторити до',
      weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']
    },
    repeater_modal: {
      header: 'Налаштувати',
      repeat_until: 'Повторити до',
      repeat_on: 'Повторювати кожні'
    }
  },
  reusable_data: {
    time_tracking_popup: {
      big_titel: "Ви працювали ",
      descriptions: ["Хочете припинити працювати?"]
    },
    tables: {
      from: "Від",
      to: "До",
      date_time: "Дата і час"
    },
    feedback: {
      header: 'Залишити відгук',
      placeholders: ['Оберіть вид коментаря', 'Додати опис'],
      types: ['баг', 'ідея', 'питання', 'інше'],
      buttons: ['Скасувати', ' Надіслати'],
      issue_text: "Ваш коментар не було надіслано. Будь ласка, спробуйте пізніше. Дякуємо!"
    },
    user_navigation: {
      no_actions_nav: {
        home: 'Головна',
        my_tribe: 'Моя зграя',
        squads: 'Мої команди',
        outlook_events: 'Події Outlook',
        google_events: 'Події Google',
        qr: 'Реєстрація або walk-in QR',
        tracker: ["Початок роботи, ", "Кінець роботи, "],
        reservations: 'Керування резерваціями',
        releases: 'Керування ресурсами',
        who_is_arround: 'Хто поряд?',
        find_colleague: 'Знайти колегу',
        report_issue: 'Повідомити про проблему',
        user_settings: 'Налаштування',
        help_center: 'Центр допомоги'
      },
      actions_nav: {
        book_desk: 'Обрати стіл',
        book_meeting_room: 'Обрати кімнату',
        add_new_release: 'Новий ресурс'
      }
    },
    button: {
      ok: 'OK',
      add: 'Додати',
      show: 'Показати',
      hide: 'Сховати',
      send: 'Надіслати',
      save: 'Зберегти',
      edit: 'Редагувати',
      more: 'Більше',
      close: 'Закрити',
      email: 'Електронна пошта',
      login: 'Увійти',
      logout: 'Вийти',
      check_in: 'Реєстрація',
      submit: 'Надіслати',
      delete: 'Видалити',
      select: 'Вибрати',
      cancel: 'Скасувати',
      upload: 'Завантажити',
      remove: 'Видалити',
      reopen: 'Відкрити',
      comment: 'Залишити коментар',
      confirm: 'Підтвердити',
      inverse: 'Інвертувати',
      refresh: 'Оновити',
      continue: 'Продовжити',
      download: 'Завантажити',
      show_all: 'Показати все',
      checked_in: 'Зареєстровано',
      select_all: 'Вибрати все',
      batch_edit: 'Пакетне редагування',
      upload_image: 'Завантажити',
      remove_image: 'Видалити зображення',
      assign_me_one: 'Призначте мені один(у)',
      back_to_dashboard: '< Назад до інформаційної панелі',
      cancel_reservation: 'Скасувати',
      check_availability : 'Перевірити наявність',
      retutn_releases_list: 'Повернутися до списку відсутностей',
      retutn_reservations_list: 'Повернутися до списку резервацій',
      retutn_reservations_releases_events_list: [ 'Повернутися до списку резервацій', ' події']
    },
    release_reservation_text: {
      outlook: 'Змініть час у календарі Outlook',
      google: 'Змініть час у календарі Google'
    }
  },
  v2_shared_components: {
    calc: {
      sum: "Total summ: ",
      count: "count: ",
      price: "price: "
    },
    tribeloo_asset_info: {
      user_info: "Призначено вам",
      user_release_info: "Цей ресурс був призначений вам. Забронюйте його, якщо хочете його використовувати.",
      user_booked_info: "Цей ресурс був призначений вам і доступний для вас в будь-який час.",
      invited_users: " запрошені колеги та тема",
      time_slot: "Недоступно сьогодні",
      buttons: {
        invite: 'Запросити колег',
        book: 'Підтвердити бронювання',
        update: 'Підтвердити зміни',
        delete: 'Скасувати бронювання',
        edit: 'Редагувати бронювання'
      }
    },
    tribeloo_asset_list: {
      headers: {
        menu: "Інструменти Tribeloo",
        favorites: "Улюблене"
      },
      filter: {
        search: "Пошук",
        all: "Усі",
        users_only: "Тільки користувачі",
        resources_only: "Тільки ресурси"
      },
      menu_items: {
        colleagues: {
          subtitle: "Знайти колегу",
          description: "Легко співпрацюйте зі своїми колегами"
        },
        bookings: {
          subtitle: "Мої бронювання",
          description: "Огляд всіх ваших бронювань"
        },
        releases: {
          subtitle: "Випуски",
          description: "Звільніть свій постійний робочий стіл для інших"
        },
        qr: {
          subtitle: "QR-сканер",
          description: "Забронюйте або відміттесь за допомогою QR-сканера"
        }
      }
    },
    tribeloo_asset_list_item: {
      check_in: ["Зареєстровано", "Реєстрація"]
    },
    tribeloo_asset_release_popper: {
      buttons: {
        create: "Створити випуск",
        cancel: "Скасувати випуск"
      }
    },
    tribeloo_assets_popper: {
      title: "Доступні ресурси",
      show_all: "Всі",
    },
    tribeloo_component_filter: {
      title: "Параметри фільтрації",
      date_range: ["Діапазон дат", "З", "До"],
      type: "Тип ресурсу",
      location: "Місце"
    },
    tribeloo_bookings_list: {
      subtitle: ['Знайти колег', 'Мої бронювання'],
      description: ['Шукайте колег, щоб знайти їхні бронювання та оптимізувати співпрацю', 'Огляд всіх ваших бронювань. Управління всіма бронюваннями в одному місці.'],
      search: " результати пошуку.",
      not_found: "Колег не знайдено."
    },
    tribeloo_camera: {
      camera_error_message: "Будь ласка, надайте доступ до камери",
      camera_not_found: "Камера не знайдена."
    },
    tribeloo_date_picker: {
      max_selection_info: "Досягнуто максимальний вибір на кілька днів",
      days_selected_text: "днів обрано",
      toggle_text_info: ["Багатоденний вибір ", "Багатоденний вибір"]
    },
    tribeloo_download_image: {
      info_dialogue: "Ваше зображення занадто велике.\nВиберіть зображення розміром менше 4 МБ."
    },
    tribeloo_edit_invites: {
      title: "Редагувати запрошення на бронювання",
      subtitle: "Електронного листа буде відправлено лише новим доданим колегам.",
      buttons: {
        main_invite: "Запросити колег",
        send_invite: ['Відправити запрошення ще раз', 'Відправити запрошення']
      },
      placeholder: "Запрошення оновлено"
    },
    tribeloo_favorites_list_item: {
      other_info: " Активні бронювання"
    },
    tribeloo_map_legend: {
      button: "Центрувати карту",
      map_legend_title: "Легенда карти",
      map_legend_items: {
        available: "Доступно",
        confirm: "Потрібна підтвердження",
        not_for_user: "Не для користувача",
        unavailable: "Недоступно"
      }
    },
    tribeloo_qr_scanner: {
      title: "Сканування QR-коду",
      subtitle: "Покладіть QR-код перед камерою, щоб забронювати або відмітитися.",
      error_message: "Помилка при скануванні QR-коду",
      processing_message: "В процесі декодування скану"
    },
    tribeloo_releases_sidebar: {
      title_resources: "Мої ресурси",
      title_releases: "Мої випуски",
      subtitle: "Випуски",
      description: "Налаштуйте час, дату та виберіть ресурс, який ви хочете випустити.",
    },
    tribeloo_type_location_neighbourhood: {
      labels: {
        type: "Тип ресурсу",
        site: "Сайт",
        wing: "Корпус",
        neighbourhoods: "Сусідства"
      }
    },
    tribeloo_upload_profile_pic: {
      title: "Завантажити зображення",
      subtitle: "Виберіть фото профілю для відображення на карті",
      info_dialog_component: "Ваше зображення занадто велике.\nВиберіть зображення розміром менше 4 МБ.",
      support_text: "Підтримує JPG або PNG",
      dd_text: "Перетягніть і відпустіть зображення або ",
      browse_text: "перегляд",
      buttons: {
        save: "Зберегти зображення",
        upload: "Завантажити зображення",
        capture: "Зробити фото за допомогою веб-камери",
      },
      img_alt: "Нове фото профілю"
    },
    tribeloo_whoisaround: {
      title: "Хто поруч?"
    },
    tribeloo_more_settings: {
      title: "Додаткові налаштування",
      labels: {
        seats: "Місця",
        amenities: "Зручності",
        visitor: "Запросити відвідувача"
      },
      toggles: {
        amenities: "Показувати лише ті, що мають всі зручності",
        booking: "Бронювання не видно для інших"
      },
      placeholders: {
        visitor: "Електронна адреса відвідувача",
        amenities: "Жодні обрані зручності"
      },
      info: "Додаткові налаштування недоступні"
    },
    tribeloo_time_range: {
      labels: {
        select: "Швидкий вибір",
        start: "Початок часу",
        end: "Кінець часу"
      },
      rangeOptions: {
        fullDay: 'Весь день',
        morning: 'Ранок',
        afternoon: 'Полудень',
        customTime: 'Власний час'
      }
    },
    tribeloo_time_picker: {
      tooltip_error: "Недійсний формат часу",
    },
    tribeloo_single_file_upload: {
      button_save: "Зберегти обрізане зображення"
    },
    tribeloo_pop_up_confirmation: {
      titles: {
        cancel: "Скасування бронювання",
        edit: "Успішне редагування бронювання",
        invite: "Редагування запрошення на бронювання",
        booked: "Успішне бронювання!",
        options: "Додаткові варіанти бронювання",
        addSubject: "Додати тему",
      },
      subtitles: {
        edit_booked: "Вам буде надіслано електронний лист з інформацією про ваше бронювання.",
        invite: "Електронний лист буде відправлено лише новим доданим колегам.",
        old: "Старе бронювання",
        new: "Нове бронювання",
        cancel: "Після скасування бронювання його не можна відновити."
      },
      input: ['Запрошення оновлено', 'Колеги запрошені'],
      subjectInput: {
        saved: "Тема збережена",
        placeholder: "Назва теми"
      },
      buttons: {
        confirm: 'Підтвердити',
        main: "Запросити колег",
        booKingSubjectInvitations: "Тема бронювання та запрошення",
        send: "Надіслати запрошення",
        update: "Оновити запрошення",
        cancel: "Скасувати бронювання",
        nevermind: "Нічого страшного",
        saveSubject: "Зберегти тему"
      }
    },
    tags_modal: {
      title: "Теги",
      subtitle: "Невелике пояснення, як працюють теги",
      small_title: ['Пропозиції', 'Всі теги'],
      colleagues: "Колеги",
      placeholder: "Пошук або додавання тегів",
      add_button: ['Додати', 'Додати новий тег'],
      save_button: "Зберегти зміни"
    },
    tribeloo_base_user_settings: {
      settings: "Налаштування користувача",
      tribe: "Мої групи"
    },
    tribeloo_filter_tribe_members: {
      title: "Параметри фільтрації",
      tag: "Теги",
      site: "Домашня сторінка"
    },
    tribeloo_my_tribe: {
      description: "Відзначте колег, щоб легко знаходити їх при створенні резервації та визначайте свої власні групи.",
      search: "Пошук"
    },
    tribeloo_tribe_member: {
      title: "Теги",
      not_found: "Теги не знайдено",
      edit_button: "Редагувати теги"
    }
  },
  v2_pages: {
    tribeloo_default_page_holder: {
      messages: {
        update_success: 'Бронювання успішно оновлено',
        update_failed: 'Не вдалося оновити бронювання.',
        cancel_failed: "Не вдалося скасувати випуск",
        error408: "Do you wont to force book?"
      }
    },
    tribeloo_external_invites: {
      info: "Ваше запрошення недійсне",
      button: "На головну"
    }
  }
};
