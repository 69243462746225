import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { interval } from 'rxjs';
import { LocalizationService } from "app/services/localization/localization.service";

@Component({
  selector: 'app-kiosk-logout-info-modal',
  templateUrl: './kiosk-logout-info-modal.component.html',
  styleUrls: ['./kiosk-logout-info-modal.component.css']
})
export class KioskLogoutInfoModalComponent {

  intervalSubscriber: any;
  iterator: number = 10

  languages: any;
  button: any;

  constructor(
     public dialogRef: MatDialogRef<KioskLogoutInfoModalComponent>,
     private localizationService: LocalizationService
   ) {
     if(this.localizationService.getComponentText('shared_components','kiosk_logout_info_modal').isSuccess) {
       this.languages = this.localizationService.getComponentText('shared_components','kiosk_logout_info_modal').data;
     }
     if(this.localizationService.getReusableDataText('button').isSuccess) {
       this.button = this.localizationService.getReusableDataText('button').data;
     }

     this.intervalSubscriber = interval(1000)
     .subscribe(() => {
       this.iterator--;
       if(this.iterator == 0) {
         this.close(false)
       }
     });
   }

   close(value) {
     this.intervalSubscriber.unsubscribe();
     this.dialogRef.close(value);
   }

}
