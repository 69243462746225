import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ImageService {

  constructor() { }

  path(serverPath) {
    return environment.image_url_type != 'absolute' ? environment.backend_url + serverPath : serverPath;
  }

  getWingsList(wings) {
    for (let i = 0; i < wings.length; i++) {
      wings[i].image = this.path(wings[i].image);
    }

    return wings;
  }

}
