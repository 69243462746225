import {
  Input,
  Output,
  Component,
  EventEmitter
} from '@angular/core';
import { LocalizationService } from "./../../services/localization/localization.service";

@Component({
  selector: 'expanded-filter-assets-list',
  templateUrl: './expanded-filter-assets-list.component.html',
  styleUrls: ['./expanded-filter-assets-list.component.css']
})
export class ExpandedFilterAssetsListComponent {

  selectedWing: number = -1;
  assetsFilterd: any[] = [];
  assetsPure: any[] = [];
  open: boolean = true;
  @Input() isCheckedData: boolean = true;
  @Input() set assets(value) {
    if(value) {
      this.assetsPure = value;
      this.filterBySelectedWing();
    }
  }
  @Input() set wingID(value) {
    this.selectedWing = value;
    this.filterBySelectedWing();
  }
  assetsSearch: string = "";
  roomsWithoutAllAmenities: boolean = true;
  @Output() selectNewAsset = new  EventEmitter<any>();
  @Output() changestateAssetsAmenitiesVisibility = new  EventEmitter<any>();

  languages: any;
  button: any;

  constructor(private localizationService: LocalizationService) {
    if(this.localizationService.getComponentText('shared_components','expanded_filter_assets_list').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','expanded_filter_assets_list').data;
    }

    if(this.localizationService.getReusableDataText('button').isSuccess) {
      this.button = this.localizationService.getReusableDataText('button').data;
    }
  }

  filterBySelectedWing() {
    if(this.assetsPure.length && this.selectedWing > -1) {
      this.assetsFilterd = this.assetsPure.filter(item => item.wing_id == this.selectedWing)
    } else {
      this.assetsFilterd = [...this.assetsPure];
    }
  }

  assignAsset(asset) {
    this.selectNewAsset.emit(asset);
  }

  changestate() {
    this.changestateAssetsAmenitiesVisibility.emit(this.roomsWithoutAllAmenities);
  }

}
