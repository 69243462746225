import { Component, OnInit, Inject } from '@angular/core';
import { LocalizationService } from "app/services/localization/localization.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-asset-details-modal',
  templateUrl: './asset-details-modal.component.html',
  styleUrls: ['./asset-details-modal.component.css']
})
export class AssetDetailsModalComponent implements OnInit {

  assetsSearch: string = "";
  roomsWithoutAllAmenities: boolean = true;
  isReportingIssue: boolean = true;
  showAssetsWithoutAllAmenities: boolean = true;

  asset: any;

  languages: any;
  button: any;

  constructor(
    public dialogRef: MatDialogRef<AssetDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localizationService: LocalizationService
  ) {
    if( Object.keys(this.data.selectedAsset).length != 0) {
      this.asset = this.data.selectedAsset;
      this.isReportingIssue = this.data.isReportingIssue;
      this.showAssetsWithoutAllAmenities = this.data.showAssetsWithoutAllAmenities;
      console.log(this.asset)
    }
    if(this.localizationService.getComponentText('shared_components','expanded_filter_assets_list').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','expanded_filter_assets_list').data;
    }
    if(this.localizationService.getReusableDataText('button').isSuccess) {
      this.button = this.localizationService.getReusableDataText('button').data;
    }
  }

  ngOnInit() {
  }

  close(data) {
    this.dialogRef.close(data);
  }

  onChange(item) {
    // this.asset = item;
    console.log(item)
  }

}
