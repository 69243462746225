import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAssetsByObjectNames'
})
export class FilterAssetsByObjectNamesPipe implements PipeTransform {

  transform(items: any[], names: string): any {
    if (!items || names=='') {
      return items;
    }

  
    return items.filter(
      item => item.name.toLowerCase().indexOf(names.toLowerCase()) !== -1 ||
      ('wing_name' in item && 'all_amenities' in item && 'free_time_slots' in item ?
      (
        item.wing_name?.toLowerCase().indexOf(names.toLowerCase()) !== -1 ||
        item.all_amenities?.toLowerCase().indexOf(names.toLowerCase()) !== -1 ||
        item.free_time_slots?.filter(timeSlot => timeSlot.text.toLowerCase().includes(names.toLowerCase())).length > 0
      ) : false)
      // item.site_name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
    );

  }

}
