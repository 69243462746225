import { en } from './en';
import { es } from './es';
import { fr } from './fr';
import { de } from './de';
import { ua } from './ua';
import { pt } from './pt';
import { nl } from './nl';

export const localizations = {
  en,
  es,
  fr,
  de,
  ua,
  pt,
  nl
};
