import {
  Input,Output,
  Component,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AttendeesModalComponent } from '../attendees-modal/attendees-modal.component';
import { environment } from '../../../environments/environment';

import * as moment from 'moment';
import * as bowser from 'bowser';

import { LocalizationService } from "./../../services/localization/localization.service";

@Component({
  selector: 'newsfeed-item',
  templateUrl: './newsfeed-item.component.html',
  styleUrls: ['./newsfeed-item.component.css']
})
export class NewsfeedItemComponent implements AfterViewInit {

  @Input() newsItem: any;
  @Input() isUser: boolean = false;
  @Output() edit = new  EventEmitter<any>();
  @Output() remove = new  EventEmitter<any>();
  @Output() messageToAttendees = new  EventEmitter<any>();
  @Output() addselfToAttendees = new  EventEmitter<any>();
  @Output() share = new  EventEmitter<any>();

  backend_url: string = environment.backend_url;
  isRelative: boolean = environment.image_url_type != 'absolute';
  collapse: boolean = true;
  isShowMoreButton: boolean = true;
  dialogData: any = true;
  setDateTimeRange: any;

  languages: any;

  constructor(
    public dialog: MatDialog,
    private cdRef : ChangeDetectorRef,
    private localizationService: LocalizationService
  ) {
    if(this.localizationService.getComponentText('shared_components','newsfeed_item').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','newsfeed_item').data;
    }
  }

  ngAfterViewInit() {
    this.setDateTimeRange = {
      from: moment(this.newsItem.event_from).toDate(),
      to: moment(this.newsItem.event_to).toDate()
    };

    if(this.newsItem.type != 'Event') {
      let show = document.getElementById(this.newsItem.id).scrollHeight != document.getElementById(this.newsItem.id).clientHeight;

      if (bowser.ios != undefined || bowser.mac != undefined) {
        this.collapse = false;
        this.isShowMoreButton = false;
        this.cdRef.detectChanges();
      } else {
        if (show != this.isShowMoreButton) {
          this.isShowMoreButton = show;
          this.cdRef.detectChanges();
        }
      }
    }

  }

  getCorrectPosition() {
    if ((document.getElementsByTagName('body')[0] as HTMLElement).clientWidth > 600 && (document.getElementsByTagName('body')[0] as HTMLElement).clientHeight > 400) {
      this.dialogData = {
        data: { },
        panelClass: ['no-scrolls'],
      };
    } else {
      this.dialogData = {
        data: { },
        panelClass: ['no-padding', 'no-scrolls'],
        position: {
          top: '0px'
        },
        maxWidth: (document.getElementsByTagName('body')[0] as HTMLElement).clientWidth < 400 ? '100vw' : '',
        width: (document.getElementsByTagName('body')[0] as HTMLElement).clientWidth < 400 ? '100vw' : '',
      };
    }
  }

  removeItem() {
    this.getCorrectPosition();
    this.dialogData.data = { text: 'Remove item?', icon: false }
    this.dialog.open(ConfirmDialogComponent, this.dialogData)
    .afterClosed().subscribe(result => {
      if(result) {
        this.remove.emit({item_id: this.newsItem.id})
      }
    });
  }

  onAttendeesAdmin() {
    this.getCorrectPosition();
    this.dialogData.data = { event: this.newsItem, type: 'atendeesAdmin' }
    this.dialog.open(AttendeesModalComponent, this.dialogData)
    .afterClosed().subscribe(message => {
      if(message) {
        this.messageToAttendees.emit({event_id: this.newsItem.id, text: message})
      }
    });
  }
  onAttendeesUser() {
    this.getCorrectPosition();
    this.dialogData.data = { event: this.newsItem, type: 'atendeesUser' }
    this.dialog.open(AttendeesModalComponent, this.dialogData)
    .afterClosed().subscribe(attend => {
      if(attend) {
        this.addselfToAttendees.emit()
      }
    });
  }

  onShare() {
    this.getCorrectPosition();
    this.dialogData.data = { event: this.newsItem, type: 'share' }
    this.dialog.open(AttendeesModalComponent, this.dialogData)
    .afterClosed().subscribe(item => {
      if(item) {
        this.share.emit()
      }
    });
  }

}
