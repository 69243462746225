import {
  Component,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.css']
})
export class VideoModalComponent {

  url: SafeResourceUrl

  constructor(
     public dialogRef: MatDialogRef<VideoModalComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private sanitizer: DomSanitizer,
  ) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.video);
  }

   onClick(): void {
     this.dialogRef.close(true);
   }

}
