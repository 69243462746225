<div class="dateTimeRange-container offset-top" fxLayout="row">
  <mat-checkbox *ngIf="isShowAllCheckActive" class="check" [(ngModel)]="showAllReleases" (change)="showAllReleasesChange()">{{button.show_all}} {{name}}</mat-checkbox>
  <div class="" fxLayout="row" fxLayoutAlign=" center" *ngIf="!showAllReleases">
    <!-- <day-time-mini [setBackground]="setBackground" [setDateTime]="{date: dateTimeRange.from, text: 'start'}" (dateTimeChange)="dateTimeChangeFrom($event)"></day-time-mini>
    <span class="dateTimeRange">to</span>
    <day-time-mini [setBackground]="setBackground" [setDateTime]="{date: dateTimeRange.to, text: 'end'}" (dateTimeChange)="dateTimeChangeTo($event)"></day-time-mini> -->
    <date-time-range-view [setDateTimeRange]="dateTimeRange" (dateTimeChange)="setNewDateTimeRange($event)"></date-time-range-view>
    <time-range-helper [setDateTimeRange]="dateTimeRange" (dateTimeRangeChange)="setNewDateTimeRange($event)"></time-range-helper>
  </div>
</div>
