import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from './../info-dialog/info-dialog.component';


@Component({
  selector: 'download-xlsx',
  templateUrl: './download-xlsx.component.html',
  styleUrls: ['./download-xlsx.component.css']
})
export class DownloadXlsxComponent {

  @Input() nameBtn : string = '';
  @Output() onXLSXLoading = new EventEmitter<any>();

  constructor(public dialog: MatDialog) { }

  fileChange(event) {
    let fileList: FileList = event.target.files;

    if(fileList.length > 0) {
      if(fileList[0].size/1024/1000 < 4) {
        let formData:FormData = new FormData();
        formData.append('csv', fileList[0], fileList[0].name);
        this.onXLSXLoading.emit(formData);
      } else {
        this.dialog.open(InfoDialogComponent, { data: { text: 'Your file is too large.\nPlease select file with size less than 4MB.', button: 'OK', isBlue: true} });
      }
    }
  }

}
