import {
  Input,
  Output,
  Component,
  EventEmitter
} from '@angular/core';

import { WingsService } from "./../../services/wings/wings.service";
import { LocalizationService } from "app/services/localization/localization.service";

@Component({
  selector: 'wing-select',
  templateUrl: './wing-select.component.html',
  styleUrls: ['./wing-select.component.css'],
  providers: [ WingsService ]
})
export class WingSelectComponent {


  wingsData: any = [];
  @Input() wingId: number = -1;
  @Input() set setSite(value) {
    if (value) {
      this.getWings(value);
    }
  }
  @Output() wingChange = new EventEmitter<any>();

  languages: any;

  constructor(
    private wingsService: WingsService,
    private localizationService: LocalizationService
  ) {
    if(this.localizationService.getComponentText('shared_components','wings_select').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','wings_select').data;
    }
  }


  getWings(siteId) {
    this.wingsService.getWingsList(siteId).subscribe(res => {
      if (res.isSuccess) {
        this.wingsData = [{id: -1, name: this.languages}, ...res.data]
        if(this.wingId != -1 && this.wingsData.length > 2) {
          let wing = this.wingsData.find(item => item.id == this.wingId);
          wing ? this.wingId = wing.id : this.wingId = -1;
        } else {
          this.wingsData.length == 2 ? this.wingId = this.wingsData[1].id : this.wingId = -1;
        }
        this.changeWingSelect();
      }
    })
  }

  changeWingSelect() {
    this.wingChange.emit(this.wingId);
  }

}
