export const es = {
  type: "es",
  code: "es-es",
  name: "Español",
  img: "flag-icon-es",
  pages: {
    login_main_navigation: {
      login: {
        pageName: 'iniciar sesión',
        callback: 'Cierra esta ventana y continúa en el complemento de Tribeloo para Outlook',
        email_dialog: {
          header: 'Por favor, ingresa tu correo electrónico',
          input: 'Correo electrónico'
        },
        sso_info:{
          text: "Su navegador bloquea la apertura de una nueva pestaña para iniciar sesión en SSO. Cambie la configuración de su navegador o haga clic en el botón 'Forzar apertura'",
          button: "Forzar aperturan"
        },
        text: {
          loading: ['Trabajando para ti', 'Si tarda demasiado,', 'por favor, recarga'],
          header: 'Bienvenido',
          sub_header: 'Reserva tus escritorios favoritos, encuentra a tus colegas y mucho más.',
          form_headers: {
            login: 'Iniciar sesión',
            email: 'Por favor, verifica el correo electrónico',
            password: '¿Olvidaste tu contraseña?'
          },
          password_info: ["¡No te preocupes! Te enviaremos un enlace de recuperación.", 'Se ha enviado una nueva contraseña a tu correo.'],
          inputs: ['Nombre de usuario', 'Contraseña', 'Correo electrónico'],
          remember_action: ['Recuérdame.', '¿No eres tú?', '¿Olvidaste tu contraseña?', 'Volver al inicio de sesión'],
          button: ['Iniciar sesión con SSO', 'Iniciar sesión', 'Restablecer contraseña'],
          info_modal: 'Revisa tu correo electrónico para cambiar tu contraseña'
        }
      },
      delegates_modal: {
        header: "Seleccionar perfil de delegado",
        sub_header: "Selecciona a la persona para la cual quieres hacer una reserva",
        button_select: "Seleccionar"
      },
      main_navigation: {
        pageName: "Navegación lateral, componente de la barra de herramientas principal",
        nav_tabs_names: {
          user: "Usuario",
          admin: "Administrator"
        },
        nav_dropdown_corg: {
          about: 'Acerca de',
          history: "History",
          feedback: 'Dar retroalimentación',
          logout: 'Cerrar sesión',
          user_settings: "Configuración de usuario",
          delegate: "Cambiar perfiles de delegado",
          user: "Cambiar a perfil de usuario",
          admin: "Cambiar a perfil de administrador"
        },
        admin_nav_items: {
          dashboard: "Dashboard",
          reservation_management: "Reservation management",
          newsfeed: "Newsfeed",
          user_management: "User management",
          site_configuration: "Site configuration",
          kiosk_configuration: "Kiosk configuration",
          issue_management: "Issue management",
          insights: "Insights"
        }
      }
    },
    external: {
      close_issue_by_hash: "Gracias por cerrar este problema!!",
      hash_reservation: "Reserva no disponible",
      google: "Cargando...",
      outlook_reservation: {
        popup: '¿Eliminar esta reserva?',
        login: "Iniciar sesion",
        no_space_info: [ "No hay espacio reservado,", "Por favor conectarse con el organizador de la reunion" ],
        site_headr: "Ubicación del sitio",
        date_time: "Hora mostrada en la zona horaria de la ubicación solicitada/ZONA HORARIA",
        space: {
          header: "Espacio",
          internal_external: [ "interno ", "externo " ]
        },
        amenities_header: "Comodidades"
      },
      reset_password: {
        info_modal: "Contraseña cambiada",
        button_update: "Actualiza contraseña ",
        placeholders: [ "Ingrese nueva constraseña ", "Re ingrese nueva contraseña " ],
        errors: [ "La contraseña no contiene el requerimiento minimo ", "La contraseña no son iguales" ],
        rules: "La contraseña debe contener al menos: 8 caracteres, 1 mayúscula, 1 minúscula, 1 dígito"
      }
    },
    kiosk: {},
    admin: {
      kiosk: {
        main_header: 'Set up a kiosk',
        card: {
          headers: {
            site: 'Select a site',
            wing: ['Select a wing ', '(optional)']
          },
          placeholders: {
            site: 'Please search or select a site',
            wing: 'Please search or select a wing'
          },
          warning: 'Not running in kiosk, PWA or full-screen mode.',
          button: 'Launch kiosk mode',
        },
        info: {
          kiosk: 'For Kiosk - please logout, close browser and than run a following command: ',
          pwa: 'For PWA or full-screen browser - use Google chrome browser and',
          button: 'Click here'
        }
      },
      dashboard: {
        main: {
          refresh: "Refresh",
          site_card: {
            header: " Dashboard",
            action: "Help center",
            sub_header: "Select a site to get an overview"
          },
          small_card: {
            header: " Meeting rooms",
            total: "TOTAL",
            assigned: "Assigned"
          },
          utilization_cards: {
            header: " Max utilization rate ",
            special_header: "Max utilization rate special types",
            tooltip: "The max utilization rate is the max ratio during that day of requested over available resources of the selected type",
            table: {
              headers: [
                { name: "All types", tag_name: "all_types" },
                { name: "Desks", tag_name: "desks" },
                { name: "Meeting rooms", tag_name: "rooms" },
                { name: "Meeting rooms - Large ", tag_name: "big_rooms" },
                { name: "Meeting rooms - External", tag_name: "external" },
                { name: "Released %", tag_name: "releases" },
              ],
              hover: ["Requested ", "Available "]
            },
            info: "Click on a number in the table to see the chart",
            zoom: ['zoom out', 'zoom in']
          },
          to_do_card: {
            header: " To-do’s",
            link: "Go to to-do list",
            table: [
              { name: "To be confirmed", tag_name: "to_be_confirmed" },
              { name: "To be assigned", tag_name: "to_be_assigned" },
              { name: "Untreated request", tag_name: "with_message" },
              { name: "Total", tag_name: "total" }
            ]
          },
          not_enough_card: {
            header: "Not enough resources",
            info_default: "Click on a number in the table to see the solutions.",
            info_selected: "Let’s see how we can solve this.",
            button_reservation: "Remind people about their reservations",
            button_release: "Request more releases"
          }
        },
        releases_table: {
          info: "You can ask people who have not released their resource for this day to release it.",
          headers: {
            user: { popup: 'owners', name: "Assigned to" },
            level: { popup: 'Level of user', name: "Level assigned user" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            asset_level: { popup: '', name: "Asset level" },
            seat: { popup: '', name: "Seat capacity" },
            amenities: { popup: '', name: "Resource’s amenities" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: "Send message to all selected releases?",
            select_owner_message: "At least one user must be selected.",
            error_message: "Error sending",
          }
        },
        reservations_table: {
          info: "You can remind people who have a reservation for this day to cancel their reservation if no longer needed.",
          headers: {
            user: { popup: 'Owners', name: "User" },
            level: { popup: 'Level', name: "Level of user" },
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            rc: { popup: 'Requested seats', name: "Requested seats" },
            ie: { popup: 'internal external', name: "internal/ external" },
            amenities: { popup: '', name: "Requested amenities" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            status: { popup: 'Statuses', name: "Status" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: "Send message to all selected reservations?",
            select_owner_message: "At least one user must be selected.",
            error_message: "Error sending",
          }
        }
      },
      newsfeed: {
        navigation: ['Newsfeed', 'Emergency message'],
        list: {
          main_header: 'Newsfeed',
          site_header: 'Select a site to check its newsfeed.',
          i_tooltipe: 'The newsfeed of a site is shown on the homepage for all sites selected by the user',
          card: {
            header: 'Settings',
            toggle: 'Show newsfeed of site',
            i_tooltipe: 'You can choose to show the newsfeed of another site',
            select: {
              toggle: 'Click here to select the site',
              placeholder: 'Select site'
            },
            auto_remove: {
              text: ['Automatically delete news items that are older than ', ' days. '],
              i_tooltipe: 'Choose how long news items are shown on the newsfeed before they get deleted. Events are removed from the newsfeed once ended.'
            }
          },
          add_item: ' Add new item',
          popups: {
            info: 'Please select a location!',
            confirm: 'Delete all existing newsitems and events of ',
            button: 'OK'
          }
        },
        form: {
          main_header: 'Emergency message ',
          site_header: 'Select a site for which you want to write an emergency message.',
          i_tooltipe: 'An emergency message, once turned on, is visible for all users of that site on the Tribeloo homepage and on the devices of that site',
          message: "Haven't message",
          placeholder: 'Emergency message line ',
          save: 'Save',
          emergency: ['Turn on emergency message', 'Turn off emergency message']
        }
      },
      site_config: {
        navigation: {
          site: "Site configuration",
          rules: "Site rules",
          table: "Resource configuration table",
          types: "Resource types",
          wayfinding: "Wayfinding"
        },
        edit_site: {
          header: "Editing site ",
          video_header: "How to add, edit and delete a site",
          buttons: {
            add: "Add new site",
            delete: "Delete site"
          },
          toggle: "Site is active & visible for users",
          toggle_warning: ["Site is currently ", "inactive & invisible"," for users"],
          base: {
            header: "Basic details",
            video_header: "How to set up a site: basic details",
            placeholder: {
              name: "site name",
              code: "plus code",
              address: "address of this site"
            },
            label: {
              code: "Define coordinates of site (plus code) ",
              address: "Address of site "
            },
            tooltip: {
              code: "Plus code can be found on Google maps and is used to show a map of the location on the wayfinding link",
              address: "Address is shown on the wayfinding link"
            },
            position: {
              header: "Position of wing",
              info: "Select a wing, by clicking on its title, to change its position.",
            },
          },
          amenities: {
            header: "Amenities",
            video_header: "How to set up a site: amenities",
            info: "Define amenities ",
            tooltip: "Amenities are additional features, like a beamer or a flipchart, which can be selected when looking for a resource. Select amenities in the list below, to activate them on the current site, so they can be assigned to a specific resource from this site.",
            counter: [" in total - ", " active"],
            placeholder: "new amenity"
          },
          manager: {
            header: "Site manager settings",
            video_header: "How to set up a site: site manager settings",
            info: "Add e-mail address office manager ",
            tooltip: "Office manager will receive emails when new issues are reported ",
            placeholder: "e-mail",
            toggle: ["Send email to-do's every ", "hours"],
          },
          device: {
            header: "Device settings",
            video_header: "How to set up a site: device settings",
            label: {
              time: "Use 24h time for devices ",
              password: "Device password (0-9 A-E) "
            },
            tooltip: {
              time: "Select time format for meeting room and desk devices: AM/PM or 24h format",
              password: "Password used to access configuration screen on devices"
            },
            placeholder: "code 0-9 A-E"
          },
          insights: {
            header: "Insight settings",
            video_header: "How to set up a site: insight settings",
            labels: {
              days: "Define working days ",
              hours: "Define working hours ",
              room: ["Define a large meeting room", "people or more"],
            },
            tooltip: {
              days: "Working days are used to calculate average utilization rate in insights",
              hours: "Working hours are used to calculate average utilization rate in insights"
            },
            range: ["from", "to"]
          },
          popups: {
            online: {
              text: 'Bring this site online?',
              descriptions: ['This will make the site visible for regular users and ready for reservations.']
            },
            offline: {
              text: 'Bring this site offline?',
              descriptions: ['This will make the site invisible for regular users. Existing reservations will be kept.']
            },
            delete_site: {
              text: 'Delete this site?',
              big_descriptions: ['This will delete all wings, resources and reservations for this site. \nUsers with this home site will be moved to having no home site.']
            },
            cant_delete_site: 'Site can not be deleted.',
            amenity: {
              exists: 'Amenity already exists.',
              cant_updated: 'Amenity cannot be updated. Used by resources on other sites.',
              cant_delete: 'Amenity cannot be deleted, because it is assigned to some resources.',
              cant_disabled: 'Amenity cannot be disabled, because it is assigned to some resources. Remove it first from all resources.'
            }
          },
          edit_amenity_dialog: "Edit amenity",
          edit_wings_sorted: {
            add_button: " Add Wing",
            position: "Position: ",
          },
          wing_position: {
            header: "Selected wing: ",
            position: "Position ",
            move: "Move to position "
          }
        },
        rules: {
          header:"Site rules",
          site_card: {
            header: "Site",
            sub_header: "Select a site for which you want to define user privileges and rules."
          },
          default_reservation_time_card: {
            header: "Default reservation time",
            sub_header_desk: "Desk",
            sub_header_room: "Meeting room",
            label_start: "Start date",
            label_end: "Duration"
          },
          auto_assign_card: {
            header: "Auto-assign resources ",
            video_header: 'How to auto-assign resources to reservations',
            sub_header: "Reservations can get a resource auto-assigned if the user requests one, if none is available it will appear in the to-do list of the office manager",
            toggle: "Activate auto-assign functionality for ‘to assign’ reservations",
          },
          check_in_reminder_email_card: {
            header: "Check-in reminder email ",
            video_header: 'How to use the check-in reminder emails & auto-cancel reservations',
            send_toggle: "Send out reminder email 15 minutes before the start of a reservation that requires check-in",
            add_button_toggle: ["Add ", "‘Cancel reservation’", " button to reminder email"],
            add_desck_toggle: ["For ", "desk", " reservations: add ", "‘Check-in’", " button to reminder email"],
            add_room_toggle: ["For ", "meeting room", " reservations: add ", "‘Check-in’", " button to reminder email"],
            auto_cancel_header: "Auto-cancel reservations that require check-in",
            cancel_desk_toggle: ["Cancel ", "desk", " reservation after", "min, if a check-in is required."],
            cancel_room_toggle: ["Cancel ", "meeting room", " reservation after", "min, if a check-in is required."],
          },
          reminder_email_card: {
            header: ["Reminder email", "for desks"],
            video_header: 'How to use the general reminder emails',
            send_toggle: ["Send reminder email ", "hours before the start of a reservation"],
            subject: {
              label: "Subject:",
              placeholder: "Reminder about your upcoming reservation"
            },
            message: {
              label: ["Message: (Markdown supported, for example to add links. ", "Check guidelines"],
              placeholder: "Add message"
            },
            agree_toggle: ["Add ", "‘I agree’", " button: by agreeing the person acknowledges they have read the message"],
            cancel_toggle: ["Add ", "‘Cancel reservation’", " button"],
            header_special: "Reminder email for meeting rooms",
            send_toggle_special: ["Send reminder email ", "hours before the start of a reservation"]
          },
          time_limit_card: {
            header: "Time limit for future reservations",
            video_header: 'How to use the time limit for future reservations',
            sub_header: "Reservations can only be made a certain time ahead by non-admin users",
            desck_toggle: ["Desk", " reservations can be created in the next"],
            room_toggle: ["Meeting room", " reservations can be created in the next"],
            special_toggle: "Reservations can be created in the next",
            postfix: "working days"
          },
          utilization_limit_card: {
            header: "Utilization limit",
            sub_header: ["Utilization of active ", " can be limited.", "Example: a site has 40 active ", ". When utilization is limited to 50%, only 20 concurrent ", " reservations can be made."],
            sub_header_types: ['desks', 'resources', 'desk'],
            toggle: ["Utilization is limited to", "percent"]
          },
          multi_day_reservation_card: {
            header: "Multi-day reservation",
            sub_header: ["Define if an employee is allowed to create one reservation for several consecutive days at once.", "When allowed, an employee will only receive the reminder emails once and only needs to check-in once at the start of his multi-day reservation, if this is required."],
            toggle: "Multi-day reservations are allowed"
          },
          cleaning_time_card: {
            header: "Cleaning",
            sub_header: "Cleaning time will be foreseen after the end of every reservation. During this period the resource cannot be booked.",
            desk_toggle: ["Time available to clean a ", "desk", "min"],
            room_toggle: ["Time available to clean a ", "meeting room", "min"]
          },
          user_message_card: {
            header: "User message when creating reservation ",
            toggle: 'Show this message when a user creates a reservation for a resource on this site',
            label: 'Message:',
            placeholder: 'Type your message here',
          },
          user_privileges_card: {
            header: "User privileges",
            video_header: 'How to set up user privileges',
            sub_header: "Define which users have access to which resources and have to get confirmation for a reservation",
            levels: {
              header: "1. Define the names of the different user levels and resource levels:",
              toggle: "User level names are the same across all sites",
              description_simple: {start: "User & resource level names", center: " can be set by site admin and are ", end: "specific for the site."},
              description_global: [
                {start: "User level names", center: " can only be set by global admin and are the ", end: "same for all sites."},
                {start: "Resource level names", center: " can be set by site admin and are ", end: "specific for the site."},
              ],
              user_label: "User level ",
              resource_label: "Resource level ",
              placeholder: "Text"
            },
            privileges: {
              header: "2. Define the privileges per user and resource level:",
              cols: "Resource level",
              rows: "User level"
            },
            rules: [
              {id: 0, line1: "Resource not available", line2: "(can be assigned by admin)", class: "red"},
              {id: 2, line1: "Resource only available ", line2: "after confirmation", class: "orange"},
              {id: 1, line1: "Resource available for", line2: "user level", class: "green"}
            ]
          },
        },
        edit_wing: {
          header: "Editing wing ",
          sub_header: "wing details",
          video_header: "How to add, edit and delete a wing",
          placeholder: "wing name",
          button: {
            back: "Back to site edit",
            add: "Add new wing",
            delete: "Delete wing"
          },
          popup: {
            text: 'Delete this wing?',
            big_descriptions:['This will delete all resources of this wing and could affect current reservations. \nCheck your to-do list if proceeding.']
          },
          assets_list: {
            header: "Resources",
            add_button: " Add resource",
            counter: " in total",
            popup: {
              text: 'Delete this resource?',
              big_descriptions:['This could affect current reservations. \nCheck your to-do list if proceeding.']
            }
          }
        },
        edit_asset: {
          buttons:{
            back: "Back to wing edit",
            add: "Add resource",
            clone: "Duplicate resource",
            delete: "Delete resource"
          },
          base: {
            header: "Basic details ",
            video_header: "How to add, edit and delete a resource & define the basic details",
            internal_external: [
              {name:"Internal only", value: true},
              {name:"External allowed", value: false}
            ],
            type_tooltipe: 'INTERNAL ROOM: used for a meeting with colleagues from within the company.\n EXTERNAL ROOM: used when also people of outside the company are invited (e.g. client).\n ZONE: contains multiple single desks that can be booked, without selecting a particular one.',
            user_tooltipe: 'Assigning a resource to a specific person, means that this person has a fixed desk and they alone can use it. They can still choose to release it for others to use, when they aren’t around.',
            zoning_tooltipe: 'Semicolon separated list of zoning names, useful for HVAC/BM integration.\n E.g.:\n lighting_7;heating_3',
            amenity_type: "other asset type",
            placeholders: {
              name: "asset name",
              user: "Select user",
              neighbourhood: "Select neighbourhood",
              amenities: "Select amenities"
            },
            labels: {
              status: "Resource status ",
              neighbourhood: "Neighbourhood ",
              user: "Assigned to ",
              level: "Resource level ",
              resource_zoning: "Resource zoning ",
              capacity: ['Seat capacity', 'Capacity'],
              type: "Resource type ",
              check_in: "Check-in required",
              amenities: "Amenities available ",
              image: "Add image (< 4MB)"
            }
          },
          size: {
            header: "Size, Shape & position ",
            video_header: "How to set up a resource: size, shape & position",
            size: {
              header: "Size & shape",
              sub_header: "Choose a shape",
              width: "Width",
              height: "Height",
              round: "Round corners"
            },
            position: {
              header: "Map position",
              horizontal: "Horizontal",
              vertical: "Vertical"
            }
          },
          calendar: {
            header: "Calendar & approval settings",
            video_header: "How to set up a resource: calendar & approval settings",
            resource: {
              label: "Resource email ",
              placeholder: "Resource email",
              tooltip:"Fill in an email to create a calendar for this resource. Tribeloo will keep the calendar up to date with reservations for this resource."
            },
            approvers: {
              label: "Approvers email ",
              placeholder: "Approvers email",
              tooltip:"Add the email address of the person who will confirm reservations for this resource. Multipe email addresses can be added separated by a semicolon ; and without any spaces before or after the semicolon"
            },
          },
          device: {
            header: "Device configuration",
            video_header: "How to set up a resource: device configuration",
            code: {
              label: "Device code ",
              placeholder: "Code"
            },
            id: {
              label: "Device ID ",
              placeholder: "ID"
            }
          },
          qr_nfc: {
            header: "QR & NFC configuration",
            video_header: "How to set up a resource: QR & NFC configuration",
            qr: {
              header: "Generate QR code",
              info: "Click on the button to generate a QR code of this resource.",
              button: "Generate QR"
            },
            nfc: "NFC configuration"
          },
          popups: {
            nfc_copy: "NFC url copied.",
            set_capacity: {
              text: 'Reduce seat capacity?',
              big_descriptions: ['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            select_owner: {
              text: 'Assign user to resource?',
              big_descriptions: ['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            internal_change: {
              text: "Change resource type to ‘Internal only’?",
              big_descriptions:["This could affect current reservations. \nCheck your to-do list if proceeding."]
            },
            remove: {
              text: 'Delete this resource?',
              big_descriptions:['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            check_card_id: {
              header: "",
              cases: [
                '1. Click "Start test" below',
                '2. On selected device, tap "Book now" or "Book later"',
                '3. Scan your card on the device',
                '4. The RFID code of the scanned card appears below',
                '5. To test another card, go to step 2'
              ],
              start: "Start test",
              stop: "Stop"
            }
          },
          assets_dragging_view: [" - click for edit", "drag & drop", "click to select"]
        },
        edit_info: {
          create: "Created on: ",
          update: "Last updated on: ",
          resource: "Resources: ",
        },
        type: {
          header: "Resource types",
          sub_header_descriprion: {
            first_line: "Standard sites have the type 'room or desk' automatically assigned.",
            second_line: ["If you want to create sites that contain another type of resource, define them here.", "These other resource types will also become visible on the Tribeloo homepage and in the tab 'More' in Outlook."]
          },
          video_header: 'How to define different resource types to book',
          card: {
            list: {
              header: "Existing resource types",
              button: "Add new resource type"
            },
            form: {
              header: ["Create resource type", "Edit resource type"],
              type: {
                label: "Resource type",
                placeholder: "Name of resource type"
              },
              icon: {
                label: "Icon",
                placeholder: "fa fa-archive",
                button: "Check icon list"
              },
              preview: {
                label: "Preview",
                text: ["Book a ", "Book a desk", "Book a meeting room"]
              },
              booking: {
                label: "Double booking by user",
                sub_label: "(desks only)"
              },
              button: {
                create: " Create type",
                delete: " Delete type",
                save: " Save changes",
              }
            }
          },
          popups: {
            unique_name: "You already have a type with the same name",
            create: 'Create type?',
            save: 'Save changes?',
            add: "New resource type is added",
            saved: "Changes saved",
            delete: 'Delete this resource type?',
            cant_delete: "This resource can't be deleted, because there are still sites of this type"
          },
        },
        assets_table: {
          buttons: {
            batch: "Batch edit",
            qr: ["QR PDF selected", "QR PDF all"]
          },
          headers: {
            number: {name: "Number", popup: ""},
            resource: {name: "Resource", popup: ""},
            wing: {name: "Wing", popup: "Wings"},
            user: {name: "Assigned to", popup: ""},
            level: {name: "Resource level", popup: "Resource level"},
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            capacity: {name: "Seat capacity", popup: "Seat capacity"},
            type: {name: "Resource type", popup: "Resource type"},
            check_in: {name: "Check-in required", popup: "Check-in required"},
            amenities: {name: "Amenities", popup: ""},
            device_id: {name: "Device ID", popup: ""},
            email: {name: "Resource email", popup: ""},
            status: {name: "Status", popup: "Status"},
            from: {name: "From", popup: ""},
            to: {name: "To", popup: ""}
          },
          popups: {
            batch: {
              header: "Edit resource details",
              sub_header: "Update",
              level: "Resource level",
              capacity: "Seat capacity",
              type: {
                label: "Resource type",
                values: [
                  { name: 'Internal only', value: true },
                  { name: 'External allowed', value: false }
                ],
              },
              check_in: {
                label: "Check-in required",
                values: [
                  { name: 'yes', value: true },
                  { name: 'no', value: false }
                ],
              },
              add_amenities: {
                label: "Add amenities",
                placeholder: "Select amenities"
              },
              remove_amenities: {
                label: "Remove amenities",
                placeholder: "Select amenities"
              },
              approvers: {
                label: "Set approvers",
                placeholder: "approvers email"
              },
              neighbourhood: {
                label: "Neighbourhoods",
                placeholder: "Select neighbourhood"
              },
              status: "Resource status"
            },
            selected: "At least one resource must be selected",
            selete_selected: ["Delete the ", " selected resources?"],
            this_delete: "Delete this resource?"
          }
        },
        wayfinding_link: {
          header: "Wayfinding link",
          privacy_card: {
            header: "Privacy",
            toggle: "Logging in is required to see information about assigned resource and floormap"
          },
          layout_card: {
            header: "Layout",
            sub_header_descriprion: {
              first_line: ["When using the Outlook add-in and Chrome extension, a wayfinding link is added to the event itself. ", "This shows invitees where they need to be and to easily find their way."],
              second_line: "You can add a company logo OR company name, and define the colors for the banner, the company name and the titles."
            },
            logo: {
              label: "Company logo",
              sub_label: "(PNG, less than 4MB)"
            },
            image_info: ["max width 210px and ", "max height 50px"],
            company_name: {
              label: "Company name",
              placeholder: "Max 20 characters"
            },
            name_color_label: "Color company name",
            baner_color_label: "Color banner",
            titels_color_label: "Color titles"
          }
        }
      },
      todo: {
        navigation: {
          list: 'Reservation to-do list',
          requests: 'Requests',
          reservation_table: 'Reservation table',
          release_table: 'Release table'
        },
        main: {
          all_button_tooltip: "Assign resources automatically to current to-do's",
          dialog_asset: "Asset must be selected first",
          details_resources_button: "View resource reservations",
          legend: {
            header: "View legend",
            items: [
              {name: "Available", type_class: "green"},
              {name: "Available, but to be confirmed", type_class: "orange"},
              {name: "Available, but rules constraint", type_class: "blue"},
              {name: "Not available", type_class: "red"}
            ]
          }
        },
        list: {
          update: "Last updated: ",
          toggle: "Show only untreated request",
          toggle_visitor: "Show only visitor reservations",
          default_name_subject: "New reservation ",
          user_prefix: "by ",
          visitor_prefix: "for ",
          tooltip: "Reservation within selected time range",
          default_name_asset: "Reservation pending"
        },
        details: {
          site_change: {
            header: "Change site",
            tooltip: "Remove assigned space first"
          },
          request: {
            header: "Request",
            treated: ["Request treated", "Request untreated"],
            buttons: {
              add: "Add note",
              contact: "Contact user"
            }
          },
          details: {
            header: ["Show details", "Hide details"],
            delete_button: "Cancel reservation",
            default_name_subject: "New reservation ",
            user_prefix: "By ",
            visitor_prefix: "For ",
            seats: {
              counter: ["Requested: seats ","Requested: "],
              type: ['internal meeting', 'external meeting'],
              amenities: "Requested amenities: "
            }
          },
          popups: {
            delete: 'Delete this reservation?',
            assigned: "Space is assigned to reservation and confirmed",
            selected: "Asset must been selected",
            available: "Asset is not available",
            message_error: "error sending"
          }
        },
        work_stack: "Click here to select the stack item",
        message_popup: {
          header: "Add note",
          sub_header: "Request",
          treated: ["Request treated", "Request untreated"]
        },
        release_table: {
          info: 'Changing time range removes all filters',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "Assigned to" },
            level: { popup: 'Level assigned user', name: "Level assigned user" },
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            amenities: { popup: '', name: "Resource’s amenities" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            subject: { popup: '', name: "Subject" },
            email: { popup: '', name: "Email" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: ["Send message to ", " shown releases for "],
            remove_release: "Delete this release?",
            remove_releases: ["Delete ", " shown releases for "],
            error_message: "Error sending",
          }
        },
        reservaton_table: {
          info: 'Changing time range removes all filters',
          change_site_buton: ' Change site for all',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'User', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            level: { popup: 'Level of user', name: "Level of user" },
            group: { popup: 'User group in ', name: "User group in " },
            email: { popup: '', name: "User email" },
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            seats: { popup: 'Requested seats', name: "Requested seats" },
            internal_external: { popup: 'Resource type', name: "Resource type" },
            amenities: { popup: '', name: "Requested amenities" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resources', name: "Resource" },
            request: { popup: 'Request', name: "Request" },
            status: { popup: 'Statuses', name: "Status" },
            acknowledged: { popup: 'Acknowledged', name: "Acknowledged" }
          },
          tooltip: {
            lock: "lock all",
            unlock: "unlock all",
            acknowledged: "From reminder email: if the person clicked on the 'I agree' button and therefore acknowledges they have read the message",
            confirm: "confirm all",
            delete: "delete all",
            send_mail: "send message to all"
          },
          dialogues: {
            chang_site: 'Change site',
            confirm: ['Confirm ', ' shown reservations from '],
            send_all_message: ["Send message to ", " shown reservations for "],
            remove_reservation: "Delete this reservations?",
            remove_reservations: ["Delete ", " shown reservations for "],
            error_message: "Error sending",
            lock: ["Lock ", " shown reservations from "],
            unlock: ["Are you sure you want to unlock ", " shown reservations from "]
          }
        },
        requests_table: {
          info: 'Changing time range removes all filters',
          add_comment_buton: 'Add comment',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "Owner" },
            visitor: { popup: '', name: "Visitor" },
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            request: { popup: 'Request', name: "Request" },
            comment: { popup: '', name: "Comment" },
            seats: { popup: '', name: "Request treated" },
            email: { popup: '', name: "Owner email" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: ["Send message to ", " shown reservations for "],
            error_message: "Error sending"
          }
        }
      },
      user_management: {
        navigation: {
          global_admin: "Global admins",
          admin: "Site admins",
          privileges: "User privileges",
          user: "User management",
          users_table: "User table",
          groups: "User groups",
          sso: "SSO",
          provisioning: "User provisioning"
        },
        global_admin: {
          header: "Global admin management",
          admins_card: {
            header: "Define the global admins",
            sub_header: "These users have access to all sites and features",
            video_header: 'How to define the site & global administrators',
            search: "Search",
            global_admin_list_header: "Site admins that are global admin",
            admin_list_header: "Site admins",
            admin_list_header_tooltip: 'You need to be site admin first, before you can be global admin'
          },
        },
        privileges: {
          header: "User rules",
          site_card: {
            header: "Site",
            sub_header: "Select a site to define it's user rules."
          },
        },
        admin: {
          header: "Site admin management",
          site_card: {
            header: "Site",
            sub_header: 'Select a site for which you want to add or remove administrators.'
          },
          admins_card: {
            header: "Add administrators for this site",
            video_header: 'How to define the site & global administrators',
            search: "Search",
            admin_list_header: "Users that are admin for this site",
            user_list_header: "Users",
            popup: {
              title: "This user has global admin rights and therefore access to all sites.",
              descriptions: ["If you want to give this user only access to certain sites, remove them from the global admin list first."]
            }
          },
        },
        user: {
          rfid_modal: {
            header: "Assign RFID to user",
            step1: {
              header: ["Step 1", " - Enter device ID & click ‘Start’"],
              placeholder: "Device ID",
              button: "Start ",
              info: ["No resource found ", "Check or re-enter ID"]
            },
            step2: ["Step 2", " - On connected device, tap ‘Book now’ or ‘Book later’"],
            step3: {
              header: ["Step 3", " - Scan card on the device, the RFID code will appear below"],
              placeholder: "Card RFID"
            },
            step4: {
              header: ["Step 4", " - Click ‘Assign RFID’, to assign it to the user"],
              button: "Assign RFID"
            }
          },
          header: "User management",
          free_version_sub_header: ["Currently using the ", "FREE VERSION with a limit of ", " users.", " Contact ", " to upgrade."],
          site_card: {
            header: "Location",
            sub_header: "Select a location to add or remove users for this site."
          },
          create_edit_card: {
            list: {
              header: "Existing users",
              button: "Add new user",
              placeholder: "search"
            },
            form: {
              header: ["Edit user", "Create user"],
              video_header: "How to create and edit a user",
              sub_header: "Select a user in the list to edit it's details.",
              name: {
                label: "Username",
                placeholder: "Name"
              },
              email: {
                label: "Email",
                placeholder: "Email"
              },
              level: {
                label: "User level ",
                placeholder: ""
              },
              group: {
                label: "User group",
                item: "Default"
              },
              site: {
                label: "Home site",
                item: "None"
              },
              resource: {
                label: "Resource",
                placeholder: ""
              },
              rfid: {
                label: "RFID",
                placeholder: "Enter RFID or click on icon on the right"
              },
              image: {
                label: "User image",
                sub_label: ["(square size, ", "less than 4MB)"]
              },
              button: {
                create: " Create user",
                reset_pin: "Reset PIN code",
                reset_password: "Reset user password",
                upload: "Upload image",
                delete: " Delete user",
                save: " Save changes"
              }
            },
            import: {
              header: "Batch upload users",
              cases: [
                "1. Download template",
                "2. Add users for this location to the template",
                "3. Upload the template file again to Tribeloo"
              ],
              button: {
                upload: "Upload file",
                download: "Download template"
              }
            },
            errors: {
              invalid_email: "Required field <email> is invalid.",
              missing_email: "Required field <email> is missing from request.",
              missing_name: "Required field <name> is missing from request.",
              missing_level: "Required field <level> is missing from request.",
            },
            popups: {
              save: 'Save changes?',
              create: 'Create user?',
              created: 'User created.',
              reset_pin: 'The reset was succesful! The user will receive an email with a new PIN code.',
              updated: 'User details have been updated.',
              delete: ' Delete this user?',
              removed: 'User removed.',
              reset_password: 'The reset was succesful! The user will receive an email with a new password.',
              send_pin: 'Send login PIN code for devices ?'
            }
          },
          pin_card: {
            header: "PIN code ",
            video_header: "When and how to use a PIN code",
            toggle: "Send PIN code by email to login on the meeting room and desk devices",
            info: "All new users and existing users that don’t have PIN code yet, will receive one."
          },
          global_settings_card: {
            header: "Global settings",
            info: "These settings are applicable for all sites"
          },
          image_card: {
            header: "Synchronise user images ",
            video_header: "How to synchronise user images of Microsoft 365 with Tribeloo",
            toggle: "Sync user images with Microsoft Office 365",
            info: "User image is synchronised once a user logs into the Tribeloo Outlook add-in"
          },
          subject_card: {
            header: "Hide reservation subject ",
            video_header: "",
            toggle: "Hide reservation subject for colleagues",
            info: 'Reservation subject shown in "who`s around" will be hidden'
          }
        },
        users_table: {
          batch_edit: {
            header: "Edit user details",
            sub_header: "Update",
            items: {
              privileges: "User group",
              level: "User level",
              site: "Home site",
              group: "User group",
              rfid: "Clear RFID for selected users"
            }
          },
          table: {
            toggle: 'Also show users from other sites',
            headers: {
              number: { popup: '', name: "Number" },
              site: { popup: 'Home Site', name: "Home site" },
              home_site_privilege: { popup: 'Home site group', name: "Home site group" },
              user: { popup: '', name: "Name of user" },
              user_privilege: { popup: 'User group in ', name: "User group in " },
              assigned_resource: { popup: 'Assigned resource in ', name: "Assigned resource in " },
              level: { popup: 'Level of user', name: "Level of user" },
              group: { popup: 'Group', name: "User group" },
              space: { popup: '', name: "Resource" },
              Owned_site: { popup: 'Owned_site', name: "Site of resource" },
              email: { popup: '', name: "User email" },
              rfid: { popup: 'RFID', name: "RFID" },
              override : {popup: 'Group assignment', name: "Group assignment"}
            },
            tooltip: {
              delete: "delete all selected users",
              send_mail: "send message to selected users"
            },
            dialogues: {
              error_message: "Error sending",
              email: 'Compose email for the selected users?',
              selected: "At least one user must be selected.",
              delete_all: ['Delete the ', ' selected users?'],
              delete: 'Delete this user?',
              rfid: "RFID cleared for selected users"
            }
          }
        },
        groups: {
          header: "Users groups",
          toggle_card: {
            header: "Enable user groups ",
            video_header: "How to set up user groups",
            toggle: "User groups are enabled",
            description: ["When ", "enabled", ", you can create user groups and define rules for visible sites or office days for each group.", "Assign a user to a group in user management or user table."]
          },
          creat_edit_card: {
            list: {
              header: "Existing users groups",
              button: "Add new user group",
              placeholder: "search",
              popups: {
                save: "Save changes?",
                create: "New user group is added.",
                update: "Changes saved.",
                delete: {
                  text: "Delete this user group?",
                  descriptions: ['Users from this group will be assigned to the default user group of their home site.']
                }
              }
            },
            form: {
              header: ["Create user group", "Edit user group"],
              select_button: "Select all",
              unselect_button: "Unselect all",
              create: " Create group",
              update: "Save changes",
              delete: " Delete group",
              name: {
                label: "Name ",
                placeholder: "Add name of user group"
              },
              rules: {
                toggle: ["‘Visible sites’ rule enabled", "‘Visible sites’ rule disabled"],
                tooltip: "When visible sites rule is enabled, a user has access to the active sites of his group, and is able to book a resource for these sites. When visible sites rule is disabled, this user group has access to all active sites.",
                placeholder: "Select visible sites",
              },
              days: {
                toggle: ["‘Office days’ rule enabled", "‘Office days’ rule disabled"],
                tooltip: "When office days rule is enabled, a user can make reservation only on the selected office days. When office days rule is disabled, this user group can make reservations on all days.",
                placeholder: "Select office days",
                week_days: ['lunes', 'martes', 'miérocles', 'jueves', 'viernes', 'sábado', 'domingo']
              }
            },
            sites_card: {
              header: "Default user group for each site",
              description_lines: [
                {start: "Users can be assigned to a specific group. In case a user doesn’t have a group assigned,", end: "the user will fall in the default group of his home site as defined below."},
                {start: "In case the user isn’t assigned to a specific group and no default group is defined for his", end: "home site, the user will only have access to his home site to book resources."}
              ],
              label: "No home site ",
              tooltipe: "This is the default group for users without a home site",
              placeholder: "group"
            }
          }
        },
        sso: {
          header: "Single Sign-On",
          description_card: {
            header: "What is it?",
            description: "Single Sign-On gives your users the ability to sign in to different applications using the same unique set of username and password. Users sign in once via the so-called Identity Provider (external to Tribeloo) and can then, from there access different apps (including Tribeloo) without logging in again. For SSO, Tribeloo supports the SAML 2.0 standard."
          },
          email_card: {
            header: "Enable welcome email",
            toggle: "Enable sending welcome email if SSO is enabled"
          },
          settings_card: {
            header: "Settings",
            items: {
              id: {
                name: "Company ID",
                tooltip: {
                  header: "",
                  descriptions: []
                }
              },
              issuer_url: {
                name: "Issuer URL",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: Identity Provider Issuer", "OneLogin: Issuer URL", "Azure: Azure AD Identifier", "Google: Entity ID"]
                }
              },
              login_url: {
                name: "SSO login URL",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: Identity Provider SSO URL", "OneLogin: SAML Endpoint", "Azure: Login URL", "Google: SSO URL"]
                }
              },
              certificate: {
                name: "Certificate",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: X.509 Certificate", "OneLogin: X.509 Certificate", "Azure: Certificate (Base64 or Raw)", "Google: Certificate 1"]
                }
              },
            },
            button: ["Disable SSO", "Enable SSO"]
          },
          popups: {
            saved: 'Configuration saved.',
            not_saved: 'Configuration not saved.',
            copied: 'Company ID copied.',
            change_sso: {
              text: ['Are you sure you want to disable Single Sign-On?', 'Are you sure you want to enable Single Sign-On?'],
              descriptions: ['All users will need to log in with a password, that they can request through ‘Forgot password?’ on the login page.', 'Users won’t be able to log in with their passwords and will need to log in with their SSO credentials.']
            }
          }
        },
        provisioning: {
          header: "User provisioning",
          description_card: {
            header: "What is it?",
            description: "User Provisioning refers to the automatic synchronization of users: people defined in your Active Directory (AD) will be automatically synchronized with the Users list in Tribeloo. E.g. new people in the AD will be added to the Users list in Tribeloo."
          },
          settings_card: {
            header: "Settings",
            items: [
              {name: "SCIM Base URL", key: "base_url", is_input: true},
              {name: "SCIM JSON Template", key: "json_template", is_input: false},
              {name: "Custom headers", key: "custom_headers", is_input: true},
              {name: "SCIM Bearer token", key: "bearer_token", is_input: false}
            ],
            button: ["Disable User provisioning", "Enable User provisioning"],
            popups: {
              copy: " copied",
              change_SCIM: {
                text: ['Are you sure you want to disable User provisioning?', 'Are you sure you want to enable User provisioning?'],
                description: ['All the current users will be kept, but won’t be updated by your external user provisioning system anymore.', 'All users will be managed externally.']
              }
            }
          }
        }
      },
      insights: {
        navigation: {
          heatmaps: "Heatmaps",
          utilization: "Utilization charts",
          history: "Reservation history",
          download: "Data download",
          statistics: "Statistics",
          tracker: "Time tracking",
          calc: "Calc resource"
        },
        calc: {
          type: "Calc helpe",
          subHeader: "This page help you with a data about your price and amount of resources from 1 month to 5 years. Also it help you: if you want to change a count of recources, or move to new place, add or remove new site, wing and etc.",
          chartHeader: "Terms & summ",
          chartDescription: "Use top form to know how much you are spending for your resources"
        },
        heatmaps: {
          type: "Heatmaps",
          subHeader: "Which days and hours are most busy?",
          allText: "Average utilization rate of selected type",
          daysText: "Daily utilization rate of selected type",
          options: {
            average: "Show average utilization rate",
            daily: "Show daily utilization rate"
          },
          tooltipe: 'The average utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period. The daily utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period. For example, daily utilization of 80% and average utilization of 40% means that: 8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours. '
        },
        utilization_charts: {
          type: "Utilization charts",
          subHeader: "Which days and hours are most busy?",
          allText: "Average utilization rate of selected type",
          daysText: "Daily utilization rate of selected type",
          table_header: "Which days are most busy? Select a day to see the utilization by hour below for that day.",
          chart_header: "Which hours are most busy"
        },
        reservation_history: {
          info: 'Changing time range removes all filters',
          overlap: 'Find overlapping reservations with ',
          couter: " more...",
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            email: { popup: '', name: "User email" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resources', name: "Resource" },
            no_shows: { popup: 'No-shows', name: "No-shows" },
            subject: { popup: '', name: "Subject" },
            seats: { popup: 'Requested seats', name: "Requested seats" },
            internal_external: { popup: 'internal/external', name: "internal/ external" },
            status: { popup: 'Statuses', name: "Status" },
            messages: { popup: 'Messages', name: "Messages" },
            acknowledged: { popup: 'Acknowledged', name: "Acknowledged" }
          },
          tooltip: {
            acknowledged: "From reminder email: if the person clicked on the 'I agree' button and therefore acknowledges they have read the message",
            send_mail: "send message to all"
          },
          dialogues: {
            error_message: "Error sending"
          }
        },
        time_tracking: {
          check: "events",
          info: 'Changing time range removes all filters',
          info_date: 'Find overlapping reservations with ',
          headers: {
            user: { popup: 'User', name: "User" },
            email: { popup: 'User email', name: "Email" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            zone: { popup: '', name: "Time zone" },
            period: { popup: '', name: "Time period" },
          },
          dialogues: {
            error_message: "Error sending"
          }
        },
        data_download: {
          header: "Data download",
          popup: {
            big_titel: 'Update data retention?',
            big_descriptions: ['Older reservation data will be deleted and no longer accessible'
                              ]},
          retention_card: {
            header: "Data retention",
            toggle: ["Reservations & releases data is automatically deleted after", "days."],
            info: "There is a minimum of 7 days and a maximum of 400 days."
          },
          automade: {
            header: "Daily automated report via email",
            sub_header: "Receive data of the reservations & releases of the previous day",
            check: "Send daily email to",
            placeholder: "Email address",
            error: "Please enter a valid email address"
          },
          download: {
            header: "Download data for a specific time range",
            info: "Data of reservations & releases",
            button: {
              all: " Download all",
              one: " Download data range"
            }
          }
        },
        statistics: {
          header: "Statistics",
          sub_header: "The table shows you a current view of all the sites and their amount of resources and users.",
          card: {
            header: "Resources and users",
            button: "Download",
            table_headers: {
              type: "Resource type",
              resources: "Resources",
              users: "Users"
            }
          }
        },
        reusable_components: {
          main_header: {
            header: "Insights",
            day: " Move timeframe per day ",
            week: " Move timeframe per week "
          },
          scale_utilization_rate: "Scale utilization rate",
          card_table: {
            table_headers: {
              special: "utilization rate",
              average: "Average",
              daily: "Daily"
            },
            description_hightlight: [
              {start:"The ", hightlight: "average", end: " utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period."},
              {start:"The ", hightlight: "daily", end: " utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period."}
            ],
            description_example: ["  For example, daily utilization of 80% and average utilization of 40% means that: ", "8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours."]
          },
          maps_cards: {
            button: {
              select: "Select",
              show_all: "Show all"
            },
            internal_external: [', Internal only', ', External allowed'],
            owner: "Owner: ",
            usage: "Usage",
            average: "average: ",
            daily: "daily: ",
            days: "days active: ",
          }
        }
      },
      space_issues: {
        navigation: ['Issue management','Cleaning', 'Issues table'],
        list: {
          main_header: 'Select a site',
          main_sub_header: 'Find resource',
          placeholder: "Please search or select a site",
          placeholder_asset: "Please search or select a resource",
          search: 'View all site issues',
          toggle: ['Click to show closed issues','Click to show open issues'],
          couter: [' open resource issues', ' closed resource issues'],
          img_link: 'See photo',
          popup_text: 'E-mail sent to your account with information about the issue. This can be forwarded to somebody that can solve it.',
        },
        table_cleaning: {
          main_header_warning: 'Changing time range removes all filters',
          table_headers: {
            id:'ID',
            data_time: 'Date & time',
            wing: 'Wing',
            resource: 'Resource',
            cleaned_at: 'Cleaned at',
            cleaned_by: 'Cleaned by'
          }
        }
      }
    },
    user: {
      external_events_list: {
        headers: [ "Eventos de Google ", "Eventos de Outlook" ],
        select: {
          header: "Organizado por ",
          placeholder: "Seleccione colega/ INVITE A PERSONAS "
        },
        info: [
          "No hay reservas disponibles desde su calendario",
          "Mostrar solo eventos con reservas de Tribeloo",
          "Seleccione evento "
        ]
      },
      find_colleague: {
        email_select: {
          more: "más..."
        },
        user_select: {
          header: "Encuentre a colega",
          placeholder: "Seleccione colega ",
          info: [ "Solicite acceso a sus colegas en ", "Mi Tribu", " para ver sus reservas" ]
        },
        list: {
          count: " (no privada)",
          info: [ "Seleccione a colega para ver sus reservass", "Haga clic en la reserva para ver en el mapa." ]
        },
        details: {
          return_button: "Vuelva al listado de Reservas ",
          info: "Seleccione una RESERVA (activo) disponible en el mapa para crear una reserva",
          modals: {
            delete_reservation: "Reserva eliminada ",
            create_reservation: "Crear una reserva "
          }
        }
      },
      find_room_report: {
        headers: {
          main: "Repote un problema por un recurso ",
          site: "Selección un sitio ",
          resource: "Encuentre recursos"
        },
        placeholders: {
          site: "Por favor busque o seleccione el lugar ",
          resource: "Por favor busque o seleccione el recurso ",
          tooltip: "Al informar un problema, se enviará al administrador del sitio para que lo resuelva. Una vez resuelto, se eliminará de esta lista.",
          description: [ "Seleccione un recurso para reportar un problema ", "Informar un problema de recursos" ]
        },
        loading: "Cargando",
        image_description: "Imagen ",
        button: {
          photo: "Adjuntar foto",
          send: "Enviar problema "
        },
        image_info: "Imagen (< 4MB)",
        couter: " problemas de recursos abiertos "
      },
      releases_list: {
        add_button: "Agregar fuera de oficina ",
        user_select: "Fuera de oficina para ",
        colleagues_info: "no tienes compañeros con comunicados visibles",
        counter: "Sin lanzamientos ",
        right_panel_info: [
          "Seleccione un colega del menú desplegable para ver sus lanzamientos.",
          "\nNo tienes compañeros con liberación visible."
        ]
      },
      release: {
        pageName: "liberado",
        header: "Detalles de fuera de oficina",
        placeholder: "Tema",
        buttons: {
          create: [ "Liberar tu ", "Confirmar" ],
          edit: [ "¿Cancelar este lanzamiento?", "Cancelar" ]
        },
        description: [ " Existen ", "no ", "reserva (s) activa (s) para este ", " durante este intervalo de tiempo." ],
        modals: {
          user: "¿Cambiar usuario? Se eliminará la versión actual.",
          not_available: "Lanzamiento no disponible",
          delete: "¿Eliminar esta versión?"
        }
      },
      reservations_list: {
        add_button: "Agregar nueva reserva",
        user_select: "Reservas para",
        tabs: [
          {name:"Personal", state: false},
          {name:"Visitante", state: true}
        ],
        colleagues_info: "No tienes compañeros con reservas visibles",
        counter: "No hay reservas",
        right_panel_info: [
          "Seleccione un colega del menú desplegable para ver sus reservas.",
          "Edite una reserva o cree una nueva para ver los recursos disponibles"
        ]
      },
      reservation: {
        pageName: "reservas",
        header_requests: [ "Aún no ha reservado.", "Complete los detalles y verifique la disponibilidad." ],
        details: " detalles",
        end: "Finalizar reserva",
        amenities_item: "otra solicitud",
        snack_bars: {
          create: "Reserva creada",
          update: "Reserva actualizada"
        },
        visitor: {
          text: "Reserva per visitante"
        },
        placeholders: {
          visitor: "Indirizzo mail",
          amenities: "Seleccionar servicios",
          no_amenities: "No se encontraron artículos",
          subject: "Tema",
          neighbourhood: "Seleccionar barrio"
        },
        public: {
          text: "Hacer privado para todos",
          tooltip: "Su reserva siempre está visible para los miembros de su colegas. Activar el interruptor lo hará invisible para todos, incluida sus colegas."
        },
        right_panel_info: {
          no_checked_availability: [ "Completar los detalles y", "comprobar disponibilidad", " para reservar un recurso" ],
          checked_availability: [ "Los detalles de la solicitud cambiaron,", "comprobar disponibilidad", " de nuevo" ]
        },
        modals: {
          amenity: {
            text: "¡Su reserva actual no se ajusta a todas las comodidades!",
            descriptions: ["Pero no te preocupes, puedes continuar con la reserva actual."]
          },
          error_404: "Reserva no disponible",
          requested_capacity: {
            text: "¡Tu espacio asignado no tiene suficientes asientos!",
            descriptions: [ "Continúe, para mantener la nueva cantidad de asientos y busque un nuevo espacio.", "O cancele el cambio de asiento y conserve su espacio actual." ]
          },
          internal: {
            text: "¡Su espacio asignado es solo para reuniones internas!",
            descriptions: [ "Continúa, para quedarte con el cambio y busca un nuevo espacio.", "O cancele el cambio y conserve su espacio actual." ]
          },
          time: {
            text: "¡Tu reserva no está disponible para este momento!",
            descriptions: [ "Continúe, para mantener la nueva hora y se actualizará su reserva.", "O cancele el cambio de hora y mantenga su reserva actual." ]
          },
          space: {
            text: "¡Su espacio asignado es solo para reuniones internas!",
            descriptions: [ "Continúa, para quedarte con el cambio y busca un nuevo espacio.", "O cancele el cambio y conserve su espacio actual." ]
          },
          invalid_email: "Debe ingresar un correo electrónico de visitante válido o desactivar el conmutador de visitante para crear una reserva.",
          delete: {
            simple: { text: "¿Eliminar esta reserva?" },
            ongoing: { text: "¿Terminar esta reserva?" },
            visitor_change: {
              text:{
                toggle: '¡Cambiar entre la reserva personal y la de visitante eliminará la reserva actual!',
                email: '¡Cambiar la dirección de correo electrónico del visitante eliminará su reserva actual!',
              },
              descriptions: [
                "Continúe, para mantener los cambios y su reserva se actualizará.",
                "O cancela el cambio y conserva tu reserva actual."
              ]
            },
            user_change: {
              text: "¡Cambiar de usuario eliminará su reserva actual!",
              descriptions: [ "Continúe, para mantener el nuevo usuario y se actualizará su reserva.", "O cancele el cambio y mantenga su reserva actual." ]
            },
            site_change: {
              text: "Si cambia de sitio, se eliminará su reserva actual.",
              descriptions: [ "Continúe, para mantener el nuevo sitio y su reserva se actualizará.", "O cancele el cambio y mantenga su reserva actual." ]
            }
          }
        }
      },
      home: {
        nwesfeed_list: "¡No hay noticias hoy!",
        pageName: "Inicio",
        emergency: {
          header: "MENSAJE DE EMERGENCIA",
          by: "por"
        },
        reservation: {
          headers: {
            upcoming: "Tu próxima reserva",
            current: "Tu reserva actual",
            no_upcoming: "No hay próximas reservas"
          },
          descriptions: [ "¡Estoy aquí para ayudarte!", "Haga su elección con los botones de abajo." ],
          loading: "Cargando ..."
        },
        newsfeed_header: "Noticias"
      },
      settings: {
        pageName: 'configuración',
        h1: 'Configuración de usuario',
        password: {
          errors: ["Al menos 1 número, 1 símbolo y longitud de 8 caracteres", 'Las contraseñas no son iguales'],
          rules: 'La contraseña debe contener al menos: 8 caracteres, 1 mayúscula, 1 minúscula, 1 número',
          rules_v2: 'Debe contener al menos: 8 caracteres, 1 mayúscula, 1 minúscula, 1 número',
          placeholders: ['Ingrese nueva contraseña', 'Vuelva a ingresar la nueva contraseña'],
          info_ok:"Contraseña cambiada con éxito",
          info_not_ok: "La contraseña no cambió"
        },
        h3_sections: {
          password: 'Cambiar contraseña',
          image: 'Agregar imagen de usuario',
          site: 'Sitio predeterminado para reservas',
          wing: 'Ala predeterminada para reservas',
          time: 'Formato de hora',
          language: 'Idioma',
          preferences: 'Preferencias',
          preferences_v2: 'Preferencias de usuario',
          delegate: 'Delegado',
          calendar: 'Invitaciones de calendario',
          wants_asset_details_popup: 'Detalles de mapas'
        },
        subsections: {
          app: "Versión app",
          sites: "Sitios visibles",
          language: "Idioma",
          time: "Formato de hora",
          calendar: "Invitaciones de calendario"
        },
        user_image_info: ['Por favor, contacta a tu', 'gerente de oficina para cambiar la imagen de usuario'],
        user_image: ['Imagen de usuario', '(tamaño cuadrado, menos de 4MB)', 'Sube una imagen de tamaño cuadrado que sea inferior a 4MB'],
        toggle: 'Usar formato de hora de 24 horas',
        app_version: "Usar nueva versión (reinicio necesario)",
        calendar_toggle: 'Enviar invitaciones de calendario para reservas realizadas en la aplicación web',
        wants_asset_details_popup_toggle: 'Mostrar ventana emergente de detalles al seleccionar un recurso en el mapa',
        no_delegates: 'Sin delegados',
        delegates_action_list: {
          search: 'Buscar',
          edit: 'Colegas que pueden crear o editar mis reservas',
          add: 'Agregar colegas'
        },
        delegates_modal: "Al marcar esta casilla, la tabla a continuación se descartará y todos podrán ver tus reuniones públicas."
      },
      who_is_arround: {
        header: "Quien esta alrededor",
        tooltip: "Compruebe quién  está en la oficina en un momento determinado. ¿Aún no tienes una Sistema Tribu? Crea uno en \"Mi Tribu\".",
        date_time: {
          main: "Fecha y hora",
          helper: "HORA"
        },
        info: "Seleccione un recurso disponible en el mapa para crear una reserva para usted",
        users_list: {
          header: {
            hide: "Esconder",
            show: "Mostrar",
            postfix: " lista de colegas"
          },
          placeholder: "Nombre de usuario"
        },
        right_panel_info: "Elija una ubicación para ver la ocupación",
        modal: "¿Crear reserva?"
      },
      my_tribe: {
        header: "Mi Tribu",
        tabs: [ "Seguidores", "Siguiendo" ],
        info: [ "Compañeros que pueden ver sus reservas no privadas", "Solicitudes para seguirte", "Compañeros cuyas reservas no privadas puede ver" ],
        serach_header: "Resultados de la búsqueda",
        no_results: "No hay resultados",
        placeholders: [ "Buscar", "Busca compañeros a los que seguir" ],
        modals: {
          confirm: {
            text: "¿Quiere seguir también a este colega?",
            cancel: "No sigas",
            confirm: "Seguir"
          },
          delete: {
            text: "¿Quieres eliminar la solicitud de este colega?",
            cancel: "Cancelar",
            confirm: "Borrar petición"
          },
          remove: {
            text: "¿Quieres eliminar a este colega?",
            cancel: "Cancelar",
            confirm: "Eliminar"
          },
          cancel: {
            text: "¿Quieres cancelar tu solicitud?",
            cancel: "No",
            confirm: "Cancelar"
          },
          unfollow: {
            text: "¿Quieres dejar de seguir a este colega?",
            description: ["Si cambia de opinión, tendrá que solicitar seguir de nuevo."],
            cancel: "Cancelar",
            confirm: "Dejar de seguir"
          }
        }
      },
      squads: {
        header: 'Mis equipos',
      },
      qr: {
        header: "Registro QR o sin cita previa",
        checkout: "¿Quieres comprobarlo?",
        checkedout: "Controlado",
        photo_description: "Escanee el código QR para registrarse en su reserva actual o para crear una reserva sin cita previa",
        start_scan_description: "Apunta al código QR y toca aquí",
        errors: {
          camera: "La cámara no es compatible con Chrome, utilice Safari",
          app: "¡Intente utilizar una aplicación especial de escáner qr!",
          device: "¡Utilice el dispositivo móvil o tableta con cámara trasera!"
        },
        modals: {
          info: "Se necesita acceso a la cámara para escanear el código QR.",
          check_in: {
            header: "Registrado para:",
            from_to: [ "De:", "Para" ],
            subject_default: "Reserva"
          }
        }
      },
      help_center: {
        nav: {
          demo: {
            header: "Demostración rápida",
            card: {
              headers: [],
              descriptions: ["Vea esta demostración rápida para obtener una buena descripción general de todas las funciones de Tribeloo."],
              links: []
            }
          },
          get_started: {
            header: "Guía de inicio",
            sub_headers: [ "Video de configuración", "Inicio de sesión único", "Aprovisionamiento de usuarios" ],
            card: {
              headers:[],
              descriptions: [ "Aprenda a comenzar con Tribeloo y realice la configuración inicial de la plataforma.", "Consulte las pautas sobre cómo instalar la función de inicio de sesión único", "Consulte las pautas sobre cómo instalar la función de aprovisionamiento de usuarios" ],
              links: ["aquí."]
            }
          },
          site_manager: {
            header: "Administrador del sitio",
            sub_headers: [ "Video de ejecución", "Configurar dispositivos" ],
            card: {
              headers: ["Configurar dispositivos"],
              descriptions: [ "Aprenda a utilizar Tribeloo como director de oficina.", "Aprenda a configurar el escritorio y los dispositivos de la sala de reuniones." ],
              links:[]
            }
          },
          user: {
            header: "Usuario",
            sub_headers: [ "Video de ejecución", "Usar el complemento de Outlook", "Instalar el complemento de Outlook", "Usando la extensión de Chrome", "Instalar la extensión de Chrome", "Instalar la aplicación en iPhone", "Instalar la aplicación en Android" ],
            card: {
              mobile: "Aprenda a utilizar la aplicación móvil Tribeloo y la solución de reserva web.",
              outlook: {
                headers: [ "Cómo utilizar el complemento de Outlook", "Cómo instalar el complemento de Outlook" ],
                descriptions: [ "Aprenda a usar el complemento Tribeloo para Outlook.", "Aprenda a instalar el complemento Tribeloo para Outlook.", "Ve a la", " Tienda Microsoft", " para descargarlo." ]
              },
              google: {
                headers: [ "Cómo usar la extensión de Chrome", "Cómo instalar la extensión de Chrome" ],
                descriptions: [ "Aprenda a usar la extensión Tribeloo para Chrome y la integración con su Calendario de Google", "Aprenda a instalar la extensión Tribeloo para Chrome, integrada con su calendario de Google.", "Ve a la", "Tienda Chrome", " para descargarlo." ]
              },
              pwa: {
                headers: [],
                descriptions: [ "Mira el video a continuación o", " descargar el PDF", " sobre cómo instalar la aplicación Tribeloo en tu iPhone", " sobre cómo instalar la aplicación Tribeloo en tu Android" ]
              }
            }
          },
          feedback: {
            sub_headers: [],
            header: "Contáctenos",
            card: {
              headers: ["¿No encuentras lo que buscas? ¿O quieres darnos tu opinión?"],
              descriptions: ["¡Dejanos ayudarte! Comparta todos los detalles que pueda para que podamos ayudarlo más rápido."],
              links:[]
            }
          }
        },
        main: {
          header: "Centro de ayuda",
          subheaders: [ "¡Bienvenido!", "Encuentre información útil sobre cómo empezar a utilizar Tribeloo.", "Siempre puede volver aquí haciendo clic en \"Centro de ayuda\" en la página de inicio o en la configuración." ],
          orange_card: [ "Clickea en el", " iconos en el software para obtener más información sobre un tema específico." ]
        }
      }
    }
  },
  shared_components: {
    wings_select: "Todas las alas",
    activity_select: {},
    assets_map: {
      types_external_internal: [ " Solo interno", ",Externo permitido" ],
      owner: "Asignado a:",
      time_slots: "Disponible: ",
      no_time_slots: "No hay franjas horarias disponibles",
      not_reservable: "No puedes reservar este recurso"
    },
    attendees_modal: {
      header_text: {
        share: "Comparta esto con sus colegas",
        attendees: "Están asistiendo a este evento"
      },
      info: [ "Seleccione colegas del menú desplegable para enviarles un correo electrónico.", "Primero, pida a sus colegas que lo agreguen a su tribu." ],
      placeholdrs: {
        colleagues: "Seleccionar colega",
        message: "Escribe tu texto aquí"
      },
      button_attendees: [ "¿No vas?", "¿Vas a ir?" ],
      more: " más...",
      send: "Enviar",
      send_all: "Enviar correo a todos",
      counter: "asistentes"
    },
    total_counter_download_table_csv: {
      batch_buton: 'Batch edit',
      button: "Descargar",
      text: " en total",
      selected: "Seleccionado",
      info:"No data for selected parameters"
    },
    confirm_dialog: {},
    date_time_range_modal: {
      headers: {
        main: "Establecer fecha y hora",
        from: "De",
        to: "Para"
      },
      tooltip: "Haga clic para mostrar el calendario",
      info: "El tiempo 'hasta' debe venir después del tiempo 'Desde'"
    },
    date_time_range_view: {
      headers: {
        from: "De",
        to: "para",
        start: "Empieza",
        end: "Termina"
      }
    },
    day_time_mini: {
      header: ["Set ", " date & time"],
      tooltip: {
        calendar: "Click for display calendar",
        time: "Click for display time"
      }
    },
    download_xlsx: {},
    expanded_filter_assets_list: {
      modal: {
        header: "Informacion de recursos",
        report_button:  "Reportar un problema"
      },
      header: " lista de disponibilidad",
      description: "Mostrar solo con todas las comodidades",
      nospace: "Nada disponible",
      types_external_internal: [ "interno", "externo" ],
      placeholder: "Buscar",
      tooltip: "El espacio no contiene todas las comodidades solicitadas"
    },
    filter_dialog: {},
    icon_video: "More info",
    info_dialog: {},
    kiosk_logout_info_modal: {
      message: "Are you still working here?",
      info: ["Your session will end in ", " seconds. "]
    },
    newsfeed_create_edit_item: {
      popup: "Discard unsaved changes?",
      markdown: "Styling with Markdown is supported",
      link: "Check guidelines",
      image_info: "Image (< 4MB, size 335 x 75 px)",
      radio: ["Type:", "News", "Event"],
      placeholder: {
        event: "New event",
        location: "Location",
      }
    },
    newsfeed_item: {
      default_subject: "",
      actions: [ "más", "cerrar" ]
    },
    loading_table_spinner: 'fetching data',
    reservation_header_info: {
      state: ['Reservation pending', 'Assigned'],
      info: "Check availability to assign one",
      internal_external: [ ', internal', ', external']
    },
    reservation_tipe_modal: "No hay opciones para agregar, edite la reserva existente",
    site_dialog: {},
    site_select: {
      placeholder: "Seleccionar sitio",
      tooltip: "Haga clic aquí para seleccionar el sitio",
      options: [ "Todos los sitios", "Sin sitio de inicio" ]
    },
    site_wings_reservation_view_external_data_set: "Haga clic para ir a esta ala",
    table_datetime_range_helper: {},
    time_range_helper: {}
  },
  user_shared_components: {
    capcity_internal_block: {
      placeholder: "asientos" /* tooltip: 'External meeting: with external visitors &#13;Internal meeting: only with internal employees',*/,
      tooltip: "Reunión externa: con visitantes externos. Reunión interna: solo con empleados internos",
      select: [ "Espacio único", "Reunión interna", "Reunión externa", "Sala" ]
    },
    list_item: {
      internal_external: [ ", interno", ", externo" ],
      info_reservation: [ "Reserva pendiente, pronto se te asignará una.", "O verifique la disponibilidad para elegir uno usted mismo." ],
      subject: [ "Nuevo fuera de oficina", "Nueva reserva" ],
      status: "liberado",
      header: "Tu "
    },
    message_details: {
      toggle: "Añadir solicitud",
      info: [ "Solicitud sin tratar", "Solicitud tratada" ],
      placeholder: "Deje una solicitud para el gerente de la oficina si tiene solicitudes adicionales"
    },
    weeks_repeater: {
      title: " Repetir hasta",
      weekdaysShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb']
    },
    repeater_modal: {
      header: "Personalizar la reincidencia",
      repeat_until: "Repetir hasta",
      repeat_on: "Repetir en"
    }
  },
  reusable_data: {
    time_tracking_popup: {
      big_titel: "You worked for ",
      descriptions: ["Do you want to end your workday?"]
    },
    tables: {
      from: "De",
      to: "Para",
      date_time: "Fecha y hora"
    },
    feedback: {
      header: "Dar opinion",
      placeholders: [ "Seleccione el tipo de comentarios", "Agregar una descripción" ],
      types: [ "insecto", "idea", "pregunta", "otro" ],
      buttons: [ "Cancelar", " Enviar" ],
      issue_text: "Hubo un problema al enviar sus comentarios. Por favor, inténtelo de nuevo más tarde. ¡Gracias!"
    },
    user_navigation: {
      no_actions_nav: {
        home: "Inicio",
        my_tribe: "Mi tribu",
        squads: 'Mis equipos',
        outlook_events: "Eventos de Outlook",
        google_events: "Eventos de Google",
        qr: "Registro QR o sin cita previa",
        tracker: [ "Play workday, ", "End workday, " ],
        reservations: "Gestionar reservas",
        releases: "Editar fuera de oficina",
        who_is_arround: "Quien esta alrededor",
        find_colleague: "Encuentra un colega",
        report_issue: "Reportar un problema",
        user_settings: "Ajustes de usuario",
        help_center: "Centro de ayuda"
      },
      actions_nav: {
        book_desk: "Reservar un escritorio",
        book_meeting_room: "Reservar sala de reuniones",
        add_new_release: "Agregar fuera de oficina"
      }
    },
    button: {
      ok: "OK",
      add: "Agregar",
      show: "Mostrar",
      hide: "Esconder",
      send: "Enviar",
      save: "Ahorrar",
      edit: "Editar",
      more: "Más",
      close: "Cerrar",
      email: "Correo electrónico",
      login: 'Iniciar sesion',
      logout: 'Cerrar sesión.',
      check_in: 'Registrarse',
      submit: "Enviar",
      delete: "Borrar",
      select: "Seleccione",
      cancel: "Cancelar",
      upload: "Subir",
      remove: "Eliminar",
      reopen: "Reabrir",
      comment: "Comentario",
      confirm: "Confirmar",
      inverse: 'Inverse',
      refresh: "Actualizar",
      continue: "Continuar",
      download: "Descargar",
      show_all: "Mostrar todo",
      checked_in: "Registrado",
      select_all: 'Select all',
      batch_edit: "Edición por lotes",
      upload_image: "Cargar imagen",
      remove_image: 'Quita la imagen',
      assign_me_one: "Asignarme uno",
      back_to_dashboard: '< Back to Dashboard',
      cancel_reservation: "Cancelar",
      check_availability: "Disponibilidad",
      retutn_releases_list: "Regresar a la lista de fuera de oficina",
      retutn_reservations_list: "Regresar al listado de reservas",
      retutn_reservations_releases_events_list: [ "Volver a", " eventos" ]
    },
    release_reservation_text: {
      outlook: "Cambiar la hora en el calendario de Outlook",
      google: "Cambiar la hora en el calendario de Google"
    }
  },
  v2_shared_components: {
    calc: {
      sum: "Total summ: ",
      count: "count: ",
      price: "price: "
    },
    tribeloo_asset_info: {
      user_info: "Asignado a ti",
        user_release_info: "Este recurso te ha sido asignado. Resérvalo si deseas usarlo.",
        user_booked_info: "Este recurso te ha sido asignado y está disponible en cualquier momento para que lo uses.",
        invited_users: " colegas invitados y asunto",
        time_slot: "No disponible hoy",
        buttons: {
        invite: 'Invitar a colegas',
          book: 'Confirmar reserva',
          update: 'Confirmar cambios',
          delete: 'Cancelar reserva',
          edit: 'Editar reserva'
      }
    },
    tribeloo_asset_list: {
      headers: {
        menu: "Herramientas de Tribeloo",
          favorites: "Favoritos"
      },
      filter: {
        search: "Buscar",
        all: "Todos",
        users_only: "Solo usuarios",
        resources_only: "Solo recursos"
      },
      menu_items: {
        colleagues: {
          subtitle: "Buscar a un colega",
            description: "Colabora fácilmente con tus colegas"
        },
        bookings: {
          subtitle: "Mis Reservas",
            description: "Una visión general de todas tus reservas"
        },
        releases: {
          subtitle: "Liberaciones",
            description: "Libera tu escritorio dedicado para que otros lo utilicen"
        },
        qr: {
          subtitle: "Escáner QR",
            description: "Reserva o realiza el check-in utilizando el escáner QR"
        }
      }
    },
    tribeloo_asset_list_item: {
      check_in: ["Registrado", "Check In"]
    },
    tribeloo_asset_release_popper: {
      buttons: {
        create: "Crear liberación",
          cancel: "Cancelar liberación"
      }
    },
    tribeloo_assets_popper: {
      title: "Recursos disponibles",
      show_all: "Todos"
    },
    tribeloo_component_filter: {
      title: "Opciones de filtro",
        date_range: ["Rango de fechas", "Desde", "Hasta"],
        type: "Tipo de recurso",
        location: "Lugar"
    },
    tribeloo_bookings_list: {
      subtitle: ['Buscar colegas', 'Mis reservas'],
        description: ['Busca colegas para encontrar sus reservas y optimizar la colaboración', 'Una visión general de todas tus reservas. Gestiona todas tus reservas en un solo lugar.'],
        search: " resultados de búsqueda.",
        not_found: "No se encontraron colegas."
    },
    tribeloo_camera: {
      camera_error_message: "Por favor, otorga acceso a la cámara",
        camera_not_found: "No se encontró ninguna cámara."
    },
    tribeloo_date_picker: {
      max_selection_info: "Se alcanzó la selección máxima de varios días",
        days_selected_text: "días seleccionados",
        toggle_text_info: ["Seleccionar varios días ", "Seleccionar varios días"]
    },
    tribeloo_download_image: {
      info_dialogue: "Tu imagen es demasiado grande.\nPor favor, selecciona una imagen con un tamaño inferior a 4 MB."
    },
    tribeloo_edit_invites: {
      title: "Editar invitación de reserva",
        subtitle: "Un correo electrónico solo se enviará a los colegas recién agregados.",
        buttons: {
        main_invite: "Invitar a colegas",
          send_invite: ['Reenviar invitación', 'Enviar invitación']
      },
      placeholder: "Invitaciones actualizadas"
    },
    tribeloo_favorites_list_item: {
      other_info: " Reservas activas"
    },
    tribeloo_map_legend: {
      button: "Centrar mapa",
        map_legend_title: "Leyenda del mapa",
        map_legend_items: {
        available: "Disponible",
          confirm: "Necesita confirmación",
          not_for_user: "No para el usuario",
          unavailable: "No disponible"
      }
    },
    tribeloo_qr_scanner: {
      title: "Escanear código QR",
        subtitle: "Coloca el código QR frente a la cámara para reservar o hacer el check-in.",
        error_message: "Error al escanear el código QR",
        processing_message: "Decodificación del escaneo en progreso"
    },
    tribeloo_releases_sidebar: {
      title_resources: "Mis recursos",
        title_releases: "Mis liberaciones",
        subtitle: "Liberaciones",
        description: "Configura la hora, la fecha y elige el recurso que deseas liberar.",
    },
    tribeloo_type_location_neighbourhood: {
      labels: {
        type: "Tipo de recurso",
          site: "Sitio",
          wing: "Ala",
          neighbourhoods: "Vecindarios"
      }
    },
    tribeloo_upload_profile_pic: {
      title: "Subir imagen",
        subtitle: "Elige una foto de perfil para mostrar en el mapa",
        info_dialog_component: "Tu imagen es demasiado grande.\nPor favor, selecciona una imagen con un tamaño inferior a 4 MB.",
        support_text: "Admite formatos JPG o PNG",
        dd_text: "Arrastra y suelta la imagen o ",
        browse_text: "explora",
        buttons: {
        save: "Guardar imagen",
          upload: "Subir imagen",
          capture: "Tomar una foto usando la cámara web",
      },
      img_alt: "Nueva foto de perfil"
    },
    tribeloo_whoisaround: {
      title: "¿Quién está cerca?"
    },
    tribeloo_more_settings: {
      title: "Más ajustes",
        labels: {
        seats: "Asientos",
          amenities: "Servicios",
          visitor: "Invitar visitante"
      },
      toggles: {
        amenities: "Mostrar solo con todos los servicios",
          booking: "La reserva no es visible para otros"
      },
      placeholders: {
        visitor: "Correo electrónico del visitante",
          amenities: "Ningún servicio seleccionado"
      },
      info: "Más ajustes no disponibles"
    },
    tribeloo_time_range: {
      labels: {
        select: "Selección rápida",
          start: "Hora de inicio",
          end: "Hora de finalización"
      },
      rangeOptions: {
        fullDay: 'Día Completo',
        morning: 'Mañana',
        afternoon: 'Tarde',
        customTime: 'Hora Personalizada'
      }
    },
    tribeloo_time_picker: {
      tooltip_error: "Formato de hora no válido",
    },
    tribeloo_single_file_upload: {
      button_save: "Guardar imagen recortada"
    },
    tribeloo_pop_up_confirmation: {
      titles: {
        cancel: "Cancelación de reserva",
          edit: "Edición de reserva exitosa",
          invite: "Editar invitación de reserva",
          booked: "¡Reserva exitosa!",
          options: "Opciones de reserva opcionales",
          addSubject: "Agregar asunto",
      },
      subtitles: {
        edit_booked: "Se le enviará un correo electrónico con información sobre su reserva.",
          invite: "Un correo electrónico solo se enviará a los colegas recién agregados.",
          old: "Reserva anterior",
          new: "Nueva reserva",
          cancel: "Una vez que se cancela una reserva, no se puede deshacer."
      },
      subjectInput: {
        saved: 'Asunto guardado',
        placeholder: 'Nombre del tema'
      },
      input: ['Invitaciones actualizadas', 'Colegas invitados'],
        buttons: {
          confirm: 'Confirmar',
        main: "Invitar a colegas",
          booKingSubjectInvitations: "Asunto de reserva e invitaciones",
          send: "Enviar invitación",
          update: "Actualizar invitaciones",
          cancel: "Cancelar reserva",
          nevermind: "No importa",
          saveSubject: "Guardar asunto"
      }
    },
    tags_modal: {
      title: "etiquetas",
        subtitle: "Pequeña explicación sobre cómo funcionan las etiquetas",
        small_title: ['Sugerencias', 'Todas las etiquetas'],
        colleagues: "colegas",
        placeholder: "Buscar o agregar etiquetas",
        add_button: ['Agregar', 'Agregar nueva etiqueta'],
        save_button: "Guardar cambios"
    },
    tribeloo_base_user_settings: {
      settings: "Configuración de usuario",
        tribe: "Mi tribu"
    },
    tribeloo_filter_tribe_members: {
      title: "Opciones de filtro",
        tag: "Etiquetas",
        site: "Sitio de inicio"
    },
    tribeloo_my_tribe: {
      description: "Etiqueta a colegas para encontrarlos fácilmente al crear una reserva y define tus propios grupos.",
        search: "Buscar"
    },
    tribeloo_tribe_member: {
      title: "Etiquetas",
        not_found: "No se encontraron etiquetas",
        edit_button: "Editar etiquetas"
    }
  },
  v2_pages: {
    tribeloo_default_page_holder: {
      messages: {
        update_success: 'Reserva actualizada con éxito',
        update_failed: 'No se pudo actualizar la reserva.',
        cancel_failed: "No se pudo cancelar la liberación",
        error408: "Do you wont to force book?"
      }
    },
    tribeloo_external_invites: {
      info: "Tu invitación no es válida",
      button: "Vete a casa"
    }
  }
};
