import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterWhoIsAround'
})
export class FilterWhoIsAroundPipe implements PipeTransform {

  transform(items: any[], names: string): any {
    if (!items || names=='') {
      return items;
    }

    return items.filter(
      item => item.user.name.toLowerCase().indexOf(names.toLowerCase()) !== -1 ||
        item.time_range.indexOf(names.toLowerCase()) !== -1 ||
        item.name.toLowerCase().indexOf(names.toLowerCase()) !== -1
    );
  }

}
