import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, switchMap, takeWhile } from 'rxjs/operators';
import { Observable, of, interval } from 'rxjs';

import { environment } from '../../../environments/environment';
import * as moment from 'moment';

@Injectable()
export class SitesService {

  constructor(
    private http: HttpClient
  ) { }

  getSitesList() {
    return this.http.get(environment.backend_url + environment.api_prefix + '/sites')
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // POST /api/1.0/site/maxcapacity {site_id:id}
  getSiteMaxcapacity(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/maxcapacity ', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/1.0/site/setonline {site_id:id, is_online:boolean}
  setOnline(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/setonline ', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/1.0/generateqrpdfurlsiteavery8 {site_type_id:} ===> url
  getQRPDF(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/generateqrpdfurlsiteavery8 ', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/1.0/getsitesoftype {site_type_id:} ===> [site1, site2, ....]
  getSitesOfType (data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/getsitesoftype ', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
// POST /api/1.0/site/currenttime {site_id:<id>}
  getSitesCurrenttime (data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/currenttime', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getSite(idSite) {
    return this.http.get(environment.backend_url + environment.api_prefix + '/sites/' + idSite)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  setformatDevice(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/setdevicetimeformat', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
//   /api/1.0/site/settodoreminder
// ARGS: {site_id:id, should_send_todo_reminders:boolean, todo_reminder_interval_hours: integer (hours) }
// RESULT: same as other /api/1.0/site apis (full site info)
  settodoreminder(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/settodoreminder', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getSiteAssets(idSite) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/assets', idSite)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getSiteAmenities(idSite) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/amenities', idSite)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getWingsSpecificSite(idSite) {
    return this.http.get(environment.backend_url + environment.api_prefix + '/sites/' + idSite + '/wings')
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getAdminSiteComplexData(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/wings', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  infoSiteWing(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/edit/info', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getAdminSiteWingsWithAssetsByDateTimeRange(data) {
    if(data.from != undefined || data.to != undefined) {
      data.from = moment(data.from).format("Y-MM-DD HH:mm:ss").toString();
      data.to = moment(data.to).format("Y-MM-DD HH:mm:ss").toString();
    }

    return this.http.post(environment.backend_url + environment.api_prefix + '/site/wings/assets', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  getAdminUsersMapsByData(data) {
    if(data.from != undefined || data.to != undefined) {
      data.from = moment(data.from).format("Y-MM-DD HH:mm:ss").toString();
      data.to = moment(data.to).format("Y-MM-DD HH:mm:ss").toString();
    }

    return this.getAdminSiteWingsWithAssetsByDateTimeRange(data)
    .pipe(
      switchMap(res => {
        if(res.isSuccess) {
          return of({
            maps: res.data,
            users: !res.data.length ? [] : res.data.map(wing => wing.assets.filter(asset => asset.colleague_info).map(asset => asset.colleague_info.map(colleague_info => {
              return {
                name: colleague_info.colleague_name,
                image: colleague_info.colleague_image,
                wing: {wing_id: asset.wing_id, wing_name: asset.wing_name},
                asset:{asset_id: asset.id, asset_name: asset.name}
              };
            }))).flat(3).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() || a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0)
          })
        } else {
          return of({maps:[], users:[]})
        }
      }),
      catchError(err => {return of(err)})
    )
  }

  getAdminSiteReservationsByDateTimeRange(data) {
    if(data.from != undefined || data.to != undefined) {
      data.from = moment(data.from).format("Y-MM-DD HH:mm:ss").toString()
      data.to = moment(data.to).format("Y-MM-DD HH:mm:ss").toString()
    }

    return this.http.post(environment.backend_url + environment.api_prefix + '/site/reservations', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getAdminSiteToDoListReservations(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/todo', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  checkAutoAssignProgress(req: any) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/issolverrunning', req)
    .pipe(
      switchMap((res:{isSuccess:boolean, data: boolean, errors: any}) => {return of(res.data)}),
      catchError(() => {return of(false)}),
      switchMap((respData: boolean) => {
        if(!respData) {
          return this.getAdminSiteToDoListReservations(req)
        } else {
          return this.checkAutoAssignProgressWithInterval(req)
        }
      })
    )
  }

  checkAutoAssignProgressWithInterval(req: any) {
    return new Observable( subscriber => {
      let longPoolInterval = interval(1500).pipe(
        switchMap(
          () => {
            return this.http.post(environment.backend_url + environment.api_prefix + '/site/issolverrunning', req)
            .pipe(
              switchMap((res:{isSuccess:boolean, data: boolean, errors: any}) => {return of(res.data)}),
              catchError(() => {return of(false)})
            )
          }),
        takeWhile(data => data, true),
      ).subscribe((respData) => {
          if(!respData) {
            longPoolInterval.unsubscribe();
            this.getAdminSiteToDoListReservations(req).subscribe(res => subscriber.next(res))
          }
      }, error => subscriber.next(error));
    })
  }

  getAutoAssign(req: any) {
    this.http.post(environment.backend_url + environment.api_prefix + '/site/startsolver', req)
    .subscribe(res =>
      console.log(res)
    )

    return this.checkAutoAssignProgressWithInterval(req);
  }

  getPossibleTimezones() {
    return this.http.post(environment.backend_url + environment.api_prefix + '/gettimezones', {})
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  setTimezoneForSite(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/timezone', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  createSite(name) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/sites/', name)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  updateSite(idSite, data) {
    return this.http.put(environment.backend_url + environment.api_prefix + '/sites/' + idSite, data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  updateSiteAddress(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/address', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  deleteSite(idSite) {
    return this.http.delete(environment.backend_url + environment.api_prefix + '/sites/' + idSite)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getGlobalAmenities(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/global/amenities',  data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  createGlobalAmenities(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/global/amenity/create', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  editGlobalAmenities(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/global/amenity/rename', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {
        console.log(err)
        return of(err)
      })
    )
  }

  removeGlobalAmenities(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/global/amenity/remove', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {
        console.log(err)
        return of(err)
      })
    )
  }

  enableSiteAmenities(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/amenity/enable', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  disableSiteAmenities(data) {
    console.log(data)

    return this.http.post(environment.backend_url + environment.api_prefix + '/site/amenity/disable', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {
        console.log(err)
        return of(err)
      })
    )
  }


  siteWorkingDays(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/workingdays', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  siteWorkingHours(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/workinghours', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  siteLargeRoom(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/largeroom', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // siteLatitude(data) {
  //   return this.http.post(environment.backend_url + environment.api_prefix + '/site/latitude', data)
  //   .pipe(
  //     tap(res => {console.log(res)}),
  //     switchMap(res => {return of(res)}),
  //     catchError(err => {return of(err)})
  //   )
  // }
  //
  // siteLongitude(data) {
  //   return this.http.post(environment.backend_url + environment.api_prefix + '/site/longitude', data)
  //   .pipe(
  //     tap(res => {console.log(res)}),
  //     switchMap(res => {return of(res)}),
  //     catchError(err => {return of(err)})
  //   )
  // }

  // /api/1.0/site/pluscode {site_id, plus_code}
  sitePlusCode(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/pluscode', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  setSiteEmail(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/setsiteadmindistributionlist', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  setSiteDeviceAdminCode(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/setdeviceadmincode', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getEmergencyMessage(data = {}) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/site/getemergencymessage', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  getActiveSiteTypes(data = {}) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/getactivesitetypes', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  getSiteTypes() {
    // POST /api/1.0/getsitetypes {} ==> [sitetype, sitetype, ....] //super admin only
    return this.http.post(environment.backend_url + environment.api_prefix + '/getsitetypes', {})
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  setSiteType(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/setsitetype', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  createSiteType(data) {
    // POST /api/1.0/createsitetype {'name':, 'icon_name':} ==> sitetype
    return this.http.post(environment.backend_url + environment.api_prefix + '/createsitetype', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  renameSiteType(data) {
    // POST /api/1.0/renamesitetype {'site_type_id':, 'name':, 'icon_name':} ==> sitetype //super admin only
    return this.http.post(environment.backend_url + environment.api_prefix + '/renamesitetype', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  deleteSiteType(data) {
  // POST /api/1.0/deletesitetype {'site_type_id':<number>} _// super admin only_
    return this.http.post(environment.backend_url + environment.api_prefix + '/deletesitetype', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getpossiblesitetypeexclusivities() {
  // POST /api/1.0/getpossiblesitetypeexclusivities
  //   RESULT:
  // ['exclusivity1', 'exclusivity2', ...]
    return this.http.post(environment.backend_url + environment.api_prefix + '/getpossiblesitetypeexclusivities', {})
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  setsitetypeexclusivity(data) {
  //   /api/1.0/setsitetypeexclusivity {site_type_id:integer, exclusivity_type:string}
  // RESULT
  // site type information for this site_type_id (also works for -1)
    return this.http.post(environment.backend_url + environment.api_prefix + '/setsitetypeexclusivity', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

}
