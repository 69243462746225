<div class="main-container">
  <ng-select class="activity-select" [dropdownPosition]="'top'" [items]="activities" [multiple]="false" [clearable]="false" [searchable]="false"  bindLabel="name" bindValue="id" [(ngModel)]="activityID" (change)="selectedActivityChanged()">
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let item of items">
        <span class="ng-value-label"><div class="Oval" [ngStyle]="{'background-color': item.color}"></div> {{item.name}}</span>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="ng-value">
        <div class="Oval ng-value-icon" [ngStyle]="{'background-color': item.color}"></div> {{item.name}}
      </div>
    </ng-template>
  </ng-select>

  <div class="range-edit-container" *ngIf="isShowRange" (click)="selectedActivityChanged()">
    <i *ngIf="wants_24h_time_format">{{dateTimeRange.from | datex: 'ddd, DD MMM YYYY, HH:mm'}} to</i>
    <i *ngIf="!wants_24h_time_format">{{dateTimeRange.from | datex: 'ddd, DD MMM YYYY, LT'}} to</i>
    <i *ngIf="wants_24h_time_format">{{dateTimeRange.to | datex: 'ddd, DD MMM YYYY, HH:mm'}}</i>
    <i *ngIf="!wants_24h_time_format">{{dateTimeRange.to | datex: 'ddd, DD MMM YYYY, LT'}}</i>
  </div>
</div>
