import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import * as moment from 'moment';

@Injectable()
export class UserReservationsService {

  constructor(
    private http: HttpClient
  ) { }

  getwhosaroundinfo(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/getwhosaroundinfo', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // POST /api/1.0/user/setinofficestatus {in_office:boolean} => {}
  setinofficestatus(data: any = {}) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/setinofficestatus', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  defaultassetforissue() {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/defaultassetforissue', {})
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  getReservations(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservations', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  getReservationAmenities(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/reservation/amenities', {reservation_id: data})
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getPublicReservations(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/listpublicreservations', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getReservationAssets(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/assets', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  getProxiedUsers() {
    return this.http.get(environment.backend_url + environment.api_prefix + '/user/getproxiedusers')
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST getproxiedusers { (optional) only_with_own_desk:boolean (default false)}``
  getProxiedUsersWithParameters(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/getproxiedusers', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // POST /api/1.0/user/listusers => colleagues will have an in_office:boolean attribute
  getColleaguesUsers(data: any = {}) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/listusers', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  usersList(data: any = {}): Observable<any> {
    return this.http.post(environment.backend_url + environment.api_prefix_v2 + '/user/getuserlist', data)
  }
  //   Set/Unset Edit mode
  // URL: /api/1.0/reservation/seteditmode
  //
  // Method: POST
  //
  // Request (BODY):
  //
  //  {
  //    "reservation_id":_reservation id_,
  //    "edit_mode":_boolean_
  //  }
  // Response (EXAMPLE RESULT - data): This is the reservation for which edit_mode has been changed.
  //
  //   {
  //     "id": 53,
  //     "from": "2017-07-06 12:00:00",
  //     "to": "2017-07-06 16:00:00",
  //     "site_name": null,
  //     "wing_name": null,
  //     "asset_name": null,
  //     "user_name": "user1",
  //     "site_id": null,
  //     "wing_id": null,
  //     "asset_id": null,
  //     "user_id": 1,
  //     "requires_confirm": 0 (1)
  //   }
  unsetEditMode(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/reservation/cleareditmode', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  setUnsetEditMode(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/reservation/seteditmode', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  reservationDetails(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/reservation', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  createReservation(data) {
    let req = {
      is_desk: data.is_desk  == undefined ? true : data.is_desk,
      assign_me_one: data.assign_me_one ? data.assign_me_one : false,
      from: data.from == undefined ? moment().add(1, 'd').hour(8).minute(0).second(0).format("Y-MM-DD HH:mm:ss").toString() : data.from,
      to:  data.to == undefined ? moment().add(1, 'd').hour(17).minute(0).second(0).format("Y-MM-DD HH:mm:ss").toString() : data.to,
      site_id: data.site_id,
      user_id: data.user_id,
      requested_capacity:  data.requested_capacity == undefined ? 1 : data.requested_capacity,
      internal_meeting:  data.internal_meeting != undefined ? data.internal_meeting : JSON.parse(localStorage.getItem("currentUser")).user.features.default_room_type_internal ? JSON.parse(localStorage.getItem("currentUser")).user.features.default_room_type_internal : false,
      asset_id: data.asset_id == undefined ? undefined : data.asset_id,
      private: data.private == undefined ? undefined : data.private,
      subject: data.subject == undefined ? undefined : data.subject,
      message: data.message == undefined ? undefined : data.message,
      message_active: data.message_active == undefined ? undefined : data.message_active,
      requested_amenities: data.requested_amenities == undefined ? undefined : data.requested_amenities,
      force: data.force ? data.force : undefined,
      repeat_until: data.repeat_until != undefined ? data.repeat_until : data.to,
      is_for_visitor: data.is_for_visitor,
      visitor_email: data.visitor_email ? data.visitor_email : ''
    }

    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/create', req)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  // api/1.0/user/reservation/bookforasset {asset_id, from, to}
  bookforasset(data) {
    let req = {
      from: data.from == undefined ? moment().add(1, 'd').hour(8).minute(0).second(0).format("Y-MM-DD HH:mm:ss").toString() : data.from,
      to:  data.to == undefined ? moment().add(1, 'd').hour(17).minute(0).second(0).format("Y-MM-DD HH:mm:ss").toString() : data.to,
      site_id: data.site_id,
      asset_id: data.asset_id == undefined ? undefined : data.asset_id,
      force: data.force ? data.force : false
    }

    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/bookforasset', req)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }
  messageReservation(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/message', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  // /user/reservation/messageactive {reservation_id, message_active:boolean }
  messageactive(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/messageactive', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  updateReservation(data) {
    data.from = moment(data.from).format("Y-MM-DD HH:mm:ss").toString();
    data.to = moment(data.to).format("Y-MM-DD HH:mm:ss").toString();

    console.log(data);

    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/update', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  updateSubjectReservation(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/subject', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  updateCapacityReservation(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/capacity', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  updateMeetingReservation(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/setinternal', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  updatePublicMeetingReservation(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/setprivate', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  updateDateTimeRangeReservation(data) {
    if(data.from != undefined || data.to != undefined) {
      data.from = moment(data.from).format("Y-MM-DD HH:mm:ss").toString();
      data.to = moment(data.to).format("Y-MM-DD HH:mm:ss").toString();
    }
    console.log(data)

    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/changeTime', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  assignReservation(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/assign', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }

  unassignReservation(data) {
    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/unassign', data)
    .pipe(
      tap(res => {console.log(res)}),
      switchMap(res => {return of(res)}),
      catchError(err => {return of(err)})
    )
  }


  addreservationamenities(data) {
    // Method: POST
    // URL: {api_prefix}/reservation/addamenity
    // req: {reservation_id, amenity_id}
    // res: {isSucces: true, data: amenity, errors: []}

    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/addamenity', data)
      .pipe(
        catchError((err) => {
          console.log(err);
          return of(err);
        })
      );
  }
  removereservationamenities(data) {
    // Method: POST
    // URL: {api_prefix}/reservation/removeamenity
    // req: {reservation_id, amenity_id}
    // res: {isSucces: true, data: null, errors: []}

    return this.http.post(environment.backend_url + environment.api_prefix + '/user/reservation/removeamenity', data)
      .pipe(
        catchError((err) => {
          console.log(err);
          return of(err);
        })
      );
  }

}
