import { Injectable }    from '@angular/core';
import { HttpClient }    from '@angular/common/http';
import { of }            from 'rxjs';
import { catchError }    from 'rxjs/operators'
import * as _            from "lodash";
import * as moment       from 'moment';
import {ExcelService}    from '../excel/excel.service';
import { environment }   from '../../../environments/environment';
import { localizations } from '../../../localizations/localizations';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  isV2 = JSON.parse(localStorage.getItem("currentUser"))?.user?.features?.is_v2;
  api_prefix: string = this.isV2 ? environment.api_prefix_v2 : environment.api_prefix;

  constructor(private http: HttpClient,
              private excelService: ExcelService) { }

  setLanguage(language, isReload: boolean = true) {
    localStorage.setItem("localLanguage", language)
    localStorage.setItem("localeDate", this.getLanguageData().code)
    moment.locale(this.getLanguageData().type)
    if(isReload) {
      if(localStorage.getItem("currentUser")) {
        this.http.post(environment.backend_url + this.api_prefix + '/user/setlocalization', {'localization': language})
        .pipe(
          catchError((err) => {
            return of(err.error);
          })
        ).subscribe(res => {
          console.log(res)
          location.reload();
        })
      } else {
        location.reload();
      }
    }
  }

  checkStructureLanguages() {
    if(environment.download_xlsx) {
      this.downloadLanguageStructureInExcel();
    }

    let languagesData = this.getLanguagesList().map(item => this.getLanguageData(item.type))
    let langsPathes = languagesData.map(item => this.objectDeepKeys(item))

    for(let i = 0; i < langsPathes.length; i++) {
      langsPathes[i] = this.getDeepKeysTypes(langsPathes[i], languagesData[i]);
      if(i) {
        console.group();
        console.log('%c Default ' + languagesData[0].type + ' vs ' + languagesData[i].type, 'background: #f6f6f6; color: #4a4a4a')
        let tmpRes = _.differenceWith(langsPathes[0],langsPathes[i], _.isEqual)
        if(tmpRes.length) {
          console.log('%c ' + languagesData[i].type + ' ' + languagesData[i].name + ' should have: ', 'background: #f6f6f6; color: red');
          console.log(tmpRes);
          console.log('%c ' + 'But not exists', 'background: #f6f6f6; color: red');
        } else {
          console.log('%c ' + 'everything is OK', 'background: #f6f6f6; color: green');
        }
        console.groupEnd()
      }
    }
  }

  downloadLanguageStructureInExcel() {
    let languagesData = this.getLanguagesList().map(item => this.getLanguageData(item.type))
    let langsPathes = languagesData.map(item => this.objectDeepKeys(item))

    console.group();
    console.log('------------------ downloadLanguageStructureInExcel path + value_should_be start ----------------')

    for (let i = 0; i < langsPathes.length; i++) {
      langsPathes[i] = this.getDeepKeysTypes(langsPathes[i], languagesData[i], true);
      langsPathes[i] = this.resetObjectArray(langsPathes[i].filter(row => row.value_should_be));
      console.log('current ' + languagesData[i].type + ' { ' + (i + 1) + ' of ' + langsPathes.length + ' } ');
    }

    let excel = [];
    for ( var property in langsPathes[0] ) {
      let myObj = {};
      myObj['Key'] = property;
      langsPathes.forEach(item => myObj[item.type] = item[property] ? item[property] : "")
      excel.push(myObj);
    }
    if(environment.download_xlsx) {
      this.excelService.exportAsExcelFile(excel, 'languages');
    }
    console.log('%c ' + "excel file download all", 'background: #f6f6f6; color: green');
    console.log(excel);
    console.log("------------------ downloadLanguageStructureInExcel path + value_should_be end ----------------")
    console.groupEnd()
  }

  resetObjectArray(array) {
    let myObj = {};
    array.forEach(item => myObj[item.path] = item.value_should_be)
    return myObj;
  }

  objectDeepKeys(obj){
    return Object.keys(obj)
      .filter(key => obj[key] instanceof Object)
      .map(key => this.objectDeepKeys(obj[key]).map(k => `${key}.${k}`))
      .reduce((x, y) =>  x.concat(y), Object.keys(obj))
  }

  getDeepKeysTypes(objectDeepKeysArr, baseObj, isDownload: boolean = false) {
    return objectDeepKeysArr.map(item => {
      let newobj = baseObj;
      item.split('.').map(newItem => newobj = newobj[newItem])
      return isDownload ?
      {path: item, type: Array.isArray(newobj) ? 'array' : typeof newobj, value_should_be: newobj == "" || typeof newobj == "string" || typeof newobj == "boolean" || typeof newobj == "number" ? newobj : null} :
      {path: item, type: Array.isArray(newobj) ? 'array' : typeof newobj}
    });
  }

  getLanguage() {
    return localStorage.getItem("localLanguage") ? localStorage.getItem("localLanguage") : "en";
  }

  getLanguagesList() {
    return Object.keys(localizations).map(item => {
      return {type: localizations[item].type, code: localizations[item].code, name: localizations[item].name, img: localizations[item].img}
    })
  }

  getLanguageData(key?: string) {
    let data = localizations[key ? key : this.getLanguage()]
    return data;
  }
  getAllLanguagesData() {
    return localizations;
  }

  getAllPagesTypes() {
    return Object.keys(this.getLanguageData()['pages'])
  }
  getAllComponents() {
    // TODO: add parameter 'name_components_pack' if it's empty return all packs arrays
    return {
      shared_components: this.getAllSharedComponents(),
      user_shared_components: this.getAllUserSharedComponents()
    }
  }
  getAllReusableDataTypes() {
    return Object.keys(this.getLanguageData()['reusable_data'])
  }

  getAllPagesText() {
    if(this.getLanguageData()['pages']) {
      return {isSuccess: true, data: this.getLanguageData().pages, errors: []}
    } else {
      return {isSuccess: false, data: null, errors: [{code: 404, description: 'pages not found'}]}
    }
  }

  getComponentText(type, componentName) {
    if(this.getLanguageData()[type]) {
      if(this.getLanguageData()[type][componentName]) {
        return {isSuccess: true, data: this.getLanguageData()[type][componentName], errors: []}
      } else {
        return {isSuccess: false, data: null, errors: [{code: 404, description: componentName + ' component not found'}]}
      }
    } else {
      return {isSuccess: false, data: null, errors: [{code: 404, description: type + ' components not found'}]}
    }
  }

  getAllSharedComponentsText() {
    if(this.getLanguageData()['shared_components'] && this.getLanguageData()['user_shared_components'] ) {
      return {
        isSuccess: true,
        data: {
          shared_components: this.getLanguageData()['shared_components'],
          user_shared_components: this.getLanguageData()['user_shared_components']
        },
        errors: []
      }
    } else {
      return {
        isSuccess: false,
        data: {
          shared_components: this.getLanguageData()['shared_components'],
          user_shared_components: this.getLanguageData()['user_shared_components']
        },
        errors: [{code: 404, description: 'can`t found all components entries'}]
      }
    }
  }

  getAllPagesByType(type) {
    return Object.keys(this.getLanguageData().pages[type])
  }
  getPageText(type, pagePath) {
    if(_.get(this.getLanguageData().pages[type], pagePath)) {
      return {isSuccess: true, data: _.get(this.getLanguageData().pages[type], pagePath), errors: []}
    } else {
      return {isSuccess: false, data: null, errors: [{code: 404, description: 'wrong page name'}]}
    }
  }

  getAllReusableData() {
    if(this.getLanguageData()['reusable_data']) {
      return {isSuccess: true, data: this.getLanguageData()['reusable_data'], errors: []}
    } else {
      return {isSuccess: false, data: null, errors: [{code: 404, description: 'not found data'}]}
    }
  }
  getReusableDataText(dataName) {
    if(this.getLanguageData()['reusable_data'][dataName]) {
      return {isSuccess: true, data: this.getLanguageData()['reusable_data'][dataName], errors: []}
    } else {
      return {isSuccess: false, data: null, errors: [{code: 404, description: 'wrong data name'}]}
    }
  }

  getAllSharedComponents() { // TODO: check getAllComponents() todo
    return Object.keys(this.getLanguageData()['shared_components'])
  }
  getAllUserSharedComponents() { // TODO: check getAllComponents() todo
    return Object.keys(this.getLanguageData()['user_shared_components'])
  }

}
