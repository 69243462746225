import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assetsWithoutAmenities'
})
export class AssetsWithoutAmenitiesPipe implements PipeTransform {

  transform(items: any[], has_requested_amenities: boolean): any {
    if (!items || has_requested_amenities) {
      return items;
    }

    return items.filter( item => {
      return item.has_requested_amenities == true;
     });
  }

}
