<div class=""  fxLayout="column" fxLayoutAlign="center center">
  <i class="fa fa-4x fa-info-circle"></i>
  <div class="message">
    {{data.text}}
  </div>
  <div class="message-description-container" *ngIf="data.descriptions">
    <p *ngFor="let description of data.descriptions">{{description}}</p>
  </div>
  <div class="message-description-container" *ngIf="data.info">
    <p class="text-center" *ngIf="data.info !=''">{{data.info}}</p>
  </div>
  <div class="message-description-container" *ngIf="data.limit_text || data.limit_date">
    <p class="text-center" *ngIf="data.limit_text !=''">{{data.limit_text}}</p>
    <p class="text-center" *ngIf="data.limit_date !=''">{{data.limit_date}}</p>
  </div>
  <div class="actions" fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="!data.link" class="button-accent" [ngClass]="{'blue-btn': data.isBlue}"  (click)="close()">{{data.button ? data.button: button.cancel}}</div>
    <a *ngIf="data.link" href="{{data.link}}" target="_blank"><div class="button-accent button-accent-special blue-btn" (click)="close()">{{data.button}}</div></a>
  </div>
</div>
