<div class="assets-list-container">
  <div class="header dropdown-list1" (click)="open = !open">
    <h3><i class="fa fa-chevron-up" [ngClass]="{'open': open}"></i> {{open ? button.hide: button.show}}{{languages.header}}</h3>
  </div>

  <div class="assets-list assets-list-open" *ngIf="open">

    <div class="header" *ngIf="assetsFilterd">
      <div class="search-container">
        <input class="search-input" type="text" placeholder="{{languages.placeholder}}"  [(ngModel)]="assetsSearch">
        <i class="fa fa-search"></i>
      </div>
    </div>
    <div class="header check-counter-container" *ngIf="isCheckedData">
      <label class="toggle" >
        <input type="checkbox" [(ngModel)]="roomsWithoutAllAmenities" (change)="changestate()">
        <div class="slide-toggle"></div>
        <span>{{languages.description}}</span>
      </label>

      <span class="assets-counter">{{assetsFilterd | filterAssetsCount: assetsSearch: !roomsWithoutAllAmenities}}</span>
    </div>

    <div *ngIf="assetsFilterd"><i class="nospace" *ngIf="assetsFilterd | filterAssetsCountBooleanPipe: assetsSearch: !roomsWithoutAllAmenities">{{isCheckedData ? languages.nospace : ''}}</i></div>

    <asset-list-item *ngFor="let asset of assetsFilterd | filterAssetsByObjectNames: assetsSearch | assetsWithoutAmenities: !roomsWithoutAllAmenities as result" [asset]="asset" (selectNewAsset)="assignAsset($event)"></asset-list-item>
  </div>
</div>
