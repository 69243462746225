import { Injectable }                                     from '@angular/core';
import { Router }                                         from '@angular/router';
import { HttpInterceptor, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpRequest, HttpHandler, HttpEvent }            from '@angular/common/http';
import { Observable, of, throwError }                     from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { GlobalStoreSiteIDService }                       from "./../globalStoreSiteID/global-store-site-id.service";
import { LocalizationService }                            from "./../localization/localization.service";
import { environment }                                    from '../../../environments/environment';

@Injectable({ providedIn: 'root'})

export class AuthService implements HttpInterceptor {

  readonly defaultResp: any = {isSuccess: false, data: null, errors:[]}
  api_prefix: string = JSON.parse(localStorage.getItem("currentUser"))?.user?.features?.is_v2 ? environment.api_prefix_v2
                                                                                             : environment.api_prefix;
  constructor(
    private http: HttpClient,
    private localizationService: LocalizationService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers;
    let height = window.innerHeight.toString()
    let width = window.innerWidth.toString()
    if (localStorage.getItem("currentUser") != undefined && localStorage.getItem("currentUser") != 'undefined') {
      headers = {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("currentUser")).token}`,
        'backend-code': window.location.pathname.search('external/reservations/calendar')==1 ? window.location.pathname.split('/')[window.location.pathname.split('/').length-1] : JSON.parse(localStorage.getItem("currentUser")).backend_code,
        'image-url-type': environment.image_url_type,
        'TRB-FRONTEND-VERSION': environment.version,
        'localization': this.localizationService.getLanguage(),
        'device-height': height,
        'device-width': width
      }
    } else if((localStorage.getItem("currentUser") == undefined || localStorage.getItem("currentUser") == 'undefined')&&(localStorage.getItem("backend_code") != undefined && localStorage.getItem("backend_code") != 'undefined')) {
      headers = {
        'backend-code': JSON.parse(localStorage.getItem("backend_code")),
        'image-url-type': environment.image_url_type,
        'TRB-FRONTEND-VERSION': environment.version,
        'localization': this.localizationService.getLanguage(),
        'device-height': height,
        'device-width': width
      }

    } else if (window.location.pathname.search('external/reservations/calendar')==1) {
      headers = {
        'backend-code': window.location.pathname.split('/')[window.location.pathname.split('/').length-1],
        'image-url-type': environment.image_url_type,
        'TRB-FRONTEND-VERSION': environment.version,
        'localization': this.localizationService.getLanguage(),
        'device-height': height,
        'device-width': width
      }
    }
    console.log(headers)
    request = request.clone({ setHeaders: headers });

    if (request.url.indexOf('/login') !== -1) {
      return next.handle(request);
    } else {
      return next.handle(request)
      .pipe(
        catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this.action401();
            // return of(err.error);
          console.log("test 401 1");
          return throwError(this.defaultResp);
        } else {
          if (err.error.errors?.length) {
            console.log("status " + err.status)
            if(err.error.errors[0].code == 401) {
              this.action401();
              console.log("test 401 2");
            }
          }
          console.log("test 401 3");
          console.log(err);
          return throwError(err.error || err.errors);
          // return throwError(this.defaultResp);
        }
      }));
    }
  }

  getHeaderInfo(data) {
    localStorage.removeItem("backend_code");
    return this.http.post(environment.single_url + this.api_prefix + '/getlogininfo', data)
    .pipe(
      catchError((err) => {
        return of(err.error);
      })
    );
  }

  ssoLogin(data) {
    return this.http.post(environment.single_url + this.api_prefix + '/ssologin', data)
    .pipe(
      catchError((err) => {
        return of(err.error);
      })
    );
  }

  action401() {
    console.log("status " + 401)
    localStorage.removeItem("currentUser");
    if(localStorage.getItem('hash')) {
      this.router.navigate(['/kiosk/landing/' + JSON.parse(localStorage.getItem('hash'))]);
    } else {
      this.router.navigate(['/login']);
    }
    GlobalStoreSiteIDService.removeItem();
  }

  getfrontendversion() {
    return this.http.get(environment.backend_url + this.api_prefix + '/getfrontendversion')
    .pipe(
      catchError((err) => {
        return of(err.error);
      })
    );
  }

  login(data) {
    return this.http.post(environment.backend_url + this.api_prefix + '/login', data)
    .pipe(
      catchError((err) => {
        return of({isSuccess: false, data: null, errors:[{code: 255, description: "Wrong email or password"}]});
      })
    );
  }

  forgotPassword(data) {
    return this.http.post(environment.backend_url + this.api_prefix + '/user/requestnewpassword', data)
      .pipe(
        catchError((err) => {
          return of(err);
        })
      )
  }

  logout(isRedirection: boolean = true) {
    if (localStorage.getItem("currentUser") != undefined && localStorage.getItem("currentUser") != 'undefined') {
      return this.http.post(environment.backend_url + this.api_prefix + '/logout', {token: JSON.parse(localStorage.getItem("currentUser")).token})
      .pipe(
        switchMap(res => {
          localStorage.removeItem("currentUser");
          GlobalStoreSiteIDService.removeItem();
          if(isRedirection) {
            localStorage.removeItem('kiosk');
            this.router.navigate(['/login']);
            // location.reload(true);
          }
          return of(res);
        }),
        catchError((err) => {
          localStorage.removeItem("currentUser");
          GlobalStoreSiteIDService.removeItem();
          if(isRedirection) {
            localStorage.removeItem('kiosk');
            this.router.navigate(['/login']);
            location.reload();
          }
          return of(err);
        })
      );
    } else {
      return of(false)
    }
  }

  isLogged() {
    console.log("test 401 4");
    if(localStorage.getItem("currentUser") == null) {
      console.log("test 401 4.1")
      this.router.navigate(['/login']);
      return of(this.defaultResp);
    }

    return this.http.post(environment.backend_url + this.api_prefix + '/whoami', {token: JSON.parse(localStorage.getItem("currentUser")).token})
    .pipe(
        catchError((err) => {
        console.log("test 401 4.2")
        localStorage.removeItem("currentUser");
        localStorage.removeItem("for_user");
        this.router.navigate(['/login']);
        return of(this.defaultResp);
        // location.reload(true);
        // return of(JSON.parse(err._body));
        // return of(err);
      })
    )
  }

}
