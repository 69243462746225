import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapsZoomToggleService {

  selectionChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() { }

  change() {
    this.selectionChange.next('')
  }
}
