import {
  Input,
  Output,
  Component,
  EventEmitter
} from '@angular/core';
import { LocalizationService } from "./../../services/localization/localization.service";

@Component({
  selector: 'reservation-header-info',
  templateUrl: './reservation-header-info.component.html',
  styleUrls: ['./reservation-header-info.component.css']
})
export class ReservationHeaderInfoComponent {

  has_neighbourhoods: boolean = JSON.parse(localStorage.getItem("currentUser")).user.features.has_neighbourhoods;
  @Input() reservationObj: any;
  @Output() unassign = new  EventEmitter<any>();
  languages: any;

  constructor(private localizationService: LocalizationService) {
    if(this.localizationService.getComponentText('shared_components','reservation_header_info').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','reservation_header_info').data;
    }
  }

  unassignAsset() {
    this.unassign.emit();
  }

}
