<div class="details-container" *ngIf="asset">
  <h3 class="gray" [ngClass]="{
    'asset-free': asset.status == 1 && asset.is_active,
    'asset-rezerved': (asset.status == 0 && asset.is_active) || (!asset.has_requested_amenities && !showAssetsWithoutAllAmenities && idAsset != asset.id),
    'asset-confirmed': asset.status == 2 && asset.is_active,
    'asset-not-for-user': asset.status == 4 && asset.is_active
  }">{{asset.name}}</h3>
  <div class="colleague-info" *ngIf="asset.colleague_info && idHoverAsset == asset.id" fxLayout="row" fxLayoutAlign=" center">
    <img loading="lazy" *ngIf="!asset.is_zone" src="{{isRelative ? backend_url + asset.colleague_info[0].colleague_image : asset.colleague_info[0].colleague_image}}">
    <div class="" *ngIf="asset.colleague_info.length > 1" fxLayout="column">
      <span>{{asset.colleague_info.length}} colleagues in zone</span><br>
      <span *ngFor="let item of asset.colleague_info">{{item.colleague_name}}</span>
    </div>
    <div fxLayout="column" *ngIf="asset.colleague_info.length == 1">
      <span>{{asset.colleague_info[0].colleague_name}}</span>
      <span *ngIf="asset.colleague_info[0].is_visitor">Visitor</span>
    </div>
  </div>
  <div class="white-container" *ngIf="idHoverAsset == asset.id">
    <b *ngIf="asset.site_type_icon == null"><i class="fa fa-user"></i> {{asset.capacity}} {{has_internal_external_rooms || asset.capacity == 1 ? asset.resource_type : 'meeting room'}}</b>
    <b *ngIf="asset.site_type_icon != null"><i class="{{asset.site_type_icon}}"></i></b>
    <br>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutAlign="center ">
        <span *ngIf="isPopup">{{asset.site_name + ', ' + asset.wing_name}} <br></span>
        <span *ngIf="asset.owner_id != null">{{languages.owner + asset.owner_name}} <br></span>
        <span *ngIf="asset.all_amenities != ''">{{asset.all_amenities}}<br></span>
        <span class="margin-top-10" *ngIf="asset.neighbourhood_name != '' && has_neighbourhoods">{{asset.neighbourhood_name}}<br></span>
      </div>
      <i class="fa fa-exclamation-triangle" *ngIf="!asset.has_requested_amenities"></i>
    </div>
    <div class="slots" *ngIf="isShowSlotsInfo && asset.free_time_slots.length">
      <div class=""><b>{{languages.time_slots}}</b></div>
      <div class="" *ngFor="let item of asset.free_time_slots">{{item.text}}</div>
    </div>
    <div class="reserv-info" *ngIf="isShowSlotsInfo && asset.is_bookable && !asset.free_time_slots.length">
      <div class=""><b>{{languages.no_time_slots}}</b></div>
    </div>
    <div class="reserv-info" *ngIf="isShowSlotsInfo && !asset.is_bookable">
      <div class=""><b>{{languages.not_reservable}}</b></div>
    </div>
    <pinch-zoom *ngIf="asset.image!=null && isPopup" [disableZoomControl]="'disable'" [disabled]="disabledZoom" #myPinchIMG [transition-duration]="200" (dblclick)="toggleZoom()">
      <img loading="lazy" src="{{isRelative ? backend_url + asset.image : asset.image}}">
    </pinch-zoom>
    <img *ngIf="asset.image!=null && !isPopup" loading="lazy" src="{{isRelative ? backend_url + asset.image : asset.image}}">
  </div>
</div>
