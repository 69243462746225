<div class="wrap" [ngClass]="{'content-loded': special_items}" fxLayout="row" *ngIf="special_items">
  <div class="warning-message" fxLayout="column" fxLayoutAlign="center center" *ngIf="!special_items.can_create_reservation && !special_items.types.length && !isShowAddRelease">
    <p>{{info}}</p>
    <div class="ok-button" (click)="onClick(undefined)">{{button.ok}}</div>
  </div>

  <div class="action-button" (click)="onClick(-1)" *ngIf="special_items.can_create_reservation && !hide_desks">
    <img loading="lazy" src="../../../assets/FFFFFF-0.png"/>
    <div class="action-button-container button-blue" fxLayoutAlign="center center">
      <div class="button-description" fxLayout="column" fxLayoutAlign="center center">
        <img loading="lazy" src="../../../assets/desk_icon_mobile.png">
        <span class="text-center">{{actions_nav.book_desk}}</span>
      </div>
    </div>
  </div>
  <div class="action-button" (click)="onClick(-2)" *ngIf="special_items.can_create_reservation && !hide_meeting_rooms">
    <img loading="lazy" src="../../../assets/FFFFFF-0.png"/>
    <div class="action-button-container button-blue" fxLayoutAlign="center center">
      <div class="button-description" fxLayout="column" fxLayoutAlign="center center">
        <img loading="lazy" src="../../../assets/MR_icon_mobile.png">
        <span class="text-center">{{actions_nav.book_meeting_room}}</span>
      </div>
    </div>
  </div>
  <div class="action-button" (click)="onClick(-3)" *ngIf="isShowAddRelease">
    <img loading="lazy" src="../../../assets/FFFFFF-0.png"/>
    <div class="action-button-container button-blue" fxLayoutAlign="center center">
      <div class="button-description" fxLayout="column" fxLayoutAlign="center center">
        <img loading="lazy" src="../../../assets/release_icon_mobile.png">
        <span class="text-center">{{actions_nav.add_new_release}}</span>
      </div>
    </div>
  </div>

  <div class="action-button" *ngFor="let item of special_items.types" (click)="onClick(item.id)">
    <img loading="lazy" src="../../../assets/FFFFFF-0.png"/>
    <div class="action-button-container" fxLayoutAlign="center center">
      <div class="button-description" fxLayout="column" fxLayoutAlign="center center">
        <i class="{{item.icon_name}} fa-2x"></i>
        <span class="text-center">{{item.name}}</span>
      </div>
    </div>
  </div>

</div>
