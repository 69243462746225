<div class="asset-list-item" [ngClass]="{
    'asset-free': asset.status == 1 && asset.is_active,
    'asset-rezerved': asset.status == 0 && asset.is_active,
    'asset-confirmed': asset.status == 2 && asset.is_active,
    'asset-not-for-user': asset.status == 4 && asset.is_active
  }" (click)="select()">
  <div class="asset-main-info">
    <span>{{asset.name}}</span>
    <span *ngIf="asset.site_type_icon == null"><i class="fa fa-user"></i> {{asset.capacity}} {{asset.capacity == 1 || !has_internal_external_rooms ? '' : asset.resource_type}}</span>
    <span *ngIf="asset.site_type_icon != null"><i class="{{asset.site_type_icon}}"></i></span>
  </div>
  <p>{{asset.wing_name}}{{asset.neighbourhood_name && has_neighbourhoods? ", " : ""}}{{has_neighbourhoods ? asset.neighbourhood_name : ''}}</p>
  <div class="asset-list-item-goupe">
    <p>{{asset.all_amenities}}</p>
    <i class="fa fa-exclamation-triangle" *ngIf="!asset.has_requested_amenities" [matTooltip]="languages.tooltip" [matTooltipPosition]="'left'"></i>
  </div>
</div>
