import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './services/auth/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { CdkTableModule } from '@angular/cdk/table';

// Main components and routing
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SnackBarModuleModule } from './snack-bar-module/snack-bar-module.module';

// Pipes
import { AssetsByWingIdPipe } from './pipes/assets/assets-by-wing-id.pipe';
import { SortByPositionPipe } from './pipes/sort-by-position/sort-by-position.pipe';
import { FilterByNamePipe } from './pipes/filter-by-name/filter-by-name.pipe';
import { FilterByProxyNamePipe } from './pipes/filter-by-proxy-name/filter-by-proxy-name.pipe';
import { NotReservedAssetsPipe } from './pipes/not-reserved-assets/not-reserved-assets.pipe';
import { FilterBySettedObjectPropertyPipe } from './pipes/filter-by-setted-object-property/filter-by-setted-object-property.pipe';
import { DatexPipe } from './pipes/datex/datex.pipe';
import { FilterPipe } from './pipes/filter/filter.pipe';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { ForUserInterceptor } from './core/interceptors/for-user.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    DatexPipe,
    FilterPipe,
    FilterByNamePipe,
    AssetsByWingIdPipe,
    SortByPositionPipe,
    FilterByProxyNamePipe,
    NotReservedAssetsPipe,
    FilterBySettedObjectPropertyPipe,
  ],
  imports: [
    BrowserAnimationsModule,
    MatSliderModule,
    MatDialogModule,
    SnackBarModuleModule,
    SharedComponentsModule,
    CdkTableModule,

    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxGoogleAnalyticsModule.forRoot(environment.g_code),
    NgxGoogleAnalyticsRouterModule
  ],
  entryComponents: [
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: localStorage.getItem("localeDate") ? localStorage.getItem("localeDate") : "en-EN"
    },
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ForUserInterceptor,
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
