import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserReservationsService } from "app/services/user-reservations/user-reservations.service";
import { NewsfeedService } from 'app/services/newsfeed/newsfeed.service';
import { environment } from '../../../environments/environment';
import { ExcelService } from 'app/services/excel/excel.service';
import { LocalizationService } from "app/services/localization/localization.service";

@Component({
  selector: 'app-attendees-modal',
  templateUrl: './attendees-modal.component.html',
  styleUrls: ['./attendees-modal.component.css'],
  providers: [NewsfeedService, UserReservationsService]
})
export class AttendeesModalComponent implements OnInit {

  headerText: string = "Are attending this event";
  type: string ="atendeesAdmin"; //share | atendeesUser
  text: string = "";
  users: any[] = [];
  selectedUsers: any[] = [];
  backend_url: string = environment.backend_url;
  isRelative: boolean = environment.image_url_type != 'absolute';
  user_is_attending: boolean = false;

  languages: any;

  constructor(
    public dialogRef: MatDialogRef<AttendeesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private newsfeedService: NewsfeedService,
    private excelService: ExcelService,
    private userReservationsService: UserReservationsService,
    private localizationService: LocalizationService
  ) {
    if(this.localizationService.getComponentText('shared_components','attendees_modal').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','attendees_modal').data;
    }
  }

  ngOnInit() {
    console.log(this.data);
    this.type = this.data.type;
    this.user_is_attending =  this.data.event.user_is_attending;
    if(this.type == 'share') {
      this.headerText = this.languages.header_text.share;
      this.userReservationsService.getColleaguesUsers()
      .subscribe(res => {
        if(res.isSuccess) {
          this.users = res.data;
        }
      });
    } else {
      this.headerText = this.languages.header_text.attendees;
      this.newsfeedService.getattendees({event_id: this.data.event.id})
      .subscribe(res => {
        if(res.isSuccess) {
          this.users = res.data;
        }
      })
    }
  }

  sendMessage(): void {
    this.dialogRef.close(this.text);
  }
  attendToEvent(): void {
    this.newsfeedService.userAttend({item_id: this.data.event.id, attend: !this.user_is_attending})
    .subscribe(res => {
      if(res.isSuccess) {
        this.dialogRef.close(true);
      }
    })
  }

  share() {
    this.newsfeedService.shareitem({item_id:  this.data.event.id, text: this.text, users: this.selectedUsers})
    .subscribe(res => {
      if(res.isSuccess) {
        this.dialogRef.close(true);
      }
    })
  }

  selectedUserChanged() {

  }

  downloadExele() {
    let excel = [];
    this.users.map(item => excel.push({username: item.name, email: item.email}))
    this.excelService.exportAsExcelFile(excel, 'attendees');
  }

}
