import {
  Input,
  Output,
  Component,
  EventEmitter
} from '@angular/core';
// Services
import { SitesService } from "./../../services/sites/sites.service";
import { UserManagementService } from "./../../services/admin/userManagement/user-management.service";
import { LocalizationService } from "./../../services/localization/localization.service";

@Component({
  selector: 'site-select',
  templateUrl: './site-select.component.html',
  styleUrls: ['./site-select.component.css'],
  providers: [ SitesService, UserManagementService ]
})
export class SiteSelectComponent {

  is_super_admin: boolean = false;
  showUser: boolean = JSON.parse(localStorage.getItem("showUser"))
  sitesData : any = [];
  @Input() selectedSite: any;
  @Input() displayNone: boolean = false;
  @Input() displayALL: boolean = false;

  @Output() siteCahnge = new EventEmitter<any>();
  @Output() siteInit = new EventEmitter<any>();

  languages:any;

  constructor(
    private sites: SitesService,
    private userManagement: UserManagementService,
    private localizationService: LocalizationService
  ) {
    if(this.localizationService.getComponentText('shared_components','site_select').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','site_select').data;
    }
    this.is_super_admin = JSON.parse(localStorage.getItem("currentUser")).user.is_super_admin;
    this.updateInfo();
  }
  updateInfo() {
    if(this.showUser) {
      this.sites.getSitesOfType({site_type_id: -1})
      .subscribe(res => {
        this.sitesData = res.data;
        this.selectedSite = res.data[0].id;
        this.siteInit.emit();
      });
    } else {
      this.userManagement.getListOfSitesAdministeredByAdmin()
      .subscribe(res => {
        this.sitesData = res.data;
        this.selectedSite = res.data[0].id;
        this.siteInit.emit();
      });
    }
  }
  actionSite(id) {
    this.userManagement.getListOfSitesAdministeredByAdmin()
    .subscribe(res => {
      this.sitesData = res.data;
      if (id != -1) {
        this.selectedSite = id;
      } else {
        this.selectedSite = res.data[0].id;
      }
      this.itemSiteCahnge(this.selectedSite);
    });
  }

  itemSiteCahnge(id) {
    this.selectedSite = id;
    this.siteCahnge.emit();
  }
}
