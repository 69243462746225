import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalStoreSiteIDService } from 'app/services/globalStoreSiteID/global-store-site-id.service';

import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  getHeaderInfo(data) {
    if(window.location.host != 'app.tribeloo.com') {
      data = {...data, is_beta: true};
    }
    localStorage.removeItem("backend_code");
    return this.http.post(environment.single_url + environment.api_prefix_v2 + '/getlogininfo', data)
    .pipe(
      catchError((err) => {
        return of(err.error);
      })
    );
  }
  getServiceInfo() {
    return this.http.post(environment.single_url + environment.api_prefix + '/serviceavailable', {})
    .pipe(
      catchError((err) => {
        return of(err.error);
      })
    );
  }
  getLoginFromToken(data) {
    return this.http.post(environment.single_url + environment.api_prefix_v2 + '/getbetaloginfromtoken', data)
    .pipe(
      catchError((err) => {
        return of(err.error);
      })
    );
  }

  login(data) {
    return this.http.post(environment.backend_url + environment.api_prefix_v2 + '/login', data)
    .pipe(
      catchError((err) => {
        return of({isSuccess: false, data: null, errors:[{code: 255, description: "Wrong email or password"}]});
      })
    );
  }

  forgotPassword(data) {
    return this.http.post(environment.backend_url + environment.api_prefix_v2 + '/user/requestnewpassword', data)
      .pipe(
        catchError((err) => {
          return of(err);
        })
      )
  }

  logout(isRedirection: boolean = true) {
    if (localStorage.getItem("currentUser") != undefined && localStorage.getItem("currentUser") != 'undefined') {
      return this.http.post(environment.backend_url + environment.api_prefix_v2 + '/logout', {token: JSON.parse(localStorage.getItem("currentUser")).token})
      .pipe(
        switchMap(res => {
          localStorage.removeItem("currentUser");
          GlobalStoreSiteIDService.removeItem();
          if(isRedirection) {
            localStorage.removeItem('kiosk');
            this.router.navigate(['/login']);
            // location.reload(true);
          }
          return of(res);
        }),
        catchError((err) => {
          localStorage.removeItem("currentUser");
          GlobalStoreSiteIDService.removeItem();
          if(isRedirection) {
            localStorage.removeItem('kiosk');
            this.router.navigate(['/login']);
            location.reload();
          }
          return of(err);
        })
      );
    } else {
      return of(false)
    }
  }
}
