export const nl = {
  type:'nl',
  code:'nl-NL',
  name:'Nederlands',
  img:'flag-icon-nl',
  pages: {
    login_main_navigation: {
      login: {
        pageName: 'login',
        callback: 'Sluit dit venster en ga verder in de Tribeloo add-in voor Outlook',
        email_dialog: {
          header: 'Voer uw e-mail in',
          input: 'E-mail'
        },
        sso_info: {
          text: "Uw browser blokkeert het openen van een nieuw tabblad voor de SSO-login. Verander uw browserinstellingen of klik op de knop 'Forceer openen'",
          button: 'Forceer openen'
        },
        text: {
          loading: ['Werken voor jou', 'Als het te lang duurt,', 'ververs dan de pagina'],
          header: 'Welkom',
          sub_header: "Boek je favoriete bureaus, vind je collega's en nog veel meer.",
          form_headers: {
            login: 'Log in',
            email: 'Controleer e-mail',
            password: 'Wachtwoord vergeten'
          },
          password_info: ["Geen zorgen! We sturen je een herstelkoppeling.", 'Er is een nieuw wachtwoord naar je verzonden.'],
          inputs: ['Gebruikersnaam', 'Wachtwoord', 'E-mail'],
          remember_action: ['Onthoud mij.', 'Ben jij dit niet?', 'Wachtwoord vergeten?', 'Terug naar inloggen'],
          button: ['Inloggen met SSO', 'Log in', 'Wachtwoord opnieuw instellen'],
          info_modal: 'Controleer je e-mail om je wachtwoord te wijzigen'
        }
      },
      delegates_modal: {
        header: "Selecteer gedelegeerd profiel",
        sub_header: "Selecteer de persoon voor wie je een reservering wilt maken",
        button_select: "Selecteer"
      },
      main_navigation: {
        pageName: 'zijbalk, hoofdwerkbalkcomponent',
        nav_tabs_names: {user: 'Gebruiker', admin: 'Beheerder'},
        nav_dropdown_corg: {
          about: 'Over',
          history: "Geschiedenis",
          feedback: 'Geef feedback',
          logout: 'Uitloggen',
          user_settings: "Gebruikersinstellingen",
          delegate: "Wissel gedelegeerde profielen",
          user: "Wissel naar gebruikersprofiel",
          admin: "Wissel naar beheerdersprofiel"
        },
        admin_nav_items: {
          dashboard: 'Dashboard',
          reservation_management: 'Reserveringsbeheer',
          newsfeed: 'Nieuwsfeed',
          user_management: 'Gebruikersbeheer',
          site_configuration: 'Siteconfiguratie',
          kiosk_configuration: 'Kioskconfiguratie',
          issue_management: 'Probleembeheer',
          insights: 'Inzichten'
        }
      }
    },
    external: {
      close_issue_by_hash: 'Bedankt voor het sluiten van dit probleem!',
      hash_reservation: 'Reservering niet beschikbaar',
      google: 'Laden...',
      outlook_reservation: {
        popup: 'Deze reservering verwijderen?',
        login: "Log in",
        no_space_info: ['Geen ruimte gereserveerd,', ' neem contact op met de organisator'],
        site_headr: 'Locatie',
        date_time: 'Tijd weergegeven in de tijdzone van de gevraagde locatie',
        space: {
          header: 'Ruimte',
          internal_external: ['intern', 'extern']
        },
        amenities_header: 'Voorzieningen'
      },
      reset_password: {
        info_modal: 'Wachtwoord gewijzigd.',
        button_update: 'Wachtwoord bijwerken',
        placeholders: ['Voer nieuw wachtwoord in', 'Voer nieuw wachtwoord opnieuw in'],
        errors: ["Wachtwoorden voldoen niet aan de vereisten", 'Wachtwoorden zijn niet hetzelfde'],
        rules: 'Wachtwoord moet minimaal bevatten: 8 tekens, 1 hoofdletter, 1 kleine letter, 1 cijfer'
      }
    },
    admin: {
      kiosk: {
        main_header: 'Set up a kiosk',
        card: {
          headers: {
            site: 'Select a site',
            wing: ['Select a wing ', '(optional)']
          },
          placeholders: {
            site: 'Please search or select a site',
            wing: 'Please search or select a wing'
          },
          warning: 'Not running in kiosk, PWA or full-screen mode.',
          button: 'Launch kiosk mode',
        },
        info: {
          kiosk: 'For Kiosk - please logout, close browser and than run a following command: ',
          pwa: 'For PWA or full-screen browser - use Google chrome browser and',
          button: 'Click here'
        }
      },
      dashboard: {
        main: {
          refresh: "Refresh",
          site_card: {
            header: " Dashboard",
            action: "Help center",
            sub_header: "Select a site to get an overview"
          },
          small_card: {
            header: " Meeting rooms",
            total: "TOTAL",
            assigned: "Assigned"
          },
          utilization_cards: {
            header: " Max utilization rate ",
            special_header: "Max utilization rate special types",
            tooltip: "The max utilization rate is the max ratio during that day of requested over available resources of the selected type",
            table: {
              headers: [
                { name: "All types", tag_name: "all_types" },
                { name: "Desks", tag_name: "desks" },
                { name: "Meeting rooms", tag_name: "rooms" },
                { name: "Meeting rooms - Large ", tag_name: "big_rooms" },
                { name: "Meeting rooms - External", tag_name: "external" },
                { name: "Released %", tag_name: "releases" },
              ],
              hover: ["Requested ", "Available "]
            },
            info: "Click on a number in the table to see the chart",
            zoom: ['zoom out', 'zoom in']
          },
          to_do_card: {
            header: " To-do’s",
            link: "Go to to-do list",
            table: [
              { name: "To be confirmed", tag_name: "to_be_confirmed" },
              { name: "To be assigned", tag_name: "to_be_assigned" },
              { name: "Untreated request", tag_name: "with_message" },
              { name: "Total", tag_name: "total" }
            ]
          },
          not_enough_card: {
            header: "Not enough resources",
            info_default: "Click on a number in the table to see the solutions.",
            info_selected: "Let’s see how we can solve this.",
            button_reservation: "Remind people about their reservations",
            button_release: "Request more releases"
          }
        },
        releases_table: {
          info: "You can ask people who have not released their resource for this day to release it.",
          headers: {
            user: { popup: 'owners', name: "Assigned to" },
            level: { popup: 'Level of user', name: "Level assigned user" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            asset_level: { popup: '', name: "Asset level" },
            seat: { popup: '', name: "Seat capacity" },
            amenities: { popup: '', name: "Resource’s amenities" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: "Send message to all selected releases?",
            select_owner_message: "At least one user must be selected.",
            error_message: "Error sending",
          }
        },
        reservations_table: {
          info: "You can remind people who have a reservation for this day to cancel their reservation if no longer needed.",
          headers: {
            user: { popup: 'Owners', name: "User" },
            level: { popup: 'Level', name: "Level of user" },
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            rc: { popup: 'Requested seats', name: "Requested seats" },
            ie: { popup: 'internal external', name: "internal/ external" },
            amenities: { popup: '', name: "Requested amenities" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            status: { popup: 'Statuses', name: "Status" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: "Send message to all selected reservations?",
            select_owner_message: "At least one user must be selected.",
            error_message: "Error sending",
          }
        }
      },
      newsfeed: {
        navigation: ['Newsfeed', 'Emergency message'],
        list: {
          main_header: 'Newsfeed',
          site_header: 'Select a site to check its newsfeed.',
          i_tooltipe: 'The newsfeed of a site is shown on the homepage for all sites selected by the user',
          card: {
            header: 'Settings',
            toggle: 'Show newsfeed of site',
            i_tooltipe: 'You can choose to show the newsfeed of another site',
            select: {
              toggle: 'Click here to select the site',
              placeholder: 'Select site'
            },
            auto_remove: {
              text: ['Automatically delete news items that are older than ', ' days. '],
              i_tooltipe: 'Choose how long news items are shown on the newsfeed before they get deleted. Events are removed from the newsfeed once ended.'
            }
          },
          add_item: ' Add new item',
          popups: {
            info: 'Please select a location!',
            confirm: 'Delete all existing newsitems and events of ',
            button: 'OK'
          }
        },
        form: {
          main_header: 'Emergency message ',
          site_header: 'Select a site for which you want to write an emergency message.',
          i_tooltipe: 'An emergency message, once turned on, is visible for all users of that site on the Tribeloo homepage and on the devices of that site',
          message: "Haven't message",
          placeholder: 'Emergency message line ',
          save: 'Save',
          emergency: ['Turn on emergency message', 'Turn off emergency message']
        }
      },
      site_config: {
        navigation: {
          site: "Site configuration",
          rules: "Site rules",
          table: "Resource configuration table",
          types: "Resource types",
          wayfinding: "Wayfinding"
        },
        edit_site: {
          header: "Editing site ",
          video_header: "How to add, edit and delete a site",
          buttons: {
            add: "Add new site",
            delete: "Delete site"
          },
          toggle: "Site is active & visible for users",
          toggle_warning: ["Site is currently ", "inactive & invisible"," for users"],
          base: {
            header: "Basic details",
            video_header: "How to set up a site: basic details",
            placeholder: {
              name: "site name",
              code: "plus code",
              address: "address of this site"
            },
            label: {
              code: "Define coordinates of site (plus code) ",
              address: "Address of site "
            },
            tooltip: {
              code: "Plus code can be found on Google maps and is used to show a map of the location on the wayfinding link",
              address: "Address is shown on the wayfinding link"
            },
            position: {
              header: "Position of wing",
              info: "Select a wing, by clicking on its title, to change its position.",
            },
          },
          amenities: {
            header: "Amenities",
            video_header: "How to set up a site: amenities",
            info: "Define amenities ",
            tooltip: "Amenities are additional features, like a beamer or a flipchart, which can be selected when looking for a resource. Select amenities in the list below, to activate them on the current site, so they can be assigned to a specific resource from this site.",
            counter: [" in total - ", " active"],
            placeholder: "new amenity"
          },
          manager: {
            header: "Site manager settings",
            video_header: "How to set up a site: site manager settings",
            info: "Add e-mail address office manager ",
            tooltip: "Office manager will receive emails when new issues are reported ",
            placeholder: "e-mail",
            toggle: ["Send email to-do's every ", "hours"],
          },
          device: {
            header: "Device settings",
            video_header: "How to set up a site: device settings",
            label: {
              time: "Use 24h time for devices ",
              password: "Device password (0-9 A-E) "
            },
            tooltip: {
              time: "Select time format for meeting room and desk devices: AM/PM or 24h format",
              password: "Password used to access configuration screen on devices"
            },
            placeholder: "code 0-9 A-E"
          },
          insights: {
            header: "Insight settings",
            video_header: "How to set up a site: insight settings",
            labels: {
              days: "Define working days ",
              hours: "Define working hours ",
              room: ["Define a large meeting room", "people or more"],
            },
            tooltip: {
              days: "Working days are used to calculate average utilization rate in insights",
              hours: "Working hours are used to calculate average utilization rate in insights"
            },
            range: ["from", "to"]
          },
          popups: {
            online: {
              text: 'Bring this site online?',
              descriptions: ['This will make the site visible for regular users and ready for reservations.']
            },
            offline: {
              text: 'Bring this site offline?',
              descriptions: ['This will make the site invisible for regular users. Existing reservations will be kept.']
            },
            delete_site: {
              text: 'Delete this site?',
              big_descriptions: ['This will delete all wings, resources and reservations for this site. \nUsers with this home site will be moved to having no home site.']
            },
            cant_delete_site: 'Site can not be deleted.',
            amenity: {
              exists: 'Amenity already exists.',
              cant_updated: 'Amenity cannot be updated. Used by resources on other sites.',
              cant_delete: 'Amenity cannot be deleted, because it is assigned to some resources.',
              cant_disabled: 'Amenity cannot be disabled, because it is assigned to some resources. Remove it first from all resources.'
            }
          },
          edit_amenity_dialog: "Edit amenity",
          edit_wings_sorted: {
            add_button: " Add Wing",
            position: "Position: ",
          },
          wing_position: {
            header: "Selected wing: ",
            position: "Position ",
            move: "Move to position "
          }
        },
        rules: {
          header:"Site rules",
          site_card: {
            header: "Site",
            sub_header: "Select a site for which you want to define user privileges and rules."
          },
          default_reservation_time_card: {
            header: "Default reservation time",
            sub_header_desk: "Desk",
            sub_header_room: "Meeting room",
            label_start: "Start date",
            label_end: "Duration"
          },
          auto_assign_card: {
            header: "Auto-assign resources ",
            video_header: 'How to auto-assign resources to reservations',
            sub_header: "Reservations can get a resource auto-assigned if the user requests one, if none is available it will appear in the to-do list of the office manager",
            toggle: "Activate auto-assign functionality for ‘to assign’ reservations",
          },
          check_in_reminder_email_card: {
            header: "Check-in reminder email ",
            video_header: 'How to use the check-in reminder emails & auto-cancel reservations',
            send_toggle: "Send out reminder email 15 minutes before the start of a reservation that requires check-in",
            add_button_toggle: ["Add ", "‘Cancel reservation’", " button to reminder email"],
            add_desck_toggle: ["For ", "desk", " reservations: add ", "‘Check-in’", " button to reminder email"],
            add_room_toggle: ["For ", "meeting room", " reservations: add ", "‘Check-in’", " button to reminder email"],
            auto_cancel_header: "Auto-cancel reservations that require check-in",
            cancel_desk_toggle: ["Cancel ", "desk", " reservation after", "min, if a check-in is required."],
            cancel_room_toggle: ["Cancel ", "meeting room", " reservation after", "min, if a check-in is required."],
          },
          reminder_email_card: {
            header: ["Reminder email", "for desks"],
            video_header: 'How to use the general reminder emails',
            send_toggle: ["Send reminder email ", "hours before the start of a reservation"],
            subject: {
              label: "Subject:",
              placeholder: "Reminder about your upcoming reservation"
            },
            message: {
              label: ["Message: (Markdown supported, for example to add links. ", "Check guidelines"],
              placeholder: "Add message"
            },
            agree_toggle: ["Add ", "‘I agree’", " button: by agreeing the person acknowledges they have read the message"],
            cancel_toggle: ["Add ", "‘Cancel reservation’", " button"],
            header_special: "Reminder email for meeting rooms",
            send_toggle_special: ["Send reminder email ", "hours before the start of a reservation"]
          },
          time_limit_card: {
            header: "Time limit for future reservations",
            video_header: 'How to use the time limit for future reservations',
            sub_header: "Reservations can only be made a certain time ahead by non-admin users",
            desck_toggle: ["Desk", " reservations can be created in the next"],
            room_toggle: ["Meeting room", " reservations can be created in the next"],
            special_toggle: "Reservations can be created in the next",
            postfix: "working days"
          },
          utilization_limit_card: {
            header: "Utilization limit",
            sub_header: ["Utilization of active ", " can be limited.", "Example: a site has 40 active ", ". When utilization is limited to 50%, only 20 concurrent ", " reservations can be made."],
            sub_header_types: ['desks', 'resources', 'desk'],
            toggle: ["Utilization is limited to", "percent"]
          },
          multi_day_reservation_card: {
            header: "Multi-day reservation",
            sub_header: ["Define if an employee is allowed to create one reservation for several consecutive days at once.", "When allowed, an employee will only receive the reminder emails once and only needs to check-in once at the start of his multi-day reservation, if this is required."],
            toggle: "Multi-day reservations are allowed"
          },
          cleaning_time_card: {
            header: "Cleaning",
            sub_header: "Cleaning time will be foreseen after the end of every reservation. During this period the resource cannot be booked.",
            desk_toggle: ["Time available to clean a ", "desk", "min"],
            room_toggle: ["Time available to clean a ", "meeting room", "min"]
          },
          user_message_card: {
            header: "User message when creating reservation ",
            toggle: 'Show this message when a user creates a reservation for a resource on this site',
            label: 'Message:',
            placeholder: 'Type your message here',
          },
          user_privileges_card: {
            header: "User privileges",
            video_header: 'How to set up user privileges',
            sub_header: "Define which users have access to which resources and have to get confirmation for a reservation",
            levels: {
              header: "1. Define the names of the different user levels and resource levels:",
              toggle: "User level names are the same across all sites",
              description_simple: {start: "User & resource level names", center: " can be set by site admin and are ", end: "specific for the site."},
              description_global: [
                {start: "User level names", center: " can only be set by global admin and are the ", end: "same for all sites."},
                {start: "Resource level names", center: " can be set by site admin and are ", end: "specific for the site."},
              ],
              user_label: "User level ",
              resource_label: "Resource level ",
              placeholder: "Text"
            },
            privileges: {
              header: "2. Define the privileges per user and resource level:",
              cols: "Resource level",
              rows: "User level"
            },
            rules: [
              {id: 0, line1: "Resource not available", line2: "(can be assigned by admin)", class: "red"},
              {id: 2, line1: "Resource only available ", line2: "after confirmation", class: "orange"},
              {id: 1, line1: "Resource available for", line2: "user level", class: "green"}
            ]
          },
        },
        edit_wing: {
          header: "Editing wing ",
          sub_header: "wing details",
          video_header: "How to add, edit and delete a wing",
          placeholder: "wing name",
          button: {
            back: "Back to site edit",
            add: "Add new wing",
            delete: "Delete wing"
          },
          popup: {
            text: 'Delete this wing?',
            big_descriptions:['This will delete all resources of this wing and could affect current reservations. \nCheck your to-do list if proceeding.']
          },
          assets_list: {
            header: "Resources",
            add_button: " Add resource",
            counter: " in total",
            popup: {
              text: 'Delete this resource?',
              big_descriptions:['This could affect current reservations. \nCheck your to-do list if proceeding.']
            }
          }
        },
        edit_asset: {
          buttons:{
            back: "Back to wing edit",
            add: "Add resource",
            clone: "Duplicate resource",
            delete: "Delete resource"
          },
          base: {
            header: "Basic details ",
            video_header: "How to add, edit and delete a resource & define the basic details",
            internal_external: [
              {name:"Internal only", value: true},
              {name:"External allowed", value: false}
            ],
            type_tooltipe: 'INTERNAL ROOM: used for a meeting with colleagues from within the company.\n EXTERNAL ROOM: used when also people of outside the company are invited (e.g. client).\n ZONE: contains multiple single desks that can be booked, without selecting a particular one.',
            user_tooltipe: 'Assigning a resource to a specific person, means that this person has a fixed desk and they alone can use it. They can still choose to release it for others to use, when they aren’t around.',
            zoning_tooltipe: 'Semicolon separated list of zoning names, useful for HVAC/BM integration.\n E.g.:\n lighting_7;heating_3',
            amenity_type: "other asset type",
            placeholders: {
              name: "asset name",
              user: "Select user",
              neighbourhood: "Select neighbourhood",
              amenities: "Select amenities"
            },
            labels: {
              status: "Resource status ",
              neighbourhood: "Neighbourhood ",
              user: "Assigned to ",
              level: "Resource level ",
              resource_zoning: "Resource zoning ",
              capacity: ['Seat capacity', 'Capacity'],
              type: "Resource type ",
              check_in: "Check-in required",
              amenities: "Amenities available ",
              image: "Add image (< 4MB)"
            }
          },
          size: {
            header: "Size, Shape & position ",
            video_header: "How to set up a resource: size, shape & position",
            size: {
              header: "Size & shape",
              sub_header: "Choose a shape",
              width: "Width",
              height: "Height",
              round: "Round corners"
            },
            position: {
              header: "Map position",
              horizontal: "Horizontal",
              vertical: "Vertical"
            }
          },
          calendar: {
            header: "Calendar & approval settings",
            video_header: "How to set up a resource: calendar & approval settings",
            resource: {
              label: "Resource email ",
              placeholder: "Resource email",
              tooltip:"Fill in an email to create a calendar for this resource. Tribeloo will keep the calendar up to date with reservations for this resource."
            },
            approvers: {
              label: "Approvers email ",
              placeholder: "Approvers email",
              tooltip:"Add the email address of the person who will confirm reservations for this resource. Multipe email addresses can be added separated by a semicolon ; and without any spaces before or after the semicolon"
            },
          },
          device: {
            header: "Device configuration",
            video_header: "How to set up a resource: device configuration",
            code: {
              label: "Device code ",
              placeholder: "Code"
            },
            id: {
              label: "Device ID ",
              placeholder: "ID"
            }
          },
          qr_nfc: {
            header: "QR & NFC configuration",
            video_header: "How to set up a resource: QR & NFC configuration",
            qr: {
              header: "Generate QR code",
              info: "Click on the button to generate a QR code of this resource.",
              button: "Generate QR"
            },
            nfc: "NFC configuration"
          },
          popups: {
            nfc_copy: "NFC url copied.",
            set_capacity: {
              text: 'Reduce seat capacity?',
              big_descriptions: ['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            select_owner: {
              text: 'Assign user to resource?',
              big_descriptions: ['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            internal_change: {
              text: "Change resource type to ‘Internal only’?",
              big_descriptions:["This could affect current reservations. \nCheck your to-do list if proceeding."]
            },
            remove: {
              text: 'Delete this resource?',
              big_descriptions:['This could affect current reservations. \nCheck your to-do list if proceeding.']
            },
            check_card_id: {
              header: "",
              cases: [
                '1. Click "Start test" below',
                '2. On selected device, tap "Book now" or "Book later"',
                '3. Scan your card on the device',
                '4. The RFID code of the scanned card appears below',
                '5. To test another card, go to step 2'
              ],
              start: "Start test",
              stop: "Stop"
            }
          },
          assets_dragging_view: [" - click for edit", "drag & drop", "click to select"]
        },
        edit_info: {
          create: "Created on: ",
          update: "Last updated on: ",
          resource: "Resources: ",
        },
        type: {
          header: "Resource types",
          sub_header_descriprion: {
            first_line: "Standard sites have the type 'room or desk' automatically assigned.",
            second_line: ["If you want to create sites that contain another type of resource, define them here.", "These other resource types will also become visible on the Tribeloo homepage and in the tab 'More' in Outlook."]
          },
          video_header: 'How to define different resource types to book',
          card: {
            list: {
              header: "Existing resource types",
              button: "Add new resource type"
            },
            form: {
              header: ["Create resource type", "Edit resource type"],
              type: {
                label: "Resource type",
                placeholder: "Name of resource type"
              },
              icon: {
                label: "Icon",
                placeholder: "fa fa-archive",
                button: "Check icon list"
              },
              preview: {
                label: "Preview",
                text: ["Book a ", "Book a desk", "Book a meeting room"]
              },
              booking: {
                label: "Double booking by user",
                sub_label: "(desks only)"
              },
              button: {
                create: " Create type",
                delete: " Delete type",
                save: " Save changes",
              }
            }
          },
          popups: {
            unique_name: "You alredy have same type name",
            create: 'Create type?',
            save: 'Save changes?',
            add: "New resource type is added",
            saved: "Changes saved",
            delete: 'Delete this resource type?',
            cant_delete: "This resource can't be deleted, because there are still sites of this type"
          },
        },
        assets_table: {
          buttons: {
            batch: "Batch edit",
            qr: ["QR PDF selected", "QR PDF all"]
          },
          headers: {
            number: {name: "Number", popup: ""},
            resource: {name: "Resource", popup: ""},
            wing: {name: "Wing", popup: "Wings"},
            user: {name: "Assigned to", popup: ""},
            level: {name: "Resource level", popup: "Resource level"},
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            capacity: {name: "Seat capacity", popup: "Seat capacity"},
            type: {name: "Resource type", popup: "Resource type"},
            check_in: {name: "Check-in required", popup: "Check-in required"},
            amenities: {name: "Amenities", popup: ""},
            device_id: {name: "Device ID", popup: ""},
            email: {name: "Resource email", popup: ""},
            status: {name: "Status", popup: "Status"},
            from: {name: "From", popup: ""},
            to: {name: "To", popup: ""}
          },
          popups: {
            batch: {
              header: "Edit resource details",
              sub_header: "Update",
              level: "Resource level",
              capacity: "Seat capacity",
              type: {
                label: "Resource type",
                values: [
                  { name: 'Internal only', value: true },
                  { name: 'External allowed', value: false }
                ],
              },
              check_in: {
                label: "Check-in required",
                values: [
                  { name: 'yes', value: true },
                  { name: 'no', value: false }
                ],
              },
              add_amenities: {
                label: "Add amenities",
                placeholder: "Select amenities"
              },
              remove_amenities: {
                label: "Remove amenities",
                placeholder: "Select amenities"
              },
              approvers: {
                label: "Set approvers",
                placeholder: "approvers email"
              },
              neighbourhood: {
                label: "Neighbourhoods",
                placeholder: "Select neighbourhood"
              },
              status: "Resource status"
            },
            selected: "At least one resource must be selected",
            selete_selected: ["Delete the ", " selected resources?"],
            this_delete: "Delete this resource?"
          }
        },
        wayfinding_link: {
          header: "Wayfinding link",
          privacy_card: {
            header: "Privacy",
            toggle: "Logging in is required to see information about assigned resource and floormap"
          },
          layout_card: {
            header: "Layout",
            sub_header_descriprion: {
              first_line: ["When using the Outlook add-in and Chrome extension, a wayfinding link is added to the event itself. ", "This shows invitees where they need to be and to easily find their way."],
              second_line: "You can add a company logo OR company name, and define the colors for the banner, the company name and the titles."
            },
            logo: {
              label: "Company logo",
              sub_label: "(PNG, less than 4MB)"
            },
            image_info: ["max width 210px and ", "max height 50px"],
            company_name: {
              label: "Company name",
              placeholder: "Max 20 characters"
            },
            name_color_label: "Color company name",
            baner_color_label: "Color banner",
            titels_color_label: "Color titles"
          }
        }
      },
      todo: {
        navigation: {
          list: 'Reservation to-do list',
          requests: 'Requests',
          reservation_table: 'Reservation table',
          release_table: 'Release table'
        },
        main: {
          all_button_tooltip: "Assign resources automatically to current to-do's",
          dialog_asset: "Asset must be selected first",
          details_resources_button: "View resource reservations",
          legend: {
            header: "View legend",
            items: [
              {name: "Available", type_class: "green"},
              {name: "Available, but to be confirmed", type_class: "orange"},
              {name: "Available, but rules constraint", type_class: "blue"},
              {name: "Not available", type_class: "red"}
            ]
          }
        },
        list: {
          update: "Last updated: ",
          toggle: "Show only untreated request",
          toggle_visitor: "Show only visitor reservations",
          default_name_subject: "New reservation ",
          user_prefix: "by ",
          visitor_prefix: "for ",
          tooltip: "Reservation within selected time range",
          default_name_asset: "Reservation pending"
        },
        details: {
          site_change: {
            header: "Change site",
            tooltip: "Remove assigned space first"
          },
          request: {
            header: "Request",
            treated: ["Request treated", "Request untreated"],
            buttons: {
              add: "Add note",
              contact: "Contact user"
            }
          },
          details: {
            header: ["Show details", "Hide details"],
            delete_button: "Cancel reservation",
            default_name_subject: "New reservation ",
            user_prefix: "By ",
            visitor_prefix: "For ",
            seats: {
              counter: ["Requested: seats ","Requested: "],
              type: ['internal meeting', 'external meeting'],
              amenities: "Requested amenities: "
            }
          },
          popups: {
            delete: 'Delete this reservation?',
            assigned: "Space is assigned to reservation and confirmed",
            selected: "Asset must been selected",
            available: "Asset is not available",
            message_error: "error sending"
          }
        },
        work_stack: "Click here to select the stack item",
        message_popup: {
          header: "Add note",
          sub_header: "Request",
          treated: ["Request treated", "Request untreated"]
        },
        release_table: {
          info: 'Changing time range removes all filters',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "Assigned to" },
            level: { popup: 'Level assigned user', name: "Level assigned user" },
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            amenities: { popup: '', name: "Resource’s amenities" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            subject: { popup: '', name: "Subject" },
            email: { popup: '', name: "Email" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: ["Send message to ", " shown releases for "],
            remove_release: "Delete this release?",
            remove_releases: ["Delete ", " shown releases for "],
            error_message: "Error sending",
          }
        },
        reservaton_table: {
          info: 'Changing time range removes all filters',
          change_site_buton: ' Change site for all',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'User', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            level: { popup: 'Level of user', name: "Level of user" },
            group: { popup: 'User group in ', name: "User group in " },
            email: { popup: '', name: "User email" },
            neighbourhood: {name: "Neighbourhood", popup: "Neighbourhood"},
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            seats: { popup: 'Requested seats', name: "Requested seats" },
            internal_external: { popup: 'Resource type', name: "Resource type" },
            amenities: { popup: '', name: "Requested amenities" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resources', name: "Resource" },
            request: { popup: 'Request', name: "Request" },
            status: { popup: 'Statuses', name: "Status" },
            acknowledged: { popup: 'Acknowledged', name: "Acknowledged" }
          },
          tooltip: {
            lock: "lock all",
            unlock: "unlock all",
            acknowledged: "From reminder email: if the person clicked on the 'I agree' button and therefore acknowledges they have read the message",
            confirm: "confirm all",
            delete: "delete all",
            send_mail: "send message to all"
          },
          dialogues: {
            chang_site: 'Change site',
            confirm: ['Confirm ', ' shown reservations from '],
            send_all_message: ["Send message to ", " shown reservations for "],
            remove_reservation: "Delete this reservations?",
            remove_reservations: ["Delete ", " shown reservations for "],
            error_message: "Error sending",
            lock: ["Lock ", " shown reservations from "],
            unlock: ["Are you sure you want to unlock ", " shown reservations from "]
          }
        },
        requests_table: {
          info: 'Changing time range removes all filters',
          add_comment_buton: 'Add comment',
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'User', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            subject: { popup: '', name: "Subject" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resource', name: "Resource" },
            request: { popup: 'Request', name: "Request" },
            comment: { popup: '', name: "Comment" },
            seats: { popup: '', name: "Request treated" },
            email: { popup: '', name: "Owner email" }
          },
          tooltip: "send message to all",
          dialogues: {
            send_all_message: ["Send message to ", " shown reservations for "],
            error_message: "Error sending"
          }
        }
      },
      user_management: {
        navigation: {
          global_admin: "Global admins",
          admin: "Site admins",
          privileges: "User rules",
          user: "User management",
          users_table: "User table",
          groups: "User groups",
          sso: "SSO",
          provisioning: "User provisioning"
        },
        global_admin: {
          header: "Global admin management",
          admins_card: {
            header: "Define the global admins",
            sub_header: "These users have access to all sites and features",
            video_header: 'How to define the site & global administrators',
            search: "Search",
            global_admin_list_header: "Site admins that are global admin",
            admin_list_header: "Site admins",
            admin_list_header_tooltip: 'You need to be site admin first, before you can be global admin'
          },
        },
        privileges: {
          header: "User rules",
          site_card: {
            header: "Site",
            sub_header: "Select a site to define it's user rules."
          },
        },
        admin: {
          header: "Site admin management",
          site_card: {
            header: "Site",
            sub_header: 'Select a site for which you want to add or remove administrators.'
          },
          admins_card: {
            header: "Add administrators for this site",
            video_header: 'How to define the site & global administrators',
            search: "Search",
            admin_list_header: "Users that are admin for this site",
            user_list_header: "Users",
            popup: {
              title: "This user has global admin rights and therefore access to all sites.",
              descriptions: ["If you want to give this user only access to certain sites, remove them from the global admin list first."]
            }
          },
        },
        user: {
          rfid_modal: {
            header: "Assign RFID to user",
            step1: {
              header: ["Step 1", " - Enter device ID & click ‘Start’"],
              placeholder: "Device ID",
              button: "Start ",
              info: ["No resource found ", "Check or re-enter ID"]
            },
            step2: ["Step 2", " - On connected device, tap ‘Book now’ or ‘Book later’"],
            step3: {
              header: ["Step 3", " - Scan card on the device, the RFID code will appear below"],
              placeholder: "Card RFID"
            },
            step4: {
              header: ["Step 4", " - Click ‘Assign RFID’, to assign it to the user"],
              button: "Assign RFID"
            }
          },
          header: "User management",
          free_version_sub_header: ["Currently using the ", "FREE VERSION with a limit of ", " users.", " Contact ", " to upgrade."],
          site_card: {
            header: "Location",
            sub_header: "Select a location to add or remove users for this site."
          },
          create_edit_card: {
            list: {
              header: "Existing users",
              button: "Create new user",
              placeholder: "Search"
            },
            form: {
              header: ["Edit user", "Fill in details"],
              video_header: "How to create and edit a user",
              sub_header: "Select a user in the list to edit it's details.",
              name: {
                label: "Username",
                placeholder: "Name"
              },
              email: {
                label: "Email",
                placeholder: "Email"
              },
              level: {
                label: "User level ",
                placeholder: ""
              },
              group: {
                label: "User group",
                item: "Default"
              },
              site: {
                label: "Home site",
                item: "None"
              },
              resource: {
                label: "Resource",
                placeholder: ""
              },
              rfid: {
                label: "RFID",
                placeholder: "Enter RFID or click on icon on the right"
              },
              image: {
                label: "User image",
                sub_label: ["(square size, ", "less than 4MB)"]
              },
              button: {
                create: " Create user",
                reset_pin: "Reset PIN code",
                reset_password: "Reset user password",
                upload: "Upload image",
                delete: " Delete user",
                save: " Save changes"
              }
            },
            import: {
              header: "Batch upload users",
              cases: [
                "1. Download template",
                "2. Add users for this location to the template",
                "3. Upload the template file again to Tribeloo"
              ],
              button: {
                upload: "Upload file",
                download: "Download template"
              }
            },
            errors: {
              invalid_email: "Required field <email> is invalid.",
              missing_email: "Required field <email> is missing from request.",
              missing_name: "Required field <name> is missing from request.",
              missing_level: "Required field <level> is missing from request.",
            },
            popups: {
              save: 'Save changes?',
              create: 'Create user?',
              created: 'User created.',
              reset_pin: 'The reset was succesful! The user will receive an email with a new PIN code.',
              updated: 'User details have been updated.',
              delete: ' Delete this user?',
              removed: 'User removed.',
              reset_password: 'The reset was succesful! The user will receive an email with a new password.',
              send_pin: 'Send login PIN code for devices ?'
            }
          },
          pin_card: {
            header: "PIN code ",
            video_header: "When and how to use a PIN code",
            toggle: "Send PIN code by email to login on the meeting room and desk devices",
            info: "All new users and existing users that don’t have PIN code yet, will receive one."
          },
          global_settings_card: {
            header: "Global settings",
            info: "These settings are applicable for all sites"
          },
          image_card: {
            header: "Synchronise user images ",
            video_header: "How to synchronise user images of Microsoft 365 with Tribeloo",
            toggle: "Sync user images with Microsoft Office 365",
            info: "User image is synchronised once a user logs into the Tribeloo Outlook add-in"
          },
          subject_card: {
            header: "Hide reservation subject ",
            video_header: "",
            toggle: "Hide reservation subject for colleagues",
            info: 'Reservation subject shown in "who`s around" will be hidden'
          }
        },
        users_table: {
          batch_edit: {
            header: "Edit user details",
            sub_header: "Update",
            items: {
              privileges: "User group",
              level: "User level",
              site: "Home site",
              group: "User group",
              rfid: "Clear RFID for selected users"
            }
          },
          table: {
            toggle: 'Also show users from other sites',
            headers: {
              number: { popup: '', name: "Number" },
              site: { popup: 'Home site', name: "Home site" },
              home_site_privilege: { popup: 'Home site group', name: "Home site group" },
              user: { popup: '', name: "Name of user" },
              user_privilege: { popup: 'User group ', name: "User group in " },
              assigned_resource: { popup: 'Assigned resource in ', name: "Assigned resource in " },
              level: { popup: 'Level of user', name: "Level of user" },
              group: { popup: 'Group', name: "User group" },
              space: { popup: '', name: "Resource" },
              Owned_site: { popup: 'Owned_site', name: "Site of resource" },
              email: { popup: '', name: "User email" },
              rfid: { popup: 'RFID', name: "RFID" },
              override : {popup: 'Group assignment', name: "Group assignment"}
            },
            tooltip: {
              delete: "delete all selected users",
              send_mail: "send message to selected users"
            },
            dialogues: {
              error_message: "Error sending",
              email: 'Compose email for the selected users?',
              selected: "At least one user must be selected.",
              delete_all: ['Delete the ', ' selected users?'],
              delete: 'Delete this user?',
              rfid: "RFID cleared for selected users"
            }
          }
        },
        groups: {
          header: "Users groups",
          toggle_card: {
            header: "Enable user groups ",
            video_header: "How to set up user groups",
            toggle: "User groups are enabled",
            description: ["When ", "enabled", ", you can create user groups and define rules for visible sites or office days for each group.", "Assign a user to a group in user management or user table."]
          },
          creat_edit_card: {
            list: {
              header: "Existing users groups",
              button: "Add new user group",
              placeholder: "search",
              popups: {
                save: "Save changes?",
                create: "New user group is added.",
                update: "Changes saved.",
                delete: {
                  text: "Delete this user group?",
                  descriptions: ['Users from this group will be assigned to the default user group of their home site.']
                }
              }
            },
            form: {
              header: ["Create user group", "Edit user group"],
              select_button: "Select all",
              unselect_button: "Unselect all",
              create: " Create group",
              update: "Save changes",
              delete: " Delete group",
              name: {
                label: "Name ",
                placeholder: "Add name of user group"
              },
              rules: {
                toggle: ["‘Visible sites’ rule enabled", "‘Visible sites’ rule disabled"],
                tooltip: "When visible sites rule is enabled, a user has access to the active sites of his group, and is able to book a resource for these sites. When visible sites rule is disabled, this user group has access to all active sites.",
                placeholder: "Select visible sites",
              },
              days: {
                toggle: ["‘Office days’ rule enabled", "‘Office days’ rule disabled"],
                tooltip: "When office days rule is enabled, a user can make reservation only on the selected office days. When office days rule is disabled, this user group can make reservations on all days.",
                placeholder: "Select office days",
                week_days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
              }
            },
            sites_card: {
              header: "Default user group for each site",
              description_lines: [
                {start: "Users can be assigned to a specific group. In case a user doesn’t have a group assigned,", end: "the user will fall in the default group of his home site as defined below."},
                {start: "In case the user isn’t assigned to a specific group and no default group is defined for his", end: "home site, the user will only have access to his home site to book resources."}
              ],
              label: "No home site ",
              tooltipe: "This is the default group for users without a home site",
              placeholder: "group"
            }
          }
        },
        sso: {
          header: "Single Sign-On",
          description_card: {
            header: "What is it?",
            description: "Single Sign-On gives your users the ability to sign in to different applications using the same unique set of username and password. Users sign in once via the so-called Identity Provider (external to Tribeloo) and can then, from there access different apps (including Tribeloo) without logging in again. For SSO, Tribeloo supports the SAML 2.0 standard."
          },
          email_card: {
            header: "Enable welcome email",
            toggle: "Enable sending welcome email if SSO is enabled"
          },
          settings_card: {
            header: "Settings",
            items: {
              id: {
                name: "Company ID",
                tooltip: {
                  header: "",
                  descriptions: []
                }
              },
              issuer_url: {
                name: "Issuer URL",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: Identity Provider Issuer", "OneLogin: Issuer URL", "Azure: Azure AD Identifier", "Google: Entity ID"]
                }
              },
              login_url: {
                name: "SSO login URL",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: Identity Provider SSO URL", "OneLogin: SAML Endpoint", "Azure: Login URL", "Google: SSO URL"]
                }
              },
              certificate: {
                name: "Certificate",
                tooltip: {
                  header: "Also referenced to as",
                  descriptions: ["Okta: X.509 Certificate", "OneLogin: X.509 Certificate", "Azure: Certificate (Base64 or Raw)", "Google: Certificate 1"]
                }
              },
            },
            button: ["Disable SSO", "Enable SSO"]
          },
          popups: {
            saved: 'Configuration saved.',
            not_saved: 'Configuration not saved.',
            copied: 'Company ID copied.',
            change_sso: {
              text: ['Are you sure you want to disable Single Sign-On?', 'Are you sure you want to enable Single Sign-On?'],
              descriptions: ['All users will need to log in with a password, that they can request through ‘Forgot password?’ on the login page.', 'Users won’t be able to log in with their passwords and will need to log in with their SSO credentials.']
            }
          }
        },
        provisioning: {
          header: "User provisioning",
          description_card: {
            header: "What is it?",
            description: "User Provisioning refers to the automatic synchronization of users: people defined in your Active Directory (AD) will be automatically synchronized with the Users list in Tribeloo. E.g. new people in the AD will be added to the Users list in Tribeloo."
          },
          settings_card: {
            header: "Settings",
            items: [
              {name: "SCIM Base URL", key: "base_url", is_input: true},
              {name: "SCIM JSON Template", key: "json_template", is_input: false},
              {name: "Custom headers", key: "custom_headers", is_input: true},
              {name: "SCIM Bearer token", key: "bearer_token", is_input: false}
            ],
            button: ["Disable User provisioning", "Enable User provisioning"],
            popups: {
              copy: " copied",
              change_SCIM: {
                text: ['Are you sure you want to disable User provisioning?', 'Are you sure you want to enable User provisioning?'],
                description: ['All the current users will be kept, but won’t be updated by your external user provisioning system anymore.', 'All users will be managed externally.']
              }
            }
          }
        }
      },
      insights: {
        navigation: {
          heatmaps: "Heatmaps",
          utilization: "Utilization charts",
          history: "Reservation history",
          download: "Data download",
          statistics: "Statistics",
          tracker: "Time tracking",
          calc: "Calc resource"
        },
        calc: {
          type: "Calc resource",
          subHeader: "This page help you with a data about your price and amount of resources from 1 month to 5 years. Also it help you: if you want to change a count of recources, or move to new place, add or remove new site, wing and etc.",
          chartHeader: "Terms & summ",
          chartDescription: "Use top form to know how much you are spending for your resources"
        },
        heatmaps: {
          type: "Heatmaps",
          subHeader: "Which days and hours are most busy?",
          allText: "Average utilization rate of selected type",
          daysText: "Daily utilization rate of selected type",
          options: {
            average: "Show average utilization rate",
            daily: "Show daily utilization rate"
          },
          tooltipe: 'The average utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period. The daily utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period. For example, daily utilization of 80% and average utilization of 40% means that: 8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours. '
        },
        utilization_charts: {
          type: "Utilization charts",
          subHeader: "Which days and hours are most busy?",
          allText: "Average utilization rate of selected type",
          daysText: "Daily utilization rate of selected type",
          table_header: "Which days are most busy? Select a day to see the utilization by hour below for that day.",
          chart_header: "Which hours are most busy"
        },
        reservation_history: {
          info: 'Changing time range removes all filters',
          overlap: 'Find overlapping reservations with ',
          couter: " more...",
          headers: {
            number: { popup: '', name: "Number" },
            user: { popup: 'Owners', name: "User" },
            visitor: { popup: 'Visitor', name: "Visitor" },
            email: { popup: '', name: "User email" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            wing: { popup: 'Wings', name: "Wing" },
            space: { popup: 'Resources', name: "Resource" },
            no_shows: { popup: 'No-shows', name: "No-shows" },
            subject: { popup: '', name: "Subject" },
            seats: { popup: 'Requested seats', name: "Requested seats" },
            internal_external: { popup: 'internal/external', name: "internal/ external" },
            status: { popup: 'Statuses', name: "Status" },
            messages: { popup: 'Messages', name: "Messages" },
            acknowledged: { popup: 'Acknowledged', name: "Acknowledged" }
          },
          tooltip: {
            acknowledged: "From reminder email: if the person clicked on the 'I agree' button and therefore acknowledges they have read the message",
            send_mail: "send message to all"
          },
          dialogues: {
            error_message: "Error sending"
          }
        },
        time_tracking: {
          check: "events",
          info: 'Changing time range removes all filters',
          info_date: 'Find overlapping reservations with ',
          headers: {
            user: { popup: 'User', name: "User" },
            email: { popup: 'User email', name: "Email" },
            from: { popup: '', name: "From" },
            to: { popup: '', name: "To" },
            zone: { popup: '', name: "Time zone" },
            period: { popup: '', name: "Time period" },
          },
          dialogues: {
            error_message: "Error sending"
          }
        },
        data_download: {
          header: "Data download",
          popup: {
            big_titel: 'Update data retention?',
            big_descriptions: ['Older reservation data will be deleted and no longer accessible'
                              ]},
          retention_card: {
            header: "Data retention",
            toggle: ["Reservations & releases data is automatically deleted after", "days."],
            info: "There is a minimum of 7 days and a maximum of 400 days."
          },
          automade: {
            header: "Daily automated report via email",
            sub_header: "Receive data of the reservations & releases of the previous day",
            check: "Send daily email to",
            placeholder: "Email address",
            error: "Please enter a valid email address"
          },
          download: {
            header: "Download data for a specific time range",
            info: "Data of reservations & releases",
            button: {
              all: " Download all",
              one: " Download data range"
            }
          }
        },
        statistics: {
          header: "Statistics",
          sub_header: "The table shows you a current view of all the sites and their amount of resources and users.",
          card: {
            header: "Resources and users",
            button: "Download",
            table_headers: {
              type: "Resource type",
              resources: "Resources",
              users: "Users"
            }
          }
        },
        reusable_components: {
          main_header: {
            header: "Insights",
            day: " Move timeframe per day ",
            week: " Move timeframe per week "
          },
          scale_utilization_rate: "Scale utilization rate",
          card_table: {
            table_headers: {
              special: "utilization rate",
              average: "Average",
              daily: "Daily"
            },
            description_hightlight: [
              {start:"The ", hightlight: "average", end: " utilization rate is a percentage of the time that the space has been used, within the working hours of the selected time period."},
              {start:"The ", hightlight: "daily", end: " utilization rate is the percentage of days that the desk has been used at least once from all days in the selected time period."}
            ],
            description_example: ["  For example, daily utilization of 80% and average utilization of 40% means that: ", "8 out of 10 desks are being used at least once during a working day and that these 8 desks are on average used during half of the working hours."]
          },
          maps_cards: {
            button: {
              select: "Select",
              show_all: "Show all"
            },
            internal_external: [', Internal only', ', External allowed'],
            owner: "Owner: ",
            usage: "Usage",
            average: "average: ",
            daily: "daily: ",
            days: "days active: ",
          }
        }
      },
      space_issues: {
        navigation: ['Issue management','Cleaning', 'Issues table'],
        list: {
          main_header: 'Select a site',
          main_sub_header: 'Find resource',
          placeholder: "Please search or select a site",
          placeholder_asset: "Please search or select a resource",
          search: 'View all site issues',
          toggle: ['Click to show closed issues','Click to show open issues'],
          couter: [' open resource issues', ' closed resource issues'],
          img_link: 'See photo',
          popup_text: 'E-mail sent to your account with information about the issue. This can be forwarded to somebody that can solve it.',
        },
        table_cleaning: {
          main_header_warning: 'Changing time range removes all filters',
          table_headers: {
            id:'ID',
            data_time: 'Date & time',
            wing: 'Wing',
            resource: 'Resource',
            cleaned_at: 'Cleaned at',
            cleaned_by: 'Cleaned by'
          }
        }
      }
    },
    user: {
      external_events_list: {
        headers: ['Google-evenementen', 'Outlook-evenementen'],
        select: {
          header: 'Georganiseerd door',
          placeholder: 'Selecteer collega'
        },
        info: ['Geen reserveringen beschikbaar uit je agenda', 'Toon alleen evenementen met Tribeloo-reserveringen', 'Geselecteerd evenement']
      },
      find_colleague: {
        email_select: {
          more: "meer...",
        },
        user_select: {
          header: 'Vind een collega',
          placeholder: 'Selecteer collega',
          info: ['Vraag toegang van je collega\'s in ', 'Mijn Stam', ' om hun reserveringen te zien']
        },
        list: {
          count: ' (niet-privé)',
          info: ['Selecteer een collega om hun reserveringen te zien.', 'Klik op reservering om op de kaart te bekijken.']
        },
        details: {
          return_button: 'Terug naar lijst met reserveringen',
          info: 'Selecteer een beschikbaar object op de kaart om een reservering voor jezelf te maken',
          modals: {
            delete_reservation: 'Reservering verwijderd',
            create_reservation: 'Reservering maken?'
          }
        }
      },
      find_room_report: {
        headers: {
          main: 'Meld een probleem voor een bron',
          site: 'Selecteer een locatie',
          resource: 'Vind bron'
        },
        placeholders: {
          site: 'Zoek of selecteer een locatie',
          resource: 'Zoek of selecteer een bron',
          tooltip: 'Bij het melden van een probleem wordt het naar de sitebeheerder gestuurd om op te lossen. Zodra het is opgelost, wordt het uit deze lijst verwijderd.',
          description: ['Selecteer een bron om een probleem te melden', 'Leg hier het probleem uit'],
        },
        loading: "Laden...",
        image_description: "Afbeelding (< 4MB)",
        button: {
          photo: 'Foto toevoegen',
          send: 'Probleem indienen'
        },
        image_info: 'Afbeelding (< 4MB)',
        couter: ' openstaande problemen',
      },
      releases_list: {
        add_button: 'Nieuwe release toevoegen',
        user_select: 'Releases voor',
        colleagues_info: "Je hebt geen collega's met zichtbare releases",
        counter: 'Geen releases',
        right_panel_info: ['Selecteer een collega uit de lijst om hun releases te zien.', "Je hebt geen collega's met zichtbare release."]
      },
      release: {
        pageName: 'release',
        header: 'Releasedetails',
        placeholder: 'Onderwerp',
        buttons: {
          create: ['Je release', 'Bevestig release'],
          edit: ['Deze release annuleren?', 'Annuleer release']
        },
        description: ['Er zijn ', 'geen ', 'actieve reservering(en) voor deze ', ' in deze tijdsperiode.'],
        modals: {
          user: 'Gebruiker wijzigen? Huidige release wordt verwijderd.',
          not_available: "Release niet beschikbaar",
          delete: 'Deze release verwijderen?'
        }
      },
      reservations_list: {
        add_button: 'Nieuwe reservering toevoegen',
        user_select: 'Reserveringen voor',
        tabs: [
          {name: "Persoonlijk", state: false},
          {name: "Bezoeker", state: true}
        ],
        colleagues_info: "Je hebt geen collega's met zichtbare reserveringen",
        counter: 'Geen reserveringen',
        right_panel_info: ['Selecteer een collega uit de lijst om hun reserveringen te zien.', 'Bewerk een reservering of maak een nieuwe om de beschikbare bronnen te zien']
      },
      reservation: {
        pageName: 'reserveringen',
        header_requests: ['Nog geen reservering.', 'Vul reserveringsdetails in en controleer beschikbaarheid.'],
        details: ' details',
        end: 'Beëindig reservering',
        amenities_item: 'andere aanvraag',
        snack_bars: {
          create: 'Reservering gemaakt',
          update: 'Reservering bijgewerkt'
        },
        visitor: {
          text: "Reservering voor bezoeker"
        },
        placeholders: {
          visitor: "E-mailadres",
          amenities: 'Selecteer voorzieningen',
          no_amenities: 'Geen items gevonden',
          subject: 'Onderwerp',
          neighbourhood: "Selecteer buurt"
        },
        public: {
          text: 'Maak privé voor iedereen',
          tooltip: 'Je reservering is altijd zichtbaar voor leden van je Stam. Als je de schakelaar activeert, wordt deze onzichtbaar voor iedereen, inclusief je Stam.'
        },
        right_panel_info: {
          no_checked_availability: ['Vul de details in en controleer', 'beschikbaarheid om een bron te boeken'],
          checked_availability: ['Aanvraagdetails gewijzigd, ', 'controleer beschikbaarheid opnieuw']
        },
        modals: {
          amenity: {
            text: "Je huidige reservering past niet bij alle voorzieningen!",
            descriptions: [
              "Maar geen zorgen, je kunt doorgaan met de huidige reservering.",
            ]
          },
          error_404: "Reservering niet beschikbaar",
          requested_capacity: {
            text: "Je toegewezen ruimte heeft niet genoeg stoelen!",
            descriptions: [
              "Ga door om het nieuwe aantal stoelen te behouden en zoek naar een nieuwe ruimte.",
              "Of annuleer de stoelwijziging en behoud je huidige ruimte.",
            ]
          },
          internal: {
            text: "Je toegewezen ruimte is alleen voor interne vergaderingen!",
            descriptions: [
              "Ga door om de wijziging te behouden en zoek naar een nieuwe ruimte.",
              "Of annuleer de wijziging en behoud je huidige ruimte.",
            ]
          },
          time: {
            text: "Je reservering is niet beschikbaar voor deze tijd!",
            descriptions: [
              "Ga door om de nieuwe tijd te behouden en je reservering wordt bijgewerkt.",
              "Of annuleer de tijdwijziging en behoud je huidige reservering."
            ]
          },
          space: {
            text: "Je toegewezen ruimte is alleen voor interne vergaderingen!",
            descriptions: [
              "Ga door om de wijziging te behouden en zoek naar een nieuwe ruimte.",
              "Of annuleer de wijziging en behoud je huidige ruimte.",
            ]
          },
          invalid_email: "Je moet een geldig e-mailadres van de bezoeker invoeren of de bezoeker-schakelaar uitschakelen om een reservering te maken.",
          delete: {
            simple: {text: 'Deze reservering verwijderen?'},
            ongoing: {text: 'Deze reservering beëindigen?'},
            visitor_change: {
              text: {
                toggle: 'Overschakelen tussen persoonlijke en bezoekersreservering zal de huidige reservering verwijderen!',
                email: "Het wijzigen van het e-mailadres van de bezoeker zal je huidige reservering verwijderen!",
              },
              descriptions: [
                "Ga door om de wijzigingen te behouden en je reservering wordt bijgewerkt.",
                "Of annuleer de wijziging en behoud je huidige reservering."
              ]
            },
            user_change: {
              text: 'Het wijzigen van de gebruiker zal je huidige reservering verwijderen!',
              descriptions: [
                "Ga door om de nieuwe gebruiker te behouden en je reservering wordt bijgewerkt.",
                "Of annuleer de wijziging en behoud je huidige reservering."
              ]
            },
            site_change: {
              text: 'Het wijzigen van de locatie zal je huidige reservering verwijderen!',
              descriptions: [
                "Ga door om de nieuwe locatie te behouden en je reservering wordt bijgewerkt.",
                "Of annuleer de wijziging en behoud je huidige reservering."
              ]
            }
          }
        }
      },
      home: {
        nwesfeed_list: 'Vandaag geen nieuws!',
        pageName: 'home',
        emergency: {
          header: 'NOODBERICHT',
          by: 'door'
        },
        reservation: {
          headers: {
            upcoming: 'Je aankomende reservering',
            current: 'Je huidige reservering',
            no_upcoming: 'Geen aankomende reserveringen'
          },
          descriptions: ['Hier om je te helpen!', 'Maak je keuze met de knoppen hieronder.'],
          loading: 'Laden...'
        },
        newsfeed_header: 'Nieuwsfeed'
      },
      settings: {
        pageName: 'instellingen',
        h1: 'Gebruikersinstellingen',
        password: {
          errors: ["Minstens 1 nummer, 1 symbool en 8 tekens lang", 'Wachtwoorden zijn niet hetzelfde'],
          rules: 'Wachtwoord moet minimaal bevatten: 8 tekens, 1 hoofdletter, 1 kleine letter, 1 cijfer',
          rules_v2: 'Moet minimaal bevatten: 8 tekens, 1 hoofdletter, 1 kleine letter, 1 cijfer',
          placeholders: ['Voer nieuw wachtwoord in', 'Voer wachtwoord opnieuw in'],
          info_ok:"Wachtwoord is gewijzigd",
          info_not_ok: "Het wachtwoord is niet gewijzigd"
        },
        h3_sections: {
          password: 'Wachtwoord wijzigen',
          image: 'Gebruikersafbeelding toevoegen',
          site: 'Standaardlocatie voor reservering',
          wing: 'Standaardvleugel voor reservering',
          time: 'Tijdformaat',
          language: 'Taal',
          preferences: 'Voorkeuren',
          preferences_v2: 'Gebruikersvoorkeuren',
          delegate: 'Gedelegeerde',
          calendar: 'Kalenderuitnodigingen',
          wants_asset_details_popup: 'Kaartinformatie'
        },
        subsections: {
          app: "App-versie",
          sites: "Zichtbare locaties",
          language: "Taal",
          time: "Tijdformaat",
          calendar: "Kalenderuitnodigingen"
        },
        user_image_info: ["Neem contact op met je kantoor manager ", "om de gebruikersafbeelding te wijzigen"],
        user_image: ['Gebruikersafbeelding', '(vierkant formaat, minder dan 4MB)', 'Upload een vierkante afbeelding van minder dan 4MB'],
        toggle: 'Gebruik 24-uurs tijdformaat',
        app_version: "Gebruik de nieuwe Tribeloo (herstart vereist)",
        calendar_toggle: 'Stuur kalenderuitnodigingen voor reserveringen gemaakt in webapplicatie',
        wants_asset_details_popup_toggle: 'Toon detailpopup bij het selecteren van een bron op de kaart',
        no_delegates: 'Geen gedelegeerden',
        delegates_action_list: {
          search: 'Zoeken',
          edit: 'Collega’s die mijn reserveringen kunnen maken of bewerken',
          add: 'Collega’s toevoegen'
        },
        delegates_modal: "Door dit vakje aan te vinken, wordt de onderstaande tabel genegeerd en kan iedereen je openbare vergaderingen zien."
      },
      who_is_arround: {
        header: 'Wie is er in de buurt',
        tooltip: 'Bekijk wie van je Stam op kantoor is op een bepaald moment. Nog geen Stam? Maak er een aan in ‘Mijn Stam’.',
        date_time: {
          main: 'Datum & tijd',
          helper: 'UUR'
        },
        info: 'Selecteer een beschikbare bron op de kaart om een reservering voor jezelf te maken',
        users_list: {
          header: {hide: 'Verberg', show: 'Toon', postfix: 'collega lijst'},
          placeholder: 'Gebruikersnaam'
        },
        right_panel_info: 'Kies een locatie om de bezetting te zien',
        modal: 'Reservering maken?'
      },
      my_tribe: {
        header: 'Mijn Stam',
        tabs: ['Volgers', 'Volgend'],
        info: ['Collega’s die je niet-privé reserveringen kunnen zien', 'Verzoeken om je te volgen', 'Collega’s wiens niet-privé reserveringen je kunt zien'],
        serach_header: 'Zoekresultaten',
        no_results: 'Geen resultaten',
        placeholders: ['Zoeken', 'Zoeken naar collega’s om te volgen'],
        modals: {
          confirm: {
            text: "Wil je deze collega ook volgen?",
            cancel: "Niet volgen",
            confirm: "Volgen"
          },
          delete: {
            text: "Wil je het verzoek van deze collega verwijderen?",
            cancel: "Annuleren",
            confirm: "Verzoek verwijderen"
          },
          remove: {
            text: "Wil je deze collega verwijderen?",
            cancel: "Annuleren",
            confirm: "Verwijderen"
          },
          cancel: {
            text: 'Wil je je verzoek annuleren?',
            cancel: 'Nee',
            confirm: 'Verzoek annuleren'
          },
          unfollow: {
            text: 'Wil je deze collega ontvolgen?',
            description: ['Als je van gedachten verandert, moet je opnieuw verzoeken om te volgen.'],
            cancel: "Annuleren",
            confirm: 'Ontvolgen'
          }
        }
      },
      squads: {
        header: 'Mijn Squads'
      },
      qr: {
        header: 'QR inchecken of walk-in',
        checkout: "Wil je uitchecken?",
        checkedout: "Uitgecheckt",
        photo_description: 'Scan de QR-code om in te checken voor je huidige reservering of om een walk-in reservering te maken',
        start_scan_description: 'Richt op QR-code en raak hier aan',
        errors: {
          camera: "Camera niet ondersteund in Chrome, gebruik Safari",
          app: "Probeer de speciale QR-scanner app te gebruiken!",
          device: "Gebruik een mobiel of tablet met achtercamera!"
        },
        modals: {
          info: 'Toegang tot de camera is nodig om de QR-code te scannen.',
          check_in: {
            header: 'Ingecheckt voor:',
            from_to: ['Van: ',', tot '],
            subject_default: 'Reservering'
          }
        }
      },
      help_center: {
        nav: {
          demo: {
            header: 'Snelstartdemo',
            card: {
              headers: [],
              descriptions: ['Bekijk deze snelle demo om een goed overzicht te krijgen van alle Tribeloo-functies.'],
              links: []
            }
          },
          get_started: {
            header: 'Aan de slag',
            sub_headers: ['Setup video', 'Single-Sign-On', 'Gebruikersprovisionering'],
            card: {
              headers: [],
              descriptions: ['Leer hoe je aan de slag gaat met Tribeloo en doe de eerste setup van het platform.', 'Bekijk de richtlijnen over het installeren van de Single-Sign-On functie', 'Bekijk de richtlijnen over het installeren van de Gebruikersprovisionering functie'],
              links: ['hier.']
            }
          },
          site_manager: {
            header: 'Locatiebeheerder',
            sub_headers: ['Rondleiding video', 'Apparaten instellen'],
            card: {
              headers: ['Apparaten instellen'],
              descriptions: ['Leer hoe je Tribeloo als kantoormanager gebruikt.', 'Leer hoe je de bureau- en vergaderruimte-apparaten configureert.'],
              links: []
            }
          },
          user: {
            header: 'Gebruiker',
            sub_headers: ['Rondleiding video', 'Outlook add-in gebruiken', 'Outlook add-in installeren', 'Chrome-extensie gebruiken', 'Chrome-extensie installeren', 'App installeren op iPhone', 'App installeren op Android'],
            card: {
              mobile: 'Leer hoe je de Tribeloo mobiele app en web boekingsoplossing gebruikt.',
              outlook: {
                headers: ['Hoe de Outlook add-in te gebruiken', 'Hoe de Outlook add-in te installeren'],
                descriptions: ['Leer hoe je de Tribeloo add-in voor Outlook gebruikt.', 'Leer hoe je de Tribeloo add-in voor Outlook installeert.', 'Ga naar de ', 'Microsoft Store', 'om het te downloaden.']
              },
              google: {
                headers: ['Hoe de Chrome-extensie te gebruiken', 'Hoe de Chrome-extensie te installeren'],
                descriptions: ['Leer hoe je de Tribeloo-extensie voor Chrome gebruikt en de integratie met je Google Kalender.', 'Leer hoe je de Tribeloo-extensie voor Chrome installeert, geïntegreerd met je Google kalender.', 'Ga naar de ', 'Chrome Store', 'om het te downloaden.']
              },
              pwa: {
                headers: [],
                descriptions: ['Bekijk de onderstaande video of', 'download de PDF', 'over hoe je de Tribeloo-app op je iPhone installeert', 'over hoe je de Tribeloo-app op je Android installeert']
              }
            }
          },
          feedback: {
            header: 'Neem contact met ons op',
            sub_headers: [],
            card: {
              headers: ["Kun je niet vinden wat je zoekt? Of wil je feedback geven?"],
              descriptions: ['Laat ons je helpen! Deel alstublieft zoveel mogelijk details, zodat we je sneller kunnen helpen.'],
              links: []
            }
          }
        },
        main: {
          header: 'Helpcentrum',
          subheaders: ['Welkom!', 'Vind nuttige informatie over hoe je aan de slag gaat met Tribeloo.', "Je kunt altijd hier terugkomen door op 'Helpcentrum' te klikken op de homepage of in de instellingen."],
          orange_card: ['Klik op de ', ' iconen in de software voor meer informatie over een specifiek onderwerp.']
        }
      }
    }
  },
  shared_components: {
    wings_select: "Alle vleugels",
    activity_select: {},
    assets_map: {
      types_external_internal: [', Alleen intern', ', Extern toegestaan'],
      owner: 'Toegewezen aan: ',
      time_slots: "Beschikbaar: ",
      no_time_slots: "Geen beschikbare tijdslots",
      not_reservable: "Je kunt deze resource niet reserveren"
    },
    attendees_modal: {
      header_text: {
        share: "Deel dit met je collega's'",
        attendees: 'Nemen deel aan dit evenement'
      },
      info: ['Selecteer collega\'s uit de dropdown om hen een e-mail te sturen.', 'Vraag je collega\'s om je eerst aan hun tribe toe te voegen'],
      placeholdrs: {
        colleagues: 'Selecteer collega',
        message: 'Typ je tekst hier'
      },
      button_attendees: ['Ga je toch niet?', 'Ga je?'],
      more: ' meer...',
      send: 'Verstuur',
      send_all: 'Verstuur e-mail naar allen',
      counter: 'deelnemers'
    },
    total_counter_download_table_csv: {
      batch_buton: 'Batchbewerking',
      button: 'Downloaden',
      text: ' weergegeven',
      selected: " geselecteerd",
      info: 'Geen gegevens voor geselecteerde parameters'
    },
    confirm_dialog: {},
    date_time_range_modal: {
      headers: {
        main: 'Stel datum & tijd in',
        from: 'Van',
        to: 'Tot'
      },
      tooltip: 'Klik om de kalender weer te geven',
      info: "'Tot' tijd moet na 'Van' tijd komen"
    },
    date_time_range_view: {
      headers: {from: 'Van', to: 'tot', start: 'Begint', end: 'Eindigt'}
    },
    day_time_mini: {
      header: ["Stel ", " datum & tijd in"],
      tooltip: {
        calendar: "Klik om de kalender weer te geven",
        time: "Klik om de tijd weer te geven"
      }
    },
    download_xlsx: {},
    expanded_filter_assets_list: {
      modal: {
        header: "Resource-informatie",
        report_button: "Meld een probleem"
      },
      header: ' beschikbaarheidslijst',
      description: 'Alleen weergeven met alle voorzieningen',
      nospace: 'Niets beschikbaar',
      types_external_internal: [', intern', ', extern'],
      placeholder: 'Zoeken',
      tooltip: 'Ruimte bevat niet alle gevraagde voorzieningen'
    },
    filter_dialog: {},
    icon_video: "Meer info",
    info_dialog: {},
    kiosk_logout_info_modal: {
      message: "Ben je nog steeds hier aan het werk?",
      info: ["Je sessie eindigt over ", " seconden. "]
    },
    newsfeed_create_edit_item: {
      popup: "Niet-opgeslagen wijzigingen verwerpen?",
      markdown: "Styling met Markdown wordt ondersteund",
      link: "Bekijk richtlijnen",
      image_info: "Afbeelding (< 4MB, grootte 335 x 75 px)",
      radio: ["Type:", "Nieuws", "Evenement"],
      placeholder: {
        event: "Nieuw evenement",
        location: "Locatie",
      }
    },
    newsfeed_item: {
      default_subject: '',
      actions: ['meer', 'sluiten']
    },
    loading_table_spinner: 'gegevens ophalen',
    reservation_header_info: {
      state: ['Reservering in afwachting', 'Toegewezen'],
      info: "Controleer de beschikbaarheid om er een toe te wijzen",
      internal_external: [ ', intern', ', extern']
    },
    reservation_tipe_modal: 'Geen opties om toe te voegen, bewerk bestaande reservering',
    site_dialog: {},
    site_select: {
      placeholder: 'Selecteer site',
      tooltip: 'Klik hier om de site te selecteren',
      options: ['Alle sites', 'Geen thuis-site']
    },
    site_wings_reservation_view_external_data_set: "Klik om naar deze vleugel te gaan",
    table_datetime_range_helper: {},
    time_range_helper: {},
  },
  user_shared_components: {
    capcity_internal_block: {
      placeholder: 'zitplaatsen',
      tooltip: 'Extern evenement: met externe bezoekers. Intern evenement: alleen met interne medewerkers',
      select: ['Enkele ruimte', 'Intern evenement', 'Extern evenement', 'Kamer']
    },
    list_item: {
      internal_external: [', intern', ', extern'],
      info_reservation: ['Reservering in afwachting, je zult binnenkort een toegewezen krijgen.', 'Of controleer de beschikbaarheid om er zelf een te kiezen.'],
      subject: ['Nieuwe vrijgave', 'Nieuwe reservering '],
      status: 'vrijgegeven',
      header: 'Je '
    },
    message_details: {
      toggle: 'Voeg verzoek toe',
      info: ['Verzoek niet behandeld', 'Verzoek behandeld'],
      placeholder: "Laat een verzoek achter voor de kantoorbeheerder als je aanvullende verzoeken hebt"
    },
    weeks_repeater: {
      title: ' Herhaal wekelijks tot',
      weekdaysShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za']
    },
    repeater_modal: {
      header: 'Pas herhaling aan',
      repeat_until: 'Herhalen tot',
      repeat_on: 'Herhalen op'
    }
  },
  reusable_data: {
    time_tracking_popup: {
      big_titel: "Je hebt gewerkt voor ",
      descriptions: ["Wil je je werkdag beëindigen?"]
    },
    tables: {
      from: "Van",
      to: "Tot",
      date_time: "Datum & tijd"
    },
    feedback: {
      header: 'Geef feedback',
      placeholders: ['Selecteer feedbacktype', 'Voeg een beschrijving toe'],
      types: ['fout', 'idee', 'vraag', 'overig'],
      buttons: ['Annuleren', 'Verstuur'],
      issue_text: "Er was een probleem bij het versturen van je feedback. Probeer het later opnieuw. Bedankt!"
    },
    user_navigation: {
      no_actions_nav: {
        home: 'Home',
        my_tribe: 'Mijn Tribe',
        squads: 'Mijn Squads',
        outlook_events: 'Outlook-evenementen',
        google_events: 'Google-evenementen',
        qr: 'QR check-in of walk-in',
        tracker: ["Werkdag starten, ", "Werkdag beëindigen, "],
        reservations: 'Beheer reserveringen',
        releases: 'Beheer vrijgaven',
        who_is_arround: 'Wie is in de buurt',
        find_colleague: 'Zoek een collega',
        report_issue: 'Meld een probleem',
        user_settings: 'Gebruikersinstellingen',
        help_center: 'Helpcentrum'
      },
      actions_nav: {
        book_desk: 'Boek een bureau',
        book_meeting_room: 'Boek een vergaderruimte',
        add_new_release: 'Voeg nieuwe vrijgave toe'
      }
    },
    button: {
      ok: 'OK',
      add: 'Toevoegen',
      show: 'Weergeven',
      hide: 'Verbergen',
      send: 'Verstuur',
      save: 'Opslaan',
      edit: 'Bewerken',
      more: 'Meer',
      close: 'Sluiten',
      email: 'E-mail',
      login: 'Inloggen',
      logout: 'Uitloggen',
      check_in: 'Inchecken',
      submit: 'Verzenden',
      delete: 'Verwijderen',
      select: 'Selecteren',
      cancel: 'Annuleren',
      upload: 'Uploaden',
      remove: 'Verwijderen',
      reopen: 'Heropenen',
      comment: 'Opmerking',
      confirm: 'Bevestigen',
      inverse: 'Omkeren',
      refresh: 'Vernieuwen',
      continue: 'Doorgaan',
      download: 'Downloaden',
      show_all: 'Toon alles',
      checked_in: 'Ingecheckt',
      select_all: 'Selecteer alles',
      batch_edit: 'Batchbewerking',
      upload_image: 'Afbeelding uploaden',
      remove_image: 'Afbeelding verwijderen',
      assign_me_one: 'Wijs er één aan mij toe',
      back_to_dashboard: '< Terug naar Dashboard',
      cancel_reservation: 'Annuleer reservering',
      check_availability: 'Controleer beschikbaarheid',
      retutn_releases_list: 'Terug naar lijst van vrijgaven',
      retutn_reservations_list: 'Terug naar lijst van reserveringen',
      retutn_reservations_releases_events_list: ['Terug naar ', ' evenementen']
    },
    release_reservation_text: {
      outlook: 'Wijzig tijd in Outlook-agenda',
      google: 'Wijzig tijd in Google-agenda'
    }
  },
  v2_shared_components: {
    calc: {
      sum: "Totaal som: ",
      count: "aantal: ",
      price: "prijs: "
    },
    tribeloo_asset_info: {
      user_info: " Aan jou toegewezen",
      user_release_info: "Deze hulpbron is aan jou toegewezen. Boek deze als je deze wilt gebruiken.",
      user_booked_info: "Deze hulpbron is aan jou toegewezen en is altijd beschikbaar voor jou om te gebruiken.",
      invited_users: " uitgenodigde collega's en onderwerp",
      time_slot: "Niet beschikbaar vandaag",
      buttons: {
        invite: 'Collega\'s uitnodigen',
        book: 'Boeking bevestigen',
        update: 'Wijzigingen bevestigen',
        delete: 'Boeking annuleren',
        edit: 'Boeking bewerken'
      }
    },
    tribeloo_asset_list: {
      headers: {
        menu: "Tribeloo tools",
        favorites: "Favorieten"
      },
      filter: {
        search: 'Zoeken',
        all: "Alle",
        users_only: "Alleen gebruikers",
        resources_only: "Alleen hulpbronnen"
      },
      menu_items: {
        colleagues: {
          subtitle: "Vind een collega",
          description: "Eenvoudig samenwerken met je collega's"
        },
        bookings: {
          subtitle: "Mijn Boekingen",
          description: "Een overzicht van al je boekingen"
        },
        releases: {
          subtitle: "Vrijgaven",
          description: "Laat je toegewezen bureau vrij voor anderen om te gebruiken"
        },
        qr: {
          subtitle: "QR Scanner",
          description: "Boek of check-in met behulp van de QR Scanner"
        }
      }
    },
    tribeloo_asset_list_item: {
      check_in: ["Ingecheckt", "Inchecken"]
    },
    tribeloo_asset_release_popper: {
      buttons: {
        create: "Creëer Vrijgave",
        cancel: "Annuleer Vrijgave"
      }
    },
    tribeloo_assets_popper: {
      title: "Beschikbare Hulpbronnen"
    },
    tribeloo_component_filter: {
      title: "Filteropties",
      date_range: ["Datum Bereik", "Van", "Tot"],
      type: "Hulpbron Type",
      location: "Plaats"
    },
    tribeloo_bookings_list: {
      subtitle: ['Vind Collega\'s', 'Mijn Boekingen'],
      description: ['Zoek naar collega\'s om hun boekingen te vinden en samenwerking te optimaliseren', 'Een overzicht van al je boekingen. Beheer al je boekingen op één plek.'],
      search: " zoekresultaten.",
      not_found: "Geen collega's gevonden."
    },
    tribeloo_camera: {
      camera_error_message: "Verleen toegang tot de camera",
      camera_not_found: "Geen camera gevonden."
    },
    tribeloo_date_picker: {
      max_selection_info: "Maximale selectie van meerdere dagen bereikt",
      days_selected_text: "dagen geselecteerd",
      toggle_text_info: ["Selectie van meerdere dagen ", "Selectie van meerdere dagen"]
    },
    tribeloo_download_image: {
      info_dialogue: "Je afbeelding is te groot.\nSelecteer een afbeelding met een grootte van minder dan 4MB."
    },
    tribeloo_edit_invites: {
      title: "Boeking Uitnodiging Bewerken",
      subtitle: "Een e-mail wordt alleen verzonden naar nieuw toegevoegde collega's.",
      buttons: {
        main_invite: "Collega's Uitnodigen",
        send_invite: ['Uitnodiging opnieuw verzenden', 'Uitnodiging versturen']
      },
      placeholder: "Uitnodigingen Bijgewerkt"
    },
    tribeloo_favorites_list_item: {
      other_info: " Actieve Boekingen"
    },
    tribeloo_map_legend: {
      button: "Kaart centreren",
      map_legend_title: "Kaart Legenda",
      map_legend_items: {
        available: "Beschikbaar",
        confirm: "Bevestiging vereist",
        not_for_user: "Niet voor gebruiker",
        unavailable: "Niet beschikbaar"
      }
    },
    tribeloo_qr_scanner: {
      title: "Scan QR-code",
      subtitle: "Houd de QR-code voor de camera om te boeken of in te checken.",
      error_message: "Fout bij scannen van QR-code",
      processing_message: "Scannen en decoderen in uitvoering"
    },
    tribeloo_releases_sidebar: {
      title_resources: "Mijn Hulpbronnen",
      title_releases: "Mijn Vrijgaven",
      subtitle: "Vrijgaven",
      description: "Configureer de tijd, datum en kies de hulpbron die je wilt vrijgeven.",
    },
    tribeloo_type_location_neighbourhood: {
      labels: {
        type: "Hulpbron type",
        site: "Locatie",
        wing: "Vleugel",
        neighbourhoods: "Buurt"
      }
    },
    tribeloo_upload_profile_pic: {
      title: "Afbeelding Uploaden",
      subtitle: "Kies een profielfoto om weer te geven op de kaart",
      info_dialog_component: "Je afbeelding is te groot.\nSelecteer een afbeelding met een grootte van minder dan 4MB.",
      support_text: "Ondersteunt JPG of PNG",
      dd_text: "Sleep en zet afbeelding neer of ",
      browse_text: "blader",
      buttons: {
        save: "Afbeelding Opslaan",
        upload: "Afbeelding Uploaden",
        capture: "Neem foto met webcam",
      },
      img_alt: "Nieuwe profielfoto"
    },
    tribeloo_whoisaround: {
      title: "Wie Is In De Buurt?"
    },
    tribeloo_more_settings: {
      title: "Meer Instellingen",
      labels: {
        seats: "Zitplaatsen",
        amenities: "Voorzieningen",
        visitor: "Uitnodig bezoeker"
      },
      toggles: {
        amenities: "Alleen tonen met alle voorzieningen",
        booking: "Boeking niet zichtbaar voor anderen"
      },
      placeholders: {
        visitor: "Bezoeker E-mail",
        amenities: "Geen voorzieningen geselecteerd"
      },
      info: "Meer instellingen niet beschikbaar"
    },
    tribeloo_time_range: {
      labels: {
        select: "Snelle Selectie",
        start: "Starttijd",
        end: "Eindtijd"
      },
      rangeOptions: {
        fullDay: 'Hele Dag',
        morning: 'Ochtend',
        afternoon: 'Middag',
        customTime: 'Aangepaste Tijd'
      }
    },
    tribeloo_time_picker: {
      tooltip_error: "Ongeldig tijdsformaat",
    },
    tribeloo_single_file_upload: {
      button_save: "Opslaan Geknipte Afbeelding"
    },
    tribeloo_pop_up_confirmation: {
      titles: {
        cancel: "Boeking Annuleren",
        edit: "Boeking Succesvol Bewerkt",
        invite: "Boeking Uitnodiging Bewerken",
        booked: "Boeking Succesvol!",
        options: "Optionele boekingsopties",
        addSubject: "Onderwerp Toevoegen",
      },
      subtitles: {
        edit_booked: "Er wordt een e-mail naar u gestuurd met informatie over uw boeking.",
        invite: "Een e-mail wordt alleen verzonden naar nieuw toegevoegde collega's.",
        old: "Oude Boeking",
        new: "Nieuwe Boeking",
        cancel: "Een geannuleerde boeking kan niet worden teruggedraaid."
      },
      input: ['Uitnodigingen Bijgewerkt', 'Collega\'s Uitgenodigd'],
      subjectInput: {
        saved: 'Onderwerp opgeslagen',
        placeholder: 'Onderwerp naam'
      },
      buttons: {
        confirm: 'Bevestigen',
        main: "Collega's Uitnodigen",
        booKingSubjectInvitations: "Boeking Onderwerp & Uitnodigingen",
        send: "Verstuur Uitnodiging",
        update: "Update Uitnodigingen",
        cancel: "Annuleer boeking",
        nevermind: "Laat maar",
        saveSubject: "Opslaan onderwerp"
      }
    },
    tags_modal: {
      title: "Tags",
      subtitle: "Gebruik tags om eenvoudig te zoeken, te groeperen en andere gebruikers uit te nodigen. Je tags zijn alleen zichtbaar voor jou.",
      small_title: ['Suggesties', 'Alle Tags'],
      colleagues: "collega's",
      placeholder: "Zoek of Voeg Tags Toe",
      add_button: ['Toevoegen', 'Nieuwe Tag Toevoegen'],
      save_button: "Wijzigingen Opslaan"
    },
    tribeloo_base_user_settings: {
      settings: "Gebruikersinstellingen",
      tribe: "Mijn Tribe"
    },
    tribeloo_filter_tribe_members: {
      title: "Filteropties",
      tag: "Tags",
      site: "Thuislocatie"
    },
    tribeloo_my_tribe: {
      description: "Tag collega's om ze gemakkelijk te vinden bij het maken van een reservering en definieer je eigen groepen.",
      search: "Zoeken"
    },
    tribeloo_tribe_member: {
      title: "Tags",
      not_found: "Geen Tags Gevonden",
      edit_button: "Tags Bewerken"
    }
  },
  v2_pages: {
    tribeloo_default_page_holder: {
      messages: {
        update_success: 'Boeking Succesvol Bijgewerkt',
        update_failed: 'Bijwerken van Boekingen Mislukt.',
        cancel_failed: "Annuleren van vrijgave mislukt",
        error408: "Wil je geforceerd boeken?"
      }
    },
    tribeloo_external_invites: {
      info: "Je Uitnodiging is Ongeldig",
      button: "Ga naar huis"
    }
  }
};
