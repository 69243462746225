import {
  Input,
  Output,
  Component,
  EventEmitter
} from '@angular/core';

import * as _ from "lodash";
import * as moment from 'moment';

import { AssetsService } from "./../../services/assets/assets.service";

import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from './../info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { DateTimeRangeModalComponent } from './../date-time-range-modal/date-time-range-modal.component';

@Component({
  selector: 'activity-select',
  templateUrl: './activity-select.component.html',
  styleUrls: ['./activity-select.component.css'],
  providers: [ AssetsService ]
})
export class ActivitySelectComponent {

  dateTimeRange = {
    "from": moment().add(1, 'days').hour(0).minute(0).second(0).toDate(),
    "to":  moment().add(8, 'days').hour(0).minute(0).second(0).toDate()
  };
  wants_24h_time_format: boolean = JSON.parse(localStorage.getItem("currentUser")).user.wants_24h_time_format;
  isShowRange: boolean = false;

  activityID: number = 0;
  activityIDSaved: number = 0;
  activities: {name: string, id: number, color: string, has_popup: boolean, popup: {type: string, title: string, descriptions: string[]}}[] = [];

  @Input() set type(value: string) {
    this.assetsService.getpossibleactivestates({type: value})
    .subscribe(res => {
      if(res.isSuccess) {
        this.activities = res.data;
        this.checkRangeShowing();
      }
    })
  };
  @Input() set activity(value: number) {
    console.log(value)
    if (value != null) {
      this.activityID =  _.cloneDeep(value);
      this.activityIDSaved =  _.cloneDeep(value);
      this.checkRangeShowing();
    }
  }
  @Input() set setDateTimeRange(value) {
    console.log(value)
    if (value.from != undefined && value.to != undefined) {
      this.dateTimeRange =  _.cloneDeep(value);
    }
  }

  @Output() onSelectedActivityChanged = new EventEmitter<any>();
  // @Input() activity: number = 0;
  // activities: {name: string, value:boolean}[] = [
    //   {name: 'Active', value: true},
    //   {name: 'Inactive', value: false}
    // ]
  // @Input() activity: boolean = false;
  // @Output() onSelectedActivityChanged = new EventEmitter<boolean>();

  constructor(
    private dialog: MatDialog,
    private assetsService: AssetsService
  ) { }

  checkRangeShowing() {
    let item = this.activities.find(item => item.id == this.activityID);
    this.isShowRange = item.has_popup && item.popup.type == "confirm_date_range";
  }

  selectedActivityChanged() {
    let item = this.activities.find(item => item.id == this.activityID);
    if(item.has_popup) {
      let popup;
      let data;

      switch (item.popup.type) {
        case "confirm":
          popup = ConfirmDialogComponent;
          data = {
            text: item.popup.title,
            big_descriptions: item.popup.descriptions,
            icon: true
          };
          break;
        case "confirm_date_range":
          popup = DateTimeRangeModalComponent;
          data = {
            minDate: moment().hour(0).minute(0).second(0).toDate(),
            text: item.popup.title,
            descriptions: item.popup.descriptions,
            icon: true,
            range: this.dateTimeRange,
            isDaysOnly: false
          };
          break;
        case "warn":
          popup = InfoDialogComponent;
          data = {
            text: item.popup.title,
            descriptions: item.popup.descriptions,
            icon: true,
            button: "OK"
          };
          break;
      }

      this.dialog.open(popup, { data: data }).afterClosed().subscribe(result => {
        console.log(result)
        if (result) {
          if("confirm_date_range" == item.popup.type) {
            this.dateTimeRange =  _.cloneDeep(result);
          }
          this.onSelectedActivityChanged.emit({is_active: this.activityID, ...this.dateTimeRange})
        } else {
          this.activityID = this.activityIDSaved;
        }
        this.checkRangeShowing();
      });
    } else {
      this.checkRangeShowing();
      this.onSelectedActivityChanged.emit({is_active: this.activityID, ...this.dateTimeRange})
    }
  }

}
