import {
  Component,
  OnDestroy,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as _ from "lodash";
import { LocalizationService } from "app/services/localization/localization.service";

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.css']
})
export class FilterDialogComponent implements OnDestroy {

  items: any = [];
  name : any = '';
  selectedAll: boolean = true;
  isApply: boolean = false;
  isChange: boolean = true;
  isClose: boolean = false;
  
  button: any;

  constructor (
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localizationService: LocalizationService
  ) {
    if(this.localizationService.getReusableDataText('button').isSuccess) {
      this.button = this.localizationService.getReusableDataText('button').data;
    }
    this.items = _.cloneDeep(this.data.items);
    this.data.items = _.orderBy(this.data.items, ['name'],['asc']);
    this.items = _.orderBy(this.items, ['name'],['asc']);
    this.name = this.data.name;
    this.selectedAll = this.items.every(item => item.isChecked == true);
  }

  selectOrUnselectAll(flag: boolean) {
    this.selectedAll = flag;

    this.items.forEach(item => {
      return item.isChecked = flag;
    });
    this.isChange = _.isEqual(this.data.items.sort(), this.items.sort());
  }

  checkChanges() {
    this.selectedAll = this.items.every(item => item.isChecked == true);
    this.isChange = _.isEqual(this.data.items.sort(), this.items.sort());
  }

  inverceSelect() {
    this.items.forEach(item => {
      return item.isChecked = !item.isChecked;
    });
    this.checkChanges();
  }

  itemClick(index) {
    this.items[index].isChecked = !this.items[index].isChecked;
    this.checkChanges();
  }

  apply() {
    this.isApply = true;
    console.log(this.items);
    this.dialogRef.close({status:true, items: this.items});
  }

  close() {
    console.log(this.items);
    this.dialogRef.close({status:false, items: []});
    this.isClose = true;
  }

  ngOnDestroy() {
    if (!this.isApply && !this.isClose) {
      this.close();
    }
  }

}
