import {
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { LocalizationService } from "./../../services/localization/localization.service";

@Component({
  selector: 'asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.css']
})
export class AssetDetailsComponent {

  @ViewChild('myPinchIMG') myPinchIMG;

  @Input() asset: any;
  @Input() isShowSlotsInfo: boolean = false;
  @Input() disabledZoom: boolean = false;
  @Input() isPopup: boolean = false;
  @Input() idHoverAsset: Number = -1;
  @Input() showAssetsWithoutAllAmenities : boolean = true;

  isRelative: boolean = environment.image_url_type != 'absolute';
  backend_url: string = environment.backend_url;
  has_internal_external_rooms: boolean = JSON.parse(localStorage.getItem("currentUser")).user.features.has_internal_external_rooms ? JSON.parse(localStorage.getItem("currentUser")).user.features.has_internal_external_rooms : false;
  has_neighbourhoods: boolean = JSON.parse(localStorage.getItem("currentUser")).user.features.has_neighbourhoods ? JSON.parse(localStorage.getItem("currentUser")).user.features.has_neighbourhoods : false;

  languages: any;

  constructor(private localizationService: LocalizationService) {
    if(this.localizationService.getComponentText('shared_components','assets_map').isSuccess) {
      this.languages = this.localizationService.getComponentText('shared_components','assets_map').data;
    }
  }

  toggleZoom() {
    this.myPinchIMG.toggleZoom();
  }

}
