<div class="todo-list-item todo-list-item-no-hover" *ngIf="reservationObj" [ngClass]="{
  'confirmedReservation' : reservationObj.status=='confirmed' || reservationObj.status == 'checked in',
  'toConfirmReservation' : reservationObj.status=='to confirm',
  'toAassignReservation' : reservationObj.status=='to assign'
}" (click)="reservationSelected(item)">
  <div class="" fxLayout="row" fxLayoutAlign="space-between center">
    <!-- <h4 class="reservation-header">{{reservationObj.asset_id == null ? 'Reservation pending' : reservationObj.asset_capacity == 1 ? 'Assigned' : 'Assigned'}} <i *ngIf="reservationObj.asset_id != null" class="fa fa-times-circle" (click)="unassignAsset()"></i></h4> -->
    <h4 class="reservation-header">{{reservationObj.asset_id == null ? languages.state[0] : languages.state[1]}} <i *ngIf="reservationObj.asset_id != null" class="fa fa-times-circle" (click)="unassignAsset()"></i></h4>
    <p class="todo-list-item-status" [ngClass]="{
      'confirmedStatus' : reservationObj.status=='confirmed' || reservationObj.status == 'checked in',
      'toConfirmStatus' : reservationObj.status=='to confirm',
      'toAassignStatus' : reservationObj.status=='to assign'
    }">{{reservationObj.status}}</p>
  </div>

  <div class="todo-list-item-info-container" *ngIf="reservationObj.status == 'to assign'">
    <p>{{languages.info}}</p>
  </div>
  <div class="todo-list-item-info-container" *ngIf="reservationObj.status != 'to assign'">
    <div class="todo-list-item-info" fxLayout="row" fxLayoutAlign="space-between start">
      <p>{{reservationObj.asset_name}}</p>

      <p *ngIf="reservationObj.site_type_icon == null"><i class="fa fa-user"></i> {{reservationObj.asset_capacity}}{{reservationObj.asset_capacity == 1 ? '': reservationObj.asset_internal ? languages.internal_external[0] : languages.internal_external[1]}}</p>
      <p *ngIf="reservationObj.site_type_icon != null"><i class="{{reservationObj.site_type_icon}}"></i></p>
    </div>
    <p>{{reservationObj.requested_site_name}}{{reservationObj.wing_name != null ? ', ' + reservationObj.wing_name : ''}}{{reservationObj.neighbourhood_name && has_neighbourhoods ? ", " : ""}}{{has_neighbourhoods ? reservationObj.neighbourhood_name : ''}}</p>

    <div class="amenities-container" fxLayout="row" fxLayoutAlign="space-between center">
      <p>{{reservationObj.asset_amenities}}</p>
      <i class="fa fa-warning" *ngIf="!reservationObj.has_requested_amenities" matTooltip="Space doesn't contain all the requested amenities" matTooltipPosition="below"></i>
    </div>
  </div>
  <div class="todo-list-item-info-container" *ngIf="reservationObj.status != 'to assign'">
    <p>{{reservationObj.available_until}}</p>
  </div>
</div>
